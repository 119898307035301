import { useContext } from 'react';

import { useQuery, useMutation, useQueryClient } from 'react-query';

import { ContextSettings } from 'context/context-settings';
import { IDiagnostic, IDiagnosticStatus, IQueryParams } from 'interfaces';
import {
  getDiagnostics,
  getDiagnosticFilterDictionaries,
  getDiagnosticById,
  getDiagnostListById,
  addDiagnostic,
  updateDiagnostic,
  validateDiagnosticMeasurments,
  processingMeasureData,
  IProccessUploadMeasuringPayload,
  downloadDiagnosticRoute,
  downloadDiagnosticReport,
  createDiagnosticReport,
  deleteDiagnosticById,
  toggleDiagnosticArchiveFlag,
  assignDiagnosticToDiagnostById,
  updateIsCheckedStatus,
  getDiagnosticReportParamsById,
} from 'services/diagnostic';
import { getMachineHeadById } from 'services/machines';
import {
  DIAGNOSTICS_KEY,
  DIAGNOSTICS_FILTERS_DICTIONARIES,
  DIAGNOSTICS_KEY_ARCHIVE,
  DIAGNOSTICS_BY_ID_KEY,
  GET_DIAGNOST_LIST_BY_ID_KEY,
  MACHINE_HEAD_BY_ID_KEY,
  CREATE_NEW,
  CREATE_NEW_AND_COPY_PARAMS_FROM_OLD,
  ERROR_MESSAGE_SPLITTER,
  DIAGNOSTIC_MEASURE_UPLOAD,
  DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY,
} from 'stream-constants';
import { cleanQueryParams } from 'utils/clean-query-params';
import { errorMessageConvert } from 'utils/error-message-convert';

const TIME_5_MINUTES = 5 * 60 * 1000;

export function useGetDiagnostics(params: IQueryParams) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const paramsWithoutScroll = cleanQueryParams({ ...params });
  delete paramsWithoutScroll.scrollTop;

  const query = useQuery(
    [DIAGNOSTICS_KEY, paramsWithoutScroll],
    () => getDiagnostics(paramsWithoutScroll),
    {
      enabled: !notificationMessage,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}

export function useGetDiagnosticFilterDictionaries(
  params: IQueryParams,
  enabledFlag: boolean,
  onSuccess: () => void,
) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const paramsWithoutScroll = cleanQueryParams({ ...params });
  delete paramsWithoutScroll.scrollTop;

  const allFilterMap = new Map((params?.filters ?? []).map((item) => [item.id, item.value]));
  const keyParams = ['creatorFullName', 'factoryTown', 'machineLinkName'].map(
    (name) => allFilterMap.get(name) ?? '',
  );

  keyParams.push(params.searchString ?? '');
  keyParams.unshift(DIAGNOSTICS_FILTERS_DICTIONARIES);

  const query = useQuery(
    keyParams,
    async () => getDiagnosticFilterDictionaries(paramsWithoutScroll),
    {
      staleTime: TIME_5_MINUTES,
      enabled: enabledFlag && !notificationMessage,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
      onSuccess: onSuccess,
    },
  );

  return query;
}

export function useMutationCreateDiagnostic(handleOnSuccess: (newData: any) => void) {
  const queryClient = useQueryClient();
  const { setNotificationMessage, setLastModifiedDiagnosticId } = useContext(ContextSettings);
  const mutationCreate = useMutation((item: IDiagnostic) => addDiagnostic(item), {
    onSuccess: async (result: { id: string }) => {
      const { id } = result;
      await Promise.all([
        queryClient.invalidateQueries(DIAGNOSTICS_FILTERS_DICTIONARIES),
        queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
        queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, id]),
        queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]),
        queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD]),
      ]);
      setLastModifiedDiagnosticId(id);
      handleOnSuccess(result);
    },
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return mutationCreate;
}

export function useMutationUpdateDiagnosticBaseParams(id: string, handleOnSuccess: () => void) {
  const queryClient = useQueryClient();
  const { setNotificationMessage, setLastModifiedDiagnosticId } = useContext(ContextSettings);
  const mutationUpdate = useMutation((item: IDiagnostic) => updateDiagnostic(item), {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(DIAGNOSTICS_FILTERS_DICTIONARIES),
        queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
        queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, id]),
        queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]),
        queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD, id]),
      ]);
      setLastModifiedDiagnosticId(id);
      handleOnSuccess();
    },
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return mutationUpdate;
}

export function useAssignDiagnosticToDiagnostById(id: string, handleOnSuccess: () => void) {
  const queryClient = useQueryClient();
  const { setNotificationMessage, setLastModifiedDiagnosticId } = useContext(ContextSettings);
  const mutationCreate = useMutation(
    (userId: string) => assignDiagnosticToDiagnostById(id, userId),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(DIAGNOSTICS_FILTERS_DICTIONARIES),
          queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, id]),
          queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]),
          queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD, id]),
        ]);
        setLastModifiedDiagnosticId(id);
        handleOnSuccess();
      },
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return mutationCreate;
}

const DATA_CREATED = {
  id: CREATE_NEW,
  status: IDiagnosticStatus.CREATED,
  description: '',
  machineLinkId: '',
  machineLinkName: '',
  // machineLinkId: '1',
  // machineLinkName: 'Тест коробки скоростей',
  factoryTown: '',
  machineInventoryNumber: '',
} as unknown as IDiagnostic;

export function useGetDiagnosticById(id: string, data?: any) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const query = useQuery(
    [DIAGNOSTICS_BY_ID_KEY, id],
    () =>
      id === CREATE_NEW || id === CREATE_NEW_AND_COPY_PARAMS_FROM_OLD
        ? Promise.resolve({ ...DATA_CREATED, ...data })
        : getDiagnosticById(id),
    {
      enabled: !notificationMessage,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}
export function useGetDiagnostListById(id: string | undefined) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const query = useQuery(
    [GET_DIAGNOST_LIST_BY_ID_KEY, id],
    () => getDiagnostListById(id ?? 'undefined'),
    {
      staleTime: 60 * TIME_5_MINUTES,
      enabled:
        !notificationMessage &&
        id !== undefined &&
        id !== CREATE_NEW &&
        id !== CREATE_NEW_AND_COPY_PARAMS_FROM_OLD,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}

export function useGetMachineHeadById(id?: string) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);

  const query = useQuery(
    [MACHINE_HEAD_BY_ID_KEY, id],
    () => (id ? getMachineHeadById(id) : Promise.resolve({} as any)),
    {
      enabled: !notificationMessage,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}

export function useMutationDownloadDiagnosticRoute() {
  const queryClient = useQueryClient();
  const { setNotificationMessage } = useContext(ContextSettings);
  const mutationDownloadRoute = useMutation((item: IDiagnostic) => downloadDiagnosticRoute(item), {
    onSuccess: async (result: { id: string }) => {
      await Promise.all([
        queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
        queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, result.id]),
        queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]),
      ]);
    },
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return mutationDownloadRoute;
}

export function useMutationCreateDiagnosticReport({
  diagnosticId,
  reportParams,
  handleOnSuccess,
}: {
  diagnosticId: string;
  reportParams: any;
  handleOnSuccess: (d: any) => void;
}) {
  const queryClient = useQueryClient();
  const { setNotificationMessage } = useContext(ContextSettings);
  const mutationDownloadRoute = useMutation(
    () => createDiagnosticReport(diagnosticId, reportParams),
    {
      onSuccess: async (result: { id: string }) => {
        await Promise.all([
          queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY]),
          queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD]),
          // queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]), // не включать! инвалидация делается в reFetch()
        ]);
        handleOnSuccess(result);
      },
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return mutationDownloadRoute;
}

export function useMutationDownloadDiagnosticReport(diagnosticId: string) {
  const queryClient = useQueryClient();
  const { setNotificationMessage } = useContext(ContextSettings);
  const mutationDownloadRoute = useMutation(() => downloadDiagnosticReport(diagnosticId), {
    onSuccess: async (result: { id: string }) => {
      await Promise.all([
        queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
        queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, result.id]),
        queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD, diagnosticId]),
        queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]),
      ]);
    },
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return mutationDownloadRoute;
}

export function useMutationValidateDiagnosticMeasurments(handleOnSuccess: (newData: any) => void) {
  const { setNotificationMessage } = useContext(ContextSettings);
  const queryClient = useQueryClient();

  const mutationValidate = useMutation(
    (ids: { diagnosticId: string; archiveId: string }) => validateDiagnosticMeasurments(ids),
    {
      onSuccess: async (result) => {
        handleOnSuccess(result);
        await Promise.all([
          queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, result.id]),
          queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD]),
          queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]),
        ]);
      },
      onError: (error: Error) => {
        const errMessage = error.message?.split(ERROR_MESSAGE_SPLITTER);
        setNotificationMessage({
          header: `Сообщение от сервера:\n${errMessage[0] ?? ''}.`,
          fullMessage: '',
        });
      },
    },
  );

  return mutationValidate;
}

export function useMutationProccessUploadMeasuring(handleOnSuccess: (newData: any) => void) {
  const queryClient = useQueryClient();
  const { setNotificationMessage } = useContext(ContextSettings);
  const mutationValidate = useMutation(
    ({
      payload,
      diagnosticId,
      archiveId,
    }: {
      payload: IProccessUploadMeasuringPayload;
      diagnosticId: string | undefined;
      archiveId: string | undefined;
    }) =>
      processingMeasureData({
        diagnosticId,
        archiveId,
        payload,
      }),
    {
      onSuccess: async (result) => {
        await Promise.all([
          queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, result.id]),
          queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD]),
          queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]),
        ]);
        handleOnSuccess(result);
      },
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return mutationValidate;
}

export function useMutationDeleteDiagnosticByid(id: string, handleOnSuccess: () => void) {
  const queryClient = useQueryClient();
  const { setNotificationMessage } = useContext(ContextSettings);
  const mutationCreate = useMutation(() => deleteDiagnosticById(id), {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(DIAGNOSTICS_FILTERS_DICTIONARIES),
        queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
        queryClient.invalidateQueries(DIAGNOSTICS_KEY_ARCHIVE),
        queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD]),
        queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]),
      ]);
      handleOnSuccess();
    },
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return mutationCreate;
}

export function useMutationToggleDiagnosticArchiveFlag(id: string, handleOnSuccess: () => void) {
  const queryClient = useQueryClient();
  const { setNotificationMessage } = useContext(ContextSettings);
  const mutationCreate = useMutation(
    (archive: boolean) => toggleDiagnosticArchiveFlag({ diagnosticId: id, archive }),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(DIAGNOSTICS_FILTERS_DICTIONARIES),
          queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
          queryClient.invalidateQueries(DIAGNOSTICS_KEY_ARCHIVE),
          queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, id]),
          queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD]),
          queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]),
        ]);
        handleOnSuccess();
      },
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return mutationCreate;
}

export function useUpdateIsCheckedStatus(id: string, handleOnSuccess: () => void) {
  const queryClient = useQueryClient();
  const { setNotificationMessage } = useContext(ContextSettings);
  const mutationCreate = useMutation(
    (status: boolean) =>
      updateIsCheckedStatus({
        diagnosticId: id,
        status,
      }),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, id]),
          queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD, id]),
          queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]),
        ]);
        handleOnSuccess();
      },
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return mutationCreate;
}

export function useGetDiagnosticReportParamsById(id: string, data?: any) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const query = useQuery(
    [DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY, id],
    () => getDiagnosticReportParamsById(id),
    {
      staleTime: TIME_5_MINUTES * 20,
      enabled: !notificationMessage,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}
