import { Route, Switch, useParams, useLocation } from 'react-router-dom';

import ErrorBoundary from 'components/error-boundary';
import GlobalErrorNotificationBar from 'components/global-error-notification-bar';
import { GlobalSpinner } from 'components/global-spinner';
import { AboutProject } from 'pages-diagnostic/about-project';
import { DiagnosticsCards } from 'pages-diagnostic/diagnostic-cards';
import { DiagnosticTopBar } from 'pages-diagnostic/diagnostic-layout/top-bar';
import { CreateDiagnostic } from 'pages-diagnostic/diagnostic-page/create-diagnostic';
import { DownloadReport } from 'pages-diagnostic/diagnostic-page/download-report/download-report';
import { ManageDiagnostic } from 'pages-diagnostic/diagnostic-page/manage-diagnostic';
import { SpectrumLayout } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-charts';
import { BearingParamsCalculatorPage } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-charts/bearing-params-calculator-page';
import { DiagnosticSpectrumList } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-list';
import { DiagnosticSummaryList } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-summary/diagnostic-summary-list';
import { DiganosticSummaryElement } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-summary/diganostic-summary-element';
import { UploadMeasureFilesFromFolder } from 'pages-diagnostic/upload-measure-files-from-folder';
import { Glossarium } from 'pages/glossarium';
import {
  DIAGNOSTICS_KEY,
  DIAGNOSTIC_SPECTRUM,
  DIAGNOSTIC_SPECTRUM_BEARING_PARAMS_CALCULATOR,
  DIAGNOSTIC_SPECTRUM_SUMMARY_ITEM,
  DIAGNOSTIC_SPECTRUM_SUMMARY_LIST,
  DIAGNOSTIC_MEASURE_UPLOAD,
  DIAGNOSTIC_DOWNLOAD_REPORT,
  CREATE_NEW,
} from 'stream-constants';
import { useGetFullName } from 'utils/hooks/use-get-full-name';

export function DiagnosticRoutes() {
  const params = useParams<{ id: string }>();
  const location = useLocation();

  const query = useGetFullName();
  const { fullname = '', redirectUrl = '' } = query?.data ?? {};

  return (
    <ErrorBoundary>
      <DiagnosticTopBar isAuthorized={!!fullname} />
      <GlobalSpinner />
      <GlobalErrorNotificationBar />
      <Switch>
        {!fullname && (
          <Route>
            {location.pathname === '/' ? (
              <AboutProject key="about" redirectUrl={redirectUrl} />
            ) : (
              <div key="empty" />
            )}
          </Route>
        )}
        <Route exact path={`/${DIAGNOSTICS_KEY}/${CREATE_NEW}`}>
          <CreateDiagnostic key={`/${DIAGNOSTICS_KEY}/${CREATE_NEW}`} />
        </Route>
        <Route exact path={`/${DIAGNOSTICS_KEY}/:id`}>
          <ManageDiagnostic key={`/${DIAGNOSTICS_KEY}/${params.id ?? 'unknown'}`} />
        </Route>
        <Route exact path={`/${DIAGNOSTIC_SPECTRUM}/:id`}>
          <DiagnosticSpectrumList key={`/${DIAGNOSTIC_SPECTRUM}/:id`} />
        </Route>
        <Route exact path={`/${DIAGNOSTIC_SPECTRUM}/:id/:elementGuideId?/:defectId?`}>
          <SpectrumLayout key={`/${DIAGNOSTIC_SPECTRUM}/:id/:elementGuideId?/:defectId?`} />
        </Route>
        <Route
          exact
          path={`/${DIAGNOSTIC_SPECTRUM_BEARING_PARAMS_CALCULATOR}/:id/:elementGuideId?/:defectId?`}
        >
          <BearingParamsCalculatorPage
            key={`/${DIAGNOSTIC_SPECTRUM_BEARING_PARAMS_CALCULATOR}/:id/:elementGuideId?/:defectId?`}
          />
        </Route>
        <Route exact path={`/${DIAGNOSTIC_SPECTRUM_SUMMARY_LIST}/:id`}>
          <DiagnosticSummaryList key={`/${DIAGNOSTIC_SPECTRUM_SUMMARY_LIST}/:id`} />
        </Route>
        <Route exact path={`/${DIAGNOSTIC_SPECTRUM_SUMMARY_ITEM}/:id/:elementGuideId`}>
          <DiganosticSummaryElement
            key={`/${DIAGNOSTIC_SPECTRUM_SUMMARY_ITEM}/:id/:elementGuideId`}
          />
        </Route>
        <Route exact path={`/${DIAGNOSTIC_MEASURE_UPLOAD}/:id/`}>
          <UploadMeasureFilesFromFolder key={`/${DIAGNOSTIC_MEASURE_UPLOAD}/:id`} />
        </Route>
        <Route exact path={`/${DIAGNOSTIC_DOWNLOAD_REPORT}/:id/`}>
          <DownloadReport key={`/${DIAGNOSTIC_DOWNLOAD_REPORT}/:id`} />
        </Route>
        <Route exact path={`/${DIAGNOSTICS_KEY}`}>
          <DiagnosticsCards key={DIAGNOSTICS_KEY} />
        </Route>
        <Route exact path="/glossarium">
          <Glossarium />
        </Route>
        <Route>
          <AboutProject key="about-222" redirectUrl={''} />
        </Route>
      </Switch>

      {/* <FooterGrid /> */}
    </ErrorBoundary>
  );
}
