import { IQueryParams } from 'interfaces';

type IEntry = [key: keyof IQueryParams, value: any];

export const cleanQueryParams = (params: IQueryParams) => {
  const newQueryParams = { ...params };
  Object.entries(newQueryParams).forEach((entry) => {
    const [key, entryValue] = entry as IEntry;
    if (entryValue === undefined || entryValue === null || entryValue === '' || entryValue === 0) {
      delete newQueryParams[key];
    }
    if (key === 'filters') {
      const newValue = (entryValue ?? []).filter(
        ({ value }: { value: string | number | null | undefined }) =>
          (value === undefined ||
            value === null ||
            value === '' ||
            value === 0 ||
            (Array.isArray(value) && value[0] === '' && value[1] === '')) === false,
      );
      if (newValue.length === 0) {
        delete newQueryParams[key];
      }
    }
  });

  return newQueryParams;
};
