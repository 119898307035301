import { useContext, useMemo, useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { CustomBreadcrumbs } from 'components/breadcrums';
import ConfirmationDialog, { IConfirmationDialog } from 'components/confirmation-dialog';
import { UniversalModalDialog } from 'components/universal-modal-dialog';
import { ContextSettings } from 'context/context-settings';
import { IScreenSize, IDiagnostic, IDiagnosticStatus } from 'interfaces';
import { useGetDiagnosticById } from 'pages-diagnostic/hooks';
import { MAX_MEASURING_CSV_FILE_SIZE, DIAGNOSTICS_KEY, RED_COLOR, NOOP } from 'stream-constants';
import { delay } from 'utils/delay';
import { errorMessageConvert } from 'utils/error-message-convert';

import {
  IFolder,
  IStatus,
  IFilters,
  sortFunction,
  getFileMeta,
  fileSizeToText,
  EMPTY_DIRECTORY,
  IMainUploadDirectory,
} from './folder-constants';
import {
  useGetFolderListById,
  useMutateFolderListById,
  useMutateUploadCsv,
  fetchMeasureUpload,
  useMutateDeleteCsvFile,
  useMutateFinishUpload,
} from './hooks';
import { UploadMeasureTable } from './upload-measure-table';
import { FIELDS, speedValidationSchema } from './validation-schema';

const COLUMN_GAP = 20;
const FIRST_TWO_COLUMNS = 0.5;
const HEIGHT_DIF = 265;

const useStyles = makeStyles<Theme, IScreenSize>((theme) =>
  createStyles({
    header: {
      fontWeight: 500,
      fontSize: '24px',
      color: '#1D2023',
      lineHeight: '28px',
      marginTop: '16px',
    },
    mainFolderContainer: {
      margin: '16px 0 16px 0',
      display: 'flex',
      alignItems: 'center',
    },
    mainSubFolderContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      maxWidth: (props) => props.width * FIRST_TWO_COLUMNS - COLUMN_GAP - 10,
      minWidth: (props) => props.width * FIRST_TWO_COLUMNS - COLUMN_GAP - 10,
    },
    columnWidthLarge: {
      marginRight: COLUMN_GAP,
      minWidth: (props) => (props.width * FIRST_TWO_COLUMNS) / 2 - COLUMN_GAP - 10,
      maxWidth: (props) => (props.width * FIRST_TWO_COLUMNS) / 2 - COLUMN_GAP - 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    columnWidthSmall: {
      marginRight: COLUMN_GAP,
      minWidth: (props) => props.width / 6 - COLUMN_GAP - 10,
      maxWidth: (props) => props.width / 6 - COLUMN_GAP - 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    columnWidthSmall2: {
      marginRight: COLUMN_GAP,
      minWidth: (props) => props.width / 6 - COLUMN_GAP - 40,
      maxWidth: (props) => props.width / 6 - COLUMN_GAP - 40,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    saveSpeeds: {
      marginLeft: COLUMN_GAP + 10,
      minWidth: (props) => props.width / 6 - COLUMN_GAP - 4,
      maxWidth: (props) => props.width / 6 - COLUMN_GAP - 4,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    readyFilesColor: {
      backgroundColor: '#E1F3FE',
      border: '1px solid #007CFF',
    },
    readyFilesColorSelected: {
      color: '#fff',
      backgroundColor: '#007CFF',
    },

    doneFilesColor: {
      backgroundColor: '#E8FAEB',
      border: '1px solid #26CD58',
    },
    doneFilesColorSelected: {
      color: '#fff',
      backgroundColor: '#26CD58',
    },

    errorFilesColor: {
      backgroundColor: '#FBE9E7',
      border: '1px solid #F95721',
    },
    errorFilesColorSelected: {
      color: '#fff',
      backgroundColor: '#F95721',
    },

    modalButtonsGroup: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 20,
    },
    breadCrumbsMargin: {
      marginTop: 28,
    },
  }),
);
const getIdByString = (str: string | null | undefined) => {
  const aaa = str ? window.btoa(encodeURIComponent(str)) : `${Math.floor(Math.random() % 1000000)}`;

  return `rowId_${aaa}`;
};

export function UploadMeasureFilesFromFolder() {
  const {
    layoutSize,
    setNotificationMessage,
    folderListManagerData,
    setFolderListManagerData,
    mainUploadDirectoryByDiagnosticId,
    setMainUploadDirectoryByDiagnosticId,
    globalFileList,
    setGlobalFileList,
  } = useContext(ContextSettings);
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const diagnosticId = params.id;

  const classes = useStyles(layoutSize);

  const diagnosticQuery = useGetDiagnosticById(diagnosticId) as { data: IDiagnostic | undefined };
  const description = diagnosticQuery.data?.description;
  const measurementsAreUploaded = diagnosticQuery.data?.status !== IDiagnosticStatus.CREATED;

  const { mainDirectoryHandle, fileSystemDict, mainDirectoryInfo } =
    mainUploadDirectoryByDiagnosticId?.[diagnosticId] ?? EMPTY_DIRECTORY;

  const setMainUploadDirectory = (a: IMainUploadDirectory | undefined) => {
    const newMainUploadDirectoryByDiagnosticId = { ...mainUploadDirectoryByDiagnosticId };
    newMainUploadDirectoryByDiagnosticId[diagnosticId] = a;
    setMainUploadDirectoryByDiagnosticId(newMainUploadDirectoryByDiagnosticId);
  };

  const [confimationDialog, setConfimationDialog] = useState<IConfirmationDialog | undefined>();

  const [validationFilter, setValidationFilter] = useState<IFilters | undefined>();

  const [fileSystemValidationModalDialogFlag, setFileSystemValidationModalDialogFlag] =
    useState<boolean>(false);

  const mutateFolderList = useMutateFolderListById(diagnosticId, NOOP);
  const mutateUploadCsv = useMutateUploadCsv(diagnosticId, NOOP);
  const mutateDeleteCsvFile = useMutateDeleteCsvFile(diagnosticId, NOOP);
  const mutateFinishUpload = useMutateFinishUpload(diagnosticId, NOOP);

  const folderListQuery = useGetFolderListById(diagnosticId) as { data: IFolder[] | undefined };

  const breadcrubms = useMemo(
    () => [
      {
        label: `Диагностика \u00AB${description}\u00BB`,
        handleOnClick: () => history.push(`/${DIAGNOSTICS_KEY}/${diagnosticId}`),
      },
      {
        label: 'Пофайловая загрузка измерений',
        handleOnClick: NOOP,
      },
    ],
    [description, diagnosticId, history],
  );

  const form = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(speedValidationSchema),
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = form;

  console.log('%c form errors = ', 'color: red', errors);

  async function setMainDirectoryFunction(directoryHandle: FileSystemDirectoryHandle | undefined) {
    let info = '';
    const fileSystemDict000: Map<string, IFolder> = new Map();

    let size = 0;
    let foldersCount = 0;
    let filesCount = 0;

    if (directoryHandle) {
      for await (const dirEntry of directoryHandle.values()) {
        if (dirEntry.kind === 'directory') {
          const obj: IFolder = {
            csvId: null,
            measurementId: null,
            folderName: dirEntry.name,
            fileMeta: null,
            outputSpeed: null,
            outputSpeedUnits: null,
            date: null,
            file: undefined,
            status: IStatus.UNKNOWN,
            readOnlyMessage: undefined,
          };
          foldersCount += 1;
          let countFilesInFolder = 0;
          for await (const fileEntry of dirEntry.values()) {
            if (fileEntry.kind === 'file') {
              const file = await fileEntry.getFile();

              const extensionNameArr = file.name.split('.');
              const extensionName = extensionNameArr[extensionNameArr.length - 1];
              if (extensionName === 'csv') {
                countFilesInFolder += 1;
                if (countFilesInFolder === 1) {
                  obj.file = file;
                  obj.fileMeta = getFileMeta(file);
                  obj.status =
                    file.size > MAX_MEASURING_CSV_FILE_SIZE ? IStatus.TOO_BIG : IStatus.READY;
                }
                if (countFilesInFolder > 1) {
                  obj.file = undefined;
                  obj.status = IStatus.MORE_THAN_ONE;
                }
                filesCount += 1;
                size += file.size ?? 1;
              }
            }
          }
          if (countFilesInFolder === 0) {
            obj.status = IStatus.FILE_NOT_FOUND;
          }

          fileSystemDict000.set(obj.folderName, obj);
        }
      }

      info = `Размер ${fileSizeToText(size)}, Папок: ${foldersCount}, Файлов (*.csv): ${
        filesCount ?? 0
      }`;
    }

    setMainUploadDirectory({
      mainDirectoryHandle: directoryHandle,
      fileSystemDict: fileSystemDict000,
      mainDirectoryInfo: info,
    });
    setFileSystemValidationModalDialogFlag(fileSystemDict000.size !== 0);
  }

  async function renewMainDirectoryInformation() {
    const mainDirectoryHandle000 = mainDirectoryHandle;
    setMainUploadDirectory(undefined);
    await delay(50);

    return await setMainDirectoryFunction(mainDirectoryHandle000);
  }

  const handleMainFolderClicked = async () => {
    setMainUploadDirectory(undefined);
    let handle;
    try {
      handle = await window.showDirectoryPicker({
        startIn: 'downloads',
        mode: 'read',
      });
      await setMainDirectoryFunction(handle);
    } catch (error) {
      setNotificationMessage({
        fullMessage: '',
        header:
          'Внимание!\nВозможно папка не выбрана пользователем,\nили на вашем компрьютере нет папки "downloads" ("загрузки"),\nили пользователь отказался давать для выбранной папки права на чтение.',
      });
      setMainUploadDirectory(undefined);
    }
  };

  const { folderListSummary, grouppedListSummary } = useMemo(() => {
    if (!Array.isArray(folderListQuery.data)) {
      return { folderListSummary: [], grouppedListSummary: [] };
    }
    const sortedArray = [...folderListQuery.data].sort((a, b) =>
      sortFunction(a.folderName, b.folderName),
    );

    const folderListSummary000 = sortedArray.map((item) => {
      const obj = { ...item };
      const info = fileSystemDict.get(item.folderName);
      if (!info) {
        if (obj.fileMeta) {
          obj.status = IStatus.DONE;
        } else {
          obj.status =
            fileSystemDict.size === 0 ? IStatus.NOT_YET_UPLOADED : IStatus.FOLDER_NOT_FOUND;
        }
      } else {
        obj.status = info.status;
        if (obj.fileMeta) {
          obj.status =
            !info?.fileMeta || obj.fileMeta === info?.fileMeta
              ? IStatus.DONE
              : IStatus.MUST_BE_REUPLOADED;
        }
        if (obj.status === IStatus.READY) {
          obj.file = info.file;
          obj.fileMeta = info.fileMeta;
        }
      }

      return obj;
    });

    let readyFiles = 0;
    let doneFiles = 0;
    let errorFiles = 0;
    for (let item of folderListSummary000) {
      switch (item.status) {
        case IStatus.READY:
          readyFiles++;
          break;
        case IStatus.DONE:
          doneFiles++;
          break;
        default:
          errorFiles++;
          break;
      }
    }
    const grouppedListSummary000 = [
      {
        id: IFilters.READY,
        title: 'Готово к загрузке:',
        color: classes.readyFilesColor,
        colorSelected: classes.readyFilesColorSelected,

        total: readyFiles,
      },
      {
        id: IFilters.ERRORS,
        title: 'Ошибок валидации:',
        color: classes.errorFilesColor,
        colorSelected: classes.errorFilesColorSelected,

        total: errorFiles,
      },
      {
        id: IFilters.DONE,
        title: 'Загружено:',
        color: classes.doneFilesColor,
        colorSelected: classes.doneFilesColorSelected,
        total: doneFiles,
      },
    ];

    return { folderListSummary: folderListSummary000, grouppedListSummary: grouppedListSummary000 };
  }, [
    classes.doneFilesColor,
    classes.doneFilesColorSelected,
    classes.errorFilesColor,
    classes.errorFilesColorSelected,
    classes.readyFilesColor,
    classes.readyFilesColorSelected,
    fileSystemDict,
    folderListQuery.data,
  ]);

  const readyToUploadFiles = folderListSummary.filter((anItem) => anItem.status === IStatus.READY);

  const uploadedFiles =
    folderListSummary.filter(
      (anItem) => anItem.status === IStatus.DONE || anItem.status === IStatus.MUST_BE_REUPLOADED,
    ) ?? [];

  let filteredSummary = useMemo(() => {
    switch (validationFilter) {
      case IFilters.READY:
        return folderListSummary.filter((item) => item.status === IStatus.READY);
      case IFilters.DONE:
        return folderListSummary.filter((item) => item.status === IStatus.DONE);
      case IFilters.ERRORS:
        return folderListSummary.filter(
          (item) => item.status !== IStatus.READY && item.status !== IStatus.DONE,
        );
      default:
        return folderListSummary;
    }
  }, [folderListSummary, validationFilter]);

  useEffect(() => {
    reset({
      [FIELDS]: filteredSummary.map((item) => item.outputSpeed?.toFixed(2)),
    });
  }, [filteredSummary, reset]);

  const onSubmit = (fields: any) => {
    const mp = new Map(
      filteredSummary.map((item, index) => [
        item.folderName,
        {
          ...item,
          outputSpeed: fields[FIELDS]?.[index],
        },
      ]),
    );

    const result = folderListSummary.map((item) => {
      const filteredValue = mp.get(item.folderName);

      return filteredValue ? filteredValue : item;
    });

    mutateFolderList.mutate(result);
  };

  useEffect(() => {
    async function uploadOneFileFormGlobalFileList() {
      const theItem = globalFileList?.[0];
      if (theItem === undefined || folderListManagerData !== undefined) {
        return;
      }
      const { folderName, file } = theItem;
      setFolderListManagerData({
        currentFolderName: folderName,
        diagnosticId,
      });
      setGlobalFileList(globalFileList?.slice(1));

      const fileMeta = getFileMeta(file);

      const unicId = getIdByString(folderName);
      const rowElement = document.getElementById(unicId);
      if (rowElement) {
        rowElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
      if (file) {
        try {
          await delay(50);
          const obj = await fetchMeasureUpload(file);
          const csvLink = obj?.id;
          if (csvLink) {
            mutateUploadCsv.mutate({ csvLink, folderName, fileMeta });
          }
        } catch (e) {
          setNotificationMessage(errorMessageConvert(e as Error, 'файл не загрузился'));
        }
      }
      setFolderListManagerData(undefined);
    }
    uploadOneFileFormGlobalFileList();
  }, [
    diagnosticId,
    folderListManagerData,
    globalFileList,
    mutateUploadCsv,
    setFolderListManagerData,
    setGlobalFileList,
    setNotificationMessage,
  ]);

  function closeWindow() {
    setFileSystemValidationModalDialogFlag(false);
    handleMainFolderClicked();
  }

  const diagnosticStatusEqMeasurmentsUploading = !measurementsAreUploaded;

  return (
    <div className={classes.breadCrumbsMargin}>
      <CustomBreadcrumbs links={breadcrubms} />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className={classes.header}>
          <span>{'Менеджер загрузки файлов измерений'}</span>
          {!measurementsAreUploaded &&
            folderListManagerData?.diagnosticId &&
            folderListManagerData?.diagnosticId !== diagnosticId && (
              <span style={{ color: RED_COLOR }}>
                {' (продолжается загрузка файлов из другой диагностики)'}
              </span>
            )}
        </div>
        <div className={classes.mainFolderContainer}>
          <UploadMeasureTable
            renewMainDirectoryInformation={renewMainDirectoryInformation}
            isModalVariant={false}
            layoutSize={{ height: layoutSize.height - HEIGHT_DIF, width: layoutSize.width - 40 }}
            diagnosticId={diagnosticId}
            mainDirectoryHandle={mainDirectoryHandle}
            mainDirectoryInfo={mainDirectoryInfo}
            handleMainFolderClicked={handleMainFolderClicked}
            grouppedListSummary={grouppedListSummary}
            validationFilter={validationFilter}
            setValidationFilter={setValidationFilter}
            filteredSummary={filteredSummary}
            diagnosticStatusEqMeasurmentsUploading={diagnosticStatusEqMeasurmentsUploading}
            form={form}
            readOnly={measurementsAreUploaded}
            deleteCsvFileFromList={mutateDeleteCsvFile.mutate}
          />
        </div>

        <div className={classes.mainFolderContainer}>
          <div className={classes.mainSubFolderContainer}>{/* <UplodaButton /> */}</div>
          <div className={classes.columnWidthSmall}></div>

          <Button
            id={'id_finish_loading_843'}
            disabled={
              readyToUploadFiles.length !== 0 ||
              uploadedFiles.length === 0 ||
              form.formState.isDirty ||
              !diagnosticStatusEqMeasurmentsUploading
            }
            className={classes.columnWidthSmall2}
            variant="contained"
            color="primary"
            size="small"
            onClick={() => {
              setConfimationDialog({
                handleConfirm: () => mutateFinishUpload.mutate(),
                handleCancel: NOOP,
                handleClose: () => {
                  setConfimationDialog(undefined);
                },
                title: 'Вы хотите полностью завершить загрузку измерений?',
                content: `Если вы загрузили все файлы и обновили (при необходимости) режимы работы (скорости), то подтвердите завершение работы.
                Диагностика перейдёт на следующий этап.
                Дальнейшая загрузка измерений для этой диагностики будет невозможна.`,
              });
            }}
          >
            {'Завершить загрузку измерений'}
          </Button>
          <Button
            id={'id_speeds_saving_843'}
            className={classes.saveSpeeds}
            disabled={!form.formState.isDirty}
            variant="contained"
            color="primary"
            size="small"
            type="submit"
          >
            {'Сохранить скорости'}
          </Button>
        </div>
      </form>
      <ConfirmationDialog {...confimationDialog} />
      {fileSystemValidationModalDialogFlag && (
        <UniversalModalDialog setClose={closeWindow}>
          <div className={classes.header}>Валидация измерений</div>
          <div className={classes.mainFolderContainer}>
            <UploadMeasureTable
              renewMainDirectoryInformation={renewMainDirectoryInformation}
              isModalVariant={true}
              layoutSize={{ height: layoutSize.height - HEIGHT_DIF, width: layoutSize.width }}
              diagnosticId={diagnosticId}
              mainDirectoryHandle={mainDirectoryHandle}
              mainDirectoryInfo={mainDirectoryInfo}
              handleMainFolderClicked={handleMainFolderClicked}
              grouppedListSummary={grouppedListSummary}
              validationFilter={validationFilter}
              setValidationFilter={setValidationFilter}
              filteredSummary={filteredSummary}
              diagnosticStatusEqMeasurmentsUploading={diagnosticStatusEqMeasurmentsUploading}
              form={form}
              deleteCsvFileFromList={mutateDeleteCsvFile.mutate}
              readOnly={measurementsAreUploaded}
            />
          </div>
          <div className={classes.modalButtonsGroup}>
            <Button variant="contained" color="default" size="small" onClick={closeWindow}>
              {'Отмена'}
            </Button>
            <div className={classes.columnWidthLarge}>
              <Button
                disabled={
                  !diagnosticStatusEqMeasurmentsUploading ||
                  !folderListSummary ||
                  !!folderListManagerData?.currentFolderName
                }
                className={classes.columnWidthLarge}
                variant="contained"
                color="primary"
                size="small"
                onClick={() =>
                  setGlobalFileList([...(globalFileList ?? []), ...readyToUploadFiles])
                }
              >
                {`Загрузить ${readyToUploadFiles.length} файлов`}
              </Button>
            </div>
          </div>
        </UniversalModalDialog>
      )}
    </div>
  );
}
