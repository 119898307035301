import { useState, useRef, useEffect } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import { HANDLE_FILTER_CHANGE_TIMEOUT } from 'stream-constants';

import styles from './styles.module.css';
interface IProps {
  searchString: string;
  setSearchString: (searchString: string) => void;
}

const SearchInput = ({ searchString, setSearchString }: IProps) => {
  const debouncingRef = useRef<ReturnType<typeof setTimeout>>();
  const [value, setValue] = useState<string>(searchString);

  useEffect(() => {
    return () => {
      debouncingRef.current && clearTimeout(debouncingRef.current);
    };
  }, []);

  const handleSearchString = (str: string) => {
    setValue(str);
    debouncingRef.current && clearTimeout(debouncingRef.current);
    if (str !== value) {
      debouncingRef.current = setTimeout(() => {
        setSearchString(str);
      }, HANDLE_FILTER_CHANGE_TIMEOUT);
    }
  };

  return (
    <div className={styles.layout}>
      <div className={styles.inputContainer}>
        <input
          id="id_text_seach_filter"
          placeholder="Поиск"
          className={styles.input}
          value={value}
          onChange={(e: any) => handleSearchString(e.target.value)}
        />

        <div className={styles.clearIcon}>
          <ClearIcon style={{ fontSize: 18 }} onClick={() => handleSearchString('')} />
        </div>
      </div>
    </div>
  );
};

export default SearchInput;
SearchInput.displayName = 'SearchInput';
