import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function OrderDescIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 13 13'}>
      <path
        d="M2.24809 11.75C2.24809 12.1642 2.58388 12.5 2.99809 12.5H10.9981C11.4123 12.5 11.7481 12.1642 11.7481 11.75C11.7481 11.3358 11.4123 11 10.9981 11H2.99809C2.58388 11 2.24809 11.3358 2.24809 11.75Z"
        fill="black"
      />
      <path
        d="M6.99939 7.74602C6.99939 8.16023 7.33518 8.49602 7.74939 8.49602H12.2473C12.6615 8.49602 12.9973 8.16023 12.9973 7.74602C12.9973 7.33181 12.6615 6.99602 12.2473 6.99602H7.74939C7.33518 6.99602 6.99939 7.33181 6.99939 7.74602Z"
        fill="black"
      />
      <path
        d="M7.74939 4.49993C7.33518 4.49993 6.99939 4.16414 6.99939 3.74993C6.99939 3.33571 7.33518 2.99993 7.74939 2.99993H12.2473C12.6615 2.99993 12.9973 3.33571 12.9973 3.74993C12.9973 4.16414 12.6615 4.49993 12.2473 4.49993H7.74939Z"
        fill="black"
      />
      <path
        d="M1.37875 4C0.751366 4 0.400214 3.30403 0.784555 2.80816L1.10742 2.39177C1.82838 1.46201 2.18886 0.997132 2.64623 0.875785C2.76218 0.84502 2.8811 0.829418 3.00006 0.828978C3.12248 0.828524 3.24495 0.844127 3.36427 0.875785C3.82164 0.997132 4.18212 1.46201 4.90307 2.39176L5.22594 2.80816C5.61028 3.30403 5.25913 4 4.63175 4C4.39182 4 4.16556 3.88864 4.01859 3.699L3.75 3.3526C3.76603 3.37339 3.77955 3.39121 3.77579 3.38667C3.76743 3.37657 3.75883 3.3667 3.75 3.35707V8.25C3.75 8.66421 3.41421 9 3 9C2.58579 9 2.25 8.66421 2.25 8.25V3.36875C2.24482 3.37464 2.23972 3.38061 2.23471 3.38667C2.23182 3.39016 2.23916 3.38053 2.25 3.36643L1.99191 3.699C1.84493 3.88864 1.61867 4 1.37875 4Z"
        fill="black"
      />
    </SvgIcon>
  );
}
