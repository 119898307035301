import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const FrameRenewIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <rect width="32" height="32" rx="6" fill="#F2F3F7" />
      <g clipPath="url(#clip0_2980_628445)">
        <path
          d="M10.0001 16C10.0002 15.436 10.0249 14.8648 10.0667 14.3034C10.2497 11.8438 10.3526 11.4746 10.9129 10.9143C11.4733 10.354 11.8424 10.2511 14.3021 10.068C14.8639 10.0262 15.4355 10.0015 16 10.0015C16.6769 10.0015 17.3634 10.037 18.0301 10.0948C18.6949 10.1524 19.173 10.201 19.5551 10.2547C19.9624 10.312 20.3868 10.2 20.6776 9.90915C20.901 9.68579 21.007 9.40178 21 9.13668C20.9904 8.77203 20.767 8.44315 20.3411 8.35713C19.7498 8.23773 19.0748 8.17778 18.2027 8.10223C17.4903 8.04051 16.7451 8.00146 16 8.00146C15.3782 8.00146 14.7564 8.02865 14.1536 8.07351C11.8284 8.24657 10.6657 8.3331 9.49873 9.50008C8.33176 10.6671 8.24523 11.8297 8.07217 14.155C8.02734 14.7573 8.00016 15.3787 8.00012 16H6.63197C6.12045 16 5.82159 16.6108 6.11657 17.0533C7.42926 19.0225 8.0856 20 9 20C9.9144 20 10.5707 19.0225 11.8834 17.0533C12.1784 16.6108 11.8795 16 11.368 16H10.0001Z"
          fill="black"
        />
        <path
          d="M25.3828 16H23.9998V16.0013C23.9998 16.6231 23.9727 17.2449 23.9278 17.8477C23.7547 20.173 23.6682 21.3356 22.5012 22.5026C21.3342 23.6695 20.1716 23.7561 17.8463 23.9291C17.2436 23.974 16.6218 24.0012 16 24.0012C15.2548 24.0012 14.5097 23.9621 13.7972 23.9004C12.9252 23.8249 12.2502 23.7649 11.659 23.6455C11.2326 23.5594 11.0091 23.2299 11 22.8647C10.9934 22.5999 11.0994 22.3165 11.3224 22.0935C11.6133 21.8027 12.0376 21.6906 12.4449 21.7479C12.827 21.8017 13.3051 21.8503 13.9698 21.9079C14.6365 21.9656 15.3231 22.0012 16 22.0012C16.5645 22.0012 17.1361 21.9765 17.6979 21.9347C20.1575 21.7516 20.5267 21.6487 21.087 21.0884C21.6473 20.5281 21.7502 20.1589 21.9333 17.6992C21.9751 17.1374 21.9998 16.5658 21.9998 16.0013V16H20.6172C20.1176 16 19.8258 15.4041 20.1138 14.9724L20.1657 14.8946C21.4452 12.9768 22.0969 12 23 12C23.9031 12 24.5547 12.9767 25.8342 14.8945L25.8862 14.9724C26.1742 15.4041 25.8824 16 25.3828 16Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2980_628445">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
