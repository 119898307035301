import { IHttpMethods } from 'interfaces';
import { GET_DIAGNOSTICS_PREDICT } from 'services/urls';
import { IS_SERVER_MOCKED } from 'stream-constants';
import { delay } from 'utils/delay';

import { fetchData, probableError } from '../service-utils';

const rawData = {} as any;

const BASE_TIME = 300;
const MIN_TIME = 200;

export const getDiagnosticsPredict = async ({
  elementGuideId,
  freqElementId,
}: {
  elementGuideId: string;
  freqElementId: string;
}): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!elementGuideId) {
    result = Promise.reject(new Error('Не задан  elementGuideId'));

    return result;
  }
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(rawData);
    }
  } else {
    result = fetchData({
      url: GET_DIAGNOSTICS_PREDICT(elementGuideId, freqElementId),
      method: IHttpMethods.GET,
      name: 'getDiagnosticsPredict',
      emptyResponse: false,
      timeoutToAbort: 35 * 60 * 1000,
    });
  }

  const t1 = performance.now();
  console.log(`getDiagnosticsPredict took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
