import { FC } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';

import { ReactQueryDevtools } from 'react-query/devtools';

import { MAX_STALE_TIME } from 'stream-constants';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      structuralSharing: false,
      retryDelay: 1000,
      staleTime: MAX_STALE_TIME,
      // refetchOnMount: false,
      refetchOnWindowFocus: false,
      // refetchOnReconnect: false,
      // refetchInterval: 2000,
      // notifyOnChangeProps: "tracked",
    },
  },
});

const ReactQueryWrapper: FC = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};

export default ReactQueryWrapper;
ReactQueryWrapper.displayName = 'ReactQueryWrapper';
