import { memo, useState, useRef } from 'react';

import { ReturnToActualZoom } from 'icons/return-to-actual-zoom';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { IFrequencyZoomParams } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

import { usePrepareGraphParams } from './hooks/use-prepare-graph-params';

type Props = { x0: number; xMax: number; center: number; width: number };

const SCROLL_BAR_HEIGHT = 7.3;

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    root: {
      height: SCROLL_BAR_HEIGHT + 30,
      width: '100%',
      zIndex: 100,
      position: 'relative',
    },

    bar: {
      position: 'absolute',
      top: 13,
      left: (props) => props.x0,
      width: (props) => props.xMax - props.x0,
      height: SCROLL_BAR_HEIGHT,
      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.2)',
      borderRadius: `${SCROLL_BAR_HEIGHT / 2}px ${SCROLL_BAR_HEIGHT / 2}px ${
        SCROLL_BAR_HEIGHT / 2
      }px ${SCROLL_BAR_HEIGHT / 2}px`,
      border: '1px solid #bbb',
    },

    slider: {
      position: 'absolute',
      top: 13,
      left: (props) => props.center - props.width / 2,
      width: (props) => props.width,
      height: SCROLL_BAR_HEIGHT,
      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
      borderRadius: `${SCROLL_BAR_HEIGHT / 2}px ${SCROLL_BAR_HEIGHT / 2}px ${
        SCROLL_BAR_HEIGHT / 2
      }px ${SCROLL_BAR_HEIGHT / 2}px`,
      border: '1px solid #888',
      '&:hover': {
        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.8)',
      },
    },
    sliderHover: {
      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.8)',
    },
    zoomIcon: {
      position: 'absolute',
      top: -3,
      left: -5,
      transform: 'scale(0.7)',
      zIndex: 110,
    },
    tranparentFullScreenDiv: {
      opacity: 0,
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      cursor: 'grab',
    },
  }),
);

export const SpectrumLayoutCustomScrollBar = memo(function ({
  graphParams,
  frequencyZoomParams,
  setFrequencyZoomParams,
}: {
  graphParams: ReturnType<typeof usePrepareGraphParams>;
  frequencyZoomParams: IFrequencyZoomParams;
  setFrequencyZoomParams: (a: IFrequencyZoomParams) => void;
}) {
  const sliderRef = useRef<HTMLDivElement | undefined>();
  const [sliderDraggedStartPosition, setSliderDraggedStartPosition] = useState<
    number | undefined
  >();

  const {
    frequencyMinAbsolute = 0,
    frequencyMaxAbsolute = 0,
    frequencyMin = 0,
    frequencyMax = 0,
  } = graphParams;
  const { toPixels } = graphParams.envelop ?? {};

  const x0 = toPixels?.({ x: frequencyMin, y: 0 })?.x ?? 0;
  const xMax = toPixels?.({ x: frequencyMax, y: 0 })?.x ?? 0;

  const xCenter =
    (((frequencyMin + frequencyMax) / 2 - frequencyMinAbsolute) /
      (frequencyMaxAbsolute - frequencyMinAbsolute)) *
      (xMax - x0) +
    x0;

  const conversionFactor = (xMax - x0) / (frequencyMaxAbsolute - frequencyMinAbsolute);
  const width = (xMax - x0) / frequencyZoomParams.zoom;
  let center = frequencyZoomParams.zoom === 1 ? (xMax + x0) / 2 : xCenter;

  if (center < x0 + width / 2) {
    center = x0 + width / 2;
  } else {
    if (center > xMax - width / 2) {
      center = xMax - width / 2;
    }
  }

  const classes = useStyles({ x0, xMax, center, width });

  if (
    toPixels === undefined ||
    frequencyMaxAbsolute === undefined ||
    frequencyMinAbsolute === undefined
  ) {
    return <div />;
  }

  return (
    <>
      <div
        className={classes.root}
        onClick={(event) => {
          if (sliderRef) {
            // @ts-ignore
            const rect = sliderRef.current?.getBoundingClientRect?.();
            if (rect && event.pageX < rect.x) {
              if (
                graphParams?.frequencyMax !== undefined &&
                graphParams?.frequencyMin !== undefined
              ) {
                let shiftValue = (graphParams?.frequencyMax - graphParams.frequencyMin) / 10;
                if (graphParams.frequencyMin - shiftValue < 0) {
                  shiftValue = graphParams.frequencyMin;
                }
                setFrequencyZoomParams({
                  weight: frequencyZoomParams.weight,
                  center: frequencyZoomParams.center - shiftValue,
                  zoom: frequencyZoomParams.zoom,
                });
              }
            }
            if (rect && event.pageX > rect.x + rect.width) {
              if (
                graphParams?.frequencyMax !== undefined &&
                graphParams?.frequencyMin !== undefined
              ) {
                let shiftValue = (graphParams.frequencyMax - graphParams.frequencyMin) / 10;
                if (graphParams.frequencyMax + shiftValue > graphParams.frequencyMaxAbsolute) {
                  shiftValue = graphParams.frequencyMaxAbsolute - graphParams.frequencyMax;
                }

                setFrequencyZoomParams({
                  weight: frequencyZoomParams.weight,
                  center: frequencyZoomParams.center + shiftValue,
                  zoom: frequencyZoomParams.zoom,
                });
              }
            }
          }
        }}
        draggable={false}
        onMouseMove={(event) => {
          if (sliderDraggedStartPosition !== undefined) {
            let newCenter =
              (event.pageX - sliderDraggedStartPosition) / conversionFactor +
              frequencyZoomParams.center;
            const chartWindow =
              (frequencyMaxAbsolute - frequencyMinAbsolute) / frequencyZoomParams.zoom;
            const minCenter = frequencyMinAbsolute + chartWindow * frequencyZoomParams.weight;
            const maxCenter = frequencyMaxAbsolute - chartWindow * (1 - frequencyZoomParams.weight);

            newCenter = Math.max(newCenter, minCenter);
            newCenter = Math.min(newCenter, maxCenter);

            setFrequencyZoomParams({
              ...frequencyZoomParams,
              center: newCenter,
            });
            setSliderDraggedStartPosition(event.pageX);
          }
        }}
        onMouseUp={(event) => {
          setSliderDraggedStartPosition(undefined);
        }}
      >
        <div className={classes.bar} draggable={false}></div>
        <div
          //@ts-ignore
          ref={sliderRef}
          draggable={false}
          className={classNames(classes.slider, {
            [classes.sliderHover]: sliderDraggedStartPosition,
          })}
          onMouseDown={(event) => {
            setSliderDraggedStartPosition(event.pageX);
          }}
        ></div>

        <div className={classes.zoomIcon}>
          <Tooltip
            title={`Сбросить масштаб c ${frequencyZoomParams.zoom.toFixed(1)} до 1.0`}
            placement="top"
          >
            <span className={classes.spaceRight}>
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setFrequencyZoomParams({ center: 0, zoom: 1, weight: 0.5 });
                }}
              >
                <div className={classes.zoomIconScale}>
                  <ReturnToActualZoom />
                </div>
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
      {sliderDraggedStartPosition && (
        <div
          className={classes.tranparentFullScreenDiv}
          draggable={false}
          onMouseUp={() => {
            setSliderDraggedStartPosition(undefined);
          }}
          onMouseMove={(event) => {
            let newCenter =
              (event.pageX - sliderDraggedStartPosition) / conversionFactor +
              frequencyZoomParams.center;
            const chartWindow =
              (frequencyMaxAbsolute - frequencyMinAbsolute) / frequencyZoomParams.zoom;
            const minCenter = frequencyMinAbsolute + chartWindow * frequencyZoomParams.weight;
            const maxCenter = frequencyMaxAbsolute - chartWindow * (1 - frequencyZoomParams.weight);

            newCenter = Math.max(newCenter, minCenter);
            newCenter = Math.min(newCenter, maxCenter);

            setFrequencyZoomParams({
              ...frequencyZoomParams,
              center: newCenter,
            });
            setSliderDraggedStartPosition(event.pageX);
          }}
        />
      )}
    </>
  );
});
