export async function midlewareWrapper(
  getDataFunction: () => Promise<any>,
  midleware: (data: any) => any,
) {
  try {
    const data = await getDataFunction();

    return Promise.resolve(midleware(data));
  } catch (error) {
    return Promise.reject(error);
  }
}
