import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ZeroIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3.08105 9.92282C3.27575 7.30684 3.3731 5.99884 4.68597 4.68597C5.99884 3.3731 7.30684 3.27575 9.92282 3.08105C10.601 3.03058 11.3005 3 12 3C12.6995 3 13.399 3.03058 14.0772 3.08105C16.6932 3.27575 18.0012 3.3731 19.314 4.68597C20.6269 5.99884 20.7243 7.30684 20.9189 9.92282C20.9694 10.601 21 11.3005 21 12C21 12.6995 20.9694 13.399 20.9189 14.0772C20.7243 16.6932 20.6269 18.0012 19.314 19.314C18.0012 20.6269 16.6932 20.7243 14.0772 20.9189C13.399 20.9694 12.6995 21 12 21C11.3005 21 10.601 20.9694 9.92282 20.9189C7.30684 20.7243 5.99884 20.6269 4.68597 19.314C3.3731 18.0012 3.27575 16.6932 3.08105 14.0772C3.03058 13.399 3 12.6995 3 12C3 11.3005 3.03058 10.601 3.08105 9.92282Z"
        fill="#E30611"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.993 6.99609C11.4322 6.99609 10.9382 7.07992 10.5109 7.24758C10.3638 7.30529 10.2616 7.43721 10.2251 7.59093C10.1567 7.8794 10.3383 8.1679 10.628 8.23091L10.6418 8.23391C10.7717 8.26218 10.9063 8.23888 11.0287 8.187C11.3069 8.06912 11.6283 8.01018 11.993 8.01018C12.4416 8.01018 12.8201 8.09938 13.1285 8.27778C13.4463 8.4468 13.6986 8.68623 13.8855 8.99609C14.0818 9.30595 14.222 9.67684 14.3061 10.1088C14.3902 10.5407 14.4322 11.0149 14.4322 11.5313V12.4327C14.4322 12.5145 14.4344 12.5677 14.4362 12.6134L14.4365 12.6231C14.4427 12.7791 14.4914 12.9328 14.596 13.0486L14.6446 13.1025C14.8379 13.3165 15.1711 13.325 15.375 13.1211C15.6151 12.881 15.75 12.5553 15.75 12.2158V11.9961C15.75 11.2355 15.6799 10.5501 15.5397 9.93976C15.3995 9.32004 15.1752 8.79422 14.8668 8.36229C14.5678 7.92098 14.1799 7.58295 13.7033 7.34821C13.2266 7.11346 12.6565 6.99609 11.993 6.99609ZM13.7033 16.644C14.1737 16.403 14.5577 16.0706 14.8551 15.6467L15.5398 16.3313C15.7594 16.551 16.1156 16.551 16.3352 16.3313C16.5549 16.1117 16.5549 15.7555 16.3352 15.5358L8.46025 7.66085C8.24058 7.44118 7.88442 7.44118 7.66475 7.66085C7.44508 7.88052 7.44508 8.23667 7.66475 8.45634L8.64246 9.43405L8.60666 9.41108C8.55082 9.57898 8.50203 9.7552 8.46028 9.93976C8.32009 10.5501 8.25 11.2355 8.25 11.9961C8.25 12.7473 8.32009 13.4327 8.46028 14.0524C8.60047 14.6628 8.82009 15.1886 9.11916 15.6299C9.42757 16.0618 9.81542 16.3998 10.2827 16.644C10.7593 16.8787 11.3294 16.9961 11.993 16.9961C12.6565 16.9961 13.2266 16.8787 13.7033 16.644ZM9.63999 10.4316C9.59184 10.774 9.56776 11.1405 9.56776 11.5313V12.4327C9.56776 12.9491 9.60981 13.428 9.69392 13.8693C9.77804 14.3013 9.91355 14.6768 10.1005 14.9961C10.2874 15.306 10.535 15.5501 10.8435 15.7285C11.1612 15.8975 11.5444 15.982 11.993 15.982C12.4416 15.982 12.8201 15.8975 13.1285 15.7285C13.4463 15.5501 13.6986 15.306 13.8855 14.9961C13.926 14.9303 13.9641 14.862 13.9998 14.7914L9.63999 10.4316Z"
        fill="white"
      />
    </SvgIcon>
  );
}
