import React, { useState, useMemo, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { StandAlonDropdown } from 'components/stand-alon-dropdown';
import { ContextSettings } from 'context/context-settings';
import {
  useMutationCreateSpectrumDefect,
  useMutationUpdateSpectrumDefect,
  useMutationDeleteSpectrumDefect,
} from 'pages-diagnostic/diagnostic-spectrum/query-hooks/query-hooks';
import {
  IDefect,
  IPreparedPeaksMap,
  IDefectDictItem,
  IFrequencyZoomParams,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import {
  DIAGNOSTIC_SPECTRUM,
  IS_MODE_EQ_DEVELOPMENT,
  RED_COLOR,
  GREEN_COLOR,
} from 'stream-constants';

import { InputRank } from './input-rank';

const RANK_ROUND_NUMBER = 2;

export const DefectCommands = React.memo(function ({
  diagnosticId,
  elementGuideId,
  freqElementId,
  defectsDictArray,
  selectedDirectFrequencies,
  selectedEnvelopFrequencies,
  currentDefect,
  currentDefectOrTemplate,
  setCurrentDefect,
  preparedPeaks,
  clearStates,
  fiterTypeForDefectOptions,
  nextSubElement,
  isReadyCheckBox,
  setFrequencyZoomParams,
  setOtherFreqElementId,
  groupCheckboxValuesByIdSet,
}: {
  diagnosticId: string;
  elementGuideId: string;
  freqElementId: string;
  defectsDictArray: IDefectDictItem[];
  selectedDirectFrequencies: Set<string>;
  selectedEnvelopFrequencies: Set<string>;
  currentDefect: IDefect | undefined;
  currentDefectOrTemplate: IDefect | undefined;
  setCurrentDefect: (d: IDefect | undefined) => void;
  preparedPeaks: IPreparedPeaksMap | undefined;
  clearStates: () => void;
  fiterTypeForDefectOptions: string | undefined;
  nextSubElement: string | undefined;
  isReadyCheckBox: boolean;
  setFrequencyZoomParams: (a: IFrequencyZoomParams) => void;
  setOtherFreqElementId: (str: string | undefined) => void;
  groupCheckboxValuesByIdSet: Set<string> | undefined;
}) {
  const history = useHistory();
  const [defectName, setDefectName] = useState<string | undefined>(
    currentDefectOrTemplate?.defectType,
  );
  const [inputDefectRank, setInputDefectRank] = useState<
    { value: string; error: string } | undefined
  >(
    currentDefectOrTemplate?.rank
      ? { value: currentDefectOrTemplate.rank.toFixed(RANK_ROUND_NUMBER), error: '' }
      : undefined,
  );

  const { setLastModifiedSpectrumElement } = useContext(ContextSettings);

  const calculatedRank = useMemo(() => {
    // rank это максимальный dY_envelope для дефекта
    let maxRank = Number.NEGATIVE_INFINITY;
    for (const freq of selectedEnvelopFrequencies) {
      const peak = preparedPeaks?.get(freq);
      if (peak) {
        const dy = peak.rawPeak?.dyEnvelop;
        if (dy !== undefined && dy !== null) {
          maxRank = Math.max(maxRank, dy);
        }
      }
    }

    if (maxRank === Number.NEGATIVE_INFINITY) {
      maxRank = 0;
    }

    return maxRank.toFixed(RANK_ROUND_NUMBER);
  }, [preparedPeaks, selectedEnvelopFrequencies]);

  let rankColor: string = '';
  let resultedDefectRank = inputDefectRank?.value;
  if (resultedDefectRank === undefined) {
    resultedDefectRank = currentDefect?.rank?.toFixed(RANK_ROUND_NUMBER);
  }
  if (resultedDefectRank === undefined) {
    resultedDefectRank = calculatedRank;
  }

  if (resultedDefectRank === calculatedRank) {
    rankColor = GREEN_COLOR;
  } else {
    rankColor = RED_COLOR;
  }

  const mutatuionArgs = () => {
    const newDefect = { ...currentDefect };
    if (defectName) {
      newDefect.defectType = defectName;
    }
    newDefect.rank = parseFloat(resultedDefectRank ?? '-123');
    if (isNaN(newDefect.rank)) {
      newDefect.rank = null;
    }

    const result = {
      diagnosticId,
      elementGuideId,
      freqElementId: freqElementId || elementGuideId,
      envelopDataList: [...selectedEnvelopFrequencies],
      spectrumDataList: [...selectedDirectFrequencies],
      defect: newDefect as IDefect,
    };
    if (IS_MODE_EQ_DEVELOPMENT && currentDefect === undefined) {
      result.defect.id = (Math.random() * 10000000).toFixed(0);
    }

    return result;
  };

  const mutationCreate = useMutationCreateSpectrumDefect((d: any) => {
    setCurrentDefect(undefined);
    setDefectName(undefined);
    clearStates();
  });

  const mutationUpdate = useMutationUpdateSpectrumDefect((d: any) => {
    setCurrentDefect(undefined);
    setDefectName(undefined);
    clearStates();
  });

  const mutationDelete = useMutationDeleteSpectrumDefect((d: any) => {
    setCurrentDefect(undefined);
    setDefectName(undefined);
    clearStates();
  });

  const defectsOptions = useMemo(() => {
    const result = defectsDictArray
      .map(({ labelId, label, validFor, groupFrequency }) => ({
        value: labelId,
        label: `${labelId}. ${label}`,
        disabled: !(
          validFor.find((elemType) => elemType === fiterTypeForDefectOptions) &&
          groupFrequency.find((gf) => groupCheckboxValuesByIdSet?.has(gf))
        ),
      }))
      .sort((a, b) => {
        if (a.disabled && !b.disabled) {
          return 1;
        }
        if (!a.disabled && b.disabled) {
          return -1;
        }
        if (a.value.length > b.value.length) {
          return 1;
        }
        if (a.value.length < b.value.length) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }

        return 0;
      });

    return result;
  }, [defectsDictArray, fiterTypeForDefectOptions, groupCheckboxValuesByIdSet]);

  const numberOfFrequencies = new Set([...selectedDirectFrequencies, ...selectedEnvelopFrequencies])
    .size;

  return (
    <>
      <br />
      {currentDefect === undefined ? (
        <>
          <StandAlonDropdown
            unicFieldName="defectName"
            key={defectName}
            label="Наименование дефекта"
            options={defectsOptions}
            name={defectName}
            setName={setDefectName}
            disabled={isReadyCheckBox}
          />
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <InputRank
                rankColor={rankColor}
                defectRank={{ value: resultedDefectRank, error: inputDefectRank?.error ?? '' }}
                calculatedRank={calculatedRank}
                setDefectRank={setInputDefectRank}
                disabled={defectName === undefined || numberOfFrequencies === 0 || isReadyCheckBox}
              />
            </Grid>
            <Grid item xs={8}>
              <Button
                variant="contained"
                disabled={
                  defectName === undefined ||
                  numberOfFrequencies === 0 ||
                  isReadyCheckBox ||
                  !!inputDefectRank?.error
                }
                onClick={() => {
                  // Создать дефект
                  mutationCreate.mutate(mutatuionArgs());
                }}
                color="primary"
              >
                <div>
                  <div>{'Добавить дефект'}</div>
                  {numberOfFrequencies !== 0 && <div>{`(${numberOfFrequencies} частот)`}</div>}
                </div>
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <InputRank
                rankColor={rankColor}
                defectRank={{ value: resultedDefectRank, error: inputDefectRank?.error ?? '' }}
                calculatedRank={calculatedRank}
                setDefectRank={setInputDefectRank}
                disabled={numberOfFrequencies === 0}
              />
            </Grid>
            <Grid item xs={8}>
              <Button
                variant="contained"
                disabled={numberOfFrequencies === 0 || isReadyCheckBox || !!inputDefectRank?.error}
                onClick={() => {
                  // Обновить дефект
                  mutationUpdate.mutate(mutatuionArgs());
                  setCurrentDefect(undefined);
                }}
                color="primary"
              >
                <div>
                  <div>{'Обновить дефект'}</div>
                  {numberOfFrequencies !== 0 && <div>{`(${numberOfFrequencies} частот)`}</div>}
                </div>
              </Button>
            </Grid>
            <Grid item xs={4}>
              {' '}
            </Grid>
            <Grid item xs={8}>
              <Button
                disabled={isReadyCheckBox}
                variant="contained"
                onClick={() => {
                  // Удалить дефект
                  mutationDelete.mutate({ defectId: currentDefect.id });
                }}
                color="secondary"
              >
                Удалить дефект
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      <br />
      <Button
        disabled={nextSubElement === undefined}
        variant={nextSubElement === null ? 'outlined' : 'contained'}
        endIcon={<ChevronRightIcon />}
        onClick={() => {
          if (nextSubElement === null) {
            history.push(`/${DIAGNOSTIC_SPECTRUM}/${diagnosticId}`);
          } else {
            setFrequencyZoomParams({ center: 0, zoom: 1, weight: 0.5 });
            setLastModifiedSpectrumElement(nextSubElement);
            history.push(`/${DIAGNOSTIC_SPECTRUM}/${diagnosticId}/${nextSubElement}`);
          }
          setOtherFreqElementId(undefined);
        }}
        color="primary"
      >
        {nextSubElement === null ? 'Возврат к списку элементов' : 'Следующий элемент'}
      </Button>
    </>
  );
});
