import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '12px',
    fontWeight: 400,
    fontSize: '12px',
    color: '#000',
    lineHeight: '16px',
  },

  item: {
    height: '24px',
    border: '1px solid #E9EAEE',
    borderRadius: '4px',
    padding: '4px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '8px',
  },

  circle: {
    height: 8,
    width: 8,
    borderRadius: 4,
  },
});

interface IlegendItem {
  colorClassName: string;
  label: string;
}

export function Legenda({ items }: { items: IlegendItem[] }) {
  const classes = useStyles();

  return (
    <div className={classes.row}>
      {items.map((item) => (
        <div key={item.label} className={classes.item}>
          <div className={classNames(classes.circle, item.colorClassName)} />
          <div>{item.label}</div>
        </div>
      ))}
    </div>
  );
}
