import { IHttpMethods } from 'interfaces';
import {
  GET_FOLDER_LIST_URL,
  UPDATE_FOLDER_LIST_URL,
  UPLOAD_CSV_URL,
  DELETE_CSV_FILE_URL,
  FINISH_UPLOAD_URL,
} from 'services/urls';
import { IS_SERVER_MOCKED } from 'stream-constants';
import { delay } from 'utils/delay';

import { fetchData, probableError } from '../service-utils';

import getFolderListJson from './mock/get-folder-list.json';

const BASE_TIME = 300;
const MIN_TIME = 200;

export const getFolderListById = async (diagnosticId: string): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId) {
    result = Promise.reject(new Error('Не задан diagnosticId или  elementGuideId'));

    return result;
  }
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(getFolderListJson);
    }
  } else {
    result = fetchData({
      url: GET_FOLDER_LIST_URL(diagnosticId),
      method: IHttpMethods.GET,
      name: 'getFolderListById',
      emptyResponse: false,
      timeoutToAbort: 10 * 60 * 1000,
    });
  }

  const t1 = performance.now();
  console.log(`getFolderListById took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const updateFolderListById = async (diagnosticId: string, data: any): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId) {
    result = Promise.reject(new Error('Не задан diagnosticId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(true);
    }
  } else {
    await fetchData({
      url: UPDATE_FOLDER_LIST_URL(diagnosticId),
      method: IHttpMethods.PUT,
      body: JSON.stringify(data),
      name: 'updateFolderListById',
      emptyResponse: true,
    });
    result = Promise.resolve(true);
  }

  const t1 = performance.now();
  console.log(`updateFolderListById took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const uploadCvs = async (diagnosticId: string, data: any): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId) {
    result = Promise.reject(new Error('Не задан diagnosticId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(true);
    }
  } else {
    result = await fetchData({
      url: UPLOAD_CSV_URL(diagnosticId),
      method: IHttpMethods.POST,
      body: JSON.stringify(data),
      name: 'uploadCvs',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`uploadCvs took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const deleteCsvFile = async (csvId: string | null): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!csvId) {
    result = Promise.reject(new Error('Не задан csvId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(true);
    }
  } else {
    await fetchData({
      url: DELETE_CSV_FILE_URL(csvId),
      method: IHttpMethods.DELETE,
      name: 'deleteCsvFile',
      emptyResponse: true,
    });
    result = Promise.resolve(true);
  }

  const t1 = performance.now();
  console.log(`deleteCsvFile took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const finishUpload = async (diagnosticId: string): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId) {
    result = Promise.reject(new Error('Не задан diagnosticId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(true);
    }
  } else {
    await fetchData({
      url: FINISH_UPLOAD_URL(diagnosticId),
      method: IHttpMethods.POST,
      name: 'finishUpload',
      emptyResponse: true,
    });
    result = Promise.resolve(true);
  }

  const t1 = performance.now();
  console.log(`finishUpload took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
