import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { elementIcon } from 'components/element-icon';
import { StyledCheckbox } from 'components/styled-checkbox';
import { MODES_DICT_NAMES } from 'interfaces';

import { IElem, elementsKey } from './common';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    message: {
      marginLeft: 60,
      color: 'grey',
    },
    layout: {
      marginLeft: 50,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      alignItems: 'center',
    },
    iconScale: {
      transform: 'translate(0px, 3px) scale(0.7)',
    },
    gap: {
      width: '26px',
    },
    column: {
      minWidth: '40px',
    },
  }),
);

interface IProps {
  nodeId: string;
  nodeCogWheelPairs: Map<string, [IElem, IElem][]>;
  selectedElements: Set<string>;
  setSelectedElements: (selectedElements: Set<string>) => void;
  setSelectedNodes: (selectedNodes: any) => void;
}

export function ElementsPairList({
  nodeId,
  nodeCogWheelPairs,
  selectedElements,
  setSelectedElements,
  setSelectedNodes,
}: IProps) {
  const classes = useStyles();
  const list = nodeCogWheelPairs.get(nodeId) ?? [];

  return (
    <>
      <Typography variant={'body1'} color="secondary" className={classes.message}>
        {'- зацепления зубчатых передач -'}
      </Typography>
      <div className={classes.layout}>
        {list.map((pair: [IElem, IElem]) => {
          const key = elementsKey(pair);
          const Component1 = elementIcon(pair[0].type);
          const Component2 = elementIcon(pair[1].type);

          return (
            <div key={key} className={classes.row}>
              <div className={classes.gap} />

              <StyledCheckbox
                checked={selectedElements.has(key)}
                onChange={(_, value) => {
                  const newSelectedElements = new Set([...selectedElements]);
                  if (value) {
                    newSelectedElements.add(key);
                    setSelectedNodes((oldSet: Set<string>) => {
                      const newSet = new Set<string>([...oldSet]);
                      newSet.add(nodeId);

                      return newSet;
                    });
                  } else {
                    newSelectedElements.delete(key);
                  }
                  setSelectedElements(newSelectedElements);
                }}
              />
              <Tooltip title={MODES_DICT_NAMES[pair[0].type]} placement={'top'}>
                <div className={classes.iconScale}>
                  <Component1 />
                </div>
              </Tooltip>

              <Typography variant={'body2'} color="secondary" className={classes.column}>
                {pair[0].title}
              </Typography>
              <Tooltip title={MODES_DICT_NAMES[pair[1].type]} placement={'top'}>
                <div className={classes.iconScale}>
                  <Component2 />
                </div>
              </Tooltip>
              <Typography variant={'body2'} color="secondary">
                {pair[1].title}
              </Typography>
            </div>
          );
        })}
      </div>
    </>
  );
}
