import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface IProps extends SvgIconProps {
  customColor?: string;
}

export function TrueElementIcon(props: IProps) {
  const { customColor, ...other } = props;

  return (
    <SvgIcon {...other} viewBox={'0 0 20 20'}>
      <rect width="20" height="20" rx="10" fill={customColor} />
      <path
        d="M12.9121 7.2969C13.1992 7.01214 13.6648 7.01214 13.952 7.2969C14.2391 7.58165 14.2391 8.04334 13.952 8.32809L9.54019 12.7031C9.25304 12.9879 8.78748 12.9879 8.50033 12.7031L6.04935 10.2725C5.7622 9.98778 5.7622 9.5261 6.04935 9.24134C6.3365 8.95658 6.80206 8.95658 7.08921 9.24134L9.02026 11.1563L12.9121 7.2969Z"
        fill="white"
      />
    </SvgIcon>
  );
}
