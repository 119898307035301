import { FC, useState } from 'react';
import { useContext } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import Avatar from 'icons/avatar-icon';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { UniversalModalDialog } from 'components/universal-modal-dialog';
import { ContextSettings } from 'context/context-settings';
import { IHttpMethods } from 'interfaces';
import { LOG_OUT } from 'services/urls';
import {
  BLACK_COLOR,
  RED_COLOR,
  CUSTOM_HEADERS_FOR_FETCH,
  IS_SERVER_MOCKED,
} from 'stream-constants';
import { delay } from 'utils/delay';
import { useGetFullName } from 'utils/hooks/use-get-full-name';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    height: '60px',
  },
  header: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
  },

  headerInfo: {
    position: 'absolute',
    top: '8px',
    left: '0px',
    display: 'flex',
    alignItems: 'center',
    gap: '9px',
  },
  projectName: {
    color: RED_COLOR,
    minWidth: '160px',
  },
  headerNav: {
    display: 'flex',
    justifyContent: 'center',
  },

  spaceBetween: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  logo: {
    width: 70,
    height: 20,
    paddingBottom: 2,
    cursor: 'pointer',
  },

  tab: {
    minWidth: 150,
    width: 150,
    fontSize: '17px',
    fontWeight: 500,
    fontStretch: '100%',
    letterSpacing: 'normal',
    lineHeight: '24px',
    color: BLACK_COLOR,
  },
  tabs: {
    '& button': {
      width: 50,
    },
  },

  emptyAvatar: {
    position: 'absolute',
    top: '0px',
    right: '12px',
  },

  avatar: {
    position: 'absolute',
    top: '8px',
    right: '0px',
    width: '44px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: '#9198A0',
    borderRadius: '50%',
    fontWeight: 700,
    fontSize: '20px',
    color: '#FFF',
  },

  exitIcon: {
    position: 'absolute',
    top: '8px',
    right: '60px',
    width: '44px',
    height: '44px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: 'lightgrey',
    borderRadius: '50%',
    fontWeight: 700,
    fontSize: '20px',
    color: '#FFF',
  },
  topBarHeight: {
    height: '70px',
  },

  modalRoot: {
    minWidth: '400px',
    maxWidth: '400px',
  },
  modalHeader: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '20px',
    color: '#1D2023',
    margin: '16px 0 16px 0',
  },
  modalSubHeader: {
    fontWeight: 400,
    fontSize: '14px',
    color: '#1D2023',
    margin: '16px 0 16px 0',
  },
}));

const TopBar: FC<{ tabsArray: { label: string; href: string; forId: string }[] }> = ({
  tabsArray,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { folderListManagerData, globalFileList } = useContext(ContextSettings);
  const [exitConfimationFlag, setExitConfimationFlag] = useState<boolean>(false);
  const setClose = () => setExitConfimationFlag(false);
  const query = useGetFullName();
  const userFio = query?.data?.fullname ?? '';

  let activeHref: string | false = `/${location.pathname.split('/')[1]}`;
  if (!tabsArray.find(({ href }) => href === activeHref)) {
    activeHref = false;
  }

  const handleChange = (event: React.BaseSyntheticEvent, newValue: string) => {
    event.preventDefault();
    history.push(newValue);
  };

  return (
    <>
      <div className={classes.root}>
        <header className={classes.header}>
          <div className={classes.headerInfo}>
            {/* <LogoIcon
              className={classes.logo}
              onClick={(event: React.BaseSyntheticEvent) => {
                handleChange(event, '/');
              }}
            /> */}
            <Typography variant="h6" className={classes.projectName}>
              VibroScan
            </Typography>
            <Tabs
              value={activeHref}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabs}
            >
              {tabsArray.map(({ label, href, forId }) => (
                <Tab
                  label={label}
                  key={label}
                  value={href}
                  classes={{ root: classes.tab }}
                  id={`tab_id_for_${forId}`}
                />
              ))}
            </Tabs>
            {folderListManagerData && (
              <>
                <CircularProgress color="primary" size={14} />
                <div>
                  <Typography variant="body2" color="primary">
                    {(globalFileList?.length ?? 0) + 1}
                  </Typography>
                </div>
              </>
            )}
          </div>

          {userFio ? (
            <>
              <Tooltip title={'Выход'}>
                <span className={classes.exitIcon}>
                  <IconButton onClick={() => setExitConfimationFlag(true)}>
                    <ExitToAppIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={userFio}>
                <div className={classes.avatar}>{userFio.slice(0, 2)}</div>
              </Tooltip>
            </>
          ) : (
            <Tooltip title="Вы не авторизованы">
              <div className={classes.emptyAvatar}>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  onClick={() => {}}
                  color="inherit"
                >
                  <Avatar style={{ fontSize: 40, color: 'lightgrey' }} />
                </IconButton>
              </div>
            </Tooltip>
          )}
        </header>
      </div>

      {exitConfimationFlag && (
        <UniversalModalDialog setClose={setClose}>
          <div className={classes.modalRoot}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className={classes.modalHeader}>Вы уверены?</div>
                <div className={classes.modalSubHeader}>
                  Вы собираетесь выйти из учетной записи. Сервис станет недоступен, и Вам
                  понадобится повторная авторизация
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button variant="contained" color="default" size="small" onClick={setClose}>
                  {'Отмена'}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={async () => {
                    try {
                      if (!IS_SERVER_MOCKED) {
                        setClose();
                        const logOutResponse = await fetch(LOG_OUT, {
                          method: IHttpMethods.GET,
                          headers: new Headers({
                            ...CUSTOM_HEADERS_FOR_FETCH(window.location),
                            Origin: window.location.origin,
                          }),
                          mode: 'cors',
                        });
                        console.log('%c logOutResponse = ', 'color: #bada55', logOutResponse); //TODO - delete vvtu
                        if (logOutResponse.ok) {
                          const json = await logOutResponse.json();
                          if (json.url) {
                            window.location.href = json.url;
                          }
                        }
                      }
                      await delay(2000);
                      window.location.href = '/';
                      await delay(10000);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {'Выйти'}
                </Button>
              </Grid>
            </Grid>
          </div>
        </UniversalModalDialog>
      )}
    </>
  );
};
export default TopBar;
TopBar.displayName = 'TopBar';
