import { useMemo, useContext } from 'react';

import { ContextSettings } from 'context/context-settings';
import { useGetFrequencyGroupsColorsDict } from 'pages-diagnostic/diagnostic-spectrum/query-hooks/query-hooks';
import {
  IPreparedPeaksMap,
  IRawData,
  IPreparedPeak,
  ENVELOP_AMPLITUDE_INDEX,
  ENVELOP_MOVING_AVG_INDEX,
  DIRECT_AMPLITUDE_INDEX,
  DIRECT_MOVING_AVG_INDEX,
  FREQUENCY_INDEX,
  FREQUENCY_INTERVALS_COUNT_FOR_HOVER,
  IPeaksColor,
  getGroupsColors,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

import { prepareGoordinatesToPixelsConvertionFunction } from './prepare-goordinates-to-pixels-convertion-function';

const SCALE_STANDART_STEPS = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000, 5000];
const frequencyOptimalStepsNumber = 10;
const amplitudeOptimalStepsNumber = 5;

type IArgs = {
  mainElementId: string | undefined;
  data: IRawData;
  canvasSize: {
    height: number;
    width: number;
  };
  frequencyZoomParams: {
    center: number;
    zoom: number;
    weight: number;
  };
  freqElementId: string;
};

export function usePrepareGraphParams({
  mainElementId,
  data,
  canvasSize,
  frequencyZoomParams,
  freqElementId,
}: IArgs) {
  const { height, width } = canvasSize;
  const { setNotificationMessage } = useContext(ContextSettings);

  const frequencyGroupsColorsDictData = useGetFrequencyGroupsColorsDict() as {
    data: Record<string, IPeaksColor> | undefined;
  };

  const obj1 = useMemo(() => {
    const arr = data?.data?.spectrums;

    const element = data?.data?.elements?.find((item) => item.elementId === mainElementId);

    const freqElement = freqElementId
      ? data?.data?.elements?.find((item) => item.elementId === freqElementId)
      : element;

    if (
      Array.isArray(data?.data?.elements) &&
      data.data.elements.length > 0 &&
      freqElement === undefined
    ) {
      const message = `В массиве elements не найден  элемент с айдишником ${
        freqElementId ? `freqElementId = ${freqElementId}` : `mainElementId = ${mainElementId}`
      }`;
      console.error(message);
      setNotificationMessage({ fullMessage: '', header: message });
    }

    if (
      !Array.isArray(arr) ||
      freqElement === undefined ||
      height === undefined ||
      width === undefined
    ) {
      return;
    }

    // ********* определение минимальных и максимальных значений *******************************************************************
    let frequencyMin = Number.POSITIVE_INFINITY;
    let frequencyMax = Number.NEGATIVE_INFINITY;
    let envelopAmplitudeMin = Number.POSITIVE_INFINITY;
    let envelopAmplitudeMax = Number.NEGATIVE_INFINITY;
    let directAmplitudeMin = Number.POSITIVE_INFINITY;
    let directAmplitudeMax = Number.NEGATIVE_INFINITY;

    for (let index = 0; index < arr.length; index += 1) {
      const item = arr[index];
      const frequency = item[FREQUENCY_INDEX];
      frequencyMin = Math.min(frequencyMin, frequency);
      frequencyMax = Math.max(frequencyMax, frequency);
      const envelopAmplitude1 = item[ENVELOP_AMPLITUDE_INDEX];
      const envelopAmplitude2 = item[ENVELOP_MOVING_AVG_INDEX];
      envelopAmplitudeMin = Math.min(envelopAmplitudeMin, envelopAmplitude1);
      envelopAmplitudeMin = Math.min(envelopAmplitudeMin, envelopAmplitude2);
      envelopAmplitudeMax = Math.max(envelopAmplitudeMax, envelopAmplitude1);
      envelopAmplitudeMax = Math.max(envelopAmplitudeMax, envelopAmplitude2);
      const directAmplitude1 = item[DIRECT_AMPLITUDE_INDEX];
      const directAmplitude2 = item[DIRECT_MOVING_AVG_INDEX];
      directAmplitudeMin = Math.min(directAmplitudeMin, directAmplitude1);
      directAmplitudeMin = Math.min(directAmplitudeMin, directAmplitude2);
      directAmplitudeMax = Math.max(directAmplitudeMax, directAmplitude1);
      directAmplitudeMax = Math.max(directAmplitudeMax, directAmplitude2);
    }

    const preparedPeaks: IPreparedPeaksMap = new Map();
    let unicIdIndex = 1;
    let groups = freqElement.data;

    for (let groupIndex = 0; groupIndex < groups.length; groupIndex += 1) {
      const group = groups[groupIndex];
      const groupColor = getGroupsColors(group.groupId, frequencyGroupsColorsDictData?.data).circle;

      for (let freqIndex = 0; freqIndex < group.data.length; freqIndex += 1) {
        const rawPeak = group.data[freqIndex];
        const { frequencyName } = rawPeak;
        const repeatedPeak = preparedPeaks.get(frequencyName);
        if (repeatedPeak) {
          const peak = Object.entries(repeatedPeak.rawPeak).find(([key, value]) => {
            //@ts-ignore
            return value !== rawPeak[key];
          });
          if (peak) {
            const message = `Валидация: Повтор частоты, но не совпадение параметров peak = ${frequencyName}`;
            console.error('message = ', message);
            // throw new Error(message);
          }
        } else {
          const {
            theoryFreq,
            realSpectrumFreq,
            realEnvelopFreq,
            theorySpectrumAmpl,
            treoryEnvelopAmpl,
            realSpectrumAmpl,
            realEnvelopAmpl,
          } = rawPeak;

          if (
            theoryFreq === undefined ||
            realSpectrumFreq === undefined ||
            realEnvelopFreq === undefined ||
            theorySpectrumAmpl === undefined ||
            treoryEnvelopAmpl === undefined ||
            realSpectrumAmpl === undefined ||
            realEnvelopAmpl === undefined
          ) {
            throw new Error(
              `Валидация: ecnm undefined в параметрах в rawPeak = ${JSON.stringify(rawPeak)}`,
            );
          }

          // envelopAmplitudeMin = Math.min(envelopAmplitudeMin, theorySpectrumAmpl); //TODO - theorySpectrumAmpl - похоже не правильная!
          // envelopAmplitudeMax = Math.max(envelopAmplitudeMax, theorySpectrumAmpl);
          // envelopAmplitudeMin = Math.min(envelopAmplitudeMin, realEnvelopAmpl);
          // envelopAmplitudeMax = Math.max(envelopAmplitudeMax, realEnvelopAmpl);

          directAmplitudeMin = Math.min(directAmplitudeMin, theorySpectrumAmpl);
          directAmplitudeMax = Math.max(directAmplitudeMax, theorySpectrumAmpl);
          if (realSpectrumAmpl !== null) {
            directAmplitudeMin = Math.min(directAmplitudeMin, realSpectrumAmpl);
            directAmplitudeMax = Math.max(directAmplitudeMax, realSpectrumAmpl);
          }

          const preparedPeak: IPreparedPeak = {
            unicId: `unicPeakId${unicIdIndex++}`,
            groupColor,
            inPixels: {
              theory: {
                envelop00000: {
                  x: theoryFreq,
                  y: treoryEnvelopAmpl,
                },
                direct00000: {
                  x: theoryFreq,
                  y: theorySpectrumAmpl,
                },
                envelop: {
                  x: 0,
                  y: 0,
                },
                direct: {
                  x: 0,
                  y: 0,
                },
              },
              real: {
                envelop00000:
                  realEnvelopFreq === null || realEnvelopAmpl === null
                    ? null
                    : {
                        x: realEnvelopFreq,
                        y: realEnvelopAmpl,
                      },
                envelop: null,
                direct00000:
                  realSpectrumFreq === null || realSpectrumAmpl === null
                    ? null
                    : {
                        x: realSpectrumFreq,
                        y: realSpectrumAmpl,
                      },
                direct: null,
              },
            },

            groupName: group.groupName,
            frequencyName,
            groupPriority: group.priority,
            rawPeak,
          };

          preparedPeaks.set(frequencyName, preparedPeak);
        }
      }
    }

    return {
      element,
      freqElement,
      frequencyMin,
      frequencyMax,
      preparedPeaks,
      envelopAmplitudeMin,
      envelopAmplitudeMax,
      directAmplitudeMin,
      directAmplitudeMax,
      groups,
    };
  }, [
    data?.data,
    freqElementId,
    frequencyGroupsColorsDictData?.data,
    height,
    mainElementId,
    setNotificationMessage,
    width,
  ]);

  const obj2 = useMemo(() => {
    if (obj1 === undefined) {
      return {};
    }
    const {
      preparedPeaks,
      envelopAmplitudeMin,
      envelopAmplitudeMax,
      directAmplitudeMin,
      directAmplitudeMax,
      element,
      freqElement,
    } = obj1;

    let { frequencyMin, frequencyMax } = obj1;
    let frequencyMinAbsolute = frequencyMin;
    let frequencyMaxAbsolute = frequencyMax;

    // ***  применение параметров зума **********************************************************

    if (frequencyZoomParams.zoom > 1) {
      const { zoom, weight, center } = frequencyZoomParams;
      const chartWindow = (frequencyMax - frequencyMin) / zoom;
      const newFrequencyMin = center - chartWindow * weight;
      const newFrequencyMax = newFrequencyMin + chartWindow;
      frequencyMin = newFrequencyMin;
      frequencyMax = newFrequencyMax;
    }

    // ***  envelopAmplitudeScaleStep определение шага по амплитуде огибающей **********************
    const envelopAmplitudeSteps = SCALE_STANDART_STEPS.map((step) =>
      Math.abs(
        Math.floor((envelopAmplitudeMax - envelopAmplitudeMin) / step) -
          amplitudeOptimalStepsNumber,
      ),
    );
    let envelopMinAmIndex = 0;
    let envelopMinAmValue = Number.POSITIVE_INFINITY;
    envelopAmplitudeSteps.forEach((value, index) => {
      if (envelopMinAmValue >= value) {
        envelopMinAmValue = value;
        envelopMinAmIndex = index;
      }
    });
    const envelopAmplitudeScaleStep = SCALE_STANDART_STEPS[envelopMinAmIndex];

    // ***  directAmplitudeScaleStep  определение шага по амплитуде прямого спектра ****************
    const directAmplitudeSteps = SCALE_STANDART_STEPS.map((step) =>
      Math.abs(
        Math.floor((directAmplitudeMax - directAmplitudeMin) / step) - amplitudeOptimalStepsNumber,
      ),
    );
    let directMinAmIndex = 0;
    let directMinAmValue = Number.POSITIVE_INFINITY;
    directAmplitudeSteps.forEach((value, index) => {
      if (directMinAmValue >= value) {
        directMinAmValue = value;
        directMinAmIndex = index;
      }
    });
    const directAmplitudeScaleStep = SCALE_STANDART_STEPS[directMinAmIndex];

    // ***  frequencySteps *******************************
    const frequencySteps = SCALE_STANDART_STEPS.map((step) =>
      Math.abs(Math.floor((frequencyMax - frequencyMin) / step) - frequencyOptimalStepsNumber),
    );
    let minFreqIndex = 0;
    let minFreqValue = Number.POSITIVE_INFINITY;
    frequencySteps.forEach((value, index) => {
      if (minFreqValue >= value) {
        minFreqValue = value;
        minFreqIndex = index;
      }
    });
    const frequencyScaleStep = SCALE_STANDART_STEPS[minFreqIndex];

    const envelop = {
      amplitudeMin: envelopAmplitudeMin - envelopAmplitudeScaleStep / 5,
      amplitudeMinValue: envelopAmplitudeMin,
      amplitudeMax: envelopAmplitudeMax + envelopAmplitudeScaleStep / 5,
      amplitudeScaleStep: envelopAmplitudeScaleStep,
    };
    const direct = {
      amplitudeMin: directAmplitudeMin - directAmplitudeScaleStep / 5,
      amplitudeMinValue: directAmplitudeMin,
      amplitudeMax: directAmplitudeMax + directAmplitudeScaleStep / 5,
      amplitudeScaleStep: directAmplitudeScaleStep,
    };

    const envelopToPixels = prepareGoordinatesToPixelsConvertionFunction({
      frequencyMin,
      frequencyMax,
      amplitudeMin: envelop.amplitudeMin,
      amplitudeMax: envelop.amplitudeMax,
      height,
      width,
    });

    const directToPixels = prepareGoordinatesToPixelsConvertionFunction({
      frequencyMin,
      frequencyMax,
      amplitudeMin: direct.amplitudeMin,
      amplitudeMax: direct.amplitudeMax,
      height,
      width,
    });

    const indexOfFrequencyInterval000: IPreparedPeak[][] = [];

    const getIndexOfFrequencyInterval = (freq: number) => {
      const result = Math.round(
        (freq * FREQUENCY_INTERVALS_COUNT_FOR_HOVER) / (frequencyMax - frequencyMin),
      );

      return result;
    };

    for (const [, preparedPeak] of preparedPeaks) {
      frequencyMinAbsolute = Math.min(
        frequencyMinAbsolute,
        preparedPeak.inPixels.theory.envelop00000.x,
      );
      frequencyMaxAbsolute = Math.max(
        frequencyMaxAbsolute,
        preparedPeak.inPixels.theory.envelop00000.x,
      );
      preparedPeak.inPixels.theory.envelop = envelopToPixels.toPixels(
        preparedPeak.inPixels.theory.envelop00000,
      );
      preparedPeak.inPixels.theory.direct = directToPixels.toPixels(
        preparedPeak.inPixels.theory.direct00000,
      );
      if (preparedPeak.inPixels.real.envelop00000 !== null) {
        frequencyMinAbsolute = Math.min(
          frequencyMinAbsolute,
          preparedPeak.inPixels.real.envelop00000.x,
        );
        frequencyMaxAbsolute = Math.max(
          frequencyMaxAbsolute,
          preparedPeak.inPixels.real.envelop00000.x,
        );
        preparedPeak.inPixels.real.envelop = envelopToPixels.toPixels(
          preparedPeak.inPixels.real.envelop00000,
        );
      }
      if (preparedPeak.inPixels.real.direct00000 !== null) {
        preparedPeak.inPixels.real.direct = directToPixels.toPixels(
          preparedPeak.inPixels.real.direct00000,
        );
      }

      const temp = new Set<number>();
      temp.add(getIndexOfFrequencyInterval(preparedPeak.inPixels.theory.envelop00000.x));
      temp.add(getIndexOfFrequencyInterval(preparedPeak.inPixels.theory.direct00000.x));
      if (preparedPeak.inPixels.real.envelop00000) {
        temp.add(getIndexOfFrequencyInterval(preparedPeak.inPixels.real.envelop00000.x));
      }
      if (preparedPeak.inPixels.real.direct00000) {
        temp.add(getIndexOfFrequencyInterval(preparedPeak.inPixels.real.direct00000.x));
      }
      for (const freqIntervalIndex of temp) {
        let arr = indexOfFrequencyInterval000[freqIntervalIndex];
        if (arr === undefined) {
          arr = [];
          indexOfFrequencyInterval000[freqIntervalIndex] = arr;
        }
        arr.push(preparedPeak);
      }
    }
    const indexOfFrequencyInterval: IPreparedPeak[][] = [];
    for (let index = 1; index < indexOfFrequencyInterval000.length - 1; index++) {
      const arrLeft = indexOfFrequencyInterval000[index - 1] ?? [];
      const arrMiddle = indexOfFrequencyInterval000[index] ?? [];
      const arrRight = indexOfFrequencyInterval000[index + 1] ?? [];
      const temp = new Set<IPreparedPeak>([...arrLeft, ...arrMiddle, ...arrRight]);
      indexOfFrequencyInterval[index] = [...temp];
    }
    indexOfFrequencyInterval[0] = indexOfFrequencyInterval000[0];
    indexOfFrequencyInterval[indexOfFrequencyInterval000.length - 1] =
      indexOfFrequencyInterval000[indexOfFrequencyInterval000.length - 1];

    return {
      element,
      freqElement,
      frequencyMin,
      frequencyMax,
      frequencyScaleStep,
      freguencyFromXPixel: envelopToPixels.freguencyFromXPixel,
      envelop: { ...envelop, toPixels: envelopToPixels.toPixels },
      direct: { ...direct, toPixels: directToPixels.toPixels },
      preparedPeaks,
      groups: obj1.groups,
      indexOfFrequencyInterval,
      getIndexOfFrequencyInterval,
      frequencyMinAbsolute,
      frequencyMaxAbsolute,
    };
  }, [frequencyZoomParams, height, obj1, width]);

  return obj2;
}
