import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ReturnToActualZoom(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 16 16'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00067 1.75386C8.00067 1.23229 7.41255 0.927436 6.98645 1.22822C6.793 1.36477 6.60904 1.49277 6.43522 1.61372C5.22026 2.45911 4.50067 2.95981 4.50067 3.62466C4.50067 4.2895 5.22026 4.7902 6.43522 5.63559C6.60904 5.75654 6.793 5.88454 6.98645 6.02109C7.41255 6.32188 8.00067 6.01714 8.00067 5.49557C8.00067 5.08869 7.74383 4.7455 7.38298 4.48862L7.33801 4.45666C7.30427 4.43271 7.28074 4.41601 7.24113 4.38727H8.00067C8.42404 4.38727 8.85277 4.40581 9.27413 4.43717C11.1189 4.57447 11.3958 4.65167 11.816 5.07191C12.2363 5.49216 12.3135 5.76905 12.4508 7.61382C12.4821 8.03517 12.5007 8.46391 12.5007 8.88727C12.5007 9.31064 12.4821 9.73937 12.4508 10.1607C12.3135 12.0055 12.2363 12.2824 11.816 12.7026C11.3958 13.1229 11.1189 13.2001 9.27413 13.3374C8.85277 13.3687 8.42404 13.3873 8.00067 13.3873C7.57731 13.3873 7.14857 13.3687 6.72722 13.3374C4.88245 13.2001 4.60555 13.1229 4.18531 12.7026C3.76507 12.2824 3.6713 12.0055 3.53401 10.1607C3.52109 9.9871 3.51034 9.81223 3.50217 9.63699C3.48289 9.22322 3.14832 8.88727 2.73411 8.88727C2.3199 8.88727 1.98289 9.2232 2.00067 9.63704C2.00986 9.85082 2.02258 10.063 2.03814 10.2721C2.16794 12.016 2.2494 12.888 3.12465 13.7633C3.9999 14.6385 4.8719 14.7034 6.61589 14.8332C7.06797 14.8669 7.53432 14.8873 8.00067 14.8873C8.46702 14.8873 8.93337 14.8669 9.38546 14.8332C11.1294 14.7034 12.0014 14.6385 12.8767 13.7633C13.7519 12.888 13.8168 12.016 13.9466 10.2721C13.9803 9.81997 14.0007 9.35362 14.0007 8.88727C14.0007 8.42092 13.9803 7.95458 13.9466 7.50249C13.8168 5.7585 13.7519 4.8865 12.8767 4.01125C12.0014 3.136 11.1294 3.07111 9.38546 2.94131C8.93337 2.90766 8.46702 2.88727 8.00067 2.88727H7.24113C7.29598 2.84748 7.3549 2.80554 7.4179 2.7607C7.77874 2.50381 8.00067 2.16074 8.00067 1.75386Z"
        fill="black"
      />
    </SvgIcon>
  );
}
