import { UseFormReturn, Path } from 'react-hook-form';

import classnames from 'classnames';
import { getErrorByPath } from 'utils/get-error-by-path';

import CheckIcon from './check-icon';
import styles from './styles.module.css';

const FieldLabelErrorWrapper = <T,>({
  form,
  fieldName,
  label,
  children,
  icon,
  labelColor,
}: {
  //@ts-ignore
  form: UseFormReturn<T>;
  fieldName: Path<T>;
  label: string;
  children: any;
  icon?: any;
  labelColor?: string;
}) => {
  const errors = form.formState.errors;
  const { isSubmitted } = form.formState;

  const errMessage = isSubmitted
    ? //@ts-ignore
      getErrorByPath(errors, fieldName)?.message
    : undefined;
  const isValid = !errMessage;

  return (
    <div className={styles.layout}>
      <div className={styles.label} style={{ color: labelColor }}>
        {label}
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.input}>{children}</div>
        {icon && <div className={styles.icon}> {icon}</div>}
        {isSubmitted &&
          (isValid ? (
            <div className={styles.icon}>
              <CheckIcon color="#2ecc71" />
            </div>
          ) : (
            <div className={styles.icon}>
              <CheckIcon color="#eb4a13" />
            </div>
          ))}
      </div>
      <div
        className={classnames(styles.label, styles.error, {
          [styles.emptyError]: isValid,
        })}
      >
        {errMessage || '.'}
      </div>
    </div>
  );
};

export default FieldLabelErrorWrapper;
FieldLabelErrorWrapper.displayName = 'FieldLabelErrorWrapper';
