import { useEffect } from 'react';

import { useIsMutating, useIsFetching } from 'react-query';

import { useForm, useWatch } from 'react-hook-form';

import { LockIcon } from 'icons/lock-icon';
import { ReturnToActualZoom } from 'icons/return-to-actual-zoom';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import FieldInputText from 'components/fields/field-input-text';
import {
  IBearingCalculatorParms,
  IRawElement,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

import { validationSchemaBearingParams } from './validation-schema-bearing-params';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      paddingBottom: 20,
    },

    row: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      gap: 10,
    },
    item: { flexBasis: '20%', marginBottom: -10 },
    resetButton: {
      maxWidth: '30px',
    },
    speedStyle: {
      width: '100%',
      minHeight: 43,
      backgroundColor: '#F2F3F7',
      border: '1px solid rgba(188, 195, 208, 0.5)',
      borderRadius: '6px',
      fontSize: '14px',
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      position: 'relative',
      fontWeight: 500,
      padding: '2px 0 0 9px',
    },
    lockIconStyle: {
      position: 'absolute',
      right: 10,
      top: 9,
    },
    labelStyle: {
      position: 'absolute',
      left: 2,
      top: -25,
      fontSize: '14px',
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.87)',
    },
  }),
);

export function CalculatorForm({
  data,
  onSubmit,
  onResetParams,
  subElement,
}: {
  data: IBearingCalculatorParms | undefined;
  onSubmit: (a: IBearingCalculatorParms) => void;
  onResetParams: () => void;
  subElement: IRawElement | undefined;
}) {
  const classes = useStyles();

  const form = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchemaBearingParams),
    defaultValues: data,
    shouldFocusError: true,
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  if (Object.keys(errors).length !== 0) {
    console.log('%c CalculatorForm errors = ', 'color: red', errors);
  }

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const isMutatingOrFetching = useIsMutating() + useIsFetching() > 0;

  const outputFrequency = useWatch({
    control: form.control,
    name: 'speed',
    defaultValue: (subElement?.subelementFrequency ?? '') as any,
  });

  let newOutputFrequency =
    ((outputFrequency ?? 0) / parseFloat(subElement?.subelementFrequency ?? '1')) *
      parseFloat(subElement?.outputFrequency ?? '1') || '';
  if (typeof newOutputFrequency === 'number') {
    newOutputFrequency = newOutputFrequency.toFixed(2);
  }

  const edIzm = String(subElement?.outputFrequency ?? '').split(' ')[1] ?? '';

  return (
    <form id="CalculatorForm" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.item}>
            <div className={classes.speedStyle}>
              <div className={classes.labelStyle}>{`Режим работы ${edIzm}`}</div>
              {newOutputFrequency}
              <LockIcon style={{ fontSize: 22 }} className={classes.lockIconStyle} />
            </div>
          </div>

          <div className={classes.item}>
            <FieldInputText<IBearingCalculatorParms>
              fieldName="innerDiameter"
              label="Внутренний диаметр (мм)"
              form={form}
              defaultValue={data?.innerDiameter || ''}
            />
          </div>

          <div className={classes.item}>
            <FieldInputText<IBearingCalculatorParms>
              fieldName="outerDiameter"
              label="Наружний диаметр (мм)"
              form={form}
              defaultValue={data?.outerDiameter || ''}
            />
          </div>

          <div className={classes.item}>
            <FieldInputText<IBearingCalculatorParms>
              fieldName="rollingElementsDiameter"
              label="Диаметр тел качения (мм)"
              form={form}
              defaultValue={data?.rollingElementsDiameter || ''}
            />
          </div>
          <div className={classes.item}>
            <Tooltip title={'Сбросить все изменения'} placement="top">
              <Button
                className={classes.resetButton}
                size="small"
                variant="contained"
                color="inherit"
                onClick={() => {
                  reset(data);
                  onResetParams();
                }}
              >
                <ReturnToActualZoom style={{ fontSize: 20 }} />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.item}>
            <FieldInputText<IBearingCalculatorParms>
              fieldName="speed"
              label="Скорость (об/мин)"
              form={form}
              defaultValue={data?.speed || ''}
            />
          </div>
          <div className={classes.item}>
            <FieldInputText<IBearingCalculatorParms>
              fieldName="rollingElementsNumber"
              label="Число тел качения"
              form={form}
              defaultValue={data?.rollingElementsNumber || ''}
            />
          </div>

          <div className={classes.item}>
            <FieldInputText<IBearingCalculatorParms>
              fieldName="contactAngle"
              label="Угол контакта (град)"
              form={form}
              defaultValue={data?.contactAngle || ''}
            />
          </div>
          <div className={classes.item}></div>

          <div className={classes.item}>
            <Button
              size="small"
              disabled={!form.formState.isDirty || isMutatingOrFetching}
              type="submit"
              variant="contained"
              color="primary"
            >
              {'Пересчитать частоты'}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
