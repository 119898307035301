import { useState, useEffect, useMemo, useCallback } from 'react';

import UnitIcon from 'icons/unit-icon';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import classNames from 'classnames';
import { MnemoSchemeRenderer } from 'components/mnemo-scheme-renderer';
import { StyledCheckbox } from 'components/styled-checkbox';
import { IElement, IElementType } from 'interfaces';
import { YELLOW_COLOR } from 'stream-constants';

import { BearingsList } from './bearings-list';
import { elementsKey, getElementIdsFromKey } from './common';
import { ElementsPairList } from './elements-pair-list';
import { useMemoNodesElementsPairs } from './hook/use-get-machine-and-all-modes-by-machine-id';

type Props = { newLayoutSize: { height: number; width: number } };

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
  },
  expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
  root: {
    margin: '0 0 0 10px',
  },
  content: {
    margin: '12px 0 0 0',
    padding: 0,
    '&$expanded': {
      margin: 0,
      padding: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    layout: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      padding: '16px 10px 10px 10px',
    },
    nodesTitle: {
      marginLeft: 10,
    },
    nodesSubTitle: {
      marginBottom: 2,
    },
    yellow: {
      color: YELLOW_COLOR,
    },
    nodesStyle: {
      marginTop: 10,
      height: (props) => props.newLayoutSize.height - 70,
      overflowY: 'auto',
      padding: '10px 10px 10px 4px',
      borderRight: '1px solid #ddd',
    },
    button: {
      position: 'absolute',
      bottom: 20,
      right: 20,
      width: '200px',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      alignItems: 'center',
    },
    unitIconMargin: {
      marginTop: '6px',
    },
  }),
);

interface IProps {
  machineLinkId: string | undefined;
  onClose: (result?: {
    selectedNodes: string[];
    selectedElements: [string, string][];
    summary: string;
  }) => void;
  newLayoutSize: { height: number; width: number };
  defaultUnits: string[];
  defaultElements: string[][] | undefined;
}

export function DetailedElemsListPage({
  machineLinkId,
  newLayoutSize,
  onClose,
  defaultUnits,
  defaultElements,
}: IProps) {
  const classes = useStyles({ newLayoutSize });

  const [selectedNodes, setSelectedNodes] = useState<Set<string>>(new Set(defaultUnits));
  const [selectedElements, setSelectedElements] = useState<Set<string>>(new Set());
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());

  const {
    vertexByIdDictionary,
    vertexByMnemonicIdDictionary,
    nodes,
    nodeElements,
    nodeCogWheelPairs,
    totalElemsAndPairsCount,
    machine,
  } = useMemoNodesElementsPairs(machineLinkId);

  const { selectedVertexIds } = useMemo(() => {
    const selectedVertexIds0 = new Map<string, number>();
    for (let key of selectedElements) {
      const arr = getElementIdsFromKey(key);
      selectedVertexIds0.set(arr[0], -1);
      if (arr[1]) {
        selectedVertexIds0.set(arr[1], -1);
      }
    }

    return { selectedVertexIds: selectedVertexIds0 };
  }, [selectedElements]);

  useEffect(() => {
    if (vertexByMnemonicIdDictionary) {
      if (defaultElements) {
        const newSelectedElementsKeys = defaultElements.map(([id1, id2]) => {
          const newId1 = vertexByMnemonicIdDictionary.get(id1)?.vertex?.id ?? '';
          const newId2 = vertexByMnemonicIdDictionary.get(id2)?.vertex?.id ?? '';
          const key = elementsKey([{ id: newId1 }, { id: newId2 }]);

          return key;
        });
        setSelectedElements(new Set(newSelectedElementsKeys));
      }
    }
  }, [defaultElements, nodeElements, vertexByMnemonicIdDictionary]);

  const allParams = {
    queryParams: {},
    setQueryParams: () => {},
    query: { data: machine } as any,
    vertexByIdDictionary,
    vertexByMnemonicIdDictionary,
    dataId: machine?.id ?? '',
  };

  const menemoSchemelayout = {
    width: `${Math.round(newLayoutSize.width * 0.667) - 20}`,
    height: `${Math.round(newLayoutSize.height) - 122}`,
  };

  const handleElementOrPairCheckboxChangeByNodeId = (nodeId: string, value: boolean) => {
    const newSelectedNodes = new Set([...selectedNodes]);
    const elems = nodeElements.get(nodeId);
    const pairs = nodeCogWheelPairs.get(nodeId);
    const newSelectedElements = new Set([...selectedElements]);
    if (value) {
      newSelectedNodes.add(nodeId);
      if (elems) {
        for (let { id } of elems) {
          newSelectedElements.add(elementsKey([{ id }, { id: '' }]));
        }
      }
      if (pairs) {
        for (let pair of pairs) {
          newSelectedElements.add(elementsKey(pair));
        }
      }
    } else {
      newSelectedNodes.delete(nodeId);
      if (elems) {
        for (let { id } of elems) {
          newSelectedElements.delete(elementsKey([{ id }, { id: '' }]));
        }
      }
      if (pairs) {
        for (let pair of pairs) {
          newSelectedElements.delete(elementsKey(pair));
        }
      }
    }
    setSelectedNodes(newSelectedNodes);
    setSelectedElements(newSelectedElements);
  };

  const handleAllNodesCheckBoxChanged = (_: any, value: boolean) => {
    if (value) {
      setSelectedNodes(new Set(nodes.map((node) => node.id)));
      const newSelectedElements = new Set([...selectedElements]);
      nodes.forEach((node) => {
        const nodeId = node.id;
        const elems = nodeElements.get(nodeId);
        const pairs = nodeCogWheelPairs.get(nodeId);
        if (elems) {
          for (let { id } of elems) {
            newSelectedElements.add(elementsKey([{ id }, { id: '' }]));
          }
        }
        if (pairs) {
          for (let pair of pairs) {
            newSelectedElements.add(elementsKey(pair));
          }
        }
      });
      setSelectedElements(newSelectedElements);
    } else {
      setSelectedNodes(new Set());
      setSelectedElements(new Set());
    }
  };

  const okButtonClick = () => {
    const elemsArr: [string, string][] = [];
    for (let item of selectedElements) {
      const [id1, id2] = getElementIdsFromKey(item);
      const mnemonicId1 = vertexByIdDictionary.get(id1)?.vertex?.mnemonicSchemeLinkId ?? '';
      const mnemonicId2 = vertexByIdDictionary.get(id2)?.vertex?.mnemonicSchemeLinkId ?? '';
      elemsArr.push([mnemonicId1, mnemonicId2]);
    }
    let summary = '';
    if (selectedNodes.size === 0) {
      summary = 'пустой список';
    } else {
      if (selectedNodes.size === nodes.length) {
        summary = `выбраны все узлы (${selectedNodes.size} шт.)`;
      } else {
        summary = `выбрано ${selectedNodes.size} из ${nodes.length} узлов`;
      }
      if (totalElemsAndPairsCount > 0) {
        summary += ` и ${((elemsArr.length / totalElemsAndPairsCount) * 100).toFixed(
          0,
        )}% элементов`;
      } else {
        summary += `, а элементов там небыло`;
      }
    }
    onClose({ selectedNodes: [...selectedNodes], selectedElements: elemsArr, summary });
  };

  const handleMnemoElementClicked = useCallback(
    (menonicId: string) => {
      const nodeClickedItem = vertexByMnemonicIdDictionary.get(menonicId);

      if (nodeClickedItem) {
        const vertex = nodeClickedItem.vertex as IElement;
        const elemNodeId = nodeClickedItem.previousVertexesArray?.[1];
        const newSelectedElements = new Set([...selectedElements]);

        if (
          vertex.elementType !== IElementType.COGWHEEL &&
          vertex.elementType !== IElementType.CLUTCH
        ) {
          const key = elementsKey([{ id: vertex.id }, { id: '' }]);
          if (selectedElements.has(key)) {
            newSelectedElements.delete(key);
          } else {
            newSelectedElements.add(key);
          }
        } else {
          const keys: string[] = [];
          let checked: boolean = false;
          nodeCogWheelPairs.forEach((pairs) => {
            pairs.forEach((pair) => {
              if (pair[0].id === vertex.id || pair[1].id === vertex.id) {
                const key = elementsKey(pair);
                keys.push(key);
                checked = checked || selectedElements.has(key);
              }
            });
          });
          if (checked) {
            keys.forEach((key) => {
              newSelectedElements.delete(key);
            });
          } else {
            keys.forEach((key) => {
              newSelectedElements.add(key);
            });
          }
        }
        setSelectedElements(newSelectedElements);
        elemNodeId && setExpandedNodes(new Set([elemNodeId]));
      }
    },
    [nodeCogWheelPairs, selectedElements, vertexByMnemonicIdDictionary],
  );

  return (
    <div className={classes.layout}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Typography variant={'h5'} color="secondary" className={classes.nodesTitle}>
            Узлы
          </Typography>
          <div className={classes.nodesStyle}>
            <div className={classNames(classes.row, classes.nodesSubTitle)}>
              <StyledCheckbox
                checked={selectedNodes.size === nodes.length}
                onChange={handleAllNodesCheckBoxChanged}
              />
              <Tooltip title={'Все узлы'} placement={'top'}>
                <MoreHorizIcon />
              </Tooltip>
              <Typography variant={'body1'} color="secondary">
                {'Все узлы'}
              </Typography>
            </div>
            {nodes.map((node) => (
              <div key={node.id}>
                <Accordion
                  expanded={expandedNodes.has(node.id)}
                  onChange={() => {
                    const newExpandedNodes = new Set([...expandedNodes]);
                    if (expandedNodes.has(node.id)) {
                      newExpandedNodes.delete(node.id);
                    } else {
                      newExpandedNodes.add(node.id);
                    }
                    setExpandedNodes(newExpandedNodes);
                  }}
                >
                  <AccordionSummary
                    aria-controls={`panel-${node.id}-content`}
                    id={`panel-${node.id}-content`}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <div className={classes.row}>
                      <StyledCheckbox
                        checked={selectedNodes.has(node.id)}
                        onChange={(_, value) =>
                          handleElementOrPairCheckboxChangeByNodeId(node.id, value)
                        }
                      />
                      <Tooltip title={'Узел'} placement={'top'}>
                        <div className={classes.unitIconMargin}>
                          <UnitIcon />
                        </div>
                      </Tooltip>
                      <Typography variant={'body1'} color="secondary">
                        {node.name}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <BearingsList
                        nodeId={node.id}
                        nodeElements={nodeElements}
                        selectedElements={selectedElements}
                        setSelectedElements={setSelectedElements}
                        setSelectedNodes={setSelectedNodes}
                      />
                      <ElementsPairList
                        nodeId={node.id}
                        nodeCogWheelPairs={nodeCogWheelPairs}
                        selectedElements={selectedElements}
                        setSelectedElements={setSelectedElements}
                        setSelectedNodes={setSelectedNodes}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </div>
          <Button
            id={'id_detaiiled_setting_save_button_352'}
            disabled={selectedElements.size === 0}
            className={classes.button}
            variant="contained"
            size="large"
            color="primary"
            onClick={okButtonClick}
          >
            Сохранить
          </Button>
        </Grid>
        <Grid item xs={8}>
          <Typography variant={'h5'} color="secondary">
            Мнемосхема
          </Typography>

          <MnemoSchemeRenderer
            allParams={allParams}
            key={machineLinkId}
            handleMnemoElementClicked={handleMnemoElementClicked}
            selectedVertexIds={selectedVertexIds}
            withInfo={false}
            layout={menemoSchemelayout}
          />
          <Typography variant={'body2'} color="primary">
            - красным цветом указаны элементы выбранных узлов
          </Typography>
          <Typography variant={'body2'} color="secondary">
            - черным цветом - не выбранные
          </Typography>
          <Typography variant={'body2'} className={classes.yellow}>
            - желтым цветом - не привязанные, следовательно не могут быть выбранными
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
