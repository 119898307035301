import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useMutationUpdateSpectrumDefectSummary } from 'pages-diagnostic/diagnostic-spectrum/query-hooks/query-hooks';
import { ISummaryElement } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'calc(100%+12px)',
      border: '2px solid rgba(188, 195, 208, 0.5)',
      borderRadius: '16px',
      marginLeft: -12,
      padding: '4px 10px',
    },
    theInput: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    label: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '24px',
    },
    buttonContainer: {
      marginLeft: -12,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    buttonStyle: {
      width: '33%',
    },
    errMessage: {
      fontSize: '11px',
      color: 'var(--main-red-color)',
    },
  }),
);

const TEXT = 'TEXT';
const INPUT_VALUE = 'INPUT_VALUE';
const NO = 'NO';

const VALIDATION_STRING_LENGTH = 1000;

export function TextInput({
  text,
  diagnosticId,
  element,
}: {
  text: string;
  diagnosticId: string;
  element: ISummaryElement | undefined;
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string>('');

  const error =
    inputValue.length >= VALIDATION_STRING_LENGTH
      ? `длинна вывода должна быть меньше чем ${VALIDATION_STRING_LENGTH}`
      : '';

  useEffect(() => {
    setInputValue(element?.summaryText ?? '');
  }, [element?.summaryText]);

  const mutationUpdate = useMutationUpdateSpectrumDefectSummary(
    { diagnosticId, element, summaryText: (inputValue ?? '').trim() },
    (d: any) => {
      //
    },
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  let buttonLabel = '';
  const theCase = `${text ? TEXT : NO}-${inputValue ? INPUT_VALUE : NO}`;
  switch (theCase) {
    case `${NO}-${NO}`:
    case `${NO}-${INPUT_VALUE}`:
      buttonLabel = 'Добавить вывод';
      break;
    case `${TEXT}-${NO}`:
      buttonLabel = 'Удалить вывод';
      break;
    case `${TEXT}-${INPUT_VALUE}`:
      buttonLabel = 'Изменить вывод';
      break;
    default:
      throw new Error(`невозможный кейс для buttonLabel (theCase = ${theCase})`);
  }

  return (
    <>
      <br />
      <div className={classes.label}>Текст вывода</div>
      <form className={classes.root} noValidate autoComplete="off">
        <Input
          placeholder="Введите текст"
          classes={{ root: classes.theInput }}
          multiline
          maxRows={8}
          minRows={4}
          value={inputValue ?? ''}
          onChange={handleChange}
          fullWidth
          disableUnderline
        />
      </form>
      {error && <div className={classes.errMessage}>{error}</div>}

      <br />
      <div className={classes.buttonContainer}>
        <Button
          disabled={(inputValue ?? '') === (text ?? '') || error !== ''}
          className={classes.buttonStyle}
          variant="contained"
          onClick={() => {
            mutationUpdate.mutate();
          }}
          color="primary"
        >
          {buttonLabel}
        </Button>
      </div>
    </>
  );
}
