import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { AllIcon } from 'icons/custom-checkbox-icons/all-icon';
import { NoneIcon } from 'icons/custom-checkbox-icons/none-icon';
import { IDiagnostic } from 'interfaces';
import { useUpdateIsCheckedStatus } from 'pages-diagnostic/hooks';
import { useGetFullName } from 'utils/hooks/use-get-full-name';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 8,
    },
    row: {
      marginLeft: 14,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 8,
    },

    message: {
      fontWeight: 400,
      fontSize: '17px',
      color: '#1D2023',
    },
    interlineInterval: {
      marginBottom: 4,
    },

    pointer: {
      cursor: 'pointer',
    },

    fullName: {
      marginTop: -3,
      fontWeight: 400,
      fontSize: '17px',
      color: '#1D2023',
    },
    email: {
      marginTop: -3,
      fontWeight: 400,
      fontSize: '17px',
      color: '#626C77',
    },
    disabled: {
      opacity: 0.5,
    },
  }),
);

export function ShowIfDiagnosticIsChecked({
  data,
  measurementsAreNotUploaded,
}: {
  data: IDiagnostic | undefined;
  measurementsAreNotUploaded: boolean;
}) {
  const classes = useStyles();
  const { approverFullName, creatorEmail, creatorFullName, isChecked } = data ?? {};

  const query = useGetFullName();
  const userFullName = query?.data?.fullname ?? '';

  const mutateIsCheckedStatus = useUpdateIsCheckedStatus(
    data?.id ?? 'mutateIsCheckedStatusUndefined',
    () => {},
  );
  const disabledByAllMeans = measurementsAreNotUploaded || userFullName !== approverFullName;

  return (
    <div className={classNames(classes.root, { [classes.disabled]: disabledByAllMeans })}>
      <Tooltip
        title={
          disabledByAllMeans ? (
            measurementsAreNotUploaded ? (
              <div>
                <div>Недоступно, так как</div>
                <div>загрузка измерений не завершена.</div>
              </div>
            ) : userFullName !== approverFullName ? (
              <div>
                <div>Доступно только для назначенного диагноста</div>
                <div>({approverFullName}).</div>
              </div>
            ) : (
              ''
            )
          ) : (
            <div>
              <div>Изменить статут диагностики</div>
              <div>(проверенная / не проверенная).</div>
            </div>
          )
        }
        placement={'top'}
      >
        <div
          id="id_is_diagnostic_checked_392"
          className={classNames(classes.row, classes.interlineInterval, {
            [classes.pointer]: !disabledByAllMeans,
          })}
          onClick={() => !disabledByAllMeans && mutateIsCheckedStatus.mutate(!isChecked)}
        >
          {isChecked && <AllIcon style={{ fontSize: 24 }} onClick={() => {}} />}
          {!isChecked && <NoneIcon style={{ fontSize: 24 }} onClick={() => {}} />}
          <div className={classes.message}>Диагностика проверена</div>
        </div>
      </Tooltip>
      <div className={classes.row}>
        <div className={classes.fullName}>Создатель диагностики:</div>
        <div className={classes.fullName}>{creatorFullName}</div>
        <div className={classes.email}>{creatorEmail}</div>
      </div>
    </div>
  );
}
