import { useContext, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { ContextSettings } from 'context/context-settings';

import { FullMessageModalDialog } from './full-message-modal-dialog';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(4),
      width: 'var(--layout-max-width)',
    },
  },
  message: {
    whiteSpace: 'pre-wrap',
  },
}));

const DEFAULT_MESSAGE = { header: '', fullMessage: '', fromServer: false };

export default function GlobalErrorNotificationBar() {
  const classes = useStyles();
  const { notificationMessage, setNotificationMessage, layoutSize } = useContext(ContextSettings);

  const [fullMessageModal, setFullMessageModal] = useState<string | undefined>();

  const { header, fullMessage, fromServer } = notificationMessage ?? DEFAULT_MESSAGE;

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotificationMessage(undefined);
  };

  return (
    <>
      <div className={classes.root}>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={!!notificationMessage}
          autoHideDuration={15000}
          onClose={handleClose}
          disableWindowBlurListener={true}
        >
          <Alert
            onClose={handleClose}
            // severity={fromServer ? 'success' : 'warning'}
            severity={fromServer ? 'success' : 'success'}
            action={
              <>
                {fullMessage && (
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                      setFullMessageModal(fullMessage);
                      setNotificationMessage(undefined);
                    }}
                  >
                    <ErrorOutlineIcon fontSize="small" color="secondary" />
                  </IconButton>
                )}
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
          >
            <div className={classes.message}>{header}</div>
          </Alert>
        </Snackbar>
      </div>
      {fullMessageModal && (
        <FullMessageModalDialog
          fullMessage={fullMessageModal}
          setClose={() => setFullMessageModal(undefined)}
          layoutSize={layoutSize}
        />
      )}
    </>
  );
}
