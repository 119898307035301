import { IMode, IModes, IHttpMethods } from 'interfaces';
import {
  GET_ALL_MODES_BY_M_ACHINE_ID,
  GET_MODES_BY_UNIT_ID_URL,
  ADD_MODE_BY_UNIT_ID_URL,
  DELETE_MODE_BY_ID_URL,
  GET_MODE_BY_ID_URL,
  UPDATE_MODE_BY_ID_URL,
  ASSIGN_TO_ME_AS_CREATOR_URL,
} from 'services/urls';
import { IS_SERVER_MOCKED } from 'stream-constants';
import { delay } from 'utils/delay';
import { generateUnicId } from 'utils/generate-unic-id';

import { fetchData, probableError } from '../service-utils';

import { base } from './mock-data';

const BASE_TIME = 300;
const MIN_TIME = 200;

export const getModesByUnitId = async (unitId: string): Promise<IModes> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      const modes = base[0].modes
        .filter((item) => item?.unitId && item.unitId === unitId)
        .sort((a, b) => (a?.order ?? 1) - (b?.order ?? 1));
      if (!modes) {
        result = Promise.reject(
          new Error(`getModesByUnitId: Не найден modes для unitId = ${unitId}`),
        );
      } else {
        const response = modes.map((item) => ({ ...item, combination: [] }));

        result = Promise.resolve(response as IModes);
      }
    }
  } else {
    result = fetchData({
      url: GET_MODES_BY_UNIT_ID_URL(unitId),
      method: IHttpMethods.POST,
      name: 'getModesByUnitId',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getModesByUnitId took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getModeByid = async (id: string): Promise<IMode> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      const mode = base[0].modes.find((item) => item?.id && item.id === id);
      if (!mode) {
        result = Promise.reject(new Error(`getModeByid: Не найден modes для id = ${id}`));
      } else {
        result = Promise.resolve(mode);
      }
    }
  } else {
    result = fetchData({
      url: GET_MODE_BY_ID_URL(id),
      method: IHttpMethods.GET,
      name: 'getModeByid',
      emptyResponse: false,
    });
  }
  const t1 = performance.now();
  console.log(`getModeByid took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const updateMode = async (modeTable: IMode): Promise<boolean> => {
  const t0 = performance.now();
  /**
   * Костыль для бекенда - Маппинг пустых строк в null для значений elemInId и elemOutId
   */
  const newCombination = {
    ...modeTable,
    combination: modeTable.combination.map((comb) => ({
      ...comb,
      shafts: comb.shafts?.map((shaft) => {
        const newShaft = { ...shaft };
        if (!newShaft.elemInId) {
          //@ts-ignore
          delete newShaft.elemInId;
        }
        if (!newShaft.elemOutId) {
          //@ts-ignore
          delete newShaft.elemOutId;
        }

        return newShaft;
      }),
    })),
  };
  /**
   * ********************************************************************************
   */

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const index = base[0].modes.findIndex((item) => item?.id && item.id === newCombination.id);
    if (index === -1) {
      result = Promise.reject(
        new Error(`Item not found. newCombination = ${JSON.stringify(newCombination, null, 4)}`),
      );
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base[0].modes[index] = newCombination;
        result = Promise.resolve(true);
      }
    }
  } else {
    result = fetchData({
      url: UPDATE_MODE_BY_ID_URL(newCombination.id),
      method: IHttpMethods.PUT,
      name: 'updateMode',
      body: JSON.stringify(newCombination),
      emptyResponse: true,
    });
  }

  const t1 = performance.now();
  console.log(`updateMode took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const deleteModeById = async (id: string): Promise<boolean> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const index = base[0].modes.findIndex((item) => item?.id && item.id === id);
    if (index === -1) {
      result = Promise.reject(new Error(`Mode not found. id = ${id}`));
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base[0].modes.splice(index, 1);
        result = Promise.resolve(true);
      }
    }
  } else {
    result = fetchData({
      url: DELETE_MODE_BY_ID_URL(id),
      method: IHttpMethods.DELETE,
      name: 'deleteModeById',
      emptyResponse: true,
    });
  }

  const t1 = performance.now();
  console.log(`deleteModeById took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const addMode = async (mode: IMode): Promise<string> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      const newid = generateUnicId();
      base[0].modes.push({
        ...mode,
        id: newid,
      });
      result = Promise.resolve(newid);
    }
  } else {
    result = fetchData({
      url: ADD_MODE_BY_UNIT_ID_URL(mode.unitId),
      method: IHttpMethods.POST,
      name: 'addMode',
      body: JSON.stringify(mode),
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`addMode took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getAllModesByMachineId = async (machineId: string): Promise<IMode[] | undefined> => {
  if (!machineId) {
    return Promise.resolve(undefined);
  }
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      const modes = base.find((item) => item.machineId === machineId)?.modes;
      if (modes) {
        modes.sort((a, b) => (a?.order ?? 1) - (b?.order ?? 1));
        const response = modes.map((item) => item);
        result = Promise.resolve(response as IMode[]);
      } else {
        result = Promise.resolve(undefined);
      }
    }
  } else {
    result = fetchData({
      url: GET_ALL_MODES_BY_M_ACHINE_ID(machineId),
      method: IHttpMethods.GET,
      name: 'getAllModesByMachineId',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getAllModesByMachineId took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
export const assignToMeAsCreator = async (machineId: string): Promise<IMode[] | undefined> => {
  if (!machineId) {
    return Promise.resolve(undefined);
  }
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(true);
    }
  } else {
    result = fetchData({
      url: ASSIGN_TO_ME_AS_CREATOR_URL(machineId),
      method: IHttpMethods.PUT,
      name: 'assignToMeAsCreator',
      emptyResponse: true,
    });
  }

  const t1 = performance.now();
  console.log(`assignToMeAsCreator took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const duplicate = async (id: string): Promise<boolean> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const index = base[0].modes.findIndex((item) => item?.id && item.id === id);
    if (index === -1) {
      result = Promise.reject(new Error(`Mode not found. id = ${id}`));
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base[0].modes.splice(index, 1);
        result = Promise.resolve(true);
      }
    }
  } else {
    result = fetchData({
      url: DELETE_MODE_BY_ID_URL(id),
      method: IHttpMethods.DELETE,
      name: 'duplicate',
      emptyResponse: true,
    });
  }

  const t1 = performance.now();
  console.log(`duplicate took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
