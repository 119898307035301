import { FC, useState } from 'react';

import { MenuIconNew } from 'icons/menu-icon-new';

import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';

const IconMenu: FC<{
  tooltipTitle: string;
  menuItems: any;
  disabled?: boolean;
  color?: 'primary' | 'secondary';
  fontSize?: number;
  forId?: string;
}> = ({ tooltipTitle, menuItems, disabled, color, fontSize, forId }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <div onClick={handleClose}>
      <Tooltip title={tooltipTitle}>
        <span>
          <IconButton
            id={forId}
            disabled={disabled}
            aria-haspopup="true"
            aria-label={tooltipTitle}
            onClick={handleClick}
            size="small"
            color={color ?? 'inherit'}
          >
            <MenuIconNew />
          </IconButton>
        </span>
      </Tooltip>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {menuItems}
      </Menu>
    </div>
  );
};
export default IconMenu;
IconMenu.displayName = 'IconMenu';
