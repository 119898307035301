import React, { useMemo } from 'react';

import { StandAlonDropdown } from 'components/stand-alon-dropdown';
import { IRawElement } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

const MAIN_SUBELEMENT_STUB = '---';

export const OtherElementName = React.memo(function ({
  elementGuideId,
  freqElementId,
  elements,
  setOtherFreqElementId,
}: {
  elementGuideId: string;
  freqElementId: string;
  elements: IRawElement[] | undefined;
  setOtherFreqElementId: (id: string | undefined) => void;
}) {
  const elementsOptions = useMemo(() => {
    const result =
      elements?.map((elem) => ({
        value: elem.elementId,
        label: elem.elementId === elementGuideId ? MAIN_SUBELEMENT_STUB : elem.elementName,
      })) ?? [];

    return result;
  }, [elementGuideId, elements]);

  return (
    <StandAlonDropdown
      unicFieldName="freqElementId"
      key={freqElementId}
      label="Выбор другого элемента"
      options={elementsOptions}
      name={freqElementId}
      setName={(elem) => setOtherFreqElementId(elem)}
      placeholder={MAIN_SUBELEMENT_STUB}
    />
  );
});
