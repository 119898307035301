import React from 'react';

import { TrueElementIcon } from 'icons/true-element-icon';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { elementIcon } from 'components/element-icon';
import { MODES_DICT_NAMES } from 'interfaces';
import { ShowDefect } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-list/components/show-defect';
import {
  ISubElement,
  subElementUnicId,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

const LOOK_TO_MHEMOSCHEME_WIDTH = 90;

type IProps = { itemWidth: number; isHeader: boolean };

const useStyles = makeStyles<Theme, IProps>((theme) =>
  createStyles({
    root: {
      paddingTop: '12px',
    },
    itemRow: {
      cursor: 'pointer',
      border: (props) => (props.isHeader ? 'none' : '1px solid #DADEE5'),
      borderRadius: '16px',
      maxWidth: (props) => props.itemWidth + 8,
      minWidth: (props) => props.itemWidth + 8,
      minHeight: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: (props) => (props.isHeader ? '#626C77' : '#000'),
      '&:hover': {
        border: (props) => (props.isHeader ? 'none' : '1px solid #626C77'),
      },
    },
    isHovered: {
      backgroundColor: '#EBEEF1 !important',
    },
    isLastModified: {
      backgroundColor: '#E1F3FE',
    },

    itemNumber: {
      padding: '0 6px 0 20px',
      maxWidth: 42,
      minWidth: 42,
      fontWeight: (props) => (props.isHeader ? 400 : 700),
      fontSize: '14px',
      cursor: 'pointer',
    },
    itemIcon: {
      padding: '0 2px 0 2px',
      maxWidth: 70,
      minWidth: 70,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 10,
    },
    itemText: {
      padding: '0 6px',
      maxWidth: (props) => Math.round((props.itemWidth - 100) * 0.55 * 0.56),
      minWidth: (props) => Math.round((props.itemWidth - 100) * 0.55 * 0.56),
      whiteSpace: 'nowrap',
      fontWeight: 500,
      fontSize: '14px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    itemText2: {
      padding: '0 6px',
      maxWidth: (props) => Math.round((props.itemWidth - 100) * 0.55 * 0.43),
      minWidth: (props) => Math.round((props.itemWidth - 100) * 0.55 * 0.43),
      wordWrap: 'break-word',
      fontWeight: 400,
      fontSize: '14px',
    },
    itemDefects: {
      padding: '0 10px 0 6px',
      maxWidth: (props) => Math.round((props.itemWidth - 100) * 0.58) - LOOK_TO_MHEMOSCHEME_WIDTH,
      minWidth: (props) => Math.round((props.itemWidth - 100) * 0.58) - LOOK_TO_MHEMOSCHEME_WIDTH,
    },
    iconScale: {
      marginTop: 3,
      transform: 'scale(0.9)',
    },
    itemText22: {
      padding: '0',
      maxWidth: LOOK_TO_MHEMOSCHEME_WIDTH,
      minWidth: LOOK_TO_MHEMOSCHEME_WIDTH,
      wordWrap: 'break-word',
      fontWeight: 400,
      fontSize: '12px',
      color: '#626C77',
      cursor: 'pointer',
    },
    iconEyeIcon: {
      marginTop: 4,
      transform: 'scale(0.6)',
      cursor: 'pointer',
    },
    hidden: {
      visibility: 'hidden',
    },
    greyTextColor: {
      padding: '0 6px',
      opacity: 0.6,
      fontWeight: 400,
    },
    uncertainTextColor: {
      color: '#ff6e3e',
    },
  }),
);

export const ElementListItem = React.memo(function ({
  index,
  item,
  itemWidth,
  defectsDict,
  isHeader,
  lastModifiedSpectrumElement,
  hoveredElementsMnemonicId,
  setHoveredElementsMnemonicId,
  onGotoTheSpectrumClick,
}: {
  index: string;
  item: ISubElement | undefined;
  itemWidth: number;
  defectsDict: Record<string, string>;
  isHeader: boolean;
  lastModifiedSpectrumElement?: string;
  hoveredElementsMnemonicId: string | undefined;
  setHoveredElementsMnemonicId: (a: string | undefined) => void;
  onGotoTheSpectrumClick?: (a: string) => void;
}) {
  const classes = useStyles({ itemWidth, isHeader });
  if (!isHeader && item === undefined) {
    throw new Error('Не задан элемент списка');
  }
  const IconComponent = elementIcon(item?.subElementType);

  return (
    <div
      className={classes.root}
      onMouseEnter={() => item && setHoveredElementsMnemonicId(item.mnemonicSchemeLinkId)}
      onMouseLeave={() => item && setHoveredElementsMnemonicId(undefined)}
    >
      <div
        id={subElementUnicId(item?.elementGuideLinkId ?? '')}
        className={classNames(classes.itemRow, {
          [classes.isHovered]: item && hoveredElementsMnemonicId === item.mnemonicSchemeLinkId,
          [classes.isLastModified]: item && item.elementGuideLinkId === lastModifiedSpectrumElement,
        })}
        onClick={() => {
          !isHeader &&
            item &&
            onGotoTheSpectrumClick &&
            onGotoTheSpectrumClick(item.elementGuideLinkId);
        }}
      >
        <div className={classes.itemNumber}>{isHeader ? '№' : <div>{index}</div>}</div>
        <div className={classes.itemIcon}>
          {!isHeader && (
            <>
              <Tooltip
                title={'Добавление дефектов завершено'}
                placement={'top'}
                disableFocusListener={!item?.isReady}
                disableHoverListener={!item?.isReady}
                disableTouchListener={!item?.isReady}
              >
                <div
                  className={classNames(classes.iconScale, { [classes.hidden]: !item?.isReady })}
                >
                  <TrueElementIcon />
                </div>
              </Tooltip>
              <Tooltip
                title={
                  item?.subElementType
                    ? MODES_DICT_NAMES[item.subElementType]
                    : 'Не известный тип элемента'
                }
                placement={'top'}
              >
                <div className={classes.iconScale}>
                  <IconComponent />
                </div>
              </Tooltip>
            </>
          )}
        </div>
        <div
          className={classNames({
            [classes.uncertainTextColor]: item?.isUncertain && !isHeader,
          })}
        >
          <div className={classes.itemText}>
            {isHeader ? 'Саб-элемент' : item?.subElementRealName}
          </div>
          {!isHeader && (
            <div
              className={classNames(classes.itemText, classes.greyTextColor, {
                [classes.uncertainTextColor]: item?.isUncertain,
              })}
            >
              {item?.subElementName}
            </div>
          )}
        </div>
        <div className={classes.itemText2}>
          {isHeader ? 'Режим работы' : `${item?.subelementFrequency ?? '???'}`}
        </div>
        <div className={classes.itemDefects}>
          {isHeader
            ? '\u2004\u2004\u2004Дефекты'
            : item?.defectsList?.map((defect) => (
                <ShowDefect
                  key={defect.id}
                  defect={defect}
                  defectsDict={defectsDict}
                  isSmallSize={true}
                />
              ))}
        </div>
      </div>
    </div>
  );
});
