import { memo, useState, useRef } from 'react';

import { ReturnToActualZoom } from 'icons/return-to-actual-zoom';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

import { ITimeScaleZoomParams } from './slicing-editor-common';
import { usePrepareEditorParams } from './use-prepare-editor-params';

type Props = { x0: number; xMax: number; center: number; width: number };

const SCROLL_BAR_HEIGHT = 7.3;

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    root: {
      height: SCROLL_BAR_HEIGHT + 30,
      width: '100%',
      zIndex: 100,
      position: 'relative',
    },

    bar: {
      position: 'absolute',
      top: 13,
      left: (props) => props.x0,
      width: (props) => props.xMax - props.x0,
      height: SCROLL_BAR_HEIGHT,
      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.2)',
      borderRadius: `${SCROLL_BAR_HEIGHT / 2}px ${SCROLL_BAR_HEIGHT / 2}px ${
        SCROLL_BAR_HEIGHT / 2
      }px ${SCROLL_BAR_HEIGHT / 2}px`,
      border: '1px solid #bbb',
    },

    slider: {
      position: 'absolute',
      top: 13,
      left: (props) => props.center - props.width / 2,
      width: (props) => props.width,
      height: SCROLL_BAR_HEIGHT,
      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.5)',
      borderRadius: `${SCROLL_BAR_HEIGHT / 2}px ${SCROLL_BAR_HEIGHT / 2}px ${
        SCROLL_BAR_HEIGHT / 2
      }px ${SCROLL_BAR_HEIGHT / 2}px`,
      border: '1px solid #888',
      '&:hover': {
        boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.8)',
      },
    },
    sliderHover: {
      boxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.8)',
    },
    zoomIcon: {
      position: 'absolute',
      top: -3,
      left: -5,
      transform: 'scale(0.7)',
      zIndex: 110,
    },
    tranparentFullScreenDiv: {
      opacity: 0,
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1000,
      cursor: 'grab',
    },
    spaceRight: {
      position: 'absolute',
      top: 10,
      left: -24,
    },
  }),
);

export const SlicingEditorCustomScrollBar = memo(function ({
  editorParams,
  timeScaleZoomParams,
  setTimeScaleZoomParams,
}: {
  editorParams: ReturnType<typeof usePrepareEditorParams>;
  timeScaleZoomParams: ITimeScaleZoomParams;
  setTimeScaleZoomParams: (a: ITimeScaleZoomParams) => void;
}) {
  const sliderRef = useRef<HTMLDivElement | undefined>();
  const [sliderDraggedStartPosition, setSliderDraggedStartPosition] = useState<
    number | undefined
  >();

  const {
    timeScaleMin = 0,
    timeScaleMax = 0,
    duration = 10,
    toPixels = (a: any) => a,
  } = editorParams ?? {};

  const x0 = toPixels?.({ x: timeScaleMin, y: 0 })?.x ?? 0;
  const xMax = toPixels?.({ x: timeScaleMax, y: 0 })?.x ?? 0;

  const xCenter = ((timeScaleMin + timeScaleMax) / 2 / duration) * (xMax - x0) + x0;

  const conversionFactor = (xMax - x0) / duration;
  const width = (xMax - x0) / timeScaleZoomParams.zoom;
  let center = timeScaleZoomParams.zoom === 1 ? (xMax + x0) / 2 : xCenter;

  if (center < x0 + width / 2) {
    center = x0 + width / 2;
  } else {
    if (center > xMax - width / 2) {
      center = xMax - width / 2;
    }
  }

  const classes = useStyles({ x0, xMax, center, width });

  if (editorParams === undefined) {
    return <div className={classes.root} />;
  }

  return (
    <>
      <div
        className={classes.root}
        onClick={(event) => {
          if (sliderRef) {
            // @ts-ignore
            const rect = sliderRef.current?.getBoundingClientRect?.();
            if (rect && event.pageX < rect.x) {
              if (
                editorParams?.timeScaleMax !== undefined &&
                editorParams?.timeScaleMin !== undefined
              ) {
                let shiftValue = (editorParams?.timeScaleMax - editorParams.timeScaleMin) / 10;
                if (editorParams.timeScaleMin - shiftValue < 0) {
                  shiftValue = editorParams.timeScaleMin;
                }
                setTimeScaleZoomParams({
                  weight: timeScaleZoomParams.weight,
                  center: timeScaleZoomParams.center - shiftValue,
                  zoom: timeScaleZoomParams.zoom,
                });
              }
            }
            if (rect && event.pageX > rect.x + rect.width) {
              if (
                editorParams?.timeScaleMax !== undefined &&
                editorParams?.timeScaleMin !== undefined
              ) {
                let shiftValue = (editorParams.timeScaleMax - editorParams.timeScaleMin) / 10;
                if (editorParams.timeScaleMax + shiftValue > 0) {
                  shiftValue = 0 - editorParams.timeScaleMax;
                }

                setTimeScaleZoomParams({
                  weight: timeScaleZoomParams.weight,
                  center: timeScaleZoomParams.center + shiftValue,
                  zoom: timeScaleZoomParams.zoom,
                });
              }
            }
          }
        }}
        draggable={false}
        onMouseMove={(event) => {
          if (sliderDraggedStartPosition !== undefined) {
            let newCenter =
              (event.pageX - sliderDraggedStartPosition) / conversionFactor +
              timeScaleZoomParams.center;
            const chartWindow = duration / timeScaleZoomParams.zoom;
            const minCenter = 0 + chartWindow * timeScaleZoomParams.weight;
            const maxCenter = duration - chartWindow * (1 - timeScaleZoomParams.weight);

            newCenter = Math.max(newCenter, minCenter);
            newCenter = Math.min(newCenter, maxCenter);

            setTimeScaleZoomParams({
              ...timeScaleZoomParams,
              center: newCenter,
            });
            setSliderDraggedStartPosition(event.pageX);
          }
        }}
        onMouseUp={(event) => {
          setSliderDraggedStartPosition(undefined);
        }}
      >
        <div className={classes.bar} draggable={false}></div>
        <div
          //@ts-ignore
          ref={sliderRef}
          draggable={false}
          className={classNames(classes.slider, {
            [classes.sliderHover]: sliderDraggedStartPosition,
          })}
          onMouseDown={(event) => {
            setSliderDraggedStartPosition(event.pageX);
          }}
        ></div>

        <div className={classes.zoomIcon}>
          <Tooltip
            title={`Сбросить масштаб c ${timeScaleZoomParams.zoom.toFixed(1)} до 1.0`}
            placement="top"
          >
            <span className={classes.spaceRight}>
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setTimeScaleZoomParams({ center: 0, zoom: 1, weight: 0.5 });
                }}
              >
                <div className={classes.zoomIconScale}>
                  <ReturnToActualZoom />
                </div>
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
      {sliderDraggedStartPosition && (
        <div
          className={classes.tranparentFullScreenDiv}
          draggable={false}
          onMouseUp={() => {
            setSliderDraggedStartPosition(undefined);
          }}
          onMouseMove={(event) => {
            let newCenter =
              (event.pageX - sliderDraggedStartPosition) / conversionFactor +
              timeScaleZoomParams.center;
            const chartWindow = duration / timeScaleZoomParams.zoom;
            const minCenter = 0 + chartWindow * timeScaleZoomParams.weight;
            const maxCenter = duration - chartWindow * (1 - timeScaleZoomParams.weight);

            newCenter = Math.max(newCenter, minCenter);
            newCenter = Math.min(newCenter, maxCenter);

            setTimeScaleZoomParams({
              ...timeScaleZoomParams,
              center: newCenter,
            });
            setSliderDraggedStartPosition(event.pageX);
          }}
        />
      )}
    </>
  );
});
