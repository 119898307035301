import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { IMachine } from 'interfaces';
import { MachineComments } from 'pages-diagnostic/diagnostic-page/diagnostic-form/machine-comments';
import { Card } from 'pages/machine-pages/components/card';
import { NOOP } from 'stream-constants';

const useStyles = makeStyles<Theme, { height: number }>((theme) =>
  createStyles({
    cardContainer: {
      position: 'relative',
      minHeight:
        'calc(var(--machines-mnemonicScheme-height) + var(--machines-footer-height) + 15px)',
      minWidth: 'calc(var(--machines-column-width) + 10px)',
    },
    commentContainer: {
      maxHeight: (props) => props.height,
      maxWidth: 270,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    comments: {
      paddingLeft: 8,
    },
  }),
);

export function CardContainer({
  machine,
  height,
  comments,
}: {
  machine: IMachine | undefined;
  height: number;
  comments:
    | {
        id: string;
        name: string;
        comment: string;
      }[]
    | undefined;
}) {
  const classes = useStyles({ height });

  return (
    <div className={classes.commentContainer}>
      <div className={classes.cardContainer}>
        <Card item={machine} onClick={NOOP} noBorder={true} />
      </div>
      <div className={classes.comments}>
        <MachineComments comments={comments} />
      </div>
    </div>
  );
}
