import { useContext } from 'react';

import { useQuery } from 'react-query';

import { ContextSettings } from 'context/context-settings';
import { getUserFullName } from 'services/system';
import { USER_FULLNAME_KEY } from 'stream-constants';
import { errorMessageConvert } from 'utils/error-message-convert';

export function useGetFullName() {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const query = useQuery([USER_FULLNAME_KEY], () => getUserFullName(), {
    staleTime: 100000000000,
    enabled: !notificationMessage,
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return query;
}
