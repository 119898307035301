import { INotificationMessage } from 'interfaces';
import { CUSTOM_HEADERS_FOR_FETCH } from 'stream-constants';

function forceDownload(blob: any, filename: string) {
  const a = document.createElement('a');
  a.setAttribute('type', 'hidden');
  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
}

// Current blob size limit is around 500MB for browsers
export async function downloadFileByUrl(
  url: string,
  filename: string,
  setErrorMessage: (payload: INotificationMessage) => void,
) {
  try {
    const response = await fetch(url, {
      headers: new Headers({
        ...CUSTOM_HEADERS_FOR_FETCH(window.location),
        Origin: window.location.origin,
      }),
      mode: 'cors',
    });
    const blob = await response.blob();

    let blobUrl = window.URL.createObjectURL(blob);
    forceDownload(blobUrl, filename);
  } catch (err: any) {
    const message = `Ошибка downloadFileByUrl: ${err.message ?? err}`;
    console.error(message);
    setErrorMessage({ header: message, fullMessage: '' });
  }
}
