import { Path } from 'react-hook-form';

export const getErrorByPath = <T>(errors: T, path: Path<T>) => {
  const arr = path.split('.');
  let result = errors;
  let i = 0;
  for (const key of arr) {
    if (result !== undefined) {
      if (i % 2 === 0) {
        //@ts-ignore
        result = result[key];
      } else {
        if (key.charAt(0) >= '0' && key.charAt(0) <= '9') {
          //@ts-ignore
          result = result[parseInt(key, 10)];
        } else {
          //@ts-ignore
          result = result[key];
        }
      }
    }
    i += 1;
  }

  return result;
};
