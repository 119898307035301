import { Controller } from 'react-hook-form';

import Input from '@material-ui/core/Input';

import { IFieldProps } from '../field-interfaces';
import FieldLabelErrorWrapper from '../field-label-error-wrapper';

const FieldInputText = <T,>(props: IFieldProps<T>) => {
  const { fieldName, form, multiline, id, placeholder } = props;

  return (
    <FieldLabelErrorWrapper {...props}>
      <Controller
        name={fieldName}
        control={form.control}
        render={({ field }) => (
          <Input
            id={id}
            placeholder={placeholder}
            multiline={multiline}
            disabled={props.disabled}
            {...field}
            inputProps={{ 'aria-controls': props.label }}
            fullWidth
            disableUnderline
          />
        )}
      />
    </FieldLabelErrorWrapper>
  );
};

export default FieldInputText;
FieldInputText.displayName = 'FieldInputText';
