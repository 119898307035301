import { AllIcon } from 'icons/custom-checkbox-icons/all-icon';
import { NoneIcon } from 'icons/custom-checkbox-icons/none-icon';
import { SomeIcon } from 'icons/custom-checkbox-icons/some-icon';
import { ZeroIcon } from 'icons/custom-checkbox-icons/zero-icon';

export enum ICheckBoxState {
  NONE,
  SOME,
  ZERO_FREQ,
  ALL,
}

const componentIcon = {
  [ICheckBoxState.ALL]: AllIcon,
  [ICheckBoxState.NONE]: NoneIcon,
  [ICheckBoxState.SOME]: SomeIcon,
  [ICheckBoxState.ZERO_FREQ]: ZeroIcon,
};

function nextState(state: ICheckBoxState) {
  switch (state) {
    case ICheckBoxState.ALL:
      return ICheckBoxState.ZERO_FREQ;
    case ICheckBoxState.ZERO_FREQ:
      return ICheckBoxState.NONE;
    case ICheckBoxState.SOME:
      return ICheckBoxState.NONE;
    case ICheckBoxState.NONE:
      return ICheckBoxState.ALL;
  }
}

export function CustomStatesCheckBox({
  state,
  onClick,
  forId,
}: {
  state: ICheckBoxState;
  onClick: (s: ICheckBoxState) => void;
  forId?: string;
}) {
  const ComponentIcon = componentIcon[state];

  return (
    <ComponentIcon style={{ fontSize: 24 }} onClick={() => onClick(nextState(state))} id={forId} />
  );
}
