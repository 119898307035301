import { useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { UseFormReturn } from 'react-hook-form';

import { ArrowRightIcon } from 'icons/arrow-right-icon';
import { FalseElementIcon } from 'icons/false-element-icon';
import { FolderIcon } from 'icons/folder-icon';
import { TrueElementIcon } from 'icons/true-element-icon';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { ContextSettings } from 'context/context-settings';
import { IScreenSize } from 'interfaces';
import { RED_COLOR, MAX_MEASURING_CSV_FILE_SIZE, DIAGNOSTIC_SPECTRUM } from 'stream-constants';

import { DragAndDropUploadFile } from './drag-and-drop-upload-file';
import { DragErrorMessageModalDialog } from './drag-error-message-modal-dialog';
import {
  IFolder,
  IStatus,
  IFilters,
  STATUS_INFO,
  fileIntoToText,
  getTextDateAndTime,
} from './folder-constants';
import { DeleteFileIcon } from './icons/delete-file-icon';
import { FrameCancelIcon } from './icons/frame-cancel-icon';
import { FrameRenewIcon } from './icons/frame-renew-icon';
import { LockIcon } from './icons/lock-icon';
import { MainFolderIcon } from './icons/main-folder-icon';
import { ModalSlicingEditorWidow } from './modal-slicing-editor-widow/modal-slicing-editor-widow';
import { FIELDS } from './validation-schema';

const validFileExtensions = ['csv'];

const COLUMN_GAP = 20;

const HEADERS = [
  'Маршрутная папка',
  'Файл csv',
  'Статус',
  '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Дата загрузки',
  '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0Режим работы',
];
const MODAL_VARIANT_HEADERS = ['Маршрутная папка', 'Статус'];

const FIRST_TWO_COLUMNS = 0.5;

const useStyles = makeStyles<Theme, IScreenSize>((theme) =>
  createStyles({
    buttonsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    mainSubFolderContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      maxWidth: (props) => props.width * FIRST_TWO_COLUMNS - COLUMN_GAP - 10,
      minWidth: (props) => props.width * FIRST_TWO_COLUMNS - COLUMN_GAP - 10,
    },

    clearIconContainer: {
      margin: '0 -6px 0 -4px',
    },

    mainFolderName: {
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '24px',
      color: '#1D2023',
      height: '24px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },

    mainFolderInfo: {
      fontSize: '17px',
      fontWeight: 400,
      lineHeight: '24px',
      height: '24px',
      color: '#626C77',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    tableHeight: {
      minHeight: (props) => props.height,
      maxHeight: (props) => props.height,
      overflowY: 'scroll',
    },

    row: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 20,
    },

    columnWidthLarge: {
      marginRight: COLUMN_GAP,
      minWidth: (props) => (props.width * FIRST_TWO_COLUMNS) / 2 - COLUMN_GAP - 10,
      maxWidth: (props) => (props.width * FIRST_TWO_COLUMNS) / 2 - COLUMN_GAP - 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    columnWidthSmall: {
      marginRight: COLUMN_GAP,
      minWidth: (props) => props.width / 6 - COLUMN_GAP - 10,
      maxWidth: (props) => props.width / 6 - COLUMN_GAP - 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    columnHeader: {
      fontWeight: 500,
      fontSize: '14px',
      color: '#626C77',
      height: 34,
    },
    columnBody: {
      fontWeight: 400,
      fontSize: '14px',
      color: '#626C77',
      height: 46,
      overflowWrap: 'anywhere',
    },

    trueElemStyle: {
      fontSize: '14px',
      color: '#12B23F',
    },

    falseElemStyle: {
      fontSize: '14px',
      color: '#EB4A13',
    },

    speedStyle: {
      width: '100%',
      minHeight: 32,
      backgroundColor: '#F2F3F7',
      border: '1px solid rgba(188, 195, 208, 0.5)',
      borderRadius: '6px',
      fontSize: '14px',
      marginRight: 8,
      padding: '0 12px',
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      position: 'relative',
    },

    inputStyle: {
      marginLeft: -4,
      width: 64,
      minHeight: 22,
      padding: '0 4px',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: '#FFF',
    },

    lockIconStyle: {
      position: 'absolute',
      right: 10,
      top: 3,
    },

    errorMessage: {
      position: 'absolute',
      left: 14,
      top: 27,
      fontSize: '10px',
      color: '#EB4A13',
    },

    alertColor: {
      color: RED_COLOR,
    },
    folderNameRow: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },

    rounded: {
      marginRight: '10px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: 4,
      padding: '4px 12px',
      height: '28px',
      borderRadius: '29px',

      fontWeight: 500,
      fontSize: '14px,',
      cursor: 'pointer',
    },
    filterMargin: {
      margin: '20px 0 10px 0',
    },
    fileNameClass: {
      cursor: 'pointer',
      color: '#007CFF',
    },
    gotoSubelementsButton: {
      width: '288px',
      marginRight: 14,
    },
  }),
);

const getIdByString = (str: string | null | undefined) => {
  const aaa = str ? window.btoa(encodeURIComponent(str)) : `${Math.floor(Math.random() % 1000000)}`;

  return `rowId_${aaa}`;
};

export function UploadMeasureTable({
  diagnosticId,
  mainDirectoryHandle,
  mainDirectoryInfo,
  handleMainFolderClicked,
  grouppedListSummary,
  validationFilter,
  setValidationFilter,
  filteredSummary,
  diagnosticStatusEqMeasurmentsUploading,
  form,
  deleteCsvFileFromList,
  isModalVariant,
  layoutSize,
  renewMainDirectoryInformation,
  readOnly,
}: {
  diagnosticId: string;
  mainDirectoryHandle: any;
  mainDirectoryInfo: string | undefined;
  handleMainFolderClicked: () => void;
  grouppedListSummary: {
    id: IFilters;
    title: string;
    color: string;
    colorSelected: string;
    total: number;
  }[];
  validationFilter: IFilters | undefined;
  setValidationFilter: (a: IFilters | undefined) => void;
  filteredSummary: IFolder[];
  diagnosticStatusEqMeasurmentsUploading: boolean;
  form: UseFormReturn;
  deleteCsvFileFromList: (id: string | null) => void;
  isModalVariant: boolean;
  layoutSize: IScreenSize;
  renewMainDirectoryInformation: () => void;
  readOnly: boolean;
}) {
  const history = useHistory();

  const { folderListManagerData, globalFileList, setGlobalFileList } = useContext(ContextSettings);
  const classes = useStyles(layoutSize);
  const [fullMessageModal, setFullMessageModal] = useState<string | undefined>();
  const [slicingEditorFileItem, setSlicingEditorFileItem] = useState<IFolder | undefined>();

  const {
    register,
    formState: { errors },
  } = form;

  return (
    <div>
      <div className={classes.buttonsContainer}>
        {mainDirectoryHandle ? (
          <div className={classes.mainSubFolderContainer}>
            <Tooltip title={'Выбрать другую папку'} placement="top">
              <span className={classes.clearIconContainer}>
                <IconButton
                  disabled={!mainDirectoryInfo}
                  aria-haspopup="true"
                  aria-label={'Выбрать другую папку'}
                  onClick={handleMainFolderClicked}
                  size="small"
                  color={'primary'}
                >
                  <FrameCancelIcon style={{ fontSize: 46 }} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={'Прочитать папку заново'} placement="top">
              <span className={classes.clearIconContainer}>
                <IconButton
                  disabled={!mainDirectoryInfo}
                  aria-haspopup="true"
                  aria-label={'Прочитать папку заново'}
                  onClick={renewMainDirectoryInformation}
                  size="small"
                  color={'primary'}
                >
                  <FrameRenewIcon style={{ fontSize: 46 }} />
                </IconButton>
              </span>
            </Tooltip>
            <MainFolderIcon style={{ fontSize: 40 }} />
            <div>
              <div className={classes.mainFolderName}>
                <div>{mainDirectoryHandle.name}</div>
              </div>
              <div className={classes.mainFolderInfo}>
                {mainDirectoryInfo ? (
                  <div>{mainDirectoryInfo}</div>
                ) : (
                  <div className={classes.alertColor}>{'Папка не активирована (нет доступа)'}</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.mainSubFolderContainer}>
            <Button
              id={'id_get_folder_for_measurments_843'}
              disabled={!diagnosticStatusEqMeasurmentsUploading}
              className={classes.columnWidthLarge}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleMainFolderClicked}
            >
              {'Выбрать папку c файлами измерений'}
            </Button>
          </div>
        )}
        <div className={classes.gotoSubelementsButton}>
          <Button
            id={'id_go_to_subelements_843'}
            disabled={diagnosticStatusEqMeasurmentsUploading}
            variant="contained"
            color="primary"
            size="small"
            endIcon={<ArrowRightIcon style={{ fontSize: 26 }} />}
            onClick={() => {
              history.push(`/${DIAGNOSTIC_SPECTRUM}/${diagnosticId}`);
            }}
          >
            {'Перейти к саб-элементам'}
          </Button>
        </div>
      </div>
      <div className={classNames(classes.row, classes.filterMargin)}>
        {grouppedListSummary.map((item) => (
          <div
            key={item.id}
            className={classNames(classes.rounded, item.color, {
              [item.colorSelected]: validationFilter === item.id,
            })}
            onClick={() => {
              if (item.id === validationFilter) {
                setValidationFilter(undefined);
              } else {
                setValidationFilter(item.id);
              }
            }}
          >
            {`${item.title} ${item.total}`}
          </div>
        ))}
      </div>

      <div className={classes.row}>
        {(isModalVariant ? MODAL_VARIANT_HEADERS : HEADERS).map((header, index) => (
          <div
            key={header}
            className={classNames(
              index < 2 ? classes.columnWidthLarge : classes.columnWidthSmall,
              classes.columnHeader,
            )}
          >
            {header}
          </div>
        ))}
      </div>
      <div className={classes.tableHeight}>
        {filteredSummary.map((item, index) => {
          let fileNameAndParams: {} | null | undefined;
          try {
            fileNameAndParams = fileIntoToText(
              item.file ?? (item.fileMeta && JSON.parse(item.fileMeta)),
            );
          } catch {}

          const isOutputSpeenEditable = !item.readOnlyMessage;

          const showSpinner =
            item.status !== IStatus.DONE &&
            (folderListManagerData?.currentFolderName === item.folderName ||
              globalFileList?.find((elem) => elem.folderName === item.folderName));

          return (
            <div key={item.folderName} className={classes.row} id={getIdByString(item.folderName)}>
              <div
                className={classNames(
                  classes.columnWidthLarge,
                  classes.columnBody,
                  classes.folderNameRow,
                )}
              >
                <FolderIcon style={{ fontSize: 24 }} />

                {item.folderName}
              </div>

              {isModalVariant === false && (
                <div className={classNames(classes.columnWidthLarge, classes.columnBody)}>
                  {fileNameAndParams !== undefined && (
                    <Tooltip
                      title={
                        diagnosticStatusEqMeasurmentsUploading
                          ? 'Редактировать сигнал'
                          : 'Просмотреть сигнал'
                      }
                      placement="top"
                    >
                      <span
                        className={classes.fileNameClass}
                        onClick={() => {
                          setSlicingEditorFileItem(item);
                        }}
                      >
                        {fileNameAndParams}
                      </span>
                    </Tooltip>
                  )}
                  {fileNameAndParams === undefined &&
                    !showSpinner &&
                    (diagnosticStatusEqMeasurmentsUploading ? (
                      <DragAndDropUploadFile
                        forId={`id_drag_and_drop_upload_file_843_${index}`}
                        disabled={!!showSpinner}
                        folderName={item.folderName}
                        uploadOneFile={(file: File | undefined) =>
                          setGlobalFileList([...(globalFileList ?? []), { ...item, file }])
                        }
                        validFileExtensions={validFileExtensions}
                        maxFileSize={MAX_MEASURING_CSV_FILE_SIZE}
                        setFullMessageModal={setFullMessageModal}
                      />
                    ) : (
                      '...'
                    ))}
                  {(item.status === IStatus.DONE || item.status === IStatus.MUST_BE_REUPLOADED) &&
                    diagnosticStatusEqMeasurmentsUploading &&
                    item.csvId && (
                      <Tooltip title={'Удалить файл с сервера'} placement="top">
                        <span>
                          <IconButton
                            id={`id_delete_file_843_${index}`}
                            aria-haspopup="true"
                            aria-label={'Удалить файл из списка загруженных'}
                            onClick={() => deleteCsvFileFromList(item.csvId)}
                            size="small"
                            color={'primary'}
                          >
                            <DeleteFileIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    )}
                  {showSpinner && (
                    <CircularProgress color="primary" size={14} style={{ marginLeft: 10 }} />
                  )}
                </div>
              )}
              <div style={{ marginRight: 4 }}>
                {item.status === IStatus.READY || item.status === IStatus.DONE ? (
                  <TrueElementIcon
                    style={{ fontSize: 18 }}
                    customColor={STATUS_INFO[item.status ?? IStatus.UNKNOWN].color}
                  />
                ) : (
                  <FalseElementIcon
                    style={{ fontSize: 18 }}
                    customColor={STATUS_INFO[item.status ?? IStatus.UNKNOWN].color}
                  />
                )}
              </div>

              <div
                className={classNames(classes.columnWidthSmall, classes.columnBody)}
                style={{ color: STATUS_INFO[item.status ?? IStatus.UNKNOWN].color }}
              >
                {STATUS_INFO[item.status ?? IStatus.UNKNOWN].message}
              </div>
              {isModalVariant === false && (
                <>
                  <div className={classNames(classes.columnWidthSmall, classes.columnBody)}>
                    {item.status === IStatus.DONE || item.status === IStatus.MUST_BE_REUPLOADED
                      ? getTextDateAndTime(item.date)
                      : '...'}
                  </div>
                  <div className={classNames(classes.columnWidthSmall, classes.speedStyle)}>
                    {isOutputSpeenEditable ? (
                      <input
                        {...register(`${FIELDS}.${index}`)}
                        className={classes.inputStyle}
                        id={`id_speed_div_843_${index}`}
                      />
                    ) : (
                      <div>{item.outputSpeed?.toFixed(2)}</div>
                    )}

                    <div>{item.outputSpeedUnits}</div>

                    {!isOutputSpeenEditable && (
                      <Tooltip title={item.readOnlyMessage ?? ''} placement="top">
                        <span>
                          <LockIcon
                            style={{ fontSize: 22 }}
                            className={classes.lockIconStyle}
                            color="primary"
                          />
                        </span>
                      </Tooltip>
                    )}
                    {errors?.[FIELDS]?.[index] && (
                      <div className={classes.errorMessage}>
                        {errors?.[FIELDS]?.[index]?.message}
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
      {fullMessageModal && (
        <DragErrorMessageModalDialog
          fullMessage={fullMessageModal}
          setClose={() => setFullMessageModal(undefined)}
        />
      )}
      {slicingEditorFileItem && (
        <ModalSlicingEditorWidow
          item={slicingEditorFileItem}
          setClose={() => setSlicingEditorFileItem(undefined)}
          readOnly={readOnly}
        />
      )}
    </div>
  );
}
