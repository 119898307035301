import { useMemo, useState, useContext, useEffect, useRef } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { useQueryClient, QueryClient } from 'react-query';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import classNames from 'classnames';
import { CustomBreadcrumbs } from 'components/breadcrums';
import { StyledCheckbox } from 'components/styled-checkbox';
import { ContextSettings } from 'context/context-settings';
import {
  useGetDiagnosticReportParamsById,
  useMutationCreateDiagnosticReport,
} from 'pages-diagnostic/hooks';
import { StyledRadio } from 'pages/machine-modes/styled-radio';
import { FETCH_MINIO_URL } from 'services/urls';
import {
  DIAGNOSTICS_KEY,
  DIAGNOSTICS_BY_ID_KEY,
  DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY,
  NOOP,
} from 'stream-constants';
import { delay } from 'utils/delay';
import { downloadFileByUrl } from 'utils/download-file-by-url';
import { removeUnvalidCharactersFromFilename } from 'utils/remove-unvalid-characters-from-filename';

const BLOCK_WIDTH = 572;
const STYLED_CHECKBOX_MARGIN = 8;

const PULLING_TIME_INTERVAL = 2000;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    header: {
      fontWeight: 700,
      fontSize: '24px',
      color: '#000',
      lineHeight: '28px',
      marginTop: '20px',
    },
    text: {
      fontWeight: 400,
      fontSize: '17px',
      lineHeight: '24px',
    },
    color626C77: { color: '#626C77' },
    color1D2023: { color: '#1D2023' },
    subHeaderContainer: {
      marginTop: 10,
    },
    marginFirst: { marginTop: 38 },
    margin28: { marginTop: 28 },
    margin18: { marginTop: 18 },
    container: {
      width: BLOCK_WIDTH + STYLED_CHECKBOX_MARGIN,
    },
    row: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    switchContainer: {
      margin: '18px 0 0 20px',
    },
    subRow: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 12,
    },

    introContainer: {
      margin: '18px 0 0 24px',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      gap: 12,
    },

    buttonRow: {
      marginTop: 58,
      width: BLOCK_WIDTH,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 16,
    },
    scaledRadio: {
      margin: '-2px -4px 0 0',
      transform: 'scale(1.3)',
    },
    introWidth: {
      width: 140,
    },
    rowSpaceBetween: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    plusMinusButton: {
      cursor: 'not-allowed',
      paddingTop: 4,
      backgroundColor: '#dee1e8',
      height: 32,
      width: 32,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 6,
      fontSize: '36px',
      fontWeight: 400,
      color: '#bcc3c9',
      '&:hover': {
        backgroundColor: '#ced1d8',
      },
    },

    plusMinusButtonActive: {
      color: '#000 !important',
      cursor: 'pointer !important',
    },

    disabledFilter: {
      opacity: 0.5,
    },
    rowSpinner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 20,
      fontSize: '14px',
      fontWeight: 400,
    },
    width100: {
      width: '100%',
    },
  }),
);

interface IReportParams {
  title: boolean;
  inventoryNumber: boolean;
  introductionType: 'PAGES' | 'INTRODUCTION';
  emptyPagesAmount: number;
  schema: boolean;
  table: boolean;
  recommendations: boolean;
  supplement: boolean;
}

interface IDiagnosticReportParams {
  description: string;
  reportPdfLink: string;
  settings: IReportParams;
}

const DEFAULT_REPORT_PARAMS: IReportParams = {
  title: false,
  inventoryNumber: false,
  introductionType: 'INTRODUCTION',
  emptyPagesAmount: 0,
  schema: false,
  table: false,
  recommendations: false,
  supplement: false,
};

async function reFetch(queryClient: QueryClient) {
  await queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY]);
  await queryClient.invalidateQueries([DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY]);
}

export function DownloadReport() {
  const queryClient = useQueryClient();
  const [refetchCount, setRefetchCount] = useState<number>(0);
  console.log('%c refetchCount = ', 'color: #fff', refetchCount); //TODO - delete vvtu
  const [reportParams, setReportParams] = useState<IReportParams>(DEFAULT_REPORT_PARAMS);

  const params = useParams<{ id: string }>();
  const history = useHistory();
  const diagnosticId = params.id;
  const { setNotificationMessage } = useContext(ContextSettings);
  // const { queryParams, setQueryParams } = useQueryParams();

  const classes = useStyles();

  const diagnosticReportParamsQuery = useGetDiagnosticReportParamsById(diagnosticId) as {
    data: IDiagnosticReportParams | undefined;
  };
  const description = diagnosticReportParamsQuery.data?.description;
  console.log('%c diagnosticReportParamsQuery = ', 'color: #bada55', diagnosticReportParamsQuery); //TODO - delete vvtu

  const settings = diagnosticReportParamsQuery.data?.settings;
  let ifThereIsAnyChangesInParams = false;
  if (settings) {
    for (const fieldName in reportParams) {
      //@ts-ignore
      if (reportParams[fieldName] !== settings[fieldName]) {
        ifThereIsAnyChangesInParams = true;
        break;
      }
    }
  }

  console.log('%c ifThereIsAnyChangesInParams = ', 'color: #bada55', ifThereIsAnyChangesInParams); //TODO - delete vvtu

  useEffect(() => {
    console.log('%c useEffect refetchCount = ', 'color: magenta', refetchCount); //TODO - delete vvtu
    if (settings && refetchCount === 0) {
      const newSettings = { ...DEFAULT_REPORT_PARAMS, ...settings };
      setReportParams(newSettings);
    }
  }, [refetchCount, settings]);

  const mutationCreateDiagnosticReport = useMutationCreateDiagnosticReport({
    diagnosticId,
    reportParams,
    handleOnSuccess: async (a: any) => {
      timeoutId.current = await delay(PULLING_TIME_INTERVAL);
      reFetch(queryClient);
      setRefetchCount(2);
      timeoutId.current = await delay(PULLING_TIME_INTERVAL);
      setRefetchCount(3);
    },
  });

  const breadcrubms = useMemo(
    () => [
      {
        label: `Диагностика \u00AB${description ?? ''}\u00BB`,
        handleOnClick: () => history.push(`/${DIAGNOSTICS_KEY}/${diagnosticId}`),
      },
      {
        label: 'Отчёт',
        handleOnClick: NOOP,
      },
    ],
    [description, diagnosticId, history],
  );

  const timeoutId = useRef<ReturnType<typeof setTimeout> | undefined>();

  const reportLink = diagnosticReportParamsQuery.data?.reportPdfLink ?? undefined;
  console.log('%c reportLink = ', 'color: #bada55', reportLink); //TODO - delete vvtu

  useEffect(() => {
    // подчистка, чтобы не было протечки памяти
    return () => {
      timeoutId.current && clearTimeout(timeoutId.current);
    };
  }, []);

  useEffect(() => {
    if (refetchCount > 2) {
      if (reportLink) {
        console.log('%c 22 reportLink = ', 'color: #f00', reportLink); //TODO - delete vvtu
        console.log('%c 22 refetchCount = ', 'color: #f00', refetchCount); //TODO - delete vvtu
        setRefetchCount(0); // stop pulling
        timeoutId.current && clearTimeout(timeoutId.current);
      } else {
        console.log('%c 11 reportLink = ', 'color: #ff0', reportLink); //TODO - delete vvtu
        console.log('%c 11 refetchCount = ', 'color: #ff0', refetchCount); //TODO - delete vvtu
        timeoutId.current = setTimeout(() => {
          // continue pulling
          reFetch(queryClient);
          setRefetchCount((count: number) => (count === 0 ? count : count + 1));
        }, PULLING_TIME_INTERVAL);
      }

      if (refetchCount > 50) {
        setNotificationMessage({
          fullMessage: 'Внимание',
          header:
            'Формирование отчёта в формате PDF может занимать до нескольких минут.\nЧерез несколько минут можете вернуться на страницу данной диагностики\nдля скачивания отчёта в формате PDF',
        });
        setRefetchCount(0); // stop pulling
      }
    }
  }, [queryClient, refetchCount, reportLink, setNotificationMessage]);

  return (
    <div className={classes.margin28}>
      <CustomBreadcrumbs links={breadcrubms} />
      <div className={classes.header}>{'Отчёт'}</div>
      <div className={classNames(classes.subHeaderContainer, classes.text, classes.color626C77)}>
        {'Оставьте выбранными или исключите ненужные вам блоки и сформируйте отчет'}
      </div>
      <div className={classNames(classes.container, classes.color1D2023, classes.marginFirst)}>
        <div className={classNames(classes.row, classes.margin38)}>
          <div className={classes.text}>1.&emsp;Титульный лист</div>
          <StyledCheckbox
            checked={reportParams.title}
            onChange={(_, value) => {
              setReportParams({ ...reportParams, title: value });
              setRefetchCount(0);
            }}
          />
        </div>
        <div
          className={classNames(classes.subRow, classes.switchContainer, {
            [classes.disabledFilter]: !reportParams.title,
          })}
        >
          <Switch
            disabled={!reportParams.title}
            checked={reportParams.inventoryNumber}
            onChange={(_, value) => {
              setReportParams({ ...reportParams, inventoryNumber: value });
              setRefetchCount(0);
            }}
            name="checkedA"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            color="primary"
          />
          <div className={classes.text}>Инвентарный номер</div>
        </div>
        <div className={classNames(classes.row, classes.margin18)}>
          <div className={classes.text}>2.&emsp;Введение/Пустые страницы</div>
        </div>

        <div className={classNames(classes.introContainer)}>
          <div className={classNames(classes.subRow, classes.introWidth)}>
            <div className={classes.scaledRadio}>
              <StyledRadio
                value={'INTRODUCTION'}
                checked={reportParams.introductionType === 'INTRODUCTION'}
                onChange={() => {
                  setReportParams({
                    ...reportParams,
                    introductionType: 'INTRODUCTION',
                    emptyPagesAmount: 0,
                  });
                  setRefetchCount(0);
                }}
              />
            </div>
            <div className={classes.text}>Введение</div>
          </div>
          <div>
            <div className={classNames(classes.subRow)}>
              <div className={classes.scaledRadio}>
                <StyledRadio
                  value={'PAGES'}
                  checked={reportParams.introductionType === 'PAGES'}
                  onChange={() => {
                    setReportParams({
                      ...reportParams,
                      introductionType: 'PAGES',
                      emptyPagesAmount: 0,
                    });
                    setRefetchCount(0);
                  }}
                />
              </div>
              <div className={classes.text}>Пустые страницы</div>
            </div>
            <div
              className={classNames(classes.rowSpaceBetween, classes.margin18, {
                [classes.disabledFilter]: reportParams.introductionType !== 'PAGES',
              })}
            >
              <div
                className={classNames(classes.plusMinusButton, {
                  [classes.plusMinusButtonActive]:
                    reportParams.introductionType === 'PAGES' && reportParams.emptyPagesAmount > 0,
                })}
                onClick={() => {
                  if (reportParams.emptyPagesAmount > 0) {
                    setReportParams({
                      ...reportParams,
                      emptyPagesAmount: reportParams.emptyPagesAmount - 1,
                    });
                    setRefetchCount(0);
                  }
                }}
              >
                &minus;
              </div>
              <div className={classes.text}>{reportParams.emptyPagesAmount}</div>
              <div
                className={classNames(classes.plusMinusButton, {
                  [classes.plusMinusButtonActive]:
                    reportParams.introductionType === 'PAGES' && reportParams.emptyPagesAmount < 10,
                })}
                onClick={() => {
                  if (reportParams.emptyPagesAmount < 10) {
                    setReportParams({
                      ...reportParams,
                      emptyPagesAmount: reportParams.emptyPagesAmount + 1,
                    });
                    setRefetchCount(0);
                  }
                }}
              >
                {'+'}
              </div>
            </div>
          </div>
        </div>
        <div className={classNames(classes.row, classes.margin28)}>
          <div className={classes.text}>3.&emsp;Кинематическая схема</div>
          <StyledCheckbox
            checked={reportParams.schema}
            onChange={(_, value) => {
              setReportParams({ ...reportParams, schema: value });
              setRefetchCount(0);
            }}
          />
        </div>
        <div className={classNames(classes.row, classes.margin28)}>
          <div className={classes.text}>4.&emsp;Таблица технического состояния</div>
          <StyledCheckbox
            checked={reportParams.table}
            onChange={(_, value) => {
              setReportParams({ ...reportParams, table: value });
              setRefetchCount(0);
            }}
          />
        </div>
        <div className={classNames(classes.row, classes.margin28)}>
          <div className={classes.text}>5.&emsp;Заключение</div>
          <StyledCheckbox
            checked={reportParams.recommendations}
            onChange={(_, value) => {
              setReportParams({ ...reportParams, recommendations: value });
              setRefetchCount(0);
            }}
          />
        </div>
        <div className={classNames(classes.row, classes.margin28)}>
          <div className={classes.text}>6.&emsp;Приложение</div>
          <StyledCheckbox
            checked={reportParams.supplement}
            onChange={(_, value) => {
              setReportParams({ ...reportParams, supplement: value });
              setRefetchCount(0);
            }}
          />
        </div>
      </div>
      <div className={classNames(classes.buttonRow)}>
        <Tooltip
          title={
            !description || (ifThereIsAnyChangesInParams === false && !!reportLink)
              ? 'Отчет уже сформирован'
              : ''
          }
          placement={'top'}
        >
          <span className={classes.width100}>
            <Button
              id={'id_finish_loading_843'}
              disabled={!description || (ifThereIsAnyChangesInParams === false && !!reportLink)}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setRefetchCount(1);
                console.log('%c -------------------------- = ', 'color: #0f0'); //TODO - delete vvtu
                description && mutationCreateDiagnosticReport.mutate();
              }}
              startIcon={<CheckIcon style={{ fontSize: 24 }} />}
            >
              {'Сформировать отчёт'}
            </Button>
          </span>
        </Tooltip>
        <Tooltip
          title={
            !reportLink || ifThereIsAnyChangesInParams || refetchCount !== 0
              ? 'Чтобы скачать отчет, необходимо его сформировать'
              : ''
          }
          placement={'top'}
        >
          <span className={classes.width100}>
            <Button
              id={'id_speeds_saving_843'}
              disabled={!reportLink || ifThereIsAnyChangesInParams || refetchCount !== 0}
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                downloadFileByUrl(
                  FETCH_MINIO_URL(reportLink),
                  `diagnostic-report-${removeUnvalidCharactersFromFilename(description)}.pdf`,
                  setNotificationMessage,
                );
              }}
              startIcon={<VerticalAlignBottomIcon style={{ fontSize: 24 }} />}
            >
              {refetchCount === 0 ? (
                <div>{`Скачать отчёт`}</div>
              ) : (
                <div className={classes.rowSpinner}>
                  <CircularProgress size={20} />
                </div>
              )}
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  );
}
