import { FC, useContext } from 'react';

import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { ContextSettings } from 'context/context-settings';
import { IMachine } from 'interfaces';
import { FETCH_MINIO_URL } from 'services/urls';
import { NO_PICTURE } from 'stream-constants';

import styles from './styles.module.css';

interface ICardProps {
  item?: IMachine;
  onClick: (item: IMachine) => void;
  id?: string;
  noBorder?: boolean;
}

export const Card: FC<ICardProps> = ({ item, onClick, id, noBorder }) => {
  const { lastModifiedMachineId } = useContext(ContextSettings);

  if (!item) {
    return <div />;
  }
  const {
    name,
    machineOwner,
    manufacturedYear,
    manufactured,
    photo,
    mnemonicScheme,
    fullness,
    isUncertain,
  } = item;

  const titleBackgroundColorClass = isUncertain
    ? styles.isUncertain
    : fullness
    ? styles.fullnessTrue
    : styles.fullnessFalse;

  const row = (key: string, value: string | undefined) => (
    <div className={styles.rowContainer}>
      <span className={styles.rowKey}>{`${key}:`}</span>
      &nbsp;<span className={styles.rowValue}>{value || '-'}</span>
    </div>
  );

  return (
    <div
      id={id}
      className={classNames(styles.layout, {
        [styles.layoutHover]: !noBorder,
        [styles.activeItem]: item.id !== undefined && item.id === lastModifiedMachineId,
        [styles.noBorder]: noBorder,
      })}
      onClick={() => onClick(item)}
    >
      <div className={styles.header}>
        <div className={styles.text}>
          <Typography variant="subtitle1" className={titleBackgroundColorClass}>
            {name ?? 'Loading...'}
          </Typography>
          <div className={styles.rows}>
            {row('Год выпуска', manufacturedYear)}
            {row('Производитель', manufactured)}
            {row('Владелец', machineOwner)}
          </div>
        </div>
      </div>
      <img
        className={styles.photo}
        src={FETCH_MINIO_URL(photo)}
        alt={NO_PICTURE('Изображение станка.')}
        decoding="async"
      />
      <img
        className={styles.mnemonicScheme}
        src={FETCH_MINIO_URL(mnemonicScheme)}
        alt={NO_PICTURE('Мнемосхема станка')}
        decoding="async"
      />
    </div>
  );
};
