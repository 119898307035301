/**
 * Server constants.
 */
export const IS_MODE_EQ_DEVELOPMENT = process.env.NODE_ENV === 'development';

//export const IS_SERVER_MOCKED = IS_MODE_EQ_DEVELOPMENT;
export const IS_SERVER_MOCKED = false;

export const SERVER_PREFIX = '/api/';

console.log('%c SERVER_PREFIX = ', 'color: #bada55', SERVER_PREFIX); //TODO - delete vvtu

export const SERVER_RESPONSE_ABORT_TIME_OUT = 15000;
export const SERVER_RESPONSE_SPINNER_TIME_OUT = 2000;

export const ERROR_MESSAGE_SPLITTER = '\u21D2';

export const CUSTOM_HEADERS_FOR_FETCH = (location: { origin: string; pathname: string }) => ({
  'Accept-Encoding': 'gzip, compress, br',
  'X-Url-Custom-Header': location?.origin,
  'X-Url-Custom-Header-Pathname': location?.pathname,
});
