import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const FrameCancelIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
      <rect width="32" height="32" rx="6" fill="#F2F3F7" />
      <g clipPath="url(#clip0_2980_628444)">
        <path
          d="M10.2929 20.2929C9.90237 20.6834 9.90237 21.3166 10.2929 21.7071C10.6834 22.0976 11.3166 22.0976 11.7071 21.7071L15.9999 17.4143L20.2929 21.7073C20.6834 22.0978 21.3166 22.0978 21.7071 21.7073C22.0976 21.3167 22.0976 20.6836 21.7071 20.293L17.4141 16.0001L21.7071 11.7071C22.0976 11.3166 22.0976 10.6834 21.7071 10.2929C21.3166 9.90237 20.6834 9.90237 20.2929 10.2929L15.9999 14.5859L11.7071 10.293C11.3166 9.90252 10.6834 9.90252 10.2929 10.293C9.90237 10.6836 9.90237 11.3167 10.2929 11.7073L14.5857 16.0001L10.2929 20.2929Z"
          fill="#F95721"
        />
      </g>
      <defs>
        <clipPath id="clip0_2980_628444">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
