import { useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { DeleteNewIcon } from 'icons/delete-new-icon';
import { EditIcon } from 'icons/edit-icon';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import ConfirmationDialog, { IConfirmationDialog } from 'components/confirmation-dialog';
import IconMenu from 'components/icon-menu';
import { useMutationDeleteDiagnosticByid } from 'pages-diagnostic/hooks';
import { DIAGNOSTICS_KEY, NOOP } from 'stream-constants';

export function MenuButton({
  setEditBaseParamsModalFlag,
}: {
  setEditBaseParamsModalFlag: (a: boolean) => void;
}) {
  const [confimationDialog, setConfimationDialog] = useState<IConfirmationDialog | undefined>();

  const history = useHistory();
  const params = useParams<{ id: string; data?: any }>();

  const mutationDeleteDiagnosticById = useMutationDeleteDiagnosticByid(params.id, () => {
    history.push(`/${DIAGNOSTICS_KEY}`);
  });

  return (
    <>
      <IconMenu
        forId="id_other_effects_392"
        tooltipTitle="Другие действия"
        menuItems={[
          <MenuItem
            key="Удалить диагностику"
            id="delete-current-diagnostic"
            onClick={() => {
              const handleConfirm = () => {
                mutationDeleteDiagnosticById.mutate();
              };
              setConfimationDialog({
                handleConfirm,
                handleCancel: NOOP,
                handleClose: () => setConfimationDialog(undefined),
                title: 'Внимание',
                content: `Выполнить удаление этой диагностики ?`,
              });
            }}
          >
            <ListItemIcon style={{ minWidth: '30px' }}>
              <DeleteNewIcon style={{ fontSize: 20 }} />
            </ListItemIcon>
            <Typography variant="body2">Удалить диагностику</Typography>
          </MenuItem>,
          <MenuItem
            key="Изменить текстовые параметры"
            id="edit-current-diagnostic-identification"
            onClick={() => {
              setEditBaseParamsModalFlag(true);
            }}
          >
            <ListItemIcon style={{ minWidth: '30px' }}>
              <EditIcon style={{ fontSize: 16 }} color="primary" />
            </ListItemIcon>
            <Typography variant="body2">Изменить текстовые параметры</Typography>
          </MenuItem>,
        ]}
        color="primary"
        fontSize={32}
      />
      <ConfirmationDialog {...confimationDialog} />
    </>
  );
}
