import { FC, useContext } from 'react';

import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ContextSettings } from 'context/context-settings';

import { DetailedElemsListPage } from './detailed-elems-list-page';
import { DetailedElemsListPageViewOnly } from './detailed-elems-list-page-view-only';

type Props = { newLayoutSize: { height: number; width: number } };

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'relative',
      backgroundColor: '#fff',
      border: '2px solid #fff',
      borderRadius: 16,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    paperSize: {
      height: (props) => props.newLayoutSize.height,
      width: (props) => props.newLayoutSize.width,
    },
    paperSizeViewOnly: {
      height: (props) => props.newLayoutSize.height,
      width: 600,
    },
  }),
);

export const DetailedElemsListModal: FC<{
  defaultUnits: string[];
  defaultElements: string[][] | undefined;
  onResultSelect: (result?: { selectedNodes: string[]; summary: string }) => void;
  machineLinkId: string | undefined;
  open: boolean;
  setOpen: (b: boolean) => void;
  viewOnly?: boolean;
  detailedInformationToView?: string;
}> = ({
  defaultUnits,
  defaultElements,
  onResultSelect,
  machineLinkId,
  open,
  setOpen,
  viewOnly,
  detailedInformationToView,
}) => {
  const { layoutSize } = useContext(ContextSettings);

  const newLayoutSize = {
    height: layoutSize.height,
    width: layoutSize.width - 300,
  };
  const classes = useStyles({ newLayoutSize });

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(200, 200, 200, 0.5)' } }}
    >
      <Fade in={open}>
        {viewOnly ? (
          <div
            className={classNames(classes.paper, classes.paperSizeViewOnly)}
            id="transition-modal-description"
          >
            <DetailedElemsListPageViewOnly
              newLayoutSize={{ ...newLayoutSize, width: 6000 }}
              detailedInformationToView={detailedInformationToView}
              onClose={handleClose}
            />
          </div>
        ) : (
          <div
            className={classNames(classes.paper, classes.paperSize)}
            id="transition-modal-description"
          >
            <DetailedElemsListPage
              defaultUnits={defaultUnits}
              defaultElements={defaultElements}
              machineLinkId={machineLinkId}
              onClose={(result: any) => {
                handleClose();
                onResultSelect(result);
              }}
              newLayoutSize={newLayoutSize}
            />
          </div>
        )}
      </Fade>
    </Modal>
  );
};
