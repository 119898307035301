import { useContext } from 'react';

import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { ContextSettings } from 'context/context-settings';
import { SimpleMnemoSchemeRenderer } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-list/components/simple-mnemo-scheme-renderer';
import { IRawElement } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import MOCK_MNEMOSCHEME from 'services/machines/images/GRS_10.svg';
import { IS_MODE_EQ_DEVELOPMENT } from 'stream-constants';

const MODAL_WIDTH = 1000;
type Props = { newLayoutSize: { height: number; width: number } };

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'relative',
      backgroundColor: '#fff',
      border: '2px solid #fff',
      borderRadius: 16,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      minHeight: (props) => props.newLayoutSize.height,
      maxHeight: (props) => props.newLayoutSize.height,
      minWidth: 1000,
      maxWidth: 1000,
    },

    header: {
      fontWeight: 500,
      fontSize: '24px',
      color: '#1D2023',
      lineHeight: '28px',
      margin: '14px 0 0 0',
    },
    subHeader: {
      fontWeight: 400,
      fontSize: '17px',
      color: '#1D2023',
      lineHeight: '28px',
      margin: '14px 0 18px 0',
    },

    saveButtonContainer: {
      marginTop: '10px',
      display: 'flex',
      justifyContent: 'flex-end',
    },

    saveButton: {
      maxWidth: 240,
    },

    mnemoscheme: {
      paddingTop: '16px',
      border: '1px solid #DADEE5',
      borderRadius: '16px',
    },
    closeIconStyle: {
      position: 'absolute',
      right: 8,
      top: 6,
      fontSize: 24,
      color: '#000',
      pointer: 'cursor',
      '&:hover': {
        color: 'red',
      },
    },
  }),
);

export function MnemonicSchemeModal({
  setClose,
  mnemonicSchemeId,
  elementGuideId,
  freqElementId,
  elements,
}: {
  setClose: () => void;
  mnemonicSchemeId: string;
  elementGuideId: string;
  freqElementId: string | undefined;
  elements: IRawElement[] | undefined;
}) {
  const { layoutSize } = useContext(ContextSettings);
  const newLayoutSize = layoutSize;
  const classes = useStyles({ newLayoutSize });

  const mnemonicScheme = IS_MODE_EQ_DEVELOPMENT ? MOCK_MNEMOSCHEME : mnemonicSchemeId;

  const elementGuide = elements?.find((item) => item.elementId === elementGuideId);
  const freqElement = elements?.find((item) => item.elementId === freqElementId);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={true}
      onClose={setClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(200, 200, 200, 0.5)' } }}
    >
      <Fade in={true}>
        <div className={classNames(classes.paper)} id="transition-modal-description-222">
          <CloseIcon className={classes.closeIconStyle} onClick={setClose} />

          <div className={classes.header}>Мнемосхема</div>
          <div className={classes.subHeader}>
            (Зеленый - основной элемент, красный - другой элемент)
          </div>
          <div className={classes.mnemoscheme}>
            <SimpleMnemoSchemeRenderer
              mnemonicScheme={mnemonicScheme}
              redMnemoId={freqElement?.mnemonicSchemeLinkId}
              greenMnemoId={elementGuide?.mnemonicSchemeLinkId}
              handleMnemoElementClicked={(id) => {}}
              layout={{
                width: `${MODAL_WIDTH - 68}px`,
                height: `${newLayoutSize.height - 220}px`,
              }}
            />
          </div>

          <div className={classes.saveButtonContainer}>
            <Button
              className={classes.saveButton}
              variant="contained"
              color="primary"
              onClick={() => {
                setClose();
              }}
            >
              Закрыть
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
