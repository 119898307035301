import { ArrowRightIcon } from 'icons/arrow-right-icon';

import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 6,
    width: 140,
  },
}));

export function LinkButton({
  onClick,
  disabled,
  forId,
}: {
  onClick: () => void;
  disabled: boolean | undefined;
  forId: string;
}) {
  const classes = useStyles();

  return (
    <Button
      id={forId}
      className={classes.button}
      size="small"
      variant="contained"
      color="inherit"
      endIcon={<ArrowRightIcon style={{ fontSize: 20 }} />}
      onClick={onClick}
      disabled={disabled}
    >
      Перейти
    </Button>
  );
}
