import { useState, useContext, useCallback, useMemo } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { UseQueryResult } from 'react-query';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import { CustomBreadcrumbs } from 'components/breadcrums';
import { ContextSettings } from 'context/context-settings';
import {
  useGetDefectsDict,
  useGetSpectrumAndPeaks,
  useGetAllDefectsByElement,
  useGetPeaksAndParamsForCalculatorPage,
  useGetDiagnosticsGetNewFreqForCalculatorPage,
} from 'pages-diagnostic/diagnostic-spectrum/query-hooks/query-hooks';
import {
  IDefect,
  IFrequencyZoomParams,
  MENU_WIDTH,
  IHoveredOrigin,
  IBearingCalculatorParms,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import { useGetDiagnosticById } from 'pages-diagnostic/hooks';
import { DIAGNOSTICS_KEY, DIAGNOSTIC_SPECTRUM, EMPTY_OBJECT, NOOP } from 'stream-constants';
import { useQueryParams } from 'utils/hooks/use-query-params';

import { Chart } from '../chart/chart';
import { FrequenciesMenu } from '../frequencies-menu/frequencies-menu';
import { FrequenciesMenuHeader } from '../frequencies-menu/frequencies-menu-header';
import { usePrepareGraphParams } from '../hooks/use-prepare-graph-params';
import { MnemonicSchemeModal } from '../modal/mnemonic-scheme-modal';
import { SpectrumLayoutCustomScrollBar } from '../spectrum-layout-custom-scroll-bar';
import { SpectrumLayoutHeader } from '../spectrum-layout-header';

import { CalculatorForm } from './calculator-form';

type Props = { height: number; width: number };

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    headerAndControlsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#000',
      margin: '16px 0 20px 0',
    },
    charts: {
      position: 'relative',
      height: (props) => props.height,
      width: (props) => props.width,
    },
    layout: {
      marginTop: 28,
      height: (props) => props.height,
      width: (props) => props.width,
    },
    row: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
    },
    menu: {
      minWidth: MENU_WIDTH,
      maxWidth: MENU_WIDTH,
      overflowX: 'hidden',
      padding: '10px 0 0 10px',
    },
    elementBody2: {
      marginLeft: 120,
    },
    chartTitle: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#626C77',
      margin: '3px 0 3px 0',
    },
    zoomLabel: {
      marginLeft: 20,
      fontWeight: 400,
      fontSize: '14px',
    },
    zoomValue: {
      display: 'inline-block',
      width: 42,
      fontWeight: 400,
      fontSize: '17px',
      paddingLeft: 6,
    },
    shiftsButtons: {
      marginLeft: '24px',
    },
    mnemonicSchemeButton: {
      marginRight: 30,
    },
    smallMargin: {
      marginBottom: 3,
    },
    spaceRight: {
      marginRight: 20,
    },
  }),
);
const EMPTY_ARRAY = Object.freeze([]);
const EMPRY_SET = new Set<string>();

export function BearingParamsCalculatorPage() {
  const history = useHistory();
  const params = useParams<{ id: string; elementGuideId: string; defectId: string | undefined }>();
  const { queryParams, setQueryParams } = useQueryParams();

  const diagnosticQuery = useGetDiagnosticById(params.id);
  const { description } = diagnosticQuery.data ?? {};

  const { layoutSize } = useContext(ContextSettings);
  const modalLayoutSize = {
    height: layoutSize.height,
    width: layoutSize.width - 80,
  };
  const canvasSize = {
    width: modalLayoutSize.width - MENU_WIDTH,
    height: modalLayoutSize.height - 380,
  };
  const classes = useStyles(modalLayoutSize);

  const [hoverPeakName, setHoverPeakName] = useState<
    { name: string; origin: IHoveredOrigin } | undefined
  >(undefined);
  const [hoverGroupName, setHoverGroupName] = useState<string>('');
  const [withTooltip, setWithTooltip] = useState<boolean>(false);
  const [withMnemonicScheme, setWithMnemonicScheme] = useState<boolean>(false);
  const [hoverArea, setHoverArea] = useState<number | undefined>();

  const [selectedEnvelopFrequencies, setSelectedEnvelopFrequencies] = useState<Set<string>>(
    new Set([...queryParams.selectedVertexes]),
  );

  const [frequencyZoomParams, setFrequencyZoomParams] = useState<IFrequencyZoomParams>({
    center: 0,
    zoom: 1,
    weight: 0.5,
  });
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());

  const clearStates = useCallback(() => {
    setSelectedEnvelopFrequencies(new Set());
  }, []);

  const defectsDictQuery = useGetDefectsDict();

  const { defectsDict = EMPTY_OBJECT } = defectsDictQuery.data ?? {};

  const defectsQuery = useGetAllDefectsByElement(params.id, params.elementGuideId);
  const defects = (defectsQuery.data?.data ?? EMPTY_ARRAY) as IDefect[];

  const isReadyCheckBox = !!defectsQuery?.data?.isReady;

  const currentDefectOrTemplate = defects.find((defect) => defect.id === params.defectId);

  const currentDefect = currentDefectOrTemplate?.status !== 1 ? currentDefectOrTemplate : undefined;

  const freqElementId = '';

  const setCurrentDefect = useCallback(
    (defect: IDefect | undefined) => {
      setExpandedGroups(new Set());
      setHoverArea(undefined);
      setHoverGroupName('');
      setHoverPeakName(undefined);
      clearStates();
      history.push(
        `/${DIAGNOSTIC_SPECTRUM}/${params.id}/${params.elementGuideId}${
          defect?.id ? `/${defect.id}` : ''
        }`,
      );
    },
    [clearStates, history, params.elementGuideId, params.id],
  );

  const query = useGetSpectrumAndPeaks(params?.id ?? '', params?.elementGuideId ?? '');

  const queryPeaksAndParamsForCalculatorForm = useGetPeaksAndParamsForCalculatorPage(
    params?.elementGuideId ?? '',
  ) as UseQueryResult<IBearingCalculatorParms>;

  const currentBearingParams = queryParams.copyData ?? queryPeaksAndParamsForCalculatorForm?.data;

  const newFreq = useGetDiagnosticsGetNewFreqForCalculatorPage(
    params?.elementGuideId ?? '',
    currentBearingParams,
  );

  const mnemonicSchemeId = query?.data?.mnemonicScheme;

  const newMainQueryData = useMemo(() => {
    if (!query?.data?.data) {
      return query?.data;
    }
    const elem = newFreq?.data?.element;

    return { ...query?.data, data: { ...query?.data?.data, elements: elem ? [elem] : [] } };
  }, [newFreq?.data?.element, query?.data]);

  const graphParams = usePrepareGraphParams({
    mainElementId: params.elementGuideId,
    freqElementId: '',
    data: newMainQueryData,
    canvasSize,
    frequencyZoomParams,
  });

  const breadcrubms = useMemo(
    () => [
      {
        label: `Диагностика \u00AB${description}\u00BB`,
        handleOnClick: () => history.push(`/${DIAGNOSTICS_KEY}/${params.id}`),
      },
      {
        label: 'Список саб-элементов для дефектов',
        handleOnClick: () => history.push(`/${DIAGNOSTIC_SPECTRUM}/${params.id}`),
      },
      {
        label: 'Добавление дефекта',
        handleOnClick: () =>
          history.push(`/${DIAGNOSTIC_SPECTRUM}/${params.id}/${params.elementGuideId}`),
      },
      {
        label: 'Калькулятор параметров подшипника',
        handleOnClick: () => {},
      },
    ],
    [description, history, params.elementGuideId, params.id],
  );

  function OthenControls() {
    return (
      <div>
        <span className={classes.mnemonicSchemeButton}>
          Мнемосхема
          <Tooltip
            title={
              mnemonicSchemeId
                ? 'Показать элементы на мнемосхеме'
                : 'Мнемосхема недоступна (нет айдишника мнемосхемы)'
            }
            placement="top"
          >
            <IconButton
              color={mnemonicSchemeId ? 'primary' : 'secondary'}
              onClick={() => setWithMnemonicScheme(true)}
              size="small"
              className={classes.smallMargin}
            >
              <DeveloperBoardIcon style={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
        </span>
        <span>
          Тултип
          <Switch
            className={classes.smallMargin}
            size="small"
            checked={withTooltip}
            onChange={() => setWithTooltip(!withTooltip)}
            color="primary"
          />
        </span>
      </div>
    );
  }

  return (
    <div className={classes.layout}>
      <CustomBreadcrumbs links={breadcrubms} />

      <div className={classes.row}>
        <div>
          <div className={classes.headerAndControlsContainer}>
            <div className={classes.headerTitle}>{'Калькулятор частот'}</div>
            <OthenControls />
          </div>

          <SpectrumLayoutHeader
            subElement={graphParams?.element}
            defects={defects}
            currentDefect={currentDefect}
            setCurrentDefect={setCurrentDefect}
            defectsDict={defectsDict}
            setSelectedEnvelopFrequencies={setSelectedEnvelopFrequencies}
            setSelectedDirectFrequencies={NOOP}
            isReadyCheckBox={isReadyCheckBox}
            diagnosticId={params.id}
            showCalulatorIcon={false}
            onCalculatorIconClick={NOOP}
          />
          <CalculatorForm
            subElement={graphParams?.element}
            data={currentBearingParams}
            onSubmit={(newParams: IBearingCalculatorParms) => {
              setQueryParams({ copyData: newParams });
            }}
            onResetParams={() => setQueryParams({ copyData: undefined })}
          />

          <div className={classes.chartTitle}>
            {'Спектр огибающей (СО) (калькулятор параметров подшипника)'}
          </div>
          <Chart
            isEnvelop={true}
            canvasSize={canvasSize}
            graphParams={graphParams}
            hoverPeakName={hoverPeakName}
            setHoverPeakName={setHoverPeakName}
            hoverGroupName={hoverGroupName}
            selectedFrequencies={selectedEnvelopFrequencies}
            setSelectedFrequencies={setSelectedEnvelopFrequencies}
            spectrumData={query.data?.data?.spectrums}
            frequencyZoomParams={frequencyZoomParams}
            setFrequencyZoomParams={setFrequencyZoomParams}
            setExpandedGroups={setExpandedGroups}
            hoverArea={hoverArea}
            setHoverArea={setHoverArea}
            withTooltip={withTooltip}
          />

          <SpectrumLayoutCustomScrollBar
            graphParams={graphParams}
            frequencyZoomParams={frequencyZoomParams}
            setFrequencyZoomParams={setFrequencyZoomParams}
          />
        </div>
        <div className={classes.menu}>
          <FrequenciesMenuHeader />

          <FrequenciesMenu
            modalLayoutSize={modalLayoutSize}
            peaksData={graphParams.groups}
            preparedPeaks={graphParams?.preparedPeaks}
            selectedEnvelopFrequencies={selectedEnvelopFrequencies}
            setSelectedEnvelopFrequencies={setSelectedEnvelopFrequencies}
            selectedDirectFrequencies={EMPRY_SET}
            setSelectedDirectFrequencies={NOOP}
            hoverPeakName={hoverPeakName}
            setHoverPeakName={setHoverPeakName}
            hoverGroupName={hoverGroupName}
            setHoverGroupName={setHoverGroupName}
            expandedGroups={expandedGroups}
            setExpandedGroups={setExpandedGroups}
            setHoverArea={setHoverArea}
          />
        </div>
      </div>
      {withMnemonicScheme && mnemonicSchemeId && (
        <MnemonicSchemeModal
          setClose={() => setWithMnemonicScheme(false)}
          mnemonicSchemeId={mnemonicSchemeId}
          elementGuideId={params.elementGuideId}
          freqElementId={freqElementId}
          elements={query.data?.data?.elements}
        />
      )}
    </div>
  );
}
