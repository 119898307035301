import { Controller } from 'react-hook-form';

import Popper from '@material-ui/core/Popper';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { IOption } from 'interfaces';
import { RED_COLOR } from 'stream-constants';

import { IFieldProps } from '../field-interfaces';
import FieldLabelErrorWrapper from '../field-label-error-wrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    option: {
      fontSize: '14px',
      fontWeight: 400,
      backgroundColor: '#ffffff',
      // Hover
      '&[data-focus="true"]': {
        backgroundColor: RED_COLOR,
        borderColor: 'transparent',
      },
      // Selected
      '&[aria-selected="true"]': {
        backgroundColor: 'ligthgrey',
        borderColor: 'transparent',
      },
    },
  }),
);

interface IProps<T> extends IFieldProps<T> {
  options: IOption[];
  noOptionsText?: string;
  placeholder?: string;
  disabled?: boolean;
  labelColor?: string;
}

const PopperMy = function (props: any) {
  return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
};

const FieldDropdownWithLabelAndValue = <T,>(props: IProps<T>) => {
  const classes = useStyles();
  const {
    form,
    options = [],
    fieldName,
    onFieldChange,
    noOptionsText,
    placeholder,
    disabled,
  } = props;

  return (
    <FieldLabelErrorWrapper {...props}>
      <Controller
        name={fieldName}
        //@ts-ignore
        control={form.control}
        //@ts-ignore
        render={({ field }) => {
          const newValue = options?.find((o) => o.value === field.value) ?? {
            value: '',
            label: '',
          };
          const newField = {
            ...field,
            value: { ...newValue },
          };

          return (
            <Autocomplete
              disabled={disabled}
              noOptionsText={noOptionsText}
              className={classes.root}
              PopperComponent={PopperMy}
              classes={{
                option: classes.option,
              }}
              options={options}
              autoHighlight
              getOptionLabel={(option) => String(option.label)}
              getOptionSelected={(option) => option.value === newValue.value}
              getOptionDisabled={(option) => !!option.disabled}
              renderOption={(option) => <>{option.label}</>}
              {...newField}
              disableClearable
              onChange={(event: any, option: IOption) => {
                onFieldChange && onFieldChange(option.value);
                field.onChange(option.value ?? '');
              }}
              renderInput={(params) => (
                <TextField
                  placeholder={placeholder}
                  {...params}
                  label=""
                  size="small"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                />
              )}
            />
          );
        }}
      />
    </FieldLabelErrorWrapper>
  );
};

export default FieldDropdownWithLabelAndValue;
FieldDropdownWithLabelAndValue.displayName = 'FieldDropdownWithLabelAndValue';
