import { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { IDiagnostic, IDiagnosticType } from 'interfaces';
import { ViewOnlyDiagnosticManualTypeModal } from 'pages-diagnostic/components/diagnostic-manual-type-modal/view-only-diagnostic-manual-type-modal';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    minHeight: 50,
    margin: '-10px 0 10px 0',
  },
  label: {
    cursor: 'pointer',
    marginLeft: 3,
    fontWeight: 400,
    fontSize: '17px',
    color: '#1D2023',
  },
  subLabel: {
    fontWeight: 500,
    fontSize: '15px',
    color: '#1D2023',
  },
  subLabelButton: {
    cursor: 'pointer',
    borderRadius: '100px',
    padding: '12px 16px',
    fontSize: '15px',
    fontWeight: 500,
    color: '#1D2023',
    backgroundColor: '#F2F3F7',
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
}));

export function DiagnosticTypeViewOnly({ data }: { data: IDiagnostic | undefined }) {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <>
      <div
        className={classes.root}
        onClick={() => data?.params?.diagnosticType === IDiagnosticType.MANUAL && setIsOpen(true)}
      >
        <div className={classes.label}>Тип диагностики</div>
        {data?.params?.diagnosticType === IDiagnosticType.EXPRESS && (
          <div className={classes.subLabel}>Экспресс диагностика</div>
        )}
        {data?.params?.diagnosticType === IDiagnosticType.DETAILED && (
          <div className={classes.subLabel}>Расширенная диагностика</div>
        )}
        {data?.params?.diagnosticType === IDiagnosticType.MANUAL && (
          <div className={classes.subLabelButton} onClick={() => setIsOpen(true)}>
            {`Ручная настройка режимов (${data?.params?.manualCombinationsTextMessage ?? '---'})`}
          </div>
        )}
      </div>
      {isOpen && (
        <ViewOnlyDiagnosticManualTypeModal
          manualSelectedModes={data?.params?.manualSelectedModes}
          open={true}
          setOpen={() => setIsOpen(false)}
        />
      )}
    </>
  );
}
