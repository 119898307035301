import { useContext, useMemo } from 'react';

import { useQuery } from 'react-query';

import { ContextSettings } from 'context/context-settings';
import { IElement, IElementType, IMode } from 'interfaces';
import { useVertexesDictionary } from 'pages/machine-pages/components/machine-form/hooks/use-vertexes-dictionary';
import { getMachineById } from 'services/machines';
import { getAllModesByMachineId } from 'services/modes';
import { MACHINE_AND_ALL_MODES_BY_ID_KEY } from 'stream-constants';
import { errorMessageConvert } from 'utils/error-message-convert';

import { IElem, elementsKey } from '../common';

const useGetMachineAndAllModesByMachineId = (dataId: string | undefined) => {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const machineId = dataId ?? '';
  const query = useQuery(
    [MACHINE_AND_ALL_MODES_BY_ID_KEY, machineId],
    () => Promise.all([getMachineById(machineId), getAllModesByMachineId(machineId)]),
    {
      enabled: !notificationMessage,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  const { vertexByIdDictionary, vertexByMnemonicIdDictionary } = useVertexesDictionary(
    query.data?.[0],
    'children',
  );

  return {
    query,
    vertexByIdDictionary,
    vertexByMnemonicIdDictionary,
  };
};

export const useMemoNodesElementsPairs = (machineLinkId: string | undefined) => {
  const { query, vertexByIdDictionary, vertexByMnemonicIdDictionary } =
    useGetMachineAndAllModesByMachineId(machineLinkId);

  const machine = query?.data?.[0];
  const modes = query?.data?.[1];
  const {
    nodes,
    nodeElements,
    nodeCogWheelPairs,
    totalElemsAndPairsCount,
    notLnkedElementKeys,
    mnemoschemeFileNotFound,
    combShaftsLengthError,
  } = useMemo(() => {
    let totalElemsAndPairsCount0 = 0;
    const nodes0 = [...(machine?.children ?? [])];
    nodes0.sort((a, b) => ((a?.order ?? 0) > (b?.order ?? 0) ? 1 : -1));

    const nodeElements0 = new Map<string, IElem[]>();
    const nodeCogWheelPairs0 = new Map<string, [IElem, IElem][]>();
    const notLnkedElementKeys0: Set<string> = new Set();
    let combShaftsLengthError0: boolean = false;
    nodes0.forEach((node) => {
      const shafts = [...(node.children ?? [])];
      shafts.sort((a, b) => ((a?.order ?? 0) > (b?.order ?? 0) ? 1 : -1));
      const bearings: IElem[] = [];
      shafts.forEach((shaft) => {
        const elemnts = [...(shaft.children ?? [])];
        elemnts.sort((a, b) => ((a?.order ?? 0) > (b?.order ?? 0) ? 1 : -1));
        elemnts.forEach((elem) => {
          if (
            elem.elementType === IElementType.BELT_TRANSMISSION ||
            elem.elementType === IElementType.BELT_TRANSMISSION_NOT_GEAR ||
            elem.elementType === IElementType.COGWHEEL ||
            elem.elementType === IElementType.CLUTCH ||
            elem.elementType === IElementType.WORM_GEAR
          ) {
            notLnkedElementKeys0.add(elem.id);
          } else {
            bearings.push({ id: elem.id, title: elem.name, type: elem.elementType });
          }
        });
      });
      nodeElements0.set(node.id, bearings);
      totalElemsAndPairsCount0 += bearings.length;
    });

    modes?.forEach((mode0) => {
      const mode = mode0 as unknown as IMode;
      const pairs: [IElem, IElem][] = [];
      const tempSet = new Set<string>();
      mode.combination.forEach((comb) => {
        if (mode.shaftsOrder.length !== comb.shafts.length) {
          combShaftsLengthError0 = true;
        }
        let prev: IElement | undefined = undefined;

        comb.shafts?.forEach((pair) => {
          const in0 = (pair.elemInId
            ? vertexByIdDictionary.get(pair.elemInId)?.vertex
            : undefined) as unknown as IElement;
          const out = (pair.elemOutId
            ? vertexByIdDictionary.get(pair.elemOutId)?.vertex
            : undefined) as unknown as IElement;

          if (prev && in0) {
            notLnkedElementKeys0.delete(prev?.id);
            notLnkedElementKeys0.delete(in0?.id);
            const arr: [IElem, IElem] = [
              { id: prev?.id, title: prev?.transmissionK ?? '', type: prev?.elementType },
              { id: in0?.id, title: in0?.transmissionK ?? '', type: prev?.elementType },
            ];
            const key = elementsKey(arr);
            if (!tempSet.has(key)) {
              pairs.push(arr);
              tempSet.add(key);
            }
          }
          prev = out;
        });
      });
      const modeUnitIdPairs = nodeCogWheelPairs0.get(mode.unitId) ?? [];
      modeUnitIdPairs.push(...pairs);
      const tempMap = new Map(
        modeUnitIdPairs.map((tempPair) => [`${tempPair[0].id}_${tempPair[1].id}`, tempPair]),
      );
      nodeCogWheelPairs0.set(mode.unitId, [...tempMap.values()]);
      totalElemsAndPairsCount0 += pairs.length;
    });

    return {
      nodes: nodes0,
      nodeElements: nodeElements0,
      nodeCogWheelPairs: nodeCogWheelPairs0,
      totalElemsAndPairsCount: totalElemsAndPairsCount0,
      notLnkedElementKeys: [...notLnkedElementKeys0],
      mnemoschemeFileNotFound: !machine?.mnemonicScheme,
      combShaftsLengthError: combShaftsLengthError0,
    };
  }, [machine, modes, vertexByIdDictionary]);

  return {
    vertexByIdDictionary,
    vertexByMnemonicIdDictionary,
    nodes,
    nodeElements,
    nodeCogWheelPairs,
    totalElemsAndPairsCount,
    machine,
    notLnkedElementKeys,
    mnemoschemeFileNotFound,
    combShaftsLengthError,
    allModes: modes,
  };
};
