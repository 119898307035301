import { IBearingCalculatorParms } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import {
  REQUIRED_ERR_MESSAGE,
  MUST_BE_A_NUMBER,
  MUST_BE_AN_INTEGER,
  MORE_THAN,
  LESS_THAN,
} from 'validation-schemas/validatian-constants';
import * as yup from 'yup';

export const validationSchemaBearingParams: yup.SchemaOf<IBearingCalculatorParms> = yup
  .object()
  .shape({
    speed: yup
      .number()
      .typeError(MUST_BE_A_NUMBER)
      .required(REQUIRED_ERR_MESSAGE)
      .min(0, MORE_THAN + '0')
      .lessThan(1000000.1, LESS_THAN + '1000000'),
    innerDiameter: yup
      .number()
      .typeError(MUST_BE_A_NUMBER)
      .required(REQUIRED_ERR_MESSAGE)
      .min(0, MORE_THAN + '0')
      .lessThan(1500.1, LESS_THAN + '1500')
      .test(
        'less-Than-outerDiameter',
        'внутренний диаметр должен быть меньше наружного диаметра',
        function lessThanOuterDiameter(diam) {
          const { outerDiameter } = this.parent;
          if ((diam ?? 0) < outerDiameter) {
            return true;
          }

          return false;
        },
      ),

    outerDiameter: yup
      .number()
      .typeError(MUST_BE_A_NUMBER)
      .min(0.0000001, MORE_THAN + '0')
      .max(1900, LESS_THAN + '1900')
      .moreThan(yup.ref('innerDiameter'), 'внешний диаметр должен быть больше внутреннего')
      .required(REQUIRED_ERR_MESSAGE),
    rollingElementsDiameter: yup
      .number()
      .typeError(MUST_BE_A_NUMBER)
      .min(0.0000001, MORE_THAN + '0')
      .max(600, LESS_THAN + '600')
      .test(
        'less-than-innerDiameter',
        'диаметр тел качения должен быть меньше внутреннего  диаметра',
        function lessThanInnerDiameter(diam) {
          const { innerDiameter } = this.parent;
          if ((diam ?? 0) < innerDiameter) {
            return true;
          }

          return false;
        },
      )
      .test(
        'less-outerDiameter',
        'диаметр тел качения должен быть меньше наружнего диаметра',
        function lessOuterDiameter(diam) {
          const { outerDiameter } = this.parent;
          if ((diam ?? 0) < outerDiameter) {
            return true;
          }

          return false;
        },
      )
      .test(
        'less-twice',
        'диаметр тел качения должен быть меньше (наружный  диаметра - внутренний диаметр) / 2',
        function lessTwice(diam) {
          const { outerDiameter, innerDiameter } = this.parent;
          if ((diam ?? 0) < ((outerDiameter ?? 0) - (innerDiameter ?? 0)) / 2) {
            return true;
          }

          return false;
        },
      )
      .required(REQUIRED_ERR_MESSAGE),
    rollingElementsNumber: yup
      .number()
      .typeError(MUST_BE_A_NUMBER)
      .required(REQUIRED_ERR_MESSAGE)
      .integer(MUST_BE_AN_INTEGER)
      .min(2, MORE_THAN + '1')
      .test(
        'less-formula',
        'диаметр тел качения должен быть меньше ((pi * диаметр наружный) / диаметр тел качения)',
        function lessFormula(numberOf) {
          const { rollingElementsDiameter, outerDiameter } = this.parent;
          if ((numberOf ?? 1) < (outerDiameter * Math.PI) / rollingElementsDiameter) {
            return true;
          }

          return false;
        },
      ),
    contactAngle: yup
      .number()
      .typeError(MUST_BE_A_NUMBER)
      .min(0, MORE_THAN + '0')
      .max(180, LESS_THAN + '180')
      .required(REQUIRED_ERR_MESSAGE),
    fullness: yup.boolean().notRequired(),
    dateOfCreation: yup.string().notRequired(),
  });
