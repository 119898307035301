import { useContext, useMemo, useState, useEffect } from 'react';

import UnitIcon from 'icons/unit-icon';

import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { CustomStatesCheckBox, ICheckBoxState } from 'components/custom-states-check-box';
import { StyledCheckbox } from 'components/styled-checkbox';
import { ContextSettings } from 'context/context-settings';
import { IMode, IMapValue } from 'interfaces';

type Props = { newLayoutSize: { height: number; width: number } };

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'relative',
      backgroundColor: '#fff',
      border: '2px solid #fff',
      borderRadius: 16,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    paperSizeViewOnly: {
      height: (props) => props.newLayoutSize.height,
      width: 600,
    },
    header: {
      fontWeight: 500,
      fontSize: '24px',
      color: '#1D2023',
      lineHeight: '28px',
      margin: '14px 0 0 0',
    },
    subHeader: {
      fontWeight: 400,
      fontSize: '17px',
      color: '#1D2023',
      lineHeight: '28px',
      margin: '14px 0 0 0',
    },
    clearAllModes: {
      maxWidth: '260px',
      transform: 'scale(0.7)',
      margin: '20px 0 0 -36px',
    },
    modes: {
      margin: '8px 0 20px 0',
      height: (props) => props.newLayoutSize.height - 206,
      overflowY: 'auto',
    },
    saveButtonContainer: {
      marginTop: '10px',
      display: 'flex',
      gap: 10,
    },

    saveButton: {
      maxWidth: 240,
    },

    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      alignItems: 'center',
    },
    unitIconMargin: {
      margin: '8px 12px 0 6px',
      transform: 'scale(1.5)',
    },
    customCheckboxMargin: {
      margin: '5px 0px 0 -2px',
      cursor: 'pointer',
    },
    unitName: {
      fontWeight: 400,
      fontSize: '20px',
      color: '#1D2023',
      lineHeight: '28px',
    },
    combinationRow: {
      margin: '0 0 0 42px',
      display: 'flex',
      flexDirection: 'row',
      gap: 12,
      alignItems: 'center',
    },
    combinationName: {
      fontWeight: 400,
      fontSize: '17px',
      color: '#1D2023',
      lineHeight: '24px',
    },
  }),
);

export function DiagnosticManualTypeModal({
  allModes,
  onResultSelect,
  open,
  setOpen,
  viewOnly,
  manualTypeInformation,
  vertexByIdDictionary,
}: {
  allModes: IMode[] | undefined;
  onResultSelect: ({
    selectedCombinations,
    combinationsTotal,
  }: {
    selectedCombinations: string[] | undefined;
    combinationsTotal: number;
  }) => void;
  open: boolean;
  setOpen: (b: boolean) => void;
  viewOnly?: boolean;
  manualTypeInformation?: string[] | undefined;
  vertexByIdDictionary: Map<string, IMapValue>;
}) {
  const { layoutSize } = useContext(ContextSettings);

  const [selectedCombinations, setSelectedCombinations] = useState<Set<string>>(new Set());
  const newLayoutSize = layoutSize;
  const classes = useStyles({ newLayoutSize });

  useEffect(() => {
    setSelectedCombinations(new Set(manualTypeInformation));
  }, [manualTypeInformation]);

  const handleClose = () => {
    setOpen(false);
  };

  const { units, combinationsTotal } = useMemo(() => {
    const combinationsMap = new Map<string, { id: string; outputSpeed: string }[]>();
    let combinationsTotal0 = 0;
    if (allModes) {
      for (let mode of allModes) {
        const combinations = combinationsMap.get(mode.unitId) ?? [];
        if (combinations.length === 0) {
          combinationsMap.set(mode.unitId, combinations);
        }
        const arr = mode.combination.map((combination) => ({
          id: combination.id,
          outputSpeed: combination.outputSpeed,
        }));
        combinationsTotal0 += arr.length;
        combinations.push(...arr);
      }
    }

    return { units: [...combinationsMap], combinationsTotal: combinationsTotal0 };
  }, [allModes]);

  const groupCheckBoxValueMap = useMemo(() => {
    const groupCheckBoxValueMap000 = new Map<string, ICheckBoxState>();
    for (let [unitId, combinations] of units) {
      if (combinations.every(({ id }) => selectedCombinations.has(id))) {
        groupCheckBoxValueMap000.set(unitId, ICheckBoxState.ALL);
      } else {
        if (combinations.some(({ id }) => selectedCombinations.has(id))) {
          groupCheckBoxValueMap000.set(unitId, ICheckBoxState.SOME);
        } else {
          groupCheckBoxValueMap000.set(unitId, ICheckBoxState.NONE);
        }
      }
    }

    return groupCheckBoxValueMap000;
  }, [selectedCombinations, units]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(200, 200, 200, 0.5)' } }}
    >
      <Fade in={open}>
        <div
          className={classNames(classes.paper, classes.paperSizeViewOnly)}
          id="transition-modal-description-222"
        >
          <div className={classes.header}>Ручная настройка режимов</div>
          <div className={classes.subHeader}>Выберите режимы работы узлов</div>

          <div className={classes.modes}>
            {units.map(([unitId, combinations], unitIndex) => (
              <div key={unitId}>
                <div className={classes.row}>
                  <div className={classes.customCheckboxMargin}>
                    <CustomStatesCheckBox
                      forId={`for_manual_unit_${unitIndex}`}
                      state={groupCheckBoxValueMap.get(unitId) ?? ICheckBoxState.NONE}
                      onClick={(state: ICheckBoxState) => {
                        const newSelectedCombinations = new Set([...selectedCombinations]);

                        switch (state) {
                          case ICheckBoxState.ALL:
                            combinations.forEach((comb) => {
                              newSelectedCombinations.add(comb.id);
                            });
                            break;

                          case ICheckBoxState.ZERO_FREQ:
                          case ICheckBoxState.SOME:
                          case ICheckBoxState.NONE:
                            combinations.forEach((comb) => {
                              newSelectedCombinations.delete(comb.id);
                            });
                        }
                        setSelectedCombinations(newSelectedCombinations);
                      }}
                    />
                  </div>
                  <Tooltip title={'Узел'} placement={'top'}>
                    <div className={classes.unitIconMargin}>
                      <UnitIcon />
                    </div>
                  </Tooltip>
                  <div className={classes.unitName}>
                    {vertexByIdDictionary.get(unitId)?.vertex?.name ?? '---'}
                  </div>
                </div>

                {combinations.map(({ id, outputSpeed }, index) => (
                  <div key={id} className={classes.combinationRow}>
                    <StyledCheckbox
                      id={`for_manual_speed_${unitIndex}_${index}`}
                      disabled={viewOnly}
                      checked={!!selectedCombinations?.has(id)}
                      onChange={(_, value) => {
                        const newSelectedCombinations = new Set(selectedCombinations);
                        if (value) {
                          newSelectedCombinations.add(id);
                        } else {
                          newSelectedCombinations.delete(id);
                        }
                        setSelectedCombinations(newSelectedCombinations);
                      }}
                    />
                    <div className={classes.combinationName}>
                      {outputSpeed ?? `outputSpeed = ???, id=${id}`}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div className={classes.saveButtonContainer}>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
            >
               Отмена
            </Button>
            <Button
              disabled={viewOnly || selectedCombinations?.size === 0}
              variant="contained"
              color="primary"
              onClick={() => {
                setOpen(false);
                onResultSelect({
                  selectedCombinations:
                    selectedCombinations === undefined || selectedCombinations.size === 0
                      ? undefined
                      : [...selectedCombinations],
                  combinationsTotal,
                });
              }}
            >
              Сохранить настройку
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
