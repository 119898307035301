import { FC, useEffect, useState, useRef } from 'react';

import { ShowVertex } from 'components/mnemo-scheme-renderer/show-vertex';
import { FETCH_MINIO_URL } from 'services/urls';

import { findNearestIdInDOM } from './find-nearest-id-in-dom';
const DOM_MNEMOSCHEME_ID = 'svg_object_menmoscheme_simple';

const TRANSPARENT_ID = 'Transparent_1';

const GREEN_ELEMENT = 'GREEN_ELEMENT';
const RED_ELEMENT = 'RED_ELEMENT';
const BLUE_ELEMENT = 'BLUE_ELEMENT';

const NORMAL_ELEMENT = 'NORMAL_ELEMENT';
const DISABLED_ELEMENT = 'DISABLED_ELEMENT';

const BLACK_COLOR = '#000';
const BLACK_COLOR_LIGHT = '#ddd';

const DARK_GREEN_COLOR = '#027722';
const DARK_GREEN_COLOR_LIGHT = '#74DF8B';

const DARK_RED_COLOR = '#DC032D';
const DARK_RED_COLOR_LIGHT = '#F2A8B7';

const YELLOW_COLOR = '#99991C';
const YELLOW_COLOR_LIGHT = '#FFFFCC';

const BLUE_COLOR = '#0097FD';
const BLUE_COLOR_LIGHT = '#E1F3FE';

function setElementColor(element: HTMLElement, strokeColor: string, fillColor: string) {
  if (element?.style) {
    element.style.stroke = strokeColor;
    element.style.fill = fillColor;
    element.style.fillOpacity = '1';
  }
  [...element.children].forEach((child) => {
    setElementColor(child as HTMLElement, strokeColor, fillColor);
  });
}

export const SimpleMnemoSchemeRenderer: FC<{
  mnemonicScheme: string | null | undefined;
  redMnemoId: string | undefined;
  greenMnemoId: string | undefined;
  blueMnemoId?: string | undefined;
  handleMnemoElementClicked: (id: string) => void;
  layout?: {
    width: string;
    height: string;
  };
  enabledMemoIds?: Set<string>;
}> = ({
  mnemonicScheme,
  redMnemoId,
  greenMnemoId,
  blueMnemoId,
  layout,
  handleMnemoElementClicked,
  enabledMemoIds,
}) => {
  const [allMnemoElements, setAllMnemoElements] = useState<HTMLElement[] | undefined>();
  const [mouseOverElemId, setMouseOverElemId] = useState<string | undefined>();
  const [cursorXY, setCursorXY] = useState<{ x: number; y: number }>();
  const [readyToShow, setReadyToShow] = useState<boolean>(false);
  const mainDivRef = useRef(null);
  const mainDivXY = (mainDivRef.current &&
    (mainDivRef.current as HTMLElement).getBoundingClientRect()) as unknown as DOMRect | undefined;

  useEffect(() => {
    const doc = document.getElementById(DOM_MNEMOSCHEME_ID) as HTMLElement | null;
    // document.getElementById(mnemoschemeRef.current)) as HTMLElement | null;

    if (!doc) {
      return;
    }
    function listener() {
      //@ts-ignore
      const contentDocument: HTMLDocument = doc.contentDocument ?? doc.getSVGDocument();

      const transparentIdElement = contentDocument?.getElementById(TRANSPARENT_ID);
      transparentIdElement?.remove();

      const iterator = contentDocument?.querySelectorAll('[id]');
      const allIds = ((iterator && [...iterator]) ?? []).map((a: any) => a.id);

      const elems = allIds?.map((id) => contentDocument.getElementById(id)) ?? [];
      const filteredElems = elems.filter((element) => element !== null);

      mnemonicScheme && setAllMnemoElements(filteredElems as HTMLElement[]);
    }

    doc.addEventListener('load', listener, false);

    return () => {
      doc.addEventListener('load', listener);
    };
  }, [mnemonicScheme]);

  useEffect(() => {
    const onClickListener: EventListener = (e) => {
      const event = e as MouseEvent;
      e.stopPropagation();
      if (handleMnemoElementClicked) {
        const id = findNearestIdInDOM(event) || '';
        handleMnemoElementClicked(id);
      }
    };

    const onMouseOverListener: EventListener = (e) => {
      const event = e as MouseEvent;
      const id = findNearestIdInDOM(event);
      setMouseOverElemId(id);
      setCursorXY({ x: event.offsetX, y: event.offsetY });
    };
    const onMouseLeaveListener = (e: MouseEvent) => {
      setMouseOverElemId(undefined);
    };

    allMnemoElements?.forEach((element) => {
      element.addEventListener('click', onClickListener);
      element.addEventListener('mouseover', onMouseOverListener);
      element.addEventListener('mouseleave', onMouseLeaveListener);
    });

    return () => {
      allMnemoElements?.forEach((element) => {
        element.removeEventListener('click', onClickListener);
        element.removeEventListener('onmouseover', onMouseOverListener);
        element.removeEventListener('mouseleave', onMouseLeaveListener);
      });
    };
  }, [allMnemoElements, handleMnemoElementClicked]);

  useEffect(() => {
    if (allMnemoElements) {
      if (enabledMemoIds) {
        for (const el of allMnemoElements.filter((item) => !enabledMemoIds.has(item.id))) {
          setElementColor(el, BLACK_COLOR_LIGHT, BLACK_COLOR_LIGHT);
        }
      }
      for (const el of allMnemoElements) {
        let theCase = NORMAL_ELEMENT;
        if (enabledMemoIds && !enabledMemoIds.has(el.id)) {
          theCase = DISABLED_ELEMENT;
        } else {
          if (el.id === redMnemoId) {
            theCase = RED_ELEMENT;
          } else {
            if (el.id === greenMnemoId) {
              theCase = GREEN_ELEMENT;
            } else {
              if (el.id === blueMnemoId) {
                theCase = BLUE_ELEMENT;
              }
            }
          }
        }

        switch (theCase) {
          case RED_ELEMENT:
            setElementColor(el, DARK_RED_COLOR, DARK_RED_COLOR_LIGHT);
            break;
          case GREEN_ELEMENT:
            setElementColor(el, DARK_GREEN_COLOR, DARK_GREEN_COLOR_LIGHT);
            break;
          case BLUE_ELEMENT:
            setElementColor(el, BLUE_COLOR, BLUE_COLOR_LIGHT);
            break;
          case NORMAL_ELEMENT:
            setElementColor(el, BLACK_COLOR, BLACK_COLOR_LIGHT);
            break;
          case DISABLED_ELEMENT:
            setElementColor(el, YELLOW_COLOR, YELLOW_COLOR_LIGHT);
            break;
          default:
            throw new Error(`Невозмжный кейс theCase = ${theCase}`);
        }
      }
      setReadyToShow(true);
    }
  }, [allMnemoElements, enabledMemoIds, greenMnemoId, redMnemoId, blueMnemoId]);

  return (
    <div style={{ position: 'relative' }} key={mnemonicScheme} ref={mainDivRef}>
      <object
        style={{ visibility: readyToShow ? 'visible' : 'hidden' }}
        id={DOM_MNEMOSCHEME_ID}
        data={FETCH_MINIO_URL(mnemonicScheme ?? '')}
        type="image/svg+xml"
        width={layout ? `${layout.width}px` : '100%'}
        height={layout ? `${layout.height}px` : '100%'}
      >
        {'Идет загрузка мнемосхемы...'}
      </object>
      {cursorXY && mouseOverElemId && (
        <ShowVertex mouseOverElemId={mouseOverElemId} position={cursorXY} xMax={mainDivXY?.width} />
      )}
    </div>
  );
};
