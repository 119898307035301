import { ExclamationMarkIcon } from 'icons/exclamation-mark-icon';

import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { UniversalModalDialog } from 'components/universal-modal-dialog';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      minWidth: '400px',
      maxWidth: '400px',
    },
    exclamationMark: {
      margin: '12px 0 0 0',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '24px',
      color: '#1D2023',
      lineHeight: '28px',
      margin: '20px 0 0 0',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      whiteSpace: 'pre-wrap',
      color: '#1D2023',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
      margin: '12px 0 24px 0',
    },
  }),
);

export function LayoutMessageModalDialog({ setClose }: { setClose: () => void }) {
  const classes = useStyles();

  return (
    <UniversalModalDialog setClose={setClose}>
      <div className={classes.root}>
        <div className={classes.exclamationMark}>
          <ExclamationMarkIcon style={{ fontSize: 44 }} />
        </div>
        <div className={classes.header}>Ширина окна не поддерживается</div>

        <div className={classes.message}>
          {
            'Ширина окна меньше 1440px.\nВы можете продолжить работу на сайте, но контент отобразиться не полностью'
          }
        </div>

        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.buttonWidth}
          onClick={setClose}
        >
          {'Понятно'}
        </Button>
      </div>
    </UniversalModalDialog>
  );
}
