import { useContext, useState, useMemo } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { CustomBreadcrumbs } from 'components/breadcrums';
import { elementIcon } from 'components/element-icon';
import { ContextSettings } from 'context/context-settings';
import { MODES_DICT_NAMES } from 'interfaces';
import { ShowDefect } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-list/components/show-defect';
import { SimpleMnemoSchemeRenderer } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-list/components/simple-mnemo-scheme-renderer';
import {
  useGetDefectsDict,
  useGetSpectrumElementSummary,
} from 'pages-diagnostic/diagnostic-spectrum/query-hooks/query-hooks';
import { ISummaryElement } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import { useGetDiagnosticById } from 'pages-diagnostic/hooks';
import {
  IS_MODE_EQ_DEVELOPMENT,
  DIAGNOSTICS_KEY,
  DIAGNOSTIC_SPECTRUM_SUMMARY_ITEM,
  DIAGNOSTIC_SPECTRUM_SUMMARY_LIST,
  EMPTY_OBJECT,
} from 'stream-constants';

import { TextInput } from './text-input';

const deDivinaProportione = (x: number) => Math.round(x * 0.5);
const GAP_TO_MENMOSCHEME = 24;
const LOOK_TO_MHEMOSCHEME_WIDTH = 150;

type IProps = { layoutSize: { width: number; height: number } };

const useStyles = makeStyles<Theme, IProps>((theme) =>
  createStyles({
    layout: {
      marginTop: 28,
    },
    containerColumns: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      gap: GAP_TO_MENMOSCHEME,
    },
    list: {
      position: 'sticky',
      top: 0,
      width: (props) => deDivinaProportione(props.layoutSize.width),
      height: '100%',
    },
    emptylist: {
      position: 'sticky',
      top: 0,
      width: (props) => deDivinaProportione(props.layoutSize.width),
      height: '30%',
    },
    mnemoschemeContainer: {
      position: 'sticky',
      top: 0,
    },
    mnemoschemeHeader: {
      fontWeight: 500,
      fontSize: '17px',
      color: '#000',
      margin: '-24px 0 28px 0',
    },
    mnemoscheme: {
      padding: 16,
      border: '1px solid #DADEE5',
      borderRadius: '16px',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    buttonStyle: {
      width: '50%',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#000',
      margin: '24px 0 24px 0',
    },
    elementNameStyle: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '28px',
      marginLeft: 10,
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    iconScale: {
      marginTop: 5,
      transform: 'scale(1)',
    },
    itemRow: {
      marginLeft: -12,
      borderRadius: '16px',
      maxWidth: (props) => deDivinaProportione(props.layoutSize.width),
      minWidth: (props) => deDivinaProportione(props.layoutSize.width),
      minHeight: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      // '&:hover': {
      border: '1px solid #000',
      // },
      color: '#000',
    },
    isSelected: {
      backgroundColor: 'lightgrey',
    },
    itemText: {
      padding: '0 6px',
      maxWidth: (props) =>
        props.layoutSize.width -
        deDivinaProportione(props.layoutSize.width) -
        LOOK_TO_MHEMOSCHEME_WIDTH -
        64,
      minWidth: (props) =>
        props.layoutSize.width -
        deDivinaProportione(props.layoutSize.width) -
        LOOK_TO_MHEMOSCHEME_WIDTH -
        64,
      wordWrap: 'break-word',
      fontWeight: 500,
      fontSize: '14px',
    },
    itemText2: {
      padding: '0 6px',
      maxWidth: LOOK_TO_MHEMOSCHEME_WIDTH,
      minWidth: LOOK_TO_MHEMOSCHEME_WIDTH,
      wordWrap: 'break-word',
      fontWeight: 400,
      fontSize: '12px',
      color: '#626C77',
    },
    iconEyeIcon: {
      marginTop: 4,
      transform: 'scale(0.6)',
    },
    greyTextColor: {
      opacity: 0.6,
      fontWeight: 400,
    },
    uncertainTextColor: {
      color: '#ff6e3e',
    },
  }),
);

export function DiganosticSummaryElement() {
  const history = useHistory();

  const params = useParams<{ id: string; elementGuideId: string }>();
  const diagnosticQuery = useGetDiagnosticById(params.id);
  const { description } = diagnosticQuery.data ?? {};

  const { layoutSize, setLastModifiedMachineId } = useContext(ContextSettings);

  const layoutSizeNew = useMemo(
    () => ({ height: layoutSize.height, width: layoutSize.width - 70 }),
    [layoutSize],
  );

  const [selectedDefectId, setSelectedDefectId] = useState<string | undefined>();

  const classes = useStyles({ layoutSize: layoutSizeNew });

  const elementListQuery = useGetSpectrumElementSummary(params?.id, params?.elementGuideId);

  const element = (elementListQuery?.data ?? {}) as ISummaryElement;

  const mnemonicScheme = IS_MODE_EQ_DEVELOPMENT ? undefined : element.mnemonicScheme;

  const defectsDictQuery = useGetDefectsDict();
  const { defectsDict = EMPTY_OBJECT } = defectsDictQuery.data ?? {};

  const IconComponent = elementIcon(element?.elementGuideParentType);

  const breadcrubms = useMemo(
    () => [
      {
        label: `Диагностика \u00AB${description}\u00BB`,
        handleOnClick: () => history.push(`/${DIAGNOSTICS_KEY}/${params.id}`),
      },
      {
        label: 'Список элементов для выводов',
        handleOnClick: () => history.push(`/${DIAGNOSTIC_SPECTRUM_SUMMARY_LIST}/${params.id}`),
      },
      {
        label: 'Вывод по элементу',
        handleOnClick: () => {},
      },
    ],
    [description, history, params.id],
  );
  const nextElementId = element?.nextElement;

  return (
    <div className={classes.layout}>
      <CustomBreadcrumbs links={breadcrubms} />

      <div className={classes.headerTitle}>{'Вывод по элементу'}</div>
      <div className={classes.row}>
        <Tooltip
          title={
            element?.elementGuideParentType
              ? MODES_DICT_NAMES[element.elementGuideParentType]
              : 'Не известный тип элемента'
          }
          placement={'top'}
        >
          <span className={classes.iconScale}>
            <IconComponent />
          </span>
        </Tooltip>
        <span className={classNames({ [classes.uncertainTextColor]: !!element?.isUncertain })}>
          <div className={classes.elementNameStyle}>{element?.elementRealName}</div>
          <div className={classNames(classes.elementNameStyle, classes.greyTextColor)}>
            {element?.mnemonicSchemeLinkId}
          </div>
        </span>
      </div>

      <div className={classes.containerColumns}>
        <div>
          {Array.isArray(element?.defectsList) && element.defectsList.length > 0 ? (
            element?.defectsList.map((defect, index) => (
              <div
                key={defect.id}
                className={classNames(classes.itemRow, {
                  [classes.isSelected]: selectedDefectId && selectedDefectId === defect.id,
                })}
                onClick={() => {
                  setSelectedDefectId(defect?.id);
                }}
              >
                <ShowDefect defect={defect} defectsDict={defectsDict} isSmallSize={false} />
                <div className={classes.itemText}>
                  {/*//@ts-ignore */}
                  {`${index + 1}. ${defectsDict?.[defect?.defectType]}`}
                </div>
              </div>
            ))
          ) : (
            <div className={classes.emptylist}>
              <br />
              --- Пустой список дефектов ---
            </div>
          )}

          <TextInput text={element?.summaryText ?? ''} diagnosticId={params.id} element={element} />
        </div>
        <div>
          <div className={classes.mnemoschemeContainer}>
            <div className={classes.mnemoschemeHeader}>Мнемосхема</div>
            <div className={classes.mnemoscheme}>
              <SimpleMnemoSchemeRenderer
                mnemonicScheme={mnemonicScheme}
                redMnemoId={element?.mnemonicSchemeLinkId ?? ''}
                greenMnemoId={''}
                handleMnemoElementClicked={(id) => {}}
                layout={{
                  width: `${layoutSize.width - deDivinaProportione(layoutSize.width) - 90}px`,
                  height: `${layoutSize.height - 230}px`,
                }}
              />
            </div>
            <br />
            <div className={classes.buttonContainer}>
              <Button
                disabled={nextElementId === undefined}
                variant={nextElementId === null ? 'outlined' : 'contained'}
                className={classes.buttonStyle}
                onClick={() => {
                  if (nextElementId === null) {
                    history.push(`/${DIAGNOSTIC_SPECTRUM_SUMMARY_LIST}/${params?.id}`);
                  } else {
                    setLastModifiedMachineId(nextElementId);
                    history.push(
                      `/${DIAGNOSTIC_SPECTRUM_SUMMARY_ITEM}/${params?.id}/${nextElementId}`,
                    );
                  }
                }}
                color="primary"
              >
                {nextElementId === null ? 'Возврат к списку' : 'Следующий элемент'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
