import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function MainFolderIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 28 36'}>
      <path
        d="M21.7559 6.20703C18.2539 2.70508 16.1455 1.01063 15.2094 0.453095C14.58 0.117034 13.54 8.71657e-05 12.6436 4.69191e-05L11.5986 0L11.6001 0.00306656C10.8797 0.0128634 10.1914 0.045744 9.53751 0.0960791C6.863 0.301974 5.52574 0.404921 3.1858 2.69517C0.845868 4.98543 0.691635 6.54951 0.383169 9.67767C0.157928 11.9618 0 14.738 0 18C0 20.7127 0.111921 23.0893 0.279155 25.1264C0.584742 28.8488 0.737536 30.7099 3.13333 33.0922C5.52912 35.4745 7.27986 35.6083 10.7814 35.8759C11.7996 35.9537 12.8773 36 14 36C15.1228 36 16.2005 35.9537 17.2188 35.8759C20.7202 35.6083 22.4708 35.4745 24.8666 33.0923C27.2624 30.71 27.4152 28.8489 27.7208 25.1267C27.8881 23.0896 28 20.7128 28 18C28 16.5088 28.0352 13.6289 27.5508 12.7773C27.4846 12.661 27.3615 12.5887 27.214 12.3981C26.4247 11.3781 24.7065 9.15771 21.7559 6.20703Z"
        fill="url(#paint0_linear_1334_480613)"
      />
      <path
        d="M27.5711 12.8017C25.6427 7.4327 19.987 2.3383 15.168 0.429688C17.506 3.51031 16.457 8.88305 17.7983 10.2243C19.4018 11.8278 24.868 10.6089 27.5711 12.8017Z"
        fill="#FFDE80"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1334_480613"
          x1="-6.49308"
          y1="3.5"
          x2="35.1298"
          y2="11.9379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC700" />
          <stop offset="1" stopColor="#FF9900" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
