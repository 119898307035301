import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  ISpectrumRawData,
  IFrequencyZoomParams,
  IHoveredOrigin,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

import { usePrepareGraphParams } from '../hooks/use-prepare-graph-params';

import { CustomTooltip } from './custom-tooltip';
import { PeaksLayer23 } from './peaks-layer-2-3';
import { SpectrumLayer1 } from './spectrum-layer-1';

type Props = { height: number; width: number };

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    chart: {
      position: 'relative',
      height: (props) => props.height,
      width: (props) => props.width,
    },
  }),
);

export function Chart({
  isEnvelop,
  canvasSize,
  graphParams,
  hoverPeakName,
  setHoverPeakName,
  hoverGroupName,
  selectedFrequencies,
  setSelectedFrequencies,
  spectrumData,
  frequencyZoomParams,
  setFrequencyZoomParams,
  setExpandedGroups,
  hoverArea,
  setHoverArea,
  withTooltip,
}: {
  isEnvelop: boolean;
  canvasSize: { height: number; width: number };
  graphParams: ReturnType<typeof usePrepareGraphParams>;
  hoverPeakName: { name: string; origin: IHoveredOrigin } | undefined;
  setHoverPeakName: (i: { name: string; origin: IHoveredOrigin } | undefined) => void;
  hoverGroupName: string;
  selectedFrequencies: Set<string>;
  setSelectedFrequencies: (s: Set<string>) => void;
  spectrumData: ISpectrumRawData;
  frequencyZoomParams: IFrequencyZoomParams;
  setFrequencyZoomParams: (a: IFrequencyZoomParams) => void;
  setExpandedGroups: (s: Set<string>) => void;
  hoverArea: number | undefined;
  setHoverArea: (a: number | undefined) => void;
  withTooltip: boolean;
}) {
  const classes = useStyles(canvasSize);

  let hoverPeak = graphParams?.preparedPeaks?.get(hoverPeakName?.name ?? '');

  if (hoverPeak) {
    const theoryFreq = hoverPeak.rawPeak.theoryFreq;
    const { frequencyMin, frequencyMax } = graphParams;
    if (
      frequencyMin === undefined ||
      frequencyMax === undefined ||
      theoryFreq < frequencyMin ||
      theoryFreq > frequencyMax
    ) {
      hoverPeak = undefined;
    }
  }

  let shouldBeShown = withTooltip;
  if (shouldBeShown) {
    switch (hoverPeakName?.origin) {
      case IHoveredOrigin.ENVELOP:
        shouldBeShown = isEnvelop;
        break;
      case IHoveredOrigin.DIRECT:
        shouldBeShown = !isEnvelop;
        break;
      case IHoveredOrigin.BOTH:
      default:
        break;
    }
    if (isEnvelop) {
    }
  }

  return (
    <div className={classes.chart}>
      {spectrumData && spectrumData.length > 0 && (
        <>
          <SpectrumLayer1
            canvasSize={canvasSize}
            graphParams={graphParams}
            isEnvelop={isEnvelop}
            spectrumData={spectrumData}
          />
          <PeaksLayer23
            canvasSize={canvasSize}
            graphParams={graphParams}
            hoverGroupName={hoverGroupName}
            hoverPeakName={hoverPeakName}
            isEnvelop={isEnvelop}
            selectedFrequencies={selectedFrequencies}
            setHoverPeakName={setHoverPeakName}
            setSelectedFrequencies={setSelectedFrequencies}
            frequencyZoomParams={frequencyZoomParams}
            setFrequencyZoomParams={setFrequencyZoomParams}
            setExpandedGroups={setExpandedGroups}
            hoverArea={hoverArea}
            setHoverArea={setHoverArea}
            withTooltip={withTooltip}
          />
        </>
      )}
      {shouldBeShown && hoverPeak && <CustomTooltip hoverPeak={hoverPeak} isEnvelop={isEnvelop} />}
    </div>
  );
}
