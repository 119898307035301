import {
  IQueryParams,
  IHttpMethods,
  IDiagnostic,
  IDiagnosticStatus,
  ISortParamsOrder,
  IDiagnostList,
} from 'interfaces';
import {
  GET_DIAGNOSTICS_URL,
  GET_DIAGNOSTIC_FILTER_DICTIONARIES,
  CREATE_DIAGNOSTIC_URL,
  GET_DIAGNOSTIC_BY_ID_URL,
  GET_DIAGNOSTIC_BASE_PARAMS_BY_ID_URL,
  DELETE_DIAGNOSTIC_URL,
  DOWNLOAD_MEASURE_GUIDE_URL,
  DOWNLOAD_REPORT_URL,
  CREATE_REPORT_URL,
  VALIDATE_MEASURE_DATA_URL,
  PROCESSING_MEASURE_DATA_URL,
  MOVE_DIAGNOSTIC_TO_ARCHIVE,
  EXTRACT_DIAGNOSTIC_FROM_ARCHIVE,
  ASSIGN_TO_DIAGNOST_URL,
  GET_DIAGNOST_LIST_URL,
  UPDATE_IS_CHECKED_STATUS_URL,
  GET_DIAGNOSTIC_REPORT_PARAMS_BY_ID_URL,
} from 'services/urls';
import { DEFAULT_PAGE_SIZE, IS_SERVER_MOCKED } from 'stream-constants';
import { delay } from 'utils/delay';
import { generateUnicId } from 'utils/generate-unic-id';

import { fetchData, probableError } from '../service-utils';

import filtersDictiontaries from './filters-dictionaries.json';
import { base } from './mock-data';

const BASE_TIME = 3000;
const MIN_TIME = 200;

export const getDiagnostics = async <T,>(
  params: IQueryParams,
): Promise<{ data: T[]; total: number }> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      const { offset = 0, pageSize = DEFAULT_PAGE_SIZE } = params;

      result = {
        data: base.slice(offset, offset + pageSize),
        total: base.length,
      };
    }
  } else {
    const { offset = 0, pageSize = DEFAULT_PAGE_SIZE, searchString, order, filters } = params;
    const newFilters = [...(filters ?? [])];
    if (searchString) {
      newFilters.push({ id: 'searchString', value: searchString });
    }
    result = fetchData({
      url: GET_DIAGNOSTICS_URL,
      method: IHttpMethods.POST,
      name: 'getDiagnostics',
      body: JSON.stringify({
        pageSize,
        offset,
        filters: newFilters,
        order:
          order === undefined || order === ISortParamsOrder.DESC
            ? ISortParamsOrder.DESC
            : ISortParamsOrder.ASC,
      }),
      emptyResponse: false,
    });
  }
  const t1 = performance.now();
  console.log(`getDiagnostics took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
export const getDiagnosticFilterDictionaries = async (params: IQueryParams): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = filtersDictiontaries;
    }
  } else {
    const { searchString, filters = [] } = params;
    const newFiters = [...filters];
    if (searchString) {
      newFiters.push({ id: 'searchString', value: searchString });
    }
    result = fetchData({
      url: GET_DIAGNOSTIC_FILTER_DICTIONARIES,
      method: IHttpMethods.POST,
      name: 'getDiagnosticFilterDictionaries',
      body: JSON.stringify({ filters: newFiters }),
      emptyResponse: false,
    });
  }
  const t1 = performance.now();
  console.log(`getDiagnosticFilterDictionaries took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getDiagnosticById = async (id: string): Promise<IDiagnostic | undefined> => {
  const t0 = performance.now();

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    }
    const diagnostic = { ...(base.find((elem) => elem.id === id) ?? {}) };
    if (!diagnostic) {
      result = Promise.reject(new Error('diagnostic not found'));
    } else {
      result = Promise.resolve(diagnostic as IDiagnostic);
    }
  } else {
    result = fetchData({
      url: GET_DIAGNOSTIC_BY_ID_URL(id),
      method: IHttpMethods.GET,
      name: 'getDiagnosticById',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getDiagnosticById took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getDiagnosticReportParamsById = async (
  id: string,
): Promise<IDiagnostic | undefined> => {
  const t0 = performance.now();

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    }
    const diagnostic = { ...(base.find((elem) => elem.id === id) ?? {}) };
    if (!diagnostic) {
      result = Promise.reject(new Error('diagnostic not found'));
    } else {
      result = Promise.resolve(diagnostic as IDiagnostic);
    }
  } else {
    result = fetchData({
      url: GET_DIAGNOSTIC_REPORT_PARAMS_BY_ID_URL(id),
      method: IHttpMethods.GET,
      name: 'getDiagnosticReportParamsById',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getDiagnosticReportParamsById took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const addDiagnostic = async (diagnostic: IDiagnostic): Promise<{ id: string }> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      const newDiagnostic = {
        ...diagnostic,
        id: generateUnicId(),
        creationDate: Date.now().toString(),
        status: IDiagnosticStatus.CREATED,
      } as IDiagnostic;

      base.push(newDiagnostic);

      const t1 = performance.now();
      console.log(`addDiagnostic took ${(t1 - t0).toFixed(2)} milliseconds.`);
      result = Promise.resolve({ id: newDiagnostic.id });
    }
  } else {
    result = fetchData({
      url: CREATE_DIAGNOSTIC_URL(diagnostic.machineLinkId),
      method: IHttpMethods.POST,
      name: 'addDiagnostic',
      body: JSON.stringify(diagnostic),
      emptyResponse: false,
    });
  }

  return result;
};

export const deleteDiagnosticById = async (id: string): Promise<boolean> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const index = base.findIndex((item) => item.id === id);
    if (index === -1) {
      result = Promise.reject(new Error(`Item not found with the id = ${id}`));
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base.splice(index, 1);
        result = Promise.resolve(true);
      }
    }
    const t1 = performance.now();
    console.log(`deleteDiagnosticById took ${(t1 - t0).toFixed(2)} milliseconds.`);
  } else {
    result = fetchData({
      url: DELETE_DIAGNOSTIC_URL(id),
      method: IHttpMethods.DELETE,
      name: 'deleteDiagnosticById',
      emptyResponse: true,
    });
  }

  return result;
};

export const updateDiagnostic = async (diagnostic: IDiagnostic): Promise<{ id: string }> => {
  const t0 = performance.now();

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const index = base.findIndex((item) => item.id === diagnostic.id);
    if (index === -1) {
      result = Promise.reject(new Error(`Item not found with the id = ${diagnostic.id}`));
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base[index] = { ...diagnostic };
        result = Promise.resolve({ id: diagnostic.id });
      }
    }
  } else {
    try {
      await fetchData({
        url: GET_DIAGNOSTIC_BASE_PARAMS_BY_ID_URL(diagnostic.id),
        method: IHttpMethods.PUT,
        name: 'updateDiagnostic',
        body: JSON.stringify(diagnostic),
        emptyResponse: true,
      });
      result = Promise.resolve({ id: diagnostic.id });
    } catch (err) {
      result = Promise.reject(new Error((err as Error).message));
    }
  }

  const t1 = performance.now();
  console.log(`updateDiagnostic took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const downloadDiagnosticRoute = async (diagnostic: IDiagnostic): Promise<{ id: string }> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const index = base.findIndex((item) => item.id === diagnostic.id);
    if (index === -1) {
      result = Promise.reject(new Error(`Item not found with the id = ${diagnostic.id}`));
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base[index] = { ...base[index] };
        result = Promise.resolve({ id: diagnostic.id });
      }
    }
  } else {
    await fetchData({
      url: DOWNLOAD_MEASURE_GUIDE_URL(diagnostic.id),
      method: IHttpMethods.GET,
      name: 'downloadDiagnosticRoute',
      emptyResponse: true,
    });
    result = Promise.resolve({ id: diagnostic.id });
  }

  const t1 = performance.now();
  console.log(`downloadDiagnosticRoute took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const downloadDiagnosticReport = async (diagnosticId: string): Promise<{ id: string }> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const index = base.findIndex((item) => item.id === diagnosticId);
    if (index === -1) {
      result = Promise.reject(new Error(`Item not found with the id = ${diagnosticId}`));
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base[index] = { ...base[index] };
        result = Promise.resolve({ id: diagnosticId });
      }
    }
  } else {
    // result = Promise.reject(new Error('downloadDiagnosticReport not implemented'));
    await fetchData({
      url: DOWNLOAD_REPORT_URL(diagnosticId),
      method: IHttpMethods.GET,
      name: 'downloadDiagnosticReport',
      emptyResponse: true,
    });
    result = Promise.resolve({ id: diagnosticId });
  }

  const t1 = performance.now();
  console.log(`downloadDiagnosticReport took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
export const createDiagnosticReport = async (
  diagnosticId: string,
  reportParams: any,
): Promise<{ id: string }> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const index = base.findIndex((item) => item.id === diagnosticId);
    if (index === -1) {
      result = Promise.reject(new Error(`Item not found with the id = ${diagnosticId}`));
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base[index] = { ...base[index] };
        result = Promise.resolve({ id: diagnosticId });
      }
    }
  } else {
    await fetchData({
      url: CREATE_REPORT_URL(diagnosticId),
      method: IHttpMethods.POST,
      name: 'createDiagnosticReport',
      body: JSON.stringify(reportParams),
      emptyResponse: true,
    });
    result = Promise.resolve({ id: diagnosticId });
  }

  const t1 = performance.now();
  console.log(`createDiagnosticReport took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const validateDiagnosticMeasurments = async ({
  diagnosticId,
  archiveId,
}: {
  diagnosticId: string;
  archiveId: string;
}): Promise<{ id: string }> => {
  const t0 = performance.now();

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const index = base.findIndex((item) => item.id === diagnosticId);
    if (index === -1) {
      result = Promise.reject(new Error(`Item not found with the id = ${diagnosticId}`));
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base[index] = { ...base[index] };
        result = Promise.resolve({ id: diagnosticId });
      }
    }
  } else {
    result = await fetchData({
      url: VALIDATE_MEASURE_DATA_URL(diagnosticId ?? 'empty_id', archiveId ?? 'empty_id'),
      method: IHttpMethods.POST,
      name: 'validateDiagnosticMeasurments',
      emptyResponse: false,
      timeoutToAbort: 35 * 60 * 1000,
    });
  }

  const t1 = performance.now();
  console.log(`validateDiagnosticMeasurments took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export interface IProccessUploadMeasuringPayload {
  data: { measurementId: string; realSpeed: number }[];
}

export const processingMeasureData = async ({
  diagnosticId,
  archiveId,
  payload,
}: {
  diagnosticId: string | undefined;
  archiveId: string | undefined;
  payload: IProccessUploadMeasuringPayload;
}): Promise<{ id: string }> => {
  const t0 = performance.now();

  if (!diagnosticId || !archiveId) {
    return Promise.resolve({ id: '' });
  }

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const index = base.findIndex((item) => item.id === diagnosticId);
    if (index === -1) {
      result = Promise.reject(new Error(`Item not found with the id = ${diagnosticId}`));
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base[index] = { ...base[index] };
        result = Promise.resolve({ id: diagnosticId });
      }
    }
  } else {
    await fetchData({
      url: PROCESSING_MEASURE_DATA_URL(diagnosticId, archiveId),
      method: IHttpMethods.POST,
      name: 'processingMeasureData',
      emptyResponse: true,
      timeoutToAbort: 60 * 60 * 1000,
      body: JSON.stringify(payload),
    });
    result = Promise.resolve({ id: diagnosticId });
  }

  const t1 = performance.now();
  console.log(`processingMeasureData took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const toggleDiagnosticArchiveFlag = async ({
  diagnosticId,
  archive,
}: {
  diagnosticId: string;
  archive: boolean;
}): Promise<boolean> => {
  const t0 = performance.now();

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const index = base.findIndex((item) => item.id === diagnosticId);
    if (index === -1) {
      result = Promise.reject(new Error(`Item not found with the id = ${diagnosticId}`));
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base[index] = { ...base[index], isArchive: archive };
        result = Promise.resolve(true);
      }
    }
  } else {
    await fetchData({
      url: archive
        ? MOVE_DIAGNOSTIC_TO_ARCHIVE(diagnosticId ?? 'empty_id')
        : EXTRACT_DIAGNOSTIC_FROM_ARCHIVE(diagnosticId ?? 'empty_id'),
      method: IHttpMethods.POST,
      name: 'toggleDiagnosticArchiveFlag',
      emptyResponse: true,
    });
    result = Promise.resolve(true);
  }

  const t1 = performance.now();
  console.log(`toggleDiagnosticArchiveFlag took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const updateIsCheckedStatus = async ({
  diagnosticId,
  status,
}: {
  diagnosticId: string;
  status: boolean;
}): Promise<boolean> => {
  const t0 = performance.now();

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const index = base.findIndex((item) => item.id === diagnosticId);
    if (index === -1) {
      result = Promise.reject(new Error(`Item not found with the id = ${diagnosticId}`));
    } else {
      const err = probableError(0.01);
      if (err) {
        result = Promise.reject(new Error(err));
      } else {
        base[index] = { ...base[index], isChecked: status };
        result = Promise.resolve(true);
      }
    }
  } else {
    await fetchData({
      url: UPDATE_IS_CHECKED_STATUS_URL(diagnosticId, status),
      method: IHttpMethods.PUT,
      name: 'updateIsCheckedStatus',
      emptyResponse: true,
    });
    result = Promise.resolve(true);
  }

  const t1 = performance.now();
  console.log(`updateIsCheckedStatus took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const assignDiagnosticToDiagnostById = async (id: string, userId: string): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(true);
    }
    const t1 = performance.now();
    console.log(`assignDiagnosticToDiagnostById took ${(t1 - t0).toFixed(2)} milliseconds.`);
  } else {
    result = fetchData({
      url: ASSIGN_TO_DIAGNOST_URL(id, userId),
      method: IHttpMethods.PUT,
      name: 'assignDiagnosticToDiagnostById',
      emptyResponse: true,
    });
  }

  return result;
};

export const getDiagnostListById = async (id: string): Promise<IDiagnostList[]> => {
  const t0 = performance.now();

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    }
    result = Promise.resolve([
      {
        surname: 'Рохманенко',
        name: 'Виктор',
        patronymic: null,
        email: 'vmrokhma@mts.ru',
        id: '6eee5687-0c7c-4b4d-afb4-c6e3edc0fb53',
      },
      {
        surname: 'Маначинская',
        name: 'Кристина',
        patronymic: 'Валерьевна',
        email: 'kvmanachin@mts.ru',
        id: 'a5db2979-06b4-4a77-9ad7-0fdde690b823',
      },
      {
        surname: 'Коновалов',
        name: 'Алексей',
        patronymic: null,
        email: 'avkonoval1@mts.ru',
        id: '5bc13194-a02c-4ce8-9c58-85a389ca9e92',
      },
    ]);
  } else {
    result = fetchData({
      url: GET_DIAGNOST_LIST_URL(id),
      method: IHttpMethods.GET,
      name: 'getDiagnostListById',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getDiagnostListById took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
