import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Li, Ul } from 'components/list';

export function AboutProject({ redirectUrl }: { redirectUrl: string | undefined }) {
  return (
    <div className="layout-min-height">
      <br />
      <Typography variant="h5" color="inherit">
        VibroScan - диагностика: цифровой сервис вибродиагностики промышленного оборудования
      </Typography>

      <Li>
        <b>Цель: </b>предоставить пользователю личный кабинет, где можно:
        <Ul>
          <Li>создавать диагностические процессы</Li>
          <Li>управлять процессами диагностик</Li>
          <Li>получать маршруты диагностик</Li>
          <Li>подгружать результаты диагностики</Li>
          <Li>получать отчёты о проведении вибродиагностик</Li>
        </Ul>
      </Li>
      <Li>
        <b>Контакты:</b>
        <Ul>
          <Li>Почта: I-iot@mts.ru</Li>
          <Li>Телефон руководителя проекта: +7 977 815-15-80 Олег Правда</Li>
          <Li>По организационным вопросам: +7 926 614-16-92 Наталья Яроцкая</Li>
        </Ul>
      </Li>
      <br />
      <div style={{ width: 200 }}>
        <Button
          id="id_authorization_button"
          type="button"
          size="small"
          variant="contained"
          color="primary"
          startIcon={<ArrowRightIcon />}
          disabled={!redirectUrl}
          onClick={() => {
            if (redirectUrl) {
              window.location.href = redirectUrl;
            }
          }}
        >
          Войти в систему
        </Button>
      </div>
    </div>
  );
}
