import { useContext, useEffect, useRef } from 'react';

import { DownloadButtonIcon } from 'icons/download-button-icon';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ContextSettings } from 'context/context-settings';
import { IDiagnostic, IDiagnosticStatus } from 'interfaces';
import { useMutationDownloadDiagnosticRoute } from 'pages-diagnostic/hooks';
import { FETCH_MINIO_URL } from 'services/urls';
import { downloadFileByUrl } from 'utils/download-file-by-url';
import { removeUnvalidCharactersFromFilename } from 'utils/remove-unvalid-characters-from-filename';

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: 20,
    width: 240,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 20,
    fontSize: '14px',
    fontWeight: 400,
  },
}));

export function DownloadRoute({
  data,
  reFetch,
}: {
  data: IDiagnostic | undefined;
  reFetch: () => void;
}) {
  const classes = useStyles();
  const { setNotificationMessage } = useContext(ContextSettings);
  const timeoutId = useRef<ReturnType<typeof setTimeout> | undefined>();
  const timeoutCount = useRef<number>(0);

  const mutationDownloadDiagnosticRoute = useMutationDownloadDiagnosticRoute();
  const measuringGuidPdf = data?.measuringGuidPdf;

  useEffect(() => {
    if (measuringGuidPdf === undefined) {
      if (timeoutId.current !== undefined) {
        clearTimeout(timeoutId.current);
      }
      timeoutId.current = setTimeout(reFetch, 2000);
      timeoutCount.current += 2;
      if (timeoutCount.current > 30) {
        setNotificationMessage({
          fullMessage: 'Внимание',
          header: `Произошла ошибка. Маршрут не сформирован. Просим извинить команду разработки.`,
        });
        timeoutCount.current = 0;
      }
    } else {
      timeoutId.current && clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
      timeoutCount.current = 0;
    }

    return () => timeoutId.current && clearTimeout(timeoutId.current);
  }, [measuringGuidPdf, reFetch, setNotificationMessage]);

  return (
    <Button
      id="id_download_the_route_392"
      disabled={!measuringGuidPdf}
      className={classes.button}
      size="small"
      variant="contained"
      color="inherit"
      startIcon={measuringGuidPdf && <DownloadButtonIcon />}
      onClick={() => {
        if (data) {
          if (data.status === IDiagnosticStatus.CREATED) {
            mutationDownloadDiagnosticRoute.mutate(data);
          }
          downloadFileByUrl(
            FETCH_MINIO_URL(measuringGuidPdf),
            `diagnostic-route-${removeUnvalidCharactersFromFilename(
              data.machineInventoryNumber,
            )}.zip`,
            setNotificationMessage,
          );
        }
      }}
    >
      {measuringGuidPdf ? (
        <div>Скачать маршрут</div>
      ) : (
        <div className={classes.row}>
          <CircularProgress size={20} />
        </div>
      )}
    </Button>
  );
}
