import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function BallscrewIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 32 32'}>
      <path d="M13.343,0.875v29.833h5.375V0.875H13.343z M18.135,30.1h-4.208V1.482h4.208V30.1z" />
      <rect x="13.927" y="1.482" fill="none" width="4.208" height="28.618" />
      <rect x="8.856" y="11.664" strokeMiterlimit="10" width="5.07" height="3.78" />
      <rect x="18.135" y="11.664" strokeMiterlimit="10" width="5.01" height="3.78" />
      <rect x="13.927" y="11.664" strokeMiterlimit="10" width="4.208" height="3.78" />
      <rect x="18.135" y="15.444" strokeMiterlimit="10" width="3.254" height="6.863" />
      <rect x="10.613" y="15.444" strokeMiterlimit="10" width="3.313" height="6.863" />
      <rect x="13.927" y="15.444" strokeMiterlimit="10" width="4.208" height="6.863" />

      <rect
        x="13.261"
        y="4.729"
        transform="matrix(0.8355 -0.5495 0.5495 0.8355 -0.1001 9.6267)"
        width="5.532"
        height="0.504"
      />

      <rect
        x="13.158"
        y="2.148"
        transform="matrix(0.8355 -0.5495 0.5495 0.8355 1.2396 8.9395)"
        width="4.784"
        height="0.504"
      />

      <rect
        x="13.261"
        y="7.957"
        transform="matrix(0.8355 -0.5495 0.5495 0.8355 -1.8742 10.1578)"
        width="5.532"
        height="0.504"
      />

      <rect
        x="13.265"
        y="11.146"
        transform="matrix(0.8355 -0.5495 0.5495 0.8355 -3.6257 10.6845)"
        width="5.532"
        height="0.504"
      />

      <rect
        x="13.342"
        y="25.373"
        transform="matrix(0.8355 -0.5495 0.5495 0.8355 -11.4312 13.0676)"
        width="5.532"
        height="0.504"
      />

      <rect
        x="13.239"
        y="22.793"
        transform="matrix(0.8355 -0.5495 0.5495 0.8355 -10.0914 12.3803)"
        width="4.784"
        height="0.503"
      />

      <rect
        x="13.342"
        y="28.602"
        transform="matrix(0.8355 -0.5495 0.5495 0.8355 -13.2054 13.5987)"
        width="5.532"
        height="0.504"
      />
    </SvgIcon>
  );
}
