const dict: Record<string, string> = {
  a: 'a',
  b: 'b',
  c: 'c',
  d: 'd',
  e: 'e',
  f: 'f',
  g: 'g',
  h: 'h',
  i: 'i',
  j: 'j',
  k: 'k',
  l: 'l',
  m: 'm',
  n: 'n',
  o: 'o',
  p: 'p',
  q: 'q',
  r: 'r',
  s: 's',
  t: 't',
  u: 'u',
  v: 'v',
  w: 'w',
  x: 'x',
  y: 'y',
  z: 'z',
  //
  а: 'a',
  б: 'b',
  в: 'v',
  г: 'g',
  д: 'd',
  е: 'e',
  ё: 'e',
  ж: 'j',
  з: 'z',
  и: 'i',
  к: 'k',
  л: 'l',
  м: 'm',
  н: 'n',
  о: 'o',
  п: 'p',
  р: 'r',
  с: 's',
  т: 't',
  у: 'u',
  ф: 'f',
  ц: 'c',
  х: 'h',
  ч: 'ch',
  ш: 'sh',
  щ: 'sch',
  э: 'e',
  ю: 'u',
  я: 'ya',
  ъ: '-',
  ь: '-',
  '0': '0',
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  _: '_',
};

export const removeUnvalidCharactersFromFilename = (str?: string) =>
  str
    ? str
        .split('')
        .map((s) => {
          const char = s.toLowerCase();
          let newChar = dict[char] ?? '-';
          if (newChar !== '-' && char !== s) {
            newChar = newChar.toUpperCase();
          }

          return newChar;
        })
        .join('')
    : 'no-inventory-number';
