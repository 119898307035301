import React from 'react';

import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: '14px',
      fontWeight: 400,
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    iconsSize: {
      fontSize: 18,
    },
  }),
);

export const CustomBreadcrumbs = React.memo(function ({
  links,
}: {
  links: { label: string; handleOnClick: () => void }[];
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Breadcrumbs
        separator={<NavigateNextIcon className={classes.iconsSize} />}
        aria-label="breadcrumb"
      >
        {links.map((item, index) =>
          index < links.length - 1 ? (
            <Link
              key={item.label}
              color="inherit"
              href="/"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                item.handleOnClick();
              }}
            >
              {item.label}
            </Link>
          ) : (
            <Typography color="textPrimary" key={item.label}>
              {item.label}
            </Typography>
          ),
        )}
      </Breadcrumbs>
    </div>
  );
});
