import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function LockIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 16 20'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5445 7.61857C14.3587 7.42817 14.174 7.26232 13.987 7.11756C13.9789 6.83455 13.9672 6.55343 13.9526 6.27603C13.8406 4.14542 13.7846 3.08011 12.7237 1.80382C12.5472 1.5915 12.0898 1.15754 11.8685 0.992435C10.5383 0 9.69217 0 8 0C6.30783 0 5.46174 0 4.13151 0.992436C3.91021 1.15754 3.45283 1.5915 3.27634 1.80382C2.21543 3.08011 2.15942 4.14542 2.0474 6.27603C2.03282 6.55343 2.02112 6.83455 2.01299 7.11756C1.82596 7.26232 1.64125 7.42817 1.4555 7.61857C0.0736372 9.03496 0.0501593 10.2476 0.00320323 12.6728C0.00109331 12.7818 0 12.8909 0 13C0 13.1091 0.0010933 13.2182 0.00320323 13.3272C0.0501592 15.7524 0.0736372 16.965 1.4555 18.3814C2.83736 19.7978 4.16164 19.8562 6.81019 19.9729C7.20346 19.9902 7.60173 20 8 20C8.39827 20 8.79654 19.9902 9.18981 19.9729C11.8384 19.8562 13.1626 19.7978 14.5445 18.3814C15.9264 16.965 15.9498 15.7524 15.9968 13.3272C15.9989 13.2182 16 13.1091 16 13C16 12.8909 15.9989 12.7818 15.9968 12.6728C15.9498 10.2476 15.9264 9.03496 14.5445 7.61857ZM4.81436 3.08229C4.24334 3.76924 4.16308 4.17047 4.0515 6.2518C4.80337 6.11553 5.68789 6.07655 6.81019 6.02709C7.20346 6.00976 7.60173 6 8 6C8.39827 6 8.79654 6.00976 9.18981 6.02709C10.3121 6.07655 11.1966 6.11553 11.9485 6.2518C11.8369 4.17047 11.7567 3.76924 11.1856 3.08229L11.1849 3.0815C10.7841 2.63301 10.2519 2.20619 9.65799 2.08116C9.31374 2.0087 8.90595 2 8 2C7.09405 2 6.68626 2.0087 6.34201 2.08116C5.74805 2.20619 5.2159 2.63303 4.81512 3.08152L4.81436 3.08229ZM9.39872 13C9.39872 12.2275 8.77249 11.6012 8 11.6012C7.22751 11.6012 6.60128 12.2275 6.60128 13C6.60128 13.7725 7.22751 14.3987 8 14.3987C8.77249 14.3987 9.39872 13.7725 9.39872 13Z"
        fill="#969FA8"
      />
    </SvgIcon>
  );
}
