import { IMachine, IElement, IVertexType, IElementType } from 'interfaces';

import DMG_50_ECOLINE from './images/DMG-50-ecoline.png';
import gearbox from './images/gearbox.jpg';
import LONG_MACHINE_PHOTO from './images/long-machine.photo.png';
import imagePath11 from './images/MASTURN_550i.png';
// import imagePath2 from './images/metal-industry-cnc-machine.png';
import MNEMO_SCHEME_000 from './images/mnemo-scheme-000.svg';
import MNEMO_SCHEME_DMG50 from './images/mnemo-scheme-DMG50.svg';
import MNEMO_SCHEME_IP500 from './images/mnemo-scheme-IP500.svg';
import NEF_400 from './images/NEF-400.png';
import MNEMO_SCHEME_NEF400 from './images/NEF400_reflect_checked_NAMED_relocatednumbers.svg';

export const base: { children: IMachine[] } = {
  children: [
    {
      children: [
        {
          children: [
            {
              order: 1,
              name: 'Вал мотора',
              id: '211',
              vertexType: IVertexType.SHAFT,
              children: [
                {
                  id: '211П',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.RADIAL_BEARING,
                  name: 'Подшипник 178832',
                  vertexType: IVertexType.ELEMENT,
                  elementLinkId: '178832',
                  order: 5,
                },
                {
                  id: '212П',
                  name: 'Комб. подшипник 824573 ываыв ываы ыва',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COMBINED_BEARING,
                  order: 6,
                },

                {
                  id: '212чс',
                  name: 'ШВП',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.BALLSCREW,
                  order: 7,
                },
                {
                  id: '21alk2чс',
                  name: '',
                  transmissionK: '140',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                  mnemonicSchemeLinkId: 'Gear wheel 1',
                  order: 8,
                },
              ] as IElement[],
            },
            {
              name: 'Вал 2',
              order: 2,
              id: '2a11',
              vertexType: IVertexType.SHAFT,
              children: [
                {
                  id: '21al111k2чс',
                  name: '',
                  transmissionK: '268',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '211aП',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.RADIAL_BEARING,
                  name: 'Подшипник 126',
                  vertexType: IVertexType.ELEMENT,
                  elementLinkId: '126',
                },

                {
                  id: '21a777k2чс',
                  name: '',
                  transmissionK: '51',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21al888k2чс',
                  name: '',
                  transmissionK: '56',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
              ] as IElement[],
            },
            {
              id: '2a12',
              name: 'Вал 3',
              order: 3,
              vertexType: IVertexType.SHAFT,
              children: [
                {
                  id: '21a000l111k2чс',
                  name: '',
                  transmissionK: '34',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a000l144411k2чс',
                  name: '',
                  transmissionK: '39',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '200011aП',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.RADIAL_BEARING,
                  name: 'Подшипник 126',
                  vertexType: IVertexType.ELEMENT,
                  elementLinkId: '126',
                },

                {
                  id: '21a000777k2чс',
                  name: '',
                  transmissionK: '38',
                  beltLength: '1000',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.BELT_TRANSMISSION,
                },
                {
                  id: '21a000l888k2чс',
                  name: '',
                  transmissionK: '29',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a0012340l888k2чс',
                  name: '',
                  transmissionK: '21',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
              ] as IElement[],
            },
            {
              id: '21a23333',
              name: 'Вал 4',
              order: 4,
              vertexType: IVertexType.SHAFT,
              children: [
                {
                  id: '21a000l789888k2чс',
                  name: '',
                  transmissionK: '38',
                  beltLength: '1000',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.BELT_TRANSMISSION,
                },
                {
                  id: '21a000l83453488k2чс',
                  name: '',
                  transmissionK: '47',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a00078678l888k2чс',
                  name: '',
                  transmissionK: '55',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a000l12383453488k2чс',
                  name: '',
                  transmissionK: '45',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a000l12383dfgdf453488k2чс',
                  name: '',
                  transmissionK: '60',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
              ] as IElement[],
            },
            {
              id: '21a2334433',
              name: 'Вал 5',
              order: 5,
              vertexType: IVertexType.SHAFT,
              children: [
                {
                  id: '21a000l78984488k2чс',
                  name: '',
                  transmissionK: '38',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a000l8345443488k2чс',
                  name: '',
                  transmissionK: '47',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a00078332678l888k2чс',
                  name: '',
                  transmissionK: '55',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a000l1238213453488k2чс',
                  name: '',
                  transmissionK: '45',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
              ] as IElement[],
            },
            {
              id: '21a232234433',
              name: 'Вал 6',
              order: 6,
              vertexType: IVertexType.SHAFT,
              children: [
                {
                  id: '21a000l7898224488k2чс',
                  name: '',
                  transmissionK: '38',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a000l8345222443488k2чс',
                  name: '',
                  transmissionK: '47',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a00078332678l22888k2чс',
                  name: '',
                  transmissionK: '55',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a000l123821345322488k2чс',
                  name: '',
                  transmissionK: '45',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
              ] as IElement[],
            },
            {
              id: '21a233334433',
              name: 'Шпиндель',
              order: 7,
              vertexType: IVertexType.SHAFT,
              children: [
                {
                  id: '21a00033l78984488k2чс',
                  name: '',
                  transmissionK: '48',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a000l834335443488k2чс',
                  name: '',
                  transmissionK: '47',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a0007833267448l888k2чс',
                  name: '',
                  transmissionK: '55',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
                {
                  id: '21a000l123821443453488k2чс',
                  name: '',
                  transmissionK: '45',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                },
              ] as IElement[],
            },
          ],
          id: '11a1a',
          name: 'Узел 1',
          vertexType: IVertexType.NODE,
          comment: 'тип мотора',
        },
        {
          children: [
            {
              name: 'Вал 1',
              id: '2w11',
              vertexType: IVertexType.SHAFT,
              children: [
                {
                  id: '21w1П',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.RADIAL_BEARING,
                  name: '178832',
                  vertexType: IVertexType.ELEMENT,
                  elementLinkId: '178832',
                },
                {
                  id: '21w2П',
                  name: 'Комб. подшипник',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COMBINED_BEARING,
                },
                {
                  id: '21w2pe',
                  name: '',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.NOTHING,
                },
                {
                  id: '21ww2чс',
                  name: 'ШВП',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.BALLSCREW,
                },
              ] as IElement[],
            },
            {
              name: 'Вал 2',
              id: '2aw11',
              vertexType: IVertexType.SHAFT,
              children: [
                {
                  id: '21w1aП',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.RADIAL_BEARING,
                  name: '178832',
                  vertexType: IVertexType.ELEMENT,
                  elementLinkId: '178832',
                },
                {
                  id: '21a2wП',
                  name: 'Комб. подшипник',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COMBINED_BEARING,
                },
                {
                  id: '21a2pwe',
                  name: '',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.NOTHING,
                },
                {
                  id: '21wa2чс',
                  name: 'ШВП',
                  vertexType: IVertexType.ELEMENT,
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.BALLSCREW,
                },
              ] as IElement[],
            },
            {
              id: '2aw12',
              name: 'Вал 3',
              vertexType: IVertexType.SHAFT,
            },
            {
              id: '21a23w333',
              name: 'Вал 4',
              vertexType: IVertexType.SHAFT,
            },
          ],
          id: '11a1wa',
          name: 'Узел 2',
          vertexType: IVertexType.NODE,
          comment: 'тип мотора',
        },
      ],

      id: '13',
      name: 'MASTURN 550i CNC 800 / 1500',
      vertexType: IVertexType.MACHINE,
      machineType: 'токарный',
      description: 'Станок токарный с ЧПУ: MASTURN 550i CNC 800 / 1500',
      fullness: false,
      manufactured: 'Kovosvit Mas, Чехия',
      photo: imagePath11,
      mnemonicScheme: MNEMO_SCHEME_IP500,
      dateOfCreation: '2021-06-25T04:08:16Z',
    },

    {
      children: [
        {
          children: [
            {
              name: 'Вал 0 входной',
              order: 1,
              children: [
                {
                  id: '162220aClutch1',
                  order: 1,
                  mnemonicSchemeLinkId: 'Clutch',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.CLUTCH,
                  vertexType: IVertexType.ELEMENT,

                  name: 'Муфта мотора',
                  elementLinkId: '',
                },
              ] as IElement[],
              id: '1627444400205',
              mnemonicSchemeLinkId: 'Engine',
              vertexType: IVertexType.SHAFT,
            },
            {
              name: 'Вал 1',
              order: 1,
              children: [
                {
                  id: '162220aClutch2',
                  order: 1,
                  mnemonicSchemeLinkId: 'Frame 1',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.CLUTCH,
                  vertexType: IVertexType.ELEMENT,

                  name: 'Муфта мотора',
                  elementLinkId: '',
                },
                {
                  id: '1627550468192',
                  order: 2,
                  mnemonicSchemeLinkId: 'Bearing 1',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.RADIAL_BEARING,
                  vertexType: IVertexType.ELEMENT,

                  name: '126',
                  elementLinkId: '126',
                },
                {
                  id: '1627550488762',
                  order: 3,
                  mnemonicSchemeLinkId: 'Bearing 2',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.RADIAL_BEARING,
                  vertexType: IVertexType.ELEMENT,
                  name: '126',
                  elementLinkId: '126',
                },
                {
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                  name: '',

                  transmissionK: '25',
                  id: '1627550513326',
                  order: 4,

                  mnemonicSchemeLinkId: 'Gear wheel 1',

                  vertexType: IVertexType.ELEMENT,
                },
              ] as IElement[],
              id: '1627550400205',
              mnemonicSchemeLinkId: 'Shaft 1',
              vertexType: IVertexType.SHAFT,
            },
            {
              children: [
                {
                  id: '1627550537460',
                  mnemonicSchemeLinkId: 'Bearing 3',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.RADIAL_BEARING,
                  vertexType: IVertexType.ELEMENT,

                  name: '178832',
                  elementLinkId: '178832',
                },
                {
                  id: '1627550551850',
                  mnemonicSchemeLinkId: 'Bearing 4',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.RADIAL_BEARING,
                  vertexType: IVertexType.ELEMENT,

                  name: '178832',
                  elementLinkId: '178832',
                },
                {
                  id: '1627550569583',
                  mnemonicSchemeLinkId: 'Gear wheel 2',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                  name: '',
                  transmissionK: '60',
                  vertexType: IVertexType.ELEMENT,
                },
                {
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                  name: '',
                  transmissionK: '30',
                  id: '1627550580909',
                  mnemonicSchemeLinkId: 'Gear wheel 3',
                  vertexType: IVertexType.ELEMENT,
                },
              ] as IElement[],
              name: 'Вал 2',
              order: 2,
              id: '1627550409975',
              mnemonicSchemeLinkId: 'Shaft 2',
              vertexType: IVertexType.SHAFT,
            },
            {
              children: [
                {
                  id: '1627550592692',
                  mnemonicSchemeLinkId: 'Bearing 6',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.RADIAL_BEARING,
                  vertexType: IVertexType.ELEMENT,

                  name: '697920ЛА',
                  elementLinkId: '001',
                },
                {
                  id: '1627550606840',
                  mnemonicSchemeLinkId: 'Bearing 5',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.RADIAL_BEARING,
                  vertexType: IVertexType.ELEMENT,

                  name: '697920ЛА',
                  elementLinkId: '001',
                },
                {
                  name: '',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                  transmissionK: '40',
                  id: '1627550632551',
                  mnemonicSchemeLinkId: 'Gear wheel 4',
                  vertexType: IVertexType.ELEMENT,
                },
                {
                  name: '',
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.COGWHEEL,
                  id: '1627550643853',
                  mnemonicSchemeLinkId: 'Gear wheel 5',
                  vertexType: IVertexType.ELEMENT,
                  transmissionK: '80',
                },
                {
                  vertexType: IVertexType.ELEMENT,
                  order: 20,
                  name: '126 name',
                  id: '1656057365467',
                  measuringPointLinkId: 'Measuring point',
                  mnemonicSchemeLinkId: 'Measuring point',
                  elementType: 'BALLSCREW',
                  elementLinkId: '126',
                },
              ] as IElement[],
              name: 'Вал 3 выходной',
              id: '1627550441500',
              order: 3,

              mnemonicSchemeLinkId: 'Shaft 3',
              vertexType: IVertexType.SHAFT,
            },
          ],
          name: 'Коробка скоростей',
          order: 10,
          comment: 'Мотор АИР-120',
          id: '1627550330639',
          vertexType: IVertexType.NODE,
        },
        // {
        //   name: 'Вторая коробка скоростей',
        //   comment: 'Мотор-120',
        //   id: '162755033333',
        //   vertexType: IVertexType.NODE,
        //   order: 20,
        //   children: [
        //     {
        //       name: 'Вал 1 входной',
        //       order: 1,
        //       id: '1627550400333205',
        //       vertexType: IVertexType.SHAFT,
        //       children: [
        //         {
        //           id: '162733300a',
        //           order: 1,
        //           mnemonicSchemeLinkId: 'Engine',
        //           measuringPointLinkId: 'Measuring point',
        //           elementType: IElementType.CLUTCH,
        //           vertexType: IVertexType.ELEMENT,
        //           name: 'Муфта мотора',
        //           elementLinkId: '',
        //         },
        //       ] as IElement[],
        //     },
        //   ],
        // },
      ],
      name: 'Тест коробки скоростей',
      fullness: true,
      machineType: 'фрейзерный',
      description: 'Коробка скоростей фрейзерного станка',
      id: '1',
      vertexType: IVertexType.MACHINE,
      photo: gearbox,
      manufactured: 'Россия',
      mnemonicScheme: MNEMO_SCHEME_000,
      dateOfCreation: '2021-06-25T04:08:16Z',
    },

    {
      children: [
        {
          children: [
            {
              children: [
                {
                  measuringPointLinkId: 'Measuring point 1',
                  elementType: IElementType.BELT_TRANSMISSION,
                  beltLength: '1000',
                  id: '1630126761255',
                  vertexType: IVertexType.ELEMENT,
                  order: 2,
                  name: '1111',
                  mnemonicSchemeLinkId: 'Belt_drive_2',
                },
              ] as IElement[],
              id: '1630126758435',
              vertexType: IVertexType.SHAFT,
              order: 2,
              name: '222',
            },
          ],
          vertexType: IVertexType.NODE,
          order: 2,
          id: '1630126741860',
          name: '1111',
        },
      ],
      id: '11',
      name: 'DMG 50 ecoline',
      vertexType: IVertexType.MACHINE,
      machineType: 'фрейзерный',
      description: '5-осевой фрезерный станок с ЧПУ для 5-осевой обработки',
      photo: DMG_50_ECOLINE,
      manufactured: 'DMG MORI Россия',
      mnemonicScheme: MNEMO_SCHEME_DMG50,
      dateOfCreation: '2021-06-25T04:08:16Z',
    },
    {
      id: '3858f488-b616-4535-a317-4d45b67cb21e',
      name: 'NEF 400',
      vertexType: IVertexType.MACHINE,
      machineType: 'универсальный',
      description: 'Универсальный токарный станок с ЧПУ и встроенным мотор-шпинделем ISM',
      children: [],
      photo: NEF_400,
      manufactured: 'DMG MORI Германия',
      mnemonicScheme: MNEMO_SCHEME_NEF400,
      dateOfCreation: '2021-06-25T04:08:16Z',
    },
    {
      id: '122',
      name: 'LONG',
      vertexType: IVertexType.MACHINE,
      machineType: 'универсальный',
      description: 'Универсальный токарный станок с ЧПУ и встроенным мотор-шпинделем ISM',
      children: [],
      photo: LONG_MACHINE_PHOTO,
      manufactured: 'DMG MORI Германия',
      dateOfCreation: '2021-06-25T04:08:16Z',
    },
  ],
};
