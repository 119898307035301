import Ballscrew from 'icons/ballscrew-icon';
import BearingIcon from 'icons/bearing-icon';
import { BeltDriveIcon } from 'icons/belt-drive-icon';
import { BeltDriveIconNotGear } from 'icons/belt-drive-icon-not-gear';
import ClutchIcon from 'icons/clutch-icon';
import CogWheelIcon from 'icons/cog-wheel-icon';
import CombinedBearingIcon from 'icons/combined-bearing-icon';
import TaperRollBearingIcon from 'icons/taper-roll-bearing-icon';
import WormGearIcon from 'icons/worm-gear-icon';

import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { IElementType } from 'interfaces';

export const elementIcon = (elementType: IElementType | undefined) => {
  switch (elementType) {
    case IElementType.RADIAL_BEARING: // радиальный подшипник
      return BearingIcon;
    case IElementType.TAPE_ROLL_BEARING: // конический подшипник
    //@ts-ignore
    // eslint-disable-next-line no-fallthrough
    case 'TAPE_ROLL_BEARING_MIN':
    //@ts-ignore
    // eslint-disable-next-line no-fallthrough
    case 'TAPE_ROLL_BEARING_MAX':
      return TaperRollBearingIcon;
    case IElementType.COMBINED_BEARING: // комбинированный подшипник
      return CombinedBearingIcon;
    case IElementType.BALLSCREW: // ШВП
      return Ballscrew;
    case IElementType.COGWHEEL: // Зубчатое колесо
      return CogWheelIcon;
    case IElementType.BELT_TRANSMISSION: // Зубчаторемённая передача
      return BeltDriveIcon;
    case IElementType.BELT_TRANSMISSION_NOT_GEAR: // Плоскоремённая передача
      return BeltDriveIconNotGear;
    case IElementType.CLUTCH: // муфта
      return ClutchIcon;
    case IElementType.WORM_GEAR: // червячная передача
      return WormGearIcon;
    default:
      return () => <ContactSupportIcon />;
  }
};
