import { useContext, useMemo, useState } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { useQueryClient } from 'react-query';

import { EditIcon } from 'icons/edit-icon';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { ContextSettings } from 'context/context-settings';
import { IDiagnostic, IDiagnosticStatus, IMachine } from 'interfaces';
import { CustomPaper } from 'pages-diagnostic/components/custom-paper/custom-paper';
import { DetailedElemsListModalLinkViewOnly } from 'pages-diagnostic/components/detaled-elems-list-modal/detailed-elems-list-modal-link-view-only';
import { EditBaseParamsModalDialog } from 'pages-diagnostic/diagnostic-page/diagnostic-form/edit-base-params-modal-dialog';
import { ShowIfDiagnosticIsChecked } from 'pages-diagnostic/diagnostic-page/diagnostic-form/machine-comments/show-if-diagnostic-is-checked';
import { useGetDiagnosticById } from 'pages-diagnostic/hooks';
import {
  DIAGNOSTICS_KEY,
  DIAGNOSTIC_MEASURE_UPLOAD,
  DIAGNOSTIC_SPECTRUM,
  DIAGNOSTIC_SPECTRUM_SUMMARY_LIST,
  DIAGNOSTIC_DOWNLOAD_REPORT,
  DIAGNOSTICS_BY_ID_KEY,
} from 'stream-constants';

import { AssignToDiagnost } from './assign-to-diagnost';
import { CardContainer } from './card-container';
import { DiagnosticTypeViewOnly } from './diagnostic-type-view-only';
import { DownloadReportIsNotPossibleBecauseNotChecked } from './download-report/download-report-is-not-possible-because-not-checked';
import { DownloadRoute } from './download-route';
import { DeffectsIcon } from './icons/deffects-icon';
import { ManagerIcon } from './icons/manager-icon';
import { ParametersIcon } from './icons/parameters-icon';
import { ReportIcon } from './icons/report-icon';
import { LinkButton } from './link-button';
import { MenuButton } from './menu-button';

const useStyles = makeStyles((theme) => ({
  mainRow: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    gap: '32px',
  },

  isCheckedFlag: {
    margin: '14px 0 14px -14px',
  },

  horizontalFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    gap: '28px',
  },
  mainFormLayout: {
    flexGrow: 2,
  },
  header: {
    fontWeight: 500,
    fontSize: '32px',
    margin: '32px 0 14px 0',
    color: '#1D2023',
  },
  subHeaderContainer: {
    margin: '0 0 24px 2px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  subHeader: {
    fontWeight: 500,
    fontSize: '24px',
    color: '#1D2023',
  },
  subSubHeader: {
    fontWeight: 500,
    fontSize: '17px',
    margin: '14px 0 12px 2px',
    color: '#1D2023',
  },
  subInfo: {
    fontWeight: 400,
    fontSize: '15px',
    margin: '12px 0 10px 2px',
    color: '#1D2023',
  },
  saveButton: {
    margin: '16px 0 0 0',
  },
  manualCombTextError: {
    fontSize: '11px',
    color: 'var(--main-red-color)',
    position: 'absolute',
    top: 42,
    left: 0,
    width: 260,
  },
  roundedButtonTextMessage: {
    position: 'relative',
    top: '8px',
    maxWidth: 226,
    minWidth: 226,
    background: '#F2F3F7',
    borderRadius: '100px',
  },
  menuIconNew: {
    position: 'absolute',
    right: -26,
    top: -106,
    cursor: 'pointer',
  },
  linkButtonContainer: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    marginBottom: 6,
  },
  linkButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  relative: {
    position: 'relative',
  },
  indentificationInfo: {
    fontWeight: 400,
    fontSize: '20px',
    margin: '0px 0 14px 3px',
    color: '#1D2023',
    maxWidth: '980px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const messageToGoToDeffects = `Для того, чтобы добавить дефекты на сигналы,\n
необходимо завершить загрузку сигналов\n
в “Менеджере загрузок”`;
const messageToGoToSummaries = `Для того, чтобы перейти к выводам,\n
  необходимо завершить загрузку сигналов\n
  в “Менеджере загрузок”`;

export function ManageDiagnostic() {
  const classes = useStyles();
  const history = useHistory();
  const { setNotificationMessage } = useContext(ContextSettings);

  const params = useParams<{ id: string }>();

  const queryClient = useQueryClient();

  const [refetchCount, setRefetchCount] = useState(0);
  const [editBaseParamsModalFlag, setEditBaseParamsModalFlag] = useState<boolean>(false);

  async function reFetch() {
    await queryClient.invalidateQueries([DIAGNOSTICS_KEY]);
    await queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY]);
    setRefetchCount(refetchCount + 1);
  }

  const query = useGetDiagnosticById(params.id);

  const data = query.data as IDiagnostic | undefined;

  const machineData = useMemo(() => {
    try {
      return JSON.parse(data?.params?.machineData ?? '{}');
    } catch (e) {
      setNotificationMessage({
        fullMessage: '',
        header: 'не смог распарсить строку "params?.machineData"',
      });

      return {};
    }
  }, [data?.params?.machineData, setNotificationMessage]) as unknown as IMachine;

  const measurementsAreUploaded = !!data?.params?.measurementsAreUploaded;

  const indentificationInfo = `${data?.factoryTown ?? ' '}, ${data?.factoryName ?? ' '}, ${
    data?.machineInventoryNumber ?? ' '
  }`;

  console.log('%c data?.status = ', 'color: #bada55', data?.status); //TODO - delete vvtu

  return (
    <>
      <div className={classes.header}>{data?.description}</div>

      <div className={classes.indentificationInfo}>
        <Tooltip title={indentificationInfo ?? ''} placement={'top'}>
          <span>{indentificationInfo}</span>
        </Tooltip>
      </div>
      <div className={classes.isCheckedFlag}>
        <ShowIfDiagnosticIsChecked
          data={data}
          measurementsAreNotUploaded={!measurementsAreUploaded}
        />
      </div>

      <div className={classes.mainRow}>
        <div className={classes.mainFormLayout}>
          <div className={classes.horizontalFlex}>
            <CustomPaper>
              <div className={classes.subHeaderContainer}>
                <ParametersIcon style={{ fontSize: 22 }} />
                <div className={classes.subHeader}>Параметры диагностики</div>
              </div>
              <AssignToDiagnost
                data={data}
                onSuccess={() => history.replace(`/${DIAGNOSTICS_KEY}/${params.id}`)}
              />
              <DiagnosticTypeViewOnly data={data} />
              <DetailedElemsListModalLinkViewOnly
                key={data?.machineLinkId}
                disabled={!data?.machineLinkId}
                viewOnly={true}
                machineLinkId={data?.machineLinkId}
                defaultSummaryValue={data?.params?.elementsSummaryLabel}
                defaultUnitsArray={data?.params?.units}
                defaultElementsArray={data?.params?.elements}
                detailedInformationToView={data?.params?.detailedInformationToView}
              />
              <DownloadRoute data={data} reFetch={reFetch} />
            </CustomPaper>
            <CustomPaper>
              <div className={classes.linkButtonContainer}>
                <div>
                  <div className={classes.subHeaderContainer}>
                    <ManagerIcon style={{ fontSize: 22 }} />
                    <div className={classes.subHeader}>Менеджер загрузки</div>
                  </div>
                  <div className={classes.subInfo}>
                    {data?.params?.measurementsTotalLabel ?? ''}
                  </div>
                </div>
                <div className={classes.linkButton}>
                  <LinkButton
                    forId={'id_diagnostic_measure_upload_392'}
                    onClick={() => history.push(`/${DIAGNOSTIC_MEASURE_UPLOAD}/${params.id}`)}
                    disabled={false}
                  />
                </div>
              </div>
            </CustomPaper>
            <CustomPaper disabled={data?.status === IDiagnosticStatus.CREATED}>
              <div className={classes.linkButtonContainer}>
                <div>
                  <Tooltip
                    placement={'top-start'}
                    title={measurementsAreUploaded ? '' : messageToGoToDeffects}
                  >
                    <div className={classes.subHeaderContainer}>
                      <DeffectsIcon style={{ fontSize: 28 }} />
                      <div className={classes.subHeader}>Добавление дефектов</div>
                    </div>
                  </Tooltip>
                  <div className={classes.subInfo}>{data?.params?.defectsTotalLabel ?? ''}</div>
                </div>
                <Tooltip
                  placement={'top'}
                  title={measurementsAreUploaded ? '' : messageToGoToDeffects}
                >
                  <div className={classes.linkButton}>
                    <LinkButton
                      forId={'id_diagnostic_goto_defects_392'}
                      disabled={!measurementsAreUploaded}
                      onClick={() => history.push(`/${DIAGNOSTIC_SPECTRUM}/${params.id}`)}
                    />
                  </div>
                </Tooltip>
              </div>
            </CustomPaper>
            <CustomPaper disabled={!measurementsAreUploaded}>
              <div className={classes.linkButtonContainer}>
                <div>
                  <Tooltip
                    placement={'top-start'}
                    title={measurementsAreUploaded ? '' : messageToGoToSummaries}
                  >
                    <div className={classes.subHeaderContainer}>
                      <EditIcon style={{ fontSize: 22 }} />
                      <div className={classes.subHeader}>Добавление выводов</div>
                    </div>
                  </Tooltip>
                  <div className={classes.subInfo}>{data?.params?.summariesTotalLabel ?? ''}</div>
                </div>
                <Tooltip
                  placement={'top'}
                  title={measurementsAreUploaded ? '' : messageToGoToSummaries}
                >
                  <div className={classes.linkButton}>
                    <LinkButton
                      forId={'id_diagnostic_goto_summaries_392'}
                      onClick={() =>
                        history.push(`/${DIAGNOSTIC_SPECTRUM_SUMMARY_LIST}/${params.id}`)
                      }
                      disabled={!measurementsAreUploaded}
                    />
                  </div>
                </Tooltip>
              </div>
            </CustomPaper>
            <CustomPaper disabled={data?.status !== IDiagnosticStatus.CHECKED}>
              <div className={classes.linkButtonContainer}>
                <div>
                  <Tooltip
                    placement={'top-start'}
                    title={measurementsAreUploaded ? '' : messageToGoToSummaries}
                  >
                    <div className={classes.subHeaderContainer}>
                      <ReportIcon style={{ fontSize: 26 }} />
                      <div className={classes.subHeader}>Отчёт</div>
                    </div>
                  </Tooltip>
                  <div className={classes.subInfo}>
                    {data?.reportPdfLink ? 'PDF отчёт сформирован' : 'Не сформирован'}
                  </div>
                </div>
                <DownloadReportIsNotPossibleBecauseNotChecked
                  visible={data?.status !== IDiagnosticStatus.CHECKED}
                >
                  <div className={classes.linkButton}>
                    <LinkButton
                      forId={'id_diagnostic_download_report_392'}
                      onClick={() => history.push(`/${DIAGNOSTIC_DOWNLOAD_REPORT}/${params.id}`)}
                      disabled={data?.status !== IDiagnosticStatus.CHECKED}
                    />
                  </div>
                </DownloadReportIsNotPossibleBecauseNotChecked>
              </div>
            </CustomPaper>
          </div>
        </div>

        {machineData && (
          <CustomPaper>
            <div className={classes.relative}>
              <div className={classes.menuIconNew}>
                <MenuButton setEditBaseParamsModalFlag={setEditBaseParamsModalFlag} />
              </div>
              <CardContainer machine={machineData} height={840} comments={data?.params?.comments} />
            </div>
          </CustomPaper>
        )}
      </div>
      {editBaseParamsModalFlag && (
        <EditBaseParamsModalDialog
          data={data}
          setClose={() => {
            setEditBaseParamsModalFlag(false);
          }}
        />
      )}
    </>
  );
}
