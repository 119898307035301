import { UseFormReturn, Path, useWatch } from 'react-hook-form';

import FieldWithIconForModalWindow from 'components/fields/field-with-icon-for-modal-window';

import { MachineModal } from './machine-modal';

type IProps<T> = {
  form: UseFormReturn<T>;
  pathOfName: Path<T>;
  pathOfMachineLinkId: Path<T>;
  pathOfMachinePhoto: Path<T>;
  disabled?: boolean;
  defaultValue?: string;
  onFieldChange: () => void;
  placeholder?: string;
};

export const FieldMachineLink = <T,>({
  form,
  pathOfName,
  pathOfMachineLinkId,
  pathOfMachinePhoto,
  disabled,
  defaultValue,
  onFieldChange,
  placeholder,
}: IProps<T>) => {
  const name = useWatch({
    control: form.control,
    name: pathOfName,
    defaultValue: (defaultValue ?? '') as any,
  });

  return (
    <FieldWithIconForModalWindow<any>
      placeholder={placeholder}
      form={form}
      fieldName={pathOfName}
      label="Модель станка"
      value={name}
      ModalIconButton={
        <MachineModal
          disabled={disabled}
          onMachineSelect={(machine: any) => {
            form.setValue(pathOfMachineLinkId, machine.id, {
              shouldDirty: true,
              shouldValidate: true,
            });
            form.setValue(pathOfName, machine.name, {
              shouldDirty: true,
              shouldValidate: true,
            });
            form.setValue(pathOfMachinePhoto, machine.photo, {
              shouldDirty: true,
              shouldValidate: true,
            });
            onFieldChange();
          }}
        />
      }
    />
  );
};
