import { useState, useRef, useEffect } from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '352px',
      height: '44px',
      borderRadius: 6,
      border: '2px dashed #bbb',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      fontWeight: 400,
      fontSize: '14px',
      color: '#1D2023',
      lineHeight: '28px',
    },
    button: {
      cursor: 'pointer',
      color: '#007CFF',
      padding: '1px 6px',
      borderRadius: 6,
      '&:hover': {
        backgroundColor: '#eee',
      },
    },
    displayNone: {
      display: 'none',
    },
    disabled: {
      opacity: 0.4,
    },
    dragOver: {
      border: '2px dashed #333',
      top: -2,
      left: -2,
      boxShadow: '2px 2px 2px gray',
    },
  }),
);

export function DragAndDropUploadFile({
  uploadOneFile,
  folderName,
  disabled,
  validFileExtensions,
  maxFileSize,
  setFullMessageModal,
  forId,
}: {
  uploadOneFile?: (file: File) => void;
  folderName: string;
  disabled: boolean;
  validFileExtensions: string[];
  maxFileSize: number;
  setFullMessageModal: (message: string | undefined) => void;
  forId?: string;
}) {
  const classes = useStyles();
  const [onDragover, setOnDragOver] = useState<boolean>(false);
  const dragOverTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  const htmlForId = forId ?? `id02034w_${window.btoa(encodeURIComponent(folderName))}`;

  function handleFileToUpload(file: File) {
    if (!file) {
      setFullMessageModal(`Выбранный вами файл не найден`);
    } else {
      const extensionNameArr = file.name.split('.');
      const extensionName = extensionNameArr[extensionNameArr.length - 1];
      if (!validFileExtensions.find((item) => item === extensionName)) {
        setFullMessageModal(
          `Невозможно загрузить файл ${
            file.name
          }, так как расширение файла (${extensionName}) не соответсвует допустимым (${validFileExtensions.join(
            ', ',
          )}).`,
        );
      } else {
        if (file.size > maxFileSize) {
          setFullMessageModal(
            `Невозможно загрузить файл (${file.name}), так как его размер ${(
              file.size / 1_000_000
            ).toFixed(0)}Мб превышает ${maxFileSize / 1_000_000} Мб.`,
          );
        } else {
          uploadOneFile?.(file);
        }
      }
    }
  }

  useEffect(() => {
    return () => {
      if (dragOverTimeoutRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(dragOverTimeoutRef.current);
      }
    };
  }, []);

  return (
    <div
      className={classNames(classes.root, {
        [classes.disabled]: disabled,
        [classes.dragOver]: onDragover,
      })}
      draggable={disabled ? false : true}
      onDrop={
        disabled
          ? undefined
          : (ev) => {
              ev.preventDefault();
              dragOverTimeoutRef.current = setTimeout(() => {
                setOnDragOver(false);
                dragOverTimeoutRef.current = undefined;
              }, 300);
              if (uploadOneFile) {
                const kolvo = ev.dataTransfer?.files?.length;
                if (kolvo > 1) {
                  setFullMessageModal(
                    `Не разрешается выбирать сразу несколько файлов (выбрано ${kolvo} файлов).`,
                  );
                } else {
                  const file = ev.dataTransfer?.files?.[0];
                  handleFileToUpload(file);
                }
              }
            }
      }
      onDragOver={(ev) => {
        ev.preventDefault();
        if (dragOverTimeoutRef.current) {
          clearTimeout(dragOverTimeoutRef.current);
        }
        dragOverTimeoutRef.current = setTimeout(() => {
          setOnDragOver(true);
          dragOverTimeoutRef.current = undefined;
        }, 20);
      }}
      onDragLeave={(ev) => {
        ev.preventDefault();
        if (dragOverTimeoutRef.current) {
          clearTimeout(dragOverTimeoutRef.current);
        }
        dragOverTimeoutRef.current = setTimeout(() => {
          setOnDragOver(false);
          dragOverTimeoutRef.current = undefined;
        }, 20);
      }}
    >
      <span className={classes.button}>
        <label htmlFor={htmlForId}>
          <input
            disabled={disabled}
            className={classes.displayNone}
            id={htmlForId}
            type={disabled ? undefined : 'file'}
            onChange={(ev) => {
              ev.preventDefault();
              if (uploadOneFile) {
                const file = ev.target?.files?.[0];
                handleFileToUpload(file as File);
              }
            }}
          />
          {'Выберите файл'}
        </label>
      </span>
      <span>или переместите его сюда</span>
    </div>
  );
}
