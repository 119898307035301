import { useContext } from 'react';

import { useQuery, useMutation, useQueryClient } from 'react-query';

import { ContextSettings } from 'context/context-settings';
import {
  getSignalToCutByCsvId,
  updateSignalFileByCsvId,
} from 'services/diagnostic-folder-list/slices-editor-service';
import { DIAGNOSTIC_GET_SIGNAL_TO_CUT_BY_CSV_ID } from 'stream-constants';
import { errorMessageConvert } from 'utils/error-message-convert';

export function useGetSignalToCutByCsvId(csvId: string) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const query = useQuery(
    [DIAGNOSTIC_GET_SIGNAL_TO_CUT_BY_CSV_ID, csvId],
    () => getSignalToCutByCsvId(csvId),
    {
      enabled: !notificationMessage,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}

export function useUpdateSignalFileByCsvId(csvId: string, handleOnSuccess: () => void) {
  const { setNotificationMessage } = useContext(ContextSettings);
  const queryClient = useQueryClient();

  const mutationUpdate = useMutation(
    (cutIntervals: number[][]) => updateSignalFileByCsvId(csvId, cutIntervals),
    {
      onSuccess: async (result) => {
        queryClient.setQueryData([DIAGNOSTIC_GET_SIGNAL_TO_CUT_BY_CSV_ID, csvId], undefined);
        queryClient.invalidateQueries([DIAGNOSTIC_GET_SIGNAL_TO_CUT_BY_CSV_ID, csvId]);
        handleOnSuccess();
      },
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return mutationUpdate;
}
