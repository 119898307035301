import { IMachine, IElement, IElementType, IVertexType } from 'interfaces';
import * as yup from 'yup';

import {
  REQUIRED_ERR_MESSAGE,
  MUST_BE_A_NUMBER,
  MUST_BE_AN_INTEGER,
  MORE_THAN,
  LESS_THAN,
  STRING_MUST_BE_LONGER_THAN,
  STRING_MUST_BE_SHORTER_THAN,
} from './validatian-constants';

export const defaultValidationSchema: yup.SchemaOf<any> = yup.object().shape({
  name: yup.string().required(REQUIRED_ERR_MESSAGE),
});

export const nodeValidationSchema: yup.SchemaOf<any> = yup.object().shape({
  name: yup.string().required(REQUIRED_ERR_MESSAGE),
  comment: yup
    .string()
    .notRequired()
    .max(400, STRING_MUST_BE_SHORTER_THAN + '400'),
});

//@ts-ignore
export const machineValidationSchema: yup.SchemaOf<IMachine> = yup.object().shape({
  id: yup.string().required(REQUIRED_ERR_MESSAGE),
  name: yup
    .string()
    .required(REQUIRED_ERR_MESSAGE)
    .max(100, STRING_MUST_BE_SHORTER_THAN + '100')
    .min(3, STRING_MUST_BE_LONGER_THAN + '3'),
  description: yup
    .string()
    .required(REQUIRED_ERR_MESSAGE)
    .max(100, STRING_MUST_BE_SHORTER_THAN + '100')
    .min(3, STRING_MUST_BE_LONGER_THAN + '3'),
  vertexType: yup.mixed<IVertexType.MACHINE>().required(),
  machineType: yup
    .string()
    .required(REQUIRED_ERR_MESSAGE)
    .min(3, STRING_MUST_BE_LONGER_THAN + '3'),
  manufactured: yup
    .string()
    .notRequired()
    .max(100, STRING_MUST_BE_SHORTER_THAN + '100'),

  manufacturedYear: yup.lazy((value) =>
    value === ''
      ? yup.string()
      : yup
          .number()
          .nullable(true)
          .typeError(MUST_BE_A_NUMBER)
          .integer(MUST_BE_AN_INTEGER)
          .min(1901, MORE_THAN + '1900')
          .lessThan(2099.1, LESS_THAN + '2099'),
  ),

  creatorData: yup.string().notRequired(),

  inventoryNumber: yup
    .string()
    .notRequired()
    .max(100, STRING_MUST_BE_SHORTER_THAN + '100'),
  machineOwner: yup
    .string()
    .notRequired()
    .max(100, STRING_MUST_BE_SHORTER_THAN + '100'),

  mnemonicScheme: yup.string().notRequired(),
  mnemonicSchemeMeta: yup.string().notRequired(),
  photo: yup.string().notRequired(),
  photoMeta: yup.string().notRequired(),
  dateOfCreation: yup.string().notRequired(),
  children: yup.array().of(yup.object().notRequired()),
  validationErrorsCount: yup.number().notRequired(),

  fullness: yup
    .boolean()
    .notRequired()
    .when('validationErrorsCount', (validationErrorsCount: number | undefined) => {
      if (validationErrorsCount !== undefined) {
        return yup
          .boolean()
          .oneOf([false], `Станок не прошел валидацию! Ошибок: ${validationErrorsCount}`);
      } else {
        return yup.boolean();
      }
    }),
});

//@ts-ignore
export const elementValidationSchema: yup.SchemaOf<IElement> = yup.object().shape({
  id: yup.string().required(),
  //@ts-ignore
  name: yup.string().when('elementType', (elementType: IElementType) => {
    switch (elementType) {
      case IElementType.RADIAL_BEARING:
      case IElementType.TAPE_ROLL_BEARING:
      case IElementType.COMBINED_BEARING:
      case IElementType.BALLSCREW:
        return yup
          .string()
          .required(REQUIRED_ERR_MESSAGE)
          .min(3, STRING_MUST_BE_LONGER_THAN + '3');
    }
  }),
  vertexType: yup.mixed<IVertexType.ELEMENT>().required(),
  elementType: yup.mixed<IElementType>().required(),
  //@ts-ignore
  elementLinkId: yup.string().when('elementType', (elementType: IElementType) => {
    switch (elementType) {
      case IElementType.RADIAL_BEARING:
      case IElementType.TAPE_ROLL_BEARING:
      case IElementType.COMBINED_BEARING:
      case IElementType.BALLSCREW:
        return yup.string().required('REQUIRED_ERR_MESSAGE');
    }
  }),
  //@ts-ignore
  transmissionK: yup.string().when('elementType', (elementType: IElementType) => {
    switch (elementType) {
      case IElementType.BELT_TRANSMISSION:
        return yup
          .number()
          .typeError(MUST_BE_A_NUMBER)
          .required(REQUIRED_ERR_MESSAGE)
          .integer(MUST_BE_AN_INTEGER)
          .min(1, MORE_THAN + '0')
          .test(
            'less-Than-beltLength1',
            'должно быть меньше числа зубьев ремня',
            function lessThan1(trK) {
              const { beltLength } = this.parent;
              if ((trK ?? 0) < beltLength) {
                return true;
              }

              return false;
            },
          );

      case IElementType.BELT_TRANSMISSION_NOT_GEAR:
        return yup
          .number()
          .typeError(MUST_BE_A_NUMBER)
          .required(REQUIRED_ERR_MESSAGE)
          .min(0, MORE_THAN + '0')
          .test(
            'less-Than-beltLength2',
            'должно быть меньше длинны ремня',
            function lessThan1(trK) {
              const { beltLength } = this.parent;
              if ((trK ?? 0) < beltLength) {
                return true;
              }

              return false;
            },
          );
      case IElementType.COGWHEEL:
      case IElementType.WORM_GEAR:
        return yup
          .number()
          .typeError(MUST_BE_A_NUMBER)
          .required(REQUIRED_ERR_MESSAGE)
          .integer(MUST_BE_AN_INTEGER)
          .min(1, MORE_THAN + '0');
    }
  }),
  //@ts-ignore
  beltLength: yup.string().when('elementType', (elementType: IElementType) => {
    switch (elementType) {
      case IElementType.BELT_TRANSMISSION:
        return yup
          .number()
          .typeError(MUST_BE_A_NUMBER)
          .required(REQUIRED_ERR_MESSAGE)
          .integer(MUST_BE_AN_INTEGER)
          .min(1, MORE_THAN + '0')
          .test(
            'less-Than-transmissionK1',
            'должно быть больше числа зубьев колеса',
            function lessThan1(beltLen) {
              const { transmissionK } = this.parent;
              if ((beltLen ?? 0) > transmissionK) {
                return true;
              }

              return false;
            },
          );
      case IElementType.BELT_TRANSMISSION_NOT_GEAR:
        return yup
          .number()
          .typeError(MUST_BE_A_NUMBER)
          .required(REQUIRED_ERR_MESSAGE)
          .min(0, MORE_THAN + '0')
          .test(
            'less-Than-transmissionK1',
            'должно быть больше диаметра колеса',
            function lessThan1(beltLen) {
              const { transmissionK } = this.parent;
              if ((beltLen ?? 0) > transmissionK) {
                return true;
              }

              return false;
            },
          );
    }
  }),
});

//@ts-ignore
export const machine: yup.SchemaOf<IElement> = yup.object().shape({
  id: yup.string().required(),
  name: yup
    .string()
    .required(REQUIRED_ERR_MESSAGE)
    .min(5, STRING_MUST_BE_LONGER_THAN + '5'),

  vertexType: yup.mixed<IVertexType.ELEMENT>().required(),
  elementType: yup.mixed<IElementType>().required(),
  //@ts-ignore
  elementLinkId: yup.string().when('elementType', (elementType: IElementType) => {
    if (
      elementType === IElementType.RADIAL_BEARING ||
      elementType === IElementType.TAPE_ROLL_BEARING ||
      elementType === IElementType.COMBINED_BEARING ||
      elementType === IElementType.BALLSCREW
    )
      return yup.string().required('REQUIRED_ERR_MESSAGE');
  }),
});
