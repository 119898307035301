import { useLayoutEffect, useRef, useContext, useState } from 'react';

import { ContextSettings } from 'context/context-settings';
import { IS_MODE_EQ_DEVELOPMENT } from 'stream-constants';
import { MAX_SCREEN_WIDTH, MIN_SCREEN_WIDTH } from 'stream-constants';

import { LayoutMessageModalDialog } from './layout-message-modal-dialog';

export function LayoutResizeEffect() {
  const [warningModalFlag, setWarningModalFlag] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const { setLayoutMinHeightAndWidth } = useContext(ContextSettings);
  useLayoutEffect(() => {
    function updateSize() {
      const newHeight = Math.floor(document.documentElement.clientHeight) - 170;
      const rawWidth = Math.floor(document.documentElement.clientWidth);
      let newWidth = Math.min(rawWidth, MAX_SCREEN_WIDTH);
      newWidth = Math.max(newWidth, MIN_SCREEN_WIDTH);
      setLayoutMinHeightAndWidth({ height: newHeight, width: newWidth });
      const element = document.documentElement;
      element.style.setProperty('--layout-min-height', `${newHeight}px`);
      element.style.setProperty('--layout-max-width', `${newWidth}px`);

      setWarningModalFlag(rawWidth < 1440);
    }

    function updateSizeWithTimeout() {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        updateSize();
        timeoutRef.current = undefined;
      }, 108);
    }

    updateSize();
    window.addEventListener('resize', updateSizeWithTimeout);

    return () => {
      window.removeEventListener('resize', updateSizeWithTimeout);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [setLayoutMinHeightAndWidth]);

  return warningModalFlag && IS_MODE_EQ_DEVELOPMENT === false ? (
    <LayoutMessageModalDialog setClose={() => setWarningModalFlag(false)} />
  ) : (
    <div />
  );
}
