import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function AllIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M3.08056 9.92453C3.27526 7.30854 3.37261 6.00055 4.68548 4.68768C5.99835 3.3748 7.30635 3.27746 9.92233 3.08276C10.6005 3.03229 11.3 3.00171 11.9995 3.00171C12.699 3.00171 13.3986 3.03229 14.0767 3.08276C16.6927 3.27746 18.0007 3.3748 19.3135 4.68768C20.6264 6.00055 20.7238 7.30854 20.9185 9.92453C20.9689 10.6027 20.9995 11.3022 20.9995 12.0017C20.9995 12.7012 20.9689 13.4008 20.9185 14.0789C20.7238 16.6949 20.6264 18.0029 19.3135 19.3157C18.0007 20.6286 16.6927 20.726 14.0767 20.9207C13.3986 20.9711 12.699 21.0017 11.9995 21.0017C11.3 21.0017 10.6005 20.9711 9.92233 20.9207C7.30635 20.726 5.99835 20.6286 4.68548 19.3157C3.37261 18.0029 3.27526 16.6949 3.08056 14.0789C3.0301 13.4008 2.99951 12.7012 2.99951 12.0017C2.99951 11.3022 3.0301 10.6027 3.08056 9.92453Z"
        fill="#E30611"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2069 10.2071C16.5974 9.81658 16.5974 9.18342 16.2069 8.79289C15.8163 8.40237 15.1832 8.40237 14.7927 8.79289L10.9998 12.5858L9.70686 11.2929C9.31634 10.9024 8.68317 10.9024 8.29265 11.2929C7.90212 11.6834 7.90212 12.3166 8.29265 12.7071L10.2926 14.7071C10.6832 15.0976 11.3163 15.0976 11.7069 14.7071L16.2069 10.2071Z"
        fill="white"
      />
    </SvgIcon>
  );
}
