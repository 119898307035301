import {
  REQUIRED_ERR_MESSAGE,
  MUST_BE_A_NUMBER,
  MORE_THAN,
} from 'validation-schemas/validatian-constants';
import * as yup from 'yup';

export const FIELDS = 'fields';

export const speedValidationSchema: yup.SchemaOf<{ [FIELDS]: number[] }> = yup.object().shape({
  [FIELDS]: yup.array().of(
    yup
      .number()
      .typeError(MUST_BE_A_NUMBER)
      .required(REQUIRED_ERR_MESSAGE)
      .min(1, MORE_THAN + '0')
      .max(100000, 'число должно быть меньше, чем 100000'),
  ),
});
