import React from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { RED_COLOR } from 'stream-constants';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    frequenciesHeader: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#000',
      margin: '-6px 0 6px 6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    iconAscReflect: {
      transform: 'scale(1,-1)',
    },
    selected: {
      fontWeight: 'bold',
      color: RED_COLOR,
    },
  }),
);

export const FrequenciesMenuHeader = React.memo(function () {
  const classes = useStyles();

  return (
    <div className={classes.frequenciesHeader}>
      <div>{'Список характерных частот'}</div>
    </div>
  );
});
