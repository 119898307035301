import { FC, useEffect, useState, useContext, useRef } from 'react';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import { ContextSettings } from 'context/context-settings';
import { IUseGetDataAndDictionaryReturnType } from 'pages/machine-pages/components/machine-form/hooks/use-get-data-and-dictionary';
import { FETCH_MINIO_URL } from 'services/urls';
import { YELLOW_COLOR, GREEN_COLOR, RED_COLOR } from 'stream-constants';

import { findNearestIdInDOM } from './find-nearest-id-in-dom';
import { ShowVertex } from './show-vertex';
const DOM_MNEMOSCHEME_ID = 'svg_object_menmoscheme';

const TRANSPARENT_ID = 'Transparent_1';

const HOVERED = 'HOVERED';
const ATTACHED = 'ATTACHED';
const SELECTED = 'SELECTED';
const CLICKED = 'CLICKED';
const NOT_HOVERED = 'NOT_HOVERED';
const NOT_ATTACHED = 'NOT_ATTACHED';
const NOT_SELECTED = 'NOT_SELECTED';
const NOT_CLICKED = 'NOT_CLICKED';

const BLACK_COLOR = '#000000';
const WHITE_COLOR = '#EEEEEE';

const BROWN_COLOR = '#97572b';

const DARK_YELLOW_COLOR = '#ad8521';
const DARK_GREEN_COLOR = '#027722';
const DARK_RED_COLOR = '#c20a22';

const setElementColor = (element: HTMLElement, strokeColor: string, fillColor: string) => {
  if (element?.style) {
    element.style.stroke = strokeColor;
    element.style.fill = fillColor;
    element.style.fillOpacity = '0.3';
  }
  [...element.children].forEach((child) => {
    setElementColor(child as HTMLElement, strokeColor, fillColor);
  });
};

export const MnemoSchemeRenderer: FC<{
  allParams: IUseGetDataAndDictionaryReturnType;
  handleMnemoElementClicked?: (id: string) => void;
  selectedVertexIds: Map<string, number>;
  withInfo: boolean;
  layout?: {
    width: string;
    height: string;
  };
  showAllIds?: boolean;
  handleSetShowAllIds?: () => void;
}> = ({
  allParams,
  selectedVertexIds,
  withInfo,
  layout,
  handleMnemoElementClicked,
  showAllIds,
  handleSetShowAllIds,
}) => {
  const { queryParams, query, vertexByIdDictionary, vertexByMnemonicIdDictionary } = allParams;
  const mainDivRef = useRef(null);
  const mainDivXY = (mainDivRef.current &&
    (mainDivRef.current as HTMLElement).getBoundingClientRect()) as unknown as DOMRect | undefined;

  const { selectedMnemonicSchemeElemId } = queryParams;

  const [mouseOverElemId, setMouseOverElemId] = useState<string | undefined>();
  const [cursorXY, setCursorXY] = useState<{ x: number; y: number }>();

  const { allMnemoElementsById, setAllMnemoElementsById } = useContext(ContextSettings);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allMnemoElements =
    query?.data?.id && allMnemoElementsById ? allMnemoElementsById[query?.data?.id] : [];

  useEffect(() => {
    const doc = document.getElementById(DOM_MNEMOSCHEME_ID);
    doc?.addEventListener(
      'load',
      function () {
        //@ts-ignore
        const contentDocument: HTMLDocument = doc.contentDocument ?? doc.getSVGDocument();

        if (showAllIds) {
          const transparentIdElement = contentDocument?.getElementById(TRANSPARENT_ID);
          transparentIdElement?.remove();
        }

        const iterator = contentDocument?.querySelectorAll('[id]');
        const allIds = ((iterator && [...iterator]) ?? []).map((a: any) => a.id);

        const elems = allIds?.map((id) => contentDocument.getElementById(id)) ?? [];
        const filteredElems = elems.filter((element) => !!element && element.id !== TRANSPARENT_ID);
        setAllMnemoElementsById({ [query?.data?.id ?? '()']: filteredElems as HTMLElement[] });
      },
      false,
    );
  }, [query?.data?.id, setAllMnemoElementsById, showAllIds]);

  useEffect(() => {
    const onClickListener: EventListener = (e) => {
      const event = e as MouseEvent;
      e.stopPropagation();
      if (handleMnemoElementClicked) {
        const id = findNearestIdInDOM(event) || '';
        handleMnemoElementClicked(id);
      }
    };

    const onMouseOverListener: EventListener = (e) => {
      const event = e as MouseEvent;
      const id = findNearestIdInDOM(event);

      setMouseOverElemId(id);
      setCursorXY({ x: event.offsetX, y: event.offsetY });
    };
    const onMouseLeaveListener = (e: MouseEvent) => {
      setMouseOverElemId(undefined);
    };

    allMnemoElements?.forEach((element) => {
      element.addEventListener('click', onClickListener);
      element.addEventListener('mouseover', onMouseOverListener);
      element.addEventListener('mouseleave', onMouseLeaveListener);
    });

    return () => {
      allMnemoElements?.forEach((element) => {
        element.removeEventListener('click', onClickListener);
        element.removeEventListener('onmouseover', onMouseOverListener);
        element.removeEventListener('mouseleave', onMouseLeaveListener);
      });
    };
  }, [allMnemoElements, handleMnemoElementClicked]);

  useEffect(() => {
    if (allMnemoElements) {
      allMnemoElements.forEach((el: HTMLElement) => {
        const vertexId = vertexByMnemonicIdDictionary.get(el.id)?.vertex?.id;
        const theCase = `${el.id === selectedMnemonicSchemeElemId ? CLICKED : NOT_CLICKED},${
          el.id === mouseOverElemId ? HOVERED : NOT_HOVERED
        },${vertexId ? ATTACHED : NOT_ATTACHED},${
          vertexId && selectedVertexIds.has(vertexId) ? SELECTED : NOT_SELECTED
        }`;

        switch (theCase) {
          case `${CLICKED},${HOVERED},${ATTACHED},${SELECTED}`:
            setElementColor(el, DARK_RED_COLOR, DARK_RED_COLOR);

            break;
          case `${CLICKED},${HOVERED},${ATTACHED},${NOT_SELECTED}`:
            setElementColor(el, BROWN_COLOR, BROWN_COLOR);

            break;
          case `${CLICKED},${HOVERED},${NOT_ATTACHED},${SELECTED}`:
            setElementColor(el, DARK_GREEN_COLOR, DARK_GREEN_COLOR);

            break;
          case `${CLICKED},${HOVERED},${NOT_ATTACHED},${NOT_SELECTED}`:
            setElementColor(el, DARK_GREEN_COLOR, DARK_GREEN_COLOR);

            break;
          case `${CLICKED},${NOT_HOVERED},${ATTACHED},${SELECTED}`:
            setElementColor(el, RED_COLOR, RED_COLOR);

            break;
          case `${CLICKED},${NOT_HOVERED},${ATTACHED},${NOT_SELECTED}`:
            setElementColor(el, BROWN_COLOR, BROWN_COLOR);

            break;
          case `${CLICKED},${NOT_HOVERED},${NOT_ATTACHED},${SELECTED}`:
            setElementColor(el, GREEN_COLOR, GREEN_COLOR);

            break;
          case `${CLICKED},${NOT_HOVERED},${NOT_ATTACHED},${NOT_SELECTED}`:
            setElementColor(el, GREEN_COLOR, GREEN_COLOR);

            break;
          case `${NOT_CLICKED},${HOVERED},${ATTACHED},${SELECTED}`:
            setElementColor(el, DARK_RED_COLOR, DARK_RED_COLOR);

            break;
          case `${NOT_CLICKED},${HOVERED},${ATTACHED},${NOT_SELECTED}`:
            setElementColor(el, BROWN_COLOR, BROWN_COLOR);

            break;
          case `${NOT_CLICKED},${HOVERED},${NOT_ATTACHED},${SELECTED}`:
            setElementColor(el, DARK_YELLOW_COLOR, DARK_YELLOW_COLOR);

            break;
          case `${NOT_CLICKED},${HOVERED},${NOT_ATTACHED},${NOT_SELECTED}`:
            setElementColor(el, DARK_YELLOW_COLOR, DARK_YELLOW_COLOR);

            break;
          case `${NOT_CLICKED},${NOT_HOVERED},${ATTACHED},${SELECTED}`:
            setElementColor(el, RED_COLOR, RED_COLOR);

            break;
          case `${NOT_CLICKED},${NOT_HOVERED},${ATTACHED},${NOT_SELECTED}`:
            setElementColor(el, BLACK_COLOR, WHITE_COLOR);

            break;
          case `${NOT_CLICKED},${NOT_HOVERED},${NOT_ATTACHED},${SELECTED}`:
            setElementColor(el, YELLOW_COLOR, YELLOW_COLOR);

            break;
          case `${NOT_CLICKED},${NOT_HOVERED},${NOT_ATTACHED},${NOT_SELECTED}`:
            setElementColor(el, YELLOW_COLOR, WHITE_COLOR);
            break;

          default:
            throw new Error(`Невозмжный кейс theCase = ${theCase}`);
        }
      });
    }
  }, [
    allMnemoElements,
    vertexByMnemonicIdDictionary,
    mouseOverElemId,
    selectedMnemonicSchemeElemId,
    selectedVertexIds,
  ]);

  const vertexIdOfMouseOverElem = vertexByMnemonicIdDictionary.get(mouseOverElemId ?? '');
  const vertexInfoOfMouseOverElem = vertexByIdDictionary.get(
    vertexIdOfMouseOverElem?.vertex?.id ?? '',
  );
  const { mnemonicScheme } = query?.data ?? {};

  return (
    <div
      style={{
        position: 'relative',
      }}
      ref={mainDivRef}
      key={showAllIds ? 'true' : 'false'}
    >
      {mnemonicScheme && (
        <object
          id={DOM_MNEMOSCHEME_ID}
          data={FETCH_MINIO_URL(mnemonicScheme)}
          type="image/svg+xml"
          width={layout ? layout.width : '100%'}
          height={layout ? layout.height : '100%'}
        >
          {'Идет загрузка мнемосхемы...'}
        </object>
      )}
      {cursorXY && mouseOverElemId && mouseOverElemId !== TRANSPARENT_ID && (
        <ShowVertex
          mouseOverElemId={mouseOverElemId}
          entity={vertexInfoOfMouseOverElem?.vertex}
          speed={selectedVertexIds.get(vertexIdOfMouseOverElem?.vertex?.id ?? '') ?? -1}
          position={cursorXY}
          xMax={mainDivXY?.width}
        />
      )}
      {withInfo && (allMnemoElements?.length ?? 0) > 0 && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button color="primary" disabled>
              {`Всего айдишников: ${allMnemoElements?.length ?? 0}`}
            </Button>
          </Grid>
          <Grid item xs={6}>
            {/* // */}
          </Grid>
        </Grid>
      )}
      {withInfo && (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!showAllIds}
                  disableRipple
                  onClick={() => {
                    handleSetShowAllIds?.();
                  }}
                />
              }
              label="Показать все названия"
            />
          </Grid>
        </Grid>
      )}
    </div>
  );
};
