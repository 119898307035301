import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function ClutchIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 32 32'}>
      <rect x="0.54" y="12.27" width="9.73" height="7.69" />
      <rect x="21.75" y="12.27" width="9.73" height="7.69" />
      <rect x="5.41" y="4.86" width="21.2" height="6.54" />
      <rect x="11.23" y="10.63" width="9.55" height="5.5" />
      <rect x="5.4" y="20.85" width="21.2" height="6.54" />
      <rect x="11.22" y="10.31" width="9.55" height="11.34" />
    </SvgIcon>
  );
}
