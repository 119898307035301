import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ResetFiltersIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 20 19'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292979 0.29196C0.683617 -0.0973201 1.31697 -0.0973201 1.70761 0.29196L17.7123 16.2983C18.1029 16.6876 18.1029 17.3188 17.7123 17.7081C17.5157 17.904 17.2577 18.0013 17 18C16.7457 17.9987 16.4917 17.9014 16.2977 17.7081L14.5157 15.9323C14.5157 15.9323 14.2415 16.1291 14.0518 16.264C13.8102 16.4358 13.5069 16.6247 13.1237 16.8634C11.1914 18.0669 9.94882 18.8408 9.02094 18.9781C8.649 19.0331 8.32763 18.9858 8.03269 18.8229C7.73775 18.66 7.52721 18.4135 7.37691 18.0702C7.00192 17.2135 7.00192 15.7537 7.00192 13.4837V8.38729L0.292979 1.70167C-0.0976596 1.31239 -0.0976596 0.681241 0.292979 0.29196ZM9.00251 10.3809L12.9946 14.3592C12.9915 14.4374 12.9872 14.5042 12.9814 14.564L12.9804 14.5743L12.9722 14.5806C12.8044 14.7088 12.555 14.8666 12.0634 15.1728C10.6525 16.0516 9.78662 16.5846 9.14952 16.8616L9.11529 16.8763L9.10973 16.8399C9.00784 16.155 9.00251 15.1413 9.00251 13.4837V10.3809Z"
        fill="black"
      />
      <path
        d="M7.18828 2.99623C7.39873 2.99565 7.62482 2.99556 7.8683 2.99556L14.1921 2.99555C15.7879 2.99556 16.7553 3.00094 17.4297 3.10217C17.4528 3.10563 17.4792 3.10871 17.5077 3.11203C17.6693 3.13083 17.8982 3.15745 17.9599 3.30595C18.0241 3.46041 17.9375 3.57561 17.8427 3.70174C17.8276 3.72175 17.8124 3.74203 17.7975 3.76279C17.4582 4.23574 16.8769 4.82422 15.8592 5.83832C15.5824 6.11417 15.2959 6.38475 15.0042 6.64831C14.4324 7.16496 13.9745 7.57104 13.5923 7.8892C13.1105 8.29034 13.0315 9.05037 13.4754 9.49279C13.8064 9.82263 14.3283 9.86968 14.6916 9.57554C15.1847 9.17632 15.7199 8.69269 16.3479 8.12526C16.6612 7.84221 16.9716 7.54919 17.2739 7.24803C19.2084 5.32021 20.1757 4.35628 19.9737 3.09977C19.9182 2.75477 19.7358 2.31578 19.5302 2.03275C18.7814 1.00193 17.2516 1.00193 14.1921 1.00193H7.8683C7.57855 1.00193 7.30582 1.00193 7.04897 1.00289C7.04449 1.00291 7.04536 1.00308 7.04536 1.00308C7.04752 1.0035 7.05509 1.00499 7.01171 1.01047C6.29219 1.10131 5.90266 2.04345 6.34914 2.61302C6.38683 2.6611 6.39065 2.6649 6.39829 2.67252C6.42928 2.7034 6.44477 2.71884 6.45984 2.73267C6.63611 2.8945 6.85989 2.98651 7.09938 2.99563C7.11985 2.99641 7.14266 2.99635 7.18828 2.99623Z"
        fill="black"
      />
    </SvgIcon>
  );
}
