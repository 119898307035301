import { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import useAutocomplete from '@material-ui/lab/useAutocomplete';

type Props = { width: number };

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    inputContainer: {
      position: 'relative',
      width: (props) => props.width,
    },
    input: {
      width: (props) => props.width,
      height: '42px',
      backgroundColor: '#F2F3F7',
      border: '1px solid rgba(188, 195, 208, 0.5)',
      borderRadius: '6px',
      padding: '0 56px 0 12px',
      fontWeight: 500,
      fontSize: '15px',
      color: ' #000',
    },
    iconsCointainer: {
      position: 'absolute',
      top: 8,
      right: 2,
      display: 'flex',
      alignItems: 'center',
    },
    iconRotate: {
      transform: 'rotate(180deg)',
    },
    listbox: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08)',
      borderRadius: '6px',
      width: (props) => props.width,
      marginTop: 4,
      padding: 0,
      zIndex: 1,
      position: 'absolute',
      listStyle: 'none',
      overflow: 'auto',
      maxHeight: 300,
      border: '1px solid rgba(0,0,0,.25)',
      '& li[data-focus="true"]': {
        backgroundColor: '#F2F3F7',
        color: 'black',
        cursor: 'pointer',
        padding: '10px 12px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      '& li:active': {
        backgroundColor: '#F2F3F7',
        color: 'white',
        padding: '10px 12px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      '& li': {
        backgroundColor: '#FFF',
        padding: '10px 12px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
  }),
);

const LOADING_OPTION = ['...загрузка...'];

export function CustomAutocomplete({
  options,
  onOptionClick,
  value,
  loading,
  width,
}: {
  options: string[];
  onOptionClick: (s: string | undefined) => void;
  value: string | undefined;
  loading: boolean;
  width: number;
}) {
  const classes = useStyles({ width });

  const [open, setOpen] = useState<boolean>(false);

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete({
      id: `autocomplete_id_assign_to_diagnost_732`,
      options: loading ? LOADING_OPTION : options,
      getOptionLabel: (option) => option.toString(),
      value: value ?? '',
      open,
      onOpen: () => setOpen(true),
      onClose: () => setOpen(false),
      onChange: (e: unknown, option: string | null) => {
        if (!loading) {
          onOptionClick(option ? option : undefined);
          setOpen(false);
        }
      },
    });

  return (
    <div>
      <div {...getRootProps()}>
        <div className={classes.inputContainer}>
          <input className={classes.input} {...getInputProps()}></input>
          <div className={classes.iconsCointainer}>
            <IconButton size="small">
              <ArrowDropDownIcon
                className={open ? classes.iconRotate : undefined}
                style={{ fontSize: 26 }}
                onClick={() => setOpen(!open)}
              />
            </IconButton>
          </div>
        </div>
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>{option}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}
