import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function DeleteFileIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 33 32'}>
      <path
        d="M0.667969 9.6C0.667969 6.23969 0.667969 4.55953 1.32193 3.27606C1.89717 2.14708 2.81505 1.2292 3.94403 0.653961C5.2275 0 6.90765 0 10.268 0H23.068C26.4283 0 28.1084 0 29.3919 0.653961C30.5209 1.2292 31.4388 2.14708 32.014 3.27606C32.668 4.55953 32.668 6.23969 32.668 9.6V22.4C32.668 25.7603 32.668 27.4405 32.014 28.7239C31.4388 29.8529 30.5209 30.7708 29.3919 31.346C28.1084 32 26.4283 32 23.068 32H10.268C6.90765 32 5.2275 32 3.94403 31.346C2.81505 30.7708 1.89717 29.8529 1.32193 28.7239C0.667969 27.4405 0.667969 25.7603 0.667969 22.4V9.6Z"
        fill="#F2F3F7"
      />
      <g clipPath="url(#clip0_1553_177692)">
        <path
          d="M10.9609 20.2929C10.5703 20.6834 10.5703 21.3166 10.9609 21.7071C11.3514 22.0976 11.9846 22.0976 12.3751 21.7071L16.6679 17.4143L20.9609 21.7073C21.3514 22.0978 21.9846 22.0978 22.3751 21.7073C22.7656 21.3167 22.7656 20.6836 22.3751 20.293L18.0821 16.0001L22.3751 11.7071C22.7656 11.3166 22.7656 10.6834 22.3751 10.2929C21.9846 9.90237 21.3514 9.90237 20.9609 10.2929L16.6679 14.5859L12.3751 10.293C11.9846 9.90252 11.3514 9.90252 10.9609 10.293C10.5703 10.6836 10.5703 11.3167 10.9609 11.7073L15.2537 16.0001L10.9609 20.2929Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1553_177692">
          <rect width="24" height="24" fill="white" transform="translate(4.66797 4)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
