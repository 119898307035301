import { FC, useContext } from 'react';

import Typography from '@material-ui/core/Typography';
import CheckIcon from 'components/fields/field-label-error-wrapper/check-icon';
import { ContextSettings } from 'context/context-settings';
import { IDiagnostic } from 'interfaces';
import { FETCH_MINIO_URL } from 'services/urls';

import { CustomStepper } from './qonto-connector-for-stepper/custom-stepper';
import styles from './styles.module.css';

interface ICardProps {
  item?: IDiagnostic;
  onClick: (id: string) => void;
}

const NO_DATA = '...';

export const DiagnosticCard: FC<ICardProps> = ({ item, onClick }) => {
  const { lastModifiedDiagnosticId } = useContext(ContextSettings);

  const { creationDate } = item ?? {};

  if (!item) {
    return <div />;
  }

  const {
    id,
    description,
    machineInventoryNumber,
    machineLinkName,
    factoryTown,
    approverFullName,
    machineLinkPhoto,
    isArchive,
    isChecked,
    isTest,
    status,
  } = item;

  return (
    <div
      id={`id_for_card_${item.id}`}
      className={`${styles.layout}${
        item.id !== undefined && item.id === lastModifiedDiagnosticId ? ` ${styles.activeItem}` : ''
      }`}
      onClick={() => onClick(id)}
    >
      <div className={isArchive ? styles.watermark : styles.none}>
        <Typography variant="h3" color="primary"></Typography>
      </div>

      <CustomStepper status={status} />

      <Typography variant="subtitle1" className={styles.description}>
        {description ?? NO_DATA}
      </Typography>
      <div className={styles.row}>
        <img
          className={styles.photo}
          src={FETCH_MINIO_URL(machineLinkPhoto)}
          alt={'Фото станка'}
          decoding="async"
        />
        <div>
          <div className={styles.fields}>
            <Typography variant="body1" component="span" color="textSecondary">
              Станок:&nbsp;
            </Typography>
            <Typography variant="body1" component="span">
              {machineLinkName ?? NO_DATA}
            </Typography>
          </div>
          <div className={styles.fields}>
            <Typography variant="body1" component="span" color="textSecondary">
              Инв.&nbsp;№:&nbsp;
            </Typography>

            <Typography variant="body1" component="span">
              {machineInventoryNumber ?? NO_DATA}
            </Typography>
          </div>
          <div className={styles.fields}>
            <Typography variant="body1" component="span" color="textSecondary">
              Дата:&nbsp;
            </Typography>
            <Typography variant="body1" component="span" color="textSecondary">
              {`${creationDate ? new Date(creationDate).toLocaleDateString('ru') : '...'}`}
            </Typography>
          </div>
          <div className={styles.fields}>
            {isChecked && (
              <div className={styles.checkedContainer}>
                <div className={styles.checkIcon}>
                  <CheckIcon color="#2ecc71" />
                </div>
                <div className={styles.greenColor}>Диагностика проверена</div>
              </div>
            )}
          </div>
          {isTest && (
            <div className={styles.fields}>
              <Typography variant="body1" component="span" color="primary">
                Тестовая диагностика
              </Typography>
            </div>
          )}
        </div>
      </div>
      <div className={styles.address}>
        <Typography variant="body1" component="span" color="textSecondary">
          Город:&nbsp;
        </Typography>
        <Typography variant="body1" component="span">
          {factoryTown ?? NO_DATA}
        </Typography>
      </div>
      <div className={styles.address}>
        <Typography variant="body1" component="span" color="textSecondary">
          Диагност:&nbsp;
        </Typography>
        <Typography variant="body1" component="span" color="primary">
          {approverFullName ?? NO_DATA}
        </Typography>
      </div>
    </div>
  );
};
