import { IHttpMethods, IErrorMessagePayload } from 'interfaces';
import { GET_USER_FULLNAME_URL } from 'services/urls';
import { SERVER_RESPONSE_ABORT_TIME_OUT, CUSTOM_HEADERS_FOR_FETCH } from 'stream-constants';
import { delay } from 'utils/delay';

export const probableError = (errorProbability: number): string => {
  const errorHappend = Math.random();
  if (errorHappend < errorProbability) {
    return `API Error =${errorHappend}`;
  }

  return '';
};

interface IFetchData {
  url: string;
  body?: any;
  method: IHttpMethods;
  name: string;
  emptyResponse: boolean;
  contentType?: string;
  timeoutToAbort?: number;
}

export const fetchData = async ({
  url,
  body,
  method,
  name,
  emptyResponse,
  contentType,
  timeoutToAbort,
}: IFetchData) => {
  let result;
  const controller = new AbortController();
  const timeoutId = setTimeout(
    () => controller.abort(),
    timeoutToAbort ?? SERVER_RESPONSE_ABORT_TIME_OUT,
  );

  const pathname = window.location.pathname;

  const headers: any = {
    ...CUSTOM_HEADERS_FOR_FETCH(window.location),
    'Content-Type': contentType ? contentType : 'application/json',
  };
  if (contentType === 'none') {
    delete headers['Content-Type'];
  }

  const obj: RequestInit = {
    signal: controller.signal,
    method,
    mode: 'cors',
    cache: 'no-store',
    credentials: 'same-origin', // include, *same-origin, omit
    headers,
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *client
  };

  if (body) {
    obj.body = body;
  }

  const response = await fetch(url, obj);

  clearTimeout(timeoutId);

  const { status, ok } = response;
  if (!ok || status >= 400) {
    let responseError;
    try {
      responseError = await response.json();
    } catch {
      responseError = `not of json format`;
    } finally {
      if (status === 401) {
        //  Авторизация
        const { detail } = responseError;
        if (detail) {
          if (url === GET_USER_FULLNAME_URL && pathname === '/') {
            return Promise.resolve({ redirectUrl: detail, fullname: '' });
          } else {
            window.location.href = detail;
            await delay(10000);
          }
        }
      }
      let parsedBody = body;
      try {
        parsedBody = JSON.parse(body);
      } catch {}

      const errorPayload: IErrorMessagePayload = {
        url,
        name,
        status,
        method,
        body: parsedBody,
        response: responseError,
      };
      const errMessage = JSON.stringify(errorPayload, null, 4);
      result = Promise.reject(new Error(errMessage));
    }
  } else {
    if (emptyResponse) {
      result = Promise.resolve({});
    } else {
      result = response.json();
    }
  }

  return result;
};
