import Tooltip from '@material-ui/core/Tooltip';

export function DownloadReportIsNotPossibleBecauseNotChecked({
  visible,
  children,
}: {
  visible: boolean;
  children: JSX.Element;
}) {
  return (
    <Tooltip
      placement={'top-start'}
      title={visible ? 'Диагностика не проверена. Формирование/скачивание  отчета невозможно.' : ''}
    >
      {children}
    </Tooltip>
  );
}
