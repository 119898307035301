const element = document.documentElement;
/**
 * max-screen-width
 */

export const MAX_SCREEN_WIDTH = 1920;
export const MIN_SCREEN_WIDTH = 1440;
export const DEFAULT_SCREEN_HEIGHT = 891;
element.style.setProperty('--max-screen-width', `${MAX_SCREEN_WIDTH}px`);
element.style.setProperty('--min-screen-width', `${MIN_SCREEN_WIDTH}px`);

/**
 * colors
 */
export const YELLOW_COLOR = '#FAC031';
export const ORANGE_COLOR = '#FA8A64';
export const GREEN_COLOR = '#4caf50';
export const RED_COLOR = '#E30611';
export const BLACK_COLOR = '#001424';

export const DARK_RED_COLOR = '#c20a22';
export const GREY_TEXT_COLOR = '#202020';

element.style.setProperty('--main-red-color', RED_COLOR);
element.style.setProperty('--main-green-color', GREEN_COLOR);

export const SCROOL_BAR_WIDTH = 8;
element.style.setProperty('--scrool-bar-width', `${SCROOL_BAR_WIDTH}px`);

/**
 * Diagnostic layout params.
 */

export const DIAGNOSTIC_COLUMN_WIDTH = 380;

export const DIAGNOSTIC_ROW_HEIGHT = 215;

export const DIAGNOSTIC_MACHINES_PHOTO_HEIGHT = 86;
export const DIAGNOSTIC_MACHINES_PHOTO_MAX_WIDTH = 160;

element.style.setProperty('--diagnostic-column-width', `${DIAGNOSTIC_COLUMN_WIDTH}px`);
element.style.setProperty('--diagnostic-row-height', `${DIAGNOSTIC_ROW_HEIGHT}px`);
element.style.setProperty(
  '--diagnostic-machines-photo-height',
  `${DIAGNOSTIC_MACHINES_PHOTO_HEIGHT}px`,
);
element.style.setProperty(
  '--diagnostic-machines-photo-max-width',
  `${DIAGNOSTIC_MACHINES_PHOTO_MAX_WIDTH}px`,
);

/**
 * Machines layout params.
 */

export const MACHINE_COLUMN_COUNT = 4;
export const MACHINE_COLUMN_WIDTH = 270;
const MACHINE_IMAGE_HEIGHT = 320;
const MACHINE_FOOTER_HEIGHT = 120;
export const MACHINE_ROW_HEIGHT = MACHINE_IMAGE_HEIGHT + MACHINE_FOOTER_HEIGHT;

export const MACHINE_FULL_WIDTH = MACHINE_COLUMN_WIDTH * MACHINE_COLUMN_COUNT + 17;

element.style.setProperty('--machines-column-count', `${MACHINE_COLUMN_COUNT}`);
element.style.setProperty('--machines-column-width', `${MACHINE_COLUMN_WIDTH}px`);
element.style.setProperty('--machines-mnemonicScheme-height', `${MACHINE_IMAGE_HEIGHT}px`);
element.style.setProperty('--machines-footer-height', `${MACHINE_FOOTER_HEIGHT}px`);

/**
 * Bearings layout params.
 */
const BEARINGS_ARCTICLE_WIDTH = 170;
const BEARINGS_FULLNESS_WIDTH = 100;
const BEARINGS_NUMBER_WIDTH = 110;
const BEARINGS_AFTER_COMMA = 40;
const BEARINGS_LAYOUT_PADDING = 10;

element.style.setProperty('--bearings-arcticle-width', `${BEARINGS_ARCTICLE_WIDTH}px`);
element.style.setProperty('--bearings-number-width', `${BEARINGS_NUMBER_WIDTH}px`);
element.style.setProperty('--bearings-after-comma', `${BEARINGS_AFTER_COMMA}px`);
element.style.setProperty('--bearings-layout-padding', `${BEARINGS_LAYOUT_PADDING}px`);
element.style.setProperty('--bearings-fullness-width', `${BEARINGS_FULLNESS_WIDTH}px`);

export const RADIAL_BEARINGS_FULL_WIDTH =
  BEARINGS_ARCTICLE_WIDTH * 2 +
  (BEARINGS_NUMBER_WIDTH + BEARINGS_AFTER_COMMA) * 5 +
  BEARINGS_FULLNESS_WIDTH +
  BEARINGS_LAYOUT_PADDING;

element.style.setProperty('--radial-bearings-full-width', `${RADIAL_BEARINGS_FULL_WIDTH}px`);

export const TAPE_ROLL_BEARINGS_FULL_WIDTH =
  BEARINGS_ARCTICLE_WIDTH * 2 +
  (BEARINGS_NUMBER_WIDTH + BEARINGS_AFTER_COMMA) * 6 +
  BEARINGS_FULLNESS_WIDTH +
  BEARINGS_LAYOUT_PADDING;

element.style.setProperty('--tape-roll-bearings-full-width', `${TAPE_ROLL_BEARINGS_FULL_WIDTH}px`);

export const BALLSCREW_BEARINGS_FULL_WIDTH =
  BEARINGS_ARCTICLE_WIDTH * 2 +
  (BEARINGS_NUMBER_WIDTH + BEARINGS_AFTER_COMMA) * 6 +
  BEARINGS_FULLNESS_WIDTH +
  BEARINGS_LAYOUT_PADDING;

element.style.setProperty('--ballscrew-bearings-full-width', `${BALLSCREW_BEARINGS_FULL_WIDTH}px`);

element.style.setProperty('--column33StartWidth_0', '25%');
element.style.setProperty('--column33StartWidth_1', '30%');
element.style.setProperty('--column33StartWidth_2', '45%');
element.style.setProperty('--columnLayoutTransitionValue', '0.3s');
