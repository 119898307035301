import React from 'react';

import Typography from '@material-ui/core/Typography';

import styles from './styles.module.css';

export function Li({ children, size }: { children: React.ReactNode; size?: 'small' }) {
  return (
    <li className={styles.li}>
      <Typography
        display="inline"
        color="inherit"
        variant={size === 'small' ? 'body2' : 'subtitle2'}
      >
        {children}
      </Typography>
    </li>
  );
}

export function Ul({ children }: { children: React.ReactNode }) {
  return <ul className={styles.ul}>{children}</ul>;
}
