export interface IFolder {
  csvId: string | null;
  csvLink?: string | null; // временное поле
  measurementId: string | null;
  folderName: string;
  fileMeta: string | null;
  outputSpeed: number | null;
  outputSpeedUnits: string | null;
  date: string | number | null; // file date
  status: IStatus;
  file?: File;
  readOnlyMessage: string | null | undefined;
}

export enum IStatus {
  UNKNOWN = 'UNKNOWN',
  MORE_THAN_ONE = 'MORE_THAN_ONE',
  FILE_NOT_FOUND = 'FILE_NOT_FOUND',
  FOLDER_NOT_FOUND = 'FOLDER_NOT_FOUND',
  TOO_BIG = 'TOO_BIG',
  READY = 'READY',
  DONE = 'DONE',
  MUST_BE_REUPLOADED = 'MUST_BE_REUPLOADED',
  NOT_YET_UPLOADED = 'NOT_YET_UPLOADED',
}

export const STATUS_INFO = {
  [IStatus.READY]: { color: '#007CFF', message: 'Готов к загрузке' },
  [IStatus.DONE]: { color: '#26CD58', message: 'Файл загружен' },
  [IStatus.FILE_NOT_FOUND]: { color: '#F95721', message: 'В папке нет файлов csv' },
  [IStatus.MORE_THAN_ONE]: { color: '#F95721', message: 'В папке несколько файлов csv' },
  [IStatus.TOO_BIG]: { color: '#F95721', message: 'Размер файла превышает 184 MB' },
  [IStatus.FOLDER_NOT_FOUND]: { color: '#F95721', message: 'Папка не найдена' },
  [IStatus.MUST_BE_REUPLOADED]: {
    color: '#F95721',
    message: 'Можно загрузить новый файл с диска вместо старого, уже загруженного, файла',
  },
  // служебные
  [IStatus.UNKNOWN]: { color: '#000', message: '...' },
  [IStatus.NOT_YET_UPLOADED]: { color: '#ffffff', message: '' },
};

export enum IFilters {
  READY,
  DONE,
  ERRORS,
}

export const EMPTY_DIRECTORY = {
  mainDirectoryHandle: undefined,
  fileSystemDict: new Map(),
  mainDirectoryInfo: undefined,
};

export interface IMainUploadDirectory {
  mainDirectoryHandle: FileSystemDirectoryHandle | undefined;
  fileSystemDict: Map<string, IFolder>;
  mainDirectoryInfo: string | undefined;
}

export const sortFunction = (a: string | undefined, b: string | undefined) => {
  const numberA = parseInt(a ?? '-');
  const numberB = parseInt(b ?? '-');
  if (isNaN(numberA) || isNaN(numberB) || numberA === numberB) {
    return (a ?? '-') > (b ?? '-') ? 1 : -1;
  }

  return numberA - numberB;
};

export function getFileMeta(file: File | undefined) {
  return file
    ? `{"lastModified": ${file.lastModified}, "name": "${file.name}", "size": ${file.size}}`
    : null;
}

const KILOBYTE = 1000;
const MEGABYTE = KILOBYTE * KILOBYTE;

export const fileSizeToText = (size: number | null | undefined) => {
  const ssize = size ?? 0;

  return ssize < MEGABYTE / 10
    ? `${(ssize / KILOBYTE).toFixed(0)} кБ`
    : `${(ssize / MEGABYTE).toFixed(ssize < MEGABYTE * 10 ? 2 : 0)} Мб`;
};

export function fileIntoToText(file: File | undefined) {
  if (!file) {
    return undefined;
  }
  const size = fileSizeToText(file?.size);

  return `${file?.name ?? ''}, ${size}`;
}

export function getTextDateAndTime(date: string | number | undefined | null) {
  if (!date) {
    return '...';
  }
  const ddate = new Date(date);
  const result = `${ddate.toLocaleDateString('ru')}г. ${ddate.toLocaleTimeString('ru')}`;

  return result;
}
