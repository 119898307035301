import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function DeffectsIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 28 28'}>
      <g clipPath="url(#clip0_150_185739)">
        <path
          d="M24.9395 8.95587C25.3478 8.49649 25.3273 7.79844 24.8927 7.36383V7.36383C24.4202 6.89136 23.6474 6.9143 23.2038 7.41395L20.6966 10.2378C18.2933 7.53113 14.9683 5.8978 11.2116 5.8978C8.27315 5.8978 5.54733 6.92573 3.29677 8.61089C2.7564 9.01551 2.73583 9.80031 3.21317 10.2777V10.2777C3.65021 10.7147 4.34791 10.7322 4.84733 10.3681C6.67546 9.03526 8.86141 8.23113 11.2116 8.23113C14.4083 8.23113 17.15 9.70113 19.11 12.0111L15.9614 15.5533C15.8467 15.6823 15.6471 15.6882 15.525 15.5662L11.3891 11.4303C11.2203 11.2614 10.9464 11.2615 10.7777 11.4305L3.20707 19.0112C2.72441 19.4945 2.72468 20.2775 3.20766 20.7605V20.7605C3.69115 21.244 4.47515 21.2436 4.95824 20.7597L10.8496 14.8585C10.9803 14.7276 11.1909 14.7227 11.3276 14.8475L15.5343 18.6879C15.6544 18.7975 15.8398 18.7916 15.9526 18.6746L20.475 13.9828C21.1909 15.2714 21.7116 16.7163 21.9986 18.2534C22.1084 18.8414 22.6064 19.2911 23.2046 19.2911V19.2911C23.8991 19.2911 24.4463 18.6919 24.3292 18.0073C23.9564 15.8284 23.1857 13.8388 22.12 12.1278L24.9395 8.95587Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_150_185739">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
