import { useEffect, useRef, useState } from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  COLOR,
  IPreparedPeak,
  IFrequencyZoomParams,
  ZOOM_STEP_WHEEL,
  GRAPH_POINT_RADIUS,
  IHoveredOrigin,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

import { usePrepareGraphParams } from '../hooks/use-prepare-graph-params';

export const TITLE_CHART_SHIFT = 80;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    chart: {
      position: 'absolute',
      top: 0,
      left: 0,
      rigth: 0,
      bottom: 0,
    },
    titleСhart: {
      position: 'absolute',
      top: 0 - TITLE_CHART_SHIFT,
      left: 0,
      rigth: 0,
      bottom: 0,
    },
    chartMovement: {
      cursor: 'grab',
    },
  }),
);

const HOVER_POINTRADIUS = 8;

function findNearestPeakName(xScreen: number, nearestPeaks: IPreparedPeak[]) {
  let nearestPeak = undefined;
  let indexCurrentMinValue = 5;

  for (const peak of nearestPeaks) {
    const { x } = peak.inPixels.theory.envelop;
    const delta = Math.abs(x - xScreen);
    if (delta < indexCurrentMinValue) {
      indexCurrentMinValue = delta;
      nearestPeak = peak;
    }
  }

  return nearestPeak;
}

export function PeaksLayer23({
  canvasSize,
  graphParams,
  hoverGroupName,
  hoverPeakName,
  isEnvelop,
  selectedFrequencies,
  setHoverPeakName,
  setSelectedFrequencies,
  frequencyZoomParams,
  setFrequencyZoomParams,
  setExpandedGroups,
  hoverArea,
  setHoverArea,
  withTooltip,
}: {
  canvasSize: { height: number; width: number };
  graphParams: ReturnType<typeof usePrepareGraphParams>;
  hoverGroupName: string;
  hoverPeakName: { name: string; origin: IHoveredOrigin } | undefined;
  isEnvelop: boolean;
  setHoverPeakName: (i: { name: string; origin: IHoveredOrigin } | undefined) => void;
  selectedFrequencies: Set<string>;
  setSelectedFrequencies: (s: Set<string>) => void;
  frequencyZoomParams: IFrequencyZoomParams;
  setFrequencyZoomParams: (a: IFrequencyZoomParams) => void;
  setExpandedGroups: (s: Set<string>) => void;
  hoverArea: number | undefined;
  setHoverArea: (a: number | undefined) => void;
  withTooltip: boolean;
}) {
  const classes = useStyles();

  const [sliderDraggedStartPosition2, setSliderDraggedStartPosition2] = useState<
    number | undefined
  >();

  const canvasPeakRef = useRef<HTMLCanvasElement | null>(null);
  const canvasHoverRef = useRef<HTMLCanvasElement | null>(null);
  const canvasTitleRef = useRef<HTMLCanvasElement | null>(null);

  const hoverIdleTimeIdRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const scrollIntoViewTimeout = useRef<ReturnType<typeof setTimeout> | undefined>();
  const movementIndicatorRef = useRef<boolean>(false);

  const { height, width } = canvasSize;

  useEffect(() => {
    return () => {
      if (scrollIntoViewTimeout.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(scrollIntoViewTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    // ********* точки семплов *****************************************************************************
    if (graphParams === undefined || !canvasPeakRef.current) {
      return;
    }
    const canvas = canvasPeakRef.current as HTMLCanvasElement;
    const ctxPeaks = canvas.getContext('2d', { alpha: true });
    if (!ctxPeaks) {
      return;
    }
    ctxPeaks.fillStyle = '#FFF';
    ctxPeaks.clearRect(0, 0, width, height);
    ctxPeaks.lineWidth = 1;

    const canvasTitle = canvasTitleRef.current as HTMLCanvasElement;
    const ctxTitles = canvasTitle.getContext('2d', { alpha: true });
    if (!ctxTitles) {
      return;
    }
    ctxTitles.fillStyle = '#FFF';
    ctxTitles.clearRect(0, 0, width, height + TITLE_CHART_SHIFT);
    ctxTitles.lineWidth = 1;

    const { preparedPeaks, frequencyMin, frequencyMax } = graphParams;

    const { toPixels, amplitudeMinValue } =
      (isEnvelop ? graphParams.envelop : graphParams.direct) ?? {};
    if (
      preparedPeaks === undefined ||
      toPixels === undefined ||
      frequencyMax === undefined ||
      frequencyMin === undefined ||
      amplitudeMinValue === undefined
    ) {
      // const message =
      //   'preparedPeaks === undefined || toPixels === undefined || frequencyMax === undefined || frequencyMin === undefined || amplitudeMinValue === undefined';
      // console.error(message);

      return;
    }
    const amplitudeMinValueInPixels = toPixels({ x: 0, y: amplitudeMinValue }).y;

    let minPeakFrequency = Number.POSITIVE_INFINITY;
    let maxPeakFrequency = Number.NEGATIVE_INFINITY;

    for (const frequencyName of selectedFrequencies) {
      const preparedPeak = preparedPeaks.get(frequencyName);
      if (preparedPeak) {
        const theoryFreq = preparedPeak.rawPeak.theoryFreq;
        if (
          selectedFrequencies.has(frequencyName) &&
          theoryFreq > frequencyMin &&
          theoryFreq < frequencyMax
        ) {
          // const color = getColorByColorIndex(preparedPeak.colorIndex).circle;

          ctxPeaks.strokeStyle = preparedPeak.groupColor;
          ctxPeaks.fillStyle = preparedPeak.groupColor;

          // палка
          const { x, y } = isEnvelop
            ? preparedPeak.inPixels.theory.envelop
            : preparedPeak.inPixels.theory.direct;
          minPeakFrequency = Math.min(minPeakFrequency, x);
          maxPeakFrequency = Math.max(maxPeakFrequency, x);
          ctxPeaks.beginPath();
          ctxPeaks.moveTo(x, amplitudeMinValueInPixels);
          ctxPeaks.lineTo(x, y);
          ctxPeaks.closePath();
          ctxPeaks.stroke();

          // куржок
          const realXY = isEnvelop
            ? preparedPeak.inPixels.real.envelop
            : preparedPeak.inPixels.real.direct;

          if (realXY) {
            ctxPeaks.beginPath();
            ctxPeaks.arc(realXY.x, realXY.y, GRAPH_POINT_RADIUS, 0, 2 * Math.PI);
            ctxPeaks.closePath();
            ctxPeaks.fill();
          }

          const textY = Math.min(y, realXY?.y ?? Number.POSITIVE_INFINITY);
          // наклонная подпись
          ctxTitles.save();
          ctxTitles.fillStyle = preparedPeak.groupColor;
          ctxTitles.translate(x, textY - 10 + TITLE_CHART_SHIFT);
          ctxTitles.rotate(Math.PI * (7 / 4));
          ctxTitles.font = 'bold 14px MTSSans';
          ctxTitles.textAlign = 'start';
          ctxTitles.fillText(frequencyName, 0, 0);
          ctxTitles.restore();
        }
      }
    }
    //нижняя линия выбранных частот
    ctxPeaks.strokeStyle = COLOR.hover;
    ctxPeaks.beginPath();
    ctxPeaks.moveTo(minPeakFrequency - 10, amplitudeMinValueInPixels);
    ctxPeaks.lineTo(maxPeakFrequency + 10, amplitudeMinValueInPixels);
    ctxPeaks.closePath();
    ctxPeaks.stroke();

    ctxPeaks.closePath();
    ctxTitles.closePath();
  }, [graphParams, height, isEnvelop, selectedFrequencies, width]);

  useEffect(() => {
    // ********* hovered peak *****************************************************************************
    if (graphParams === undefined || !canvasPeakRef.current) {
      return;
    }
    const canvas = canvasHoverRef.current as HTMLCanvasElement;
    const ctxHovers = canvas.getContext('2d', { alpha: true });
    if (!ctxHovers) {
      return;
    }
    const {
      preparedPeaks,
      frequencyMin,
      frequencyMax,
      indexOfFrequencyInterval,
      getIndexOfFrequencyInterval,
      freguencyFromXPixel,
    } = graphParams;
    const { toPixels, amplitudeMinValue } =
      (isEnvelop ? graphParams.envelop : graphParams.direct) ?? {};
    if (
      preparedPeaks === undefined ||
      toPixels === undefined ||
      freguencyFromXPixel === undefined ||
      amplitudeMinValue === undefined ||
      frequencyMin === undefined ||
      frequencyMax === undefined ||
      indexOfFrequencyInterval === undefined ||
      getIndexOfFrequencyInterval === undefined
    ) {
      // const message =
      //   'preparedPeaks === undefined || toPixels === undefined || amplitudeMinValue === undefined';
      // console.error(message);

      return;
    }

    ctxHovers.fillStyle = '#FFF';
    ctxHovers.clearRect(0, 0, width, height);

    ctxHovers.strokeStyle = COLOR.hover;
    ctxHovers.fillStyle = COLOR.hover;

    const lowAmplitudeInPixels = toPixels({ x: 0, y: amplitudeMinValue }).y;

    const hoveredpeak = preparedPeaks.get(hoverPeakName?.name ?? '');

    if (
      hoveredpeak &&
      hoveredpeak.rawPeak.theoryFreq >= frequencyMin &&
      hoveredpeak.rawPeak.theoryFreq <= frequencyMax
    ) {
      const theoryXY = isEnvelop
        ? hoveredpeak.inPixels.theory.envelop
        : hoveredpeak.inPixels.theory.direct;
      const realXY = isEnvelop
        ? hoveredpeak.inPixels.real.envelop
        : hoveredpeak.inPixels.real.direct;

      const { x, y } = theoryXY;
      ctxHovers.beginPath();
      ctxHovers.lineWidth = 5;
      ctxHovers.moveTo(x, lowAmplitudeInPixels);
      ctxHovers.lineTo(x, y);
      ctxHovers.closePath();
      ctxHovers.stroke();

      if (realXY) {
        ctxHovers.arc(realXY.x, realXY.y, HOVER_POINTRADIUS, 0, 2 * Math.PI);
        ctxHovers.fill();
      }
      if (withTooltip) {
        const color = '#eee';
        ctxHovers.strokeStyle = color;
        ctxHovers.fillStyle = color;

        ctxHovers.beginPath();
        ctxHovers.lineWidth = 1;
        ctxHovers.moveTo(x, lowAmplitudeInPixels);
        ctxHovers.lineTo(x, 0);
        ctxHovers.closePath();
        ctxHovers.stroke();
      }
    }
    // ********* hoverArea *****************************************************************************
    // ctxHovers.globalAlpha = 0.2;

    const hoveredPeaks =
      indexOfFrequencyInterval[
        getIndexOfFrequencyInterval(freguencyFromXPixel(hoverArea ?? -1000))
      ] ?? [];
    for (const peak of hoveredPeaks) {
      const theoryXY = isEnvelop ? peak.inPixels.theory.envelop : peak.inPixels.theory.direct;
      const { x, y } = theoryXY;
      const rawX = peak.rawPeak.theoryFreq;
      if (rawX >= frequencyMin && rawX <= frequencyMax) {
        // палка
        ctxHovers.strokeStyle = peak.groupColor;
        ctxHovers.lineWidth = 1;
        ctxHovers.beginPath();
        ctxHovers.moveTo(x, lowAmplitudeInPixels);
        ctxHovers.lineTo(x, y);
        ctxHovers.closePath();
        ctxHovers.stroke();
      }
    }
  }, [graphParams, height, hoverArea, hoverPeakName?.name, isEnvelop, width, withTooltip]);

  useEffect(() => {
    // ********* hovered groupName *****************************************************************************
    if (graphParams === undefined || !canvasPeakRef.current) {
      return;
    }
    const canvas = canvasHoverRef.current as HTMLCanvasElement;
    const ctxHovers = canvas.getContext('2d', { alpha: true });
    if (!ctxHovers) {
      return;
    }
    const { preparedPeaks, frequencyMin, frequencyMax } = graphParams;
    const { toPixels, amplitudeMinValue } =
      (isEnvelop ? graphParams.envelop : graphParams.direct) ?? {};
    if (
      preparedPeaks === undefined ||
      toPixels === undefined ||
      amplitudeMinValue === undefined ||
      frequencyMin === undefined ||
      frequencyMax === undefined
    ) {
      // const message =
      //   'preparedPeaks === undefined || toPixels === undefined || amplitudeMinValue === undefined';
      // console.error(message);

      return;
    }

    ctxHovers.fillStyle = '#FFF';
    ctxHovers.clearRect(0, 0, width, height);

    ctxHovers.strokeStyle = COLOR.hover;
    ctxHovers.fillStyle = COLOR.hover;

    const lowAmplitudeInPixels = toPixels({ x: 0, y: amplitudeMinValue }).y;

    // ********* hoverGroupName *****************************************************************************
    // ctxHovers.globalAlpha = 0.2;
    for (const [, peak] of preparedPeaks) {
      const theoryXY = isEnvelop ? peak.inPixels.theory.envelop : peak.inPixels.theory.direct;
      const { x, y } = theoryXY;
      const rawX = peak.rawPeak.theoryFreq;
      if (rawX >= frequencyMin && rawX <= frequencyMax) {
        if (hoverGroupName && peak.groupName === hoverGroupName) {
          // палка
          ctxHovers.strokeStyle = peak.groupColor;
          ctxHovers.lineWidth = 1;
          ctxHovers.beginPath();
          ctxHovers.moveTo(x, lowAmplitudeInPixels);
          ctxHovers.lineTo(x, y);
          ctxHovers.closePath();
          ctxHovers.stroke();
        }
      }
    }
  }, [graphParams, height, hoverGroupName, isEnvelop, width]);

  useEffect(() => {
    if (graphParams === undefined || !canvasPeakRef.current) {
      return;
    }
    const {
      preparedPeaks,
      indexOfFrequencyInterval,
      getIndexOfFrequencyInterval,
      freguencyFromXPixel,
      frequencyMaxAbsolute,
      frequencyMinAbsolute,
      frequencyMax,
      frequencyMin,
    } = graphParams;

    if (
      preparedPeaks === undefined ||
      indexOfFrequencyInterval === undefined ||
      getIndexOfFrequencyInterval === undefined ||
      freguencyFromXPixel === undefined ||
      frequencyMaxAbsolute === undefined ||
      frequencyMinAbsolute === undefined ||
      frequencyMax === undefined ||
      frequencyMin === undefined
    ) {
      console.error('preparedPeaks === undefined');

      return;
    }

    const { amplitudeMax, amplitudeMin, toPixels } = isEnvelop
      ? graphParams.envelop
      : graphParams.direct;
    const amplitudeMaxInPixels = toPixels({ x: 0, y: amplitudeMax }).y;
    const amplitudeMinInPixels = toPixels({ x: 0, y: amplitudeMin }).y;

    const mouseMove = (event: MouseEvent) => {
      if (hoverIdleTimeIdRef.current) {
        clearTimeout(hoverIdleTimeIdRef.current);
      }

      const xScreen = event.offsetX;
      const yScreen = event.offsetY;

      if (sliderDraggedStartPosition2 !== undefined) {
        movementIndicatorRef.current = true;
        const conversionFactor = freguencyFromXPixel(1000) / 1000 / frequencyZoomParams.zoom;
        let newCenter =
          (sliderDraggedStartPosition2 - xScreen) * conversionFactor + frequencyZoomParams.center;
        const chartWindow =
          (frequencyMaxAbsolute - frequencyMinAbsolute) / frequencyZoomParams.zoom;
        const minCenter = frequencyMinAbsolute + chartWindow * frequencyZoomParams.weight;
        const maxCenter = frequencyMaxAbsolute - chartWindow * (1 - frequencyZoomParams.weight);

        newCenter = Math.max(newCenter, minCenter);
        newCenter = Math.min(newCenter, maxCenter);

        setFrequencyZoomParams({
          ...frequencyZoomParams,
          center: newCenter,
        });
        setSliderDraggedStartPosition2(xScreen);
      } else {
        hoverIdleTimeIdRef.current = setTimeout(() => {
          hoverIdleTimeIdRef.current = undefined;

          if (yScreen < amplitudeMinInPixels && yScreen > amplitudeMaxInPixels) {
            const nearestPeaks =
              indexOfFrequencyInterval[
                getIndexOfFrequencyInterval(freguencyFromXPixel(xScreen ?? -1000))
              ] ?? [];
            const nearestPeak = findNearestPeakName(xScreen, nearestPeaks);
            setHoverPeakName({
              name: nearestPeak?.frequencyName ?? '',
              origin: isEnvelop ? IHoveredOrigin.ENVELOP : IHoveredOrigin.DIRECT,
            });
            setHoverArea(xScreen);
          } else {
            setHoverPeakName(undefined);
            setHoverArea(undefined);
          }
        }, 20);
      }
    };

    const canvas: HTMLCanvasElement | null = canvasPeakRef.current;
    if (canvas) {
      canvas.addEventListener('mousemove', mouseMove);
      //@ts-ignore
      canvas.addEventListener('touchmove', mouseMove);
    }

    return () => {
      if (hoverIdleTimeIdRef.current) {
        clearTimeout(hoverIdleTimeIdRef.current);
      }
      if (canvas) {
        canvas.removeEventListener('mousemove', mouseMove);
        //@ts-ignore
        canvas.removeEventListener('touchmove', mouseMove);
      }
    };
  }, [
    frequencyZoomParams,
    graphParams,
    isEnvelop,
    setFrequencyZoomParams,
    setHoverArea,
    setHoverPeakName,
    sliderDraggedStartPosition2,
  ]);

  useEffect(() => {
    if (graphParams === undefined || !canvasPeakRef.current) {
      return;
    }
    const {
      preparedPeaks,
      frequencyMax,
      frequencyMin,
      indexOfFrequencyInterval,
      getIndexOfFrequencyInterval,
      freguencyFromXPixel,
    } = graphParams;
    if (
      preparedPeaks === undefined ||
      frequencyMax === undefined ||
      frequencyMin === undefined ||
      indexOfFrequencyInterval === undefined ||
      getIndexOfFrequencyInterval === undefined ||
      freguencyFromXPixel === undefined
    ) {
      console.error('preparedPeaks === undefined');

      return;
    }

    const mouseDown = (event: MouseEvent) => {
      movementIndicatorRef.current = false;
      setSliderDraggedStartPosition2(event.offsetX);
    };

    const mouseUp = (event: MouseEvent) => {
      const xScreen = event.offsetX;

      if (movementIndicatorRef.current) {
        movementIndicatorRef.current = false;
        setSliderDraggedStartPosition2(undefined);
      } else {
        //single click
        const nearestPeaks =
          indexOfFrequencyInterval[getIndexOfFrequencyInterval(freguencyFromXPixel(xScreen))] ?? [];
        const peak = findNearestPeakName(xScreen, nearestPeaks);
        const peakName = peak?.frequencyName;
        if (peakName) {
          const newSelectedFrequencies = new Set([...selectedFrequencies]);
          if (newSelectedFrequencies.has(peakName)) {
            newSelectedFrequencies.delete(peakName);
          } else {
            newSelectedFrequencies.add(peakName);

            setExpandedGroups(new Set([peak.groupName]));
            const unicId = peak.unicId;
            const SCROLL_INTO_VIEW_TIMEOUT = 1000;
            if (scrollIntoViewTimeout.current) {
              // eslint-disable-next-line react-hooks/exhaustive-deps
              clearTimeout(scrollIntoViewTimeout.current);
            }
            scrollIntoViewTimeout.current = setTimeout(() => {
              scrollIntoViewTimeout.current = undefined;
              const peakElement = document.getElementById(unicId);
              if (peakElement) {
                peakElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
              }
            }, SCROLL_INTO_VIEW_TIMEOUT);
          }
          setSelectedFrequencies(newSelectedFrequencies);
        }
        setSliderDraggedStartPosition2(undefined);
      }
    };

    const canvas: HTMLCanvasElement | null = canvasPeakRef.current;
    if (canvas) {
      canvas.addEventListener('mousedown', mouseDown);
      canvas.addEventListener('mouseup', mouseUp);
      //@ts-ignore
      canvas.addEventListener('touchstart', mouseDown);
      //@ts-ignore
      canvas.addEventListener('touchend', mouseUp);
      //@ts-ignore
      canvas.addEventListener('touchcancel', mouseUp);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('mousedown', mouseDown);
        canvas.removeEventListener('mouseup', mouseUp);
        //@ts-ignore
        canvas.removeEventListener('touchstart', mouseDown);
        //@ts-ignore
        canvas.removeEventListener('touchend', mouseUp);
        //@ts-ignore
        canvas.removeEventListener('touchcancel', mouseUp);
      }

      if (hoverIdleTimeIdRef.current) {
        clearTimeout(hoverIdleTimeIdRef.current);
      }
    };
  }, [
    graphParams,
    selectedFrequencies,
    setExpandedGroups,
    setSelectedFrequencies,
    sliderDraggedStartPosition2,
  ]);

  useEffect(() => {
    if (graphParams === undefined || !canvasPeakRef.current) {
      return;
    }
    const {
      freguencyFromXPixel,
      frequencyMax,
      frequencyMin,
      frequencyMinAbsolute,
      frequencyMaxAbsolute,
    } = graphParams;

    if (
      freguencyFromXPixel === undefined ||
      frequencyMax === undefined ||
      frequencyMin === undefined ||
      frequencyMinAbsolute === undefined ||
      frequencyMaxAbsolute === undefined
    ) {
      return;
    }

    const mouseWheel = (event: WheelEvent) => {
      movementIndicatorRef.current = false;
      setSliderDraggedStartPosition2(undefined);

      const xScreen = event.offsetX;
      const deltaY = event.deltaY;
      let newZoom =
        deltaY < 0
          ? frequencyZoomParams.zoom * ZOOM_STEP_WHEEL
          : frequencyZoomParams.zoom / ZOOM_STEP_WHEEL;

      if (newZoom < 1) {
        newZoom = 1;
      } else {
        if (newZoom > 35) {
          newZoom = 35;
        }
      }

      let newCenter = freguencyFromXPixel(xScreen);
      const newWeight = (newCenter - frequencyMin) / (frequencyMax - frequencyMin);

      const chartWindow = (frequencyMaxAbsolute - frequencyMinAbsolute) / newZoom;
      const minCenter = frequencyMinAbsolute + chartWindow * newWeight;
      const maxCenter = frequencyMaxAbsolute - chartWindow * (1 - newWeight);

      newCenter = Math.max(newCenter, minCenter);
      newCenter = Math.min(newCenter, maxCenter);

      setFrequencyZoomParams({
        weight: newWeight,
        center: newCenter,
        zoom: newZoom,
      });
    };

    const canvas: HTMLCanvasElement | null = canvasPeakRef.current;
    if (canvas) {
      canvas.addEventListener('wheel', mouseWheel);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('wheel', mouseWheel);
      }
    };
  }, [frequencyZoomParams.zoom, graphParams, setFrequencyZoomParams]);

  return (
    <>
      <canvas className={classes.chart} ref={canvasHoverRef} height={height} width={width} />
      <canvas
        className={classes.titleСhart}
        ref={canvasTitleRef}
        height={height + TITLE_CHART_SHIFT}
        width={width}
      />
      <canvas
        className={classNames(classes.chart, {
          [classes.chartMovement]: movementIndicatorRef.current && sliderDraggedStartPosition2,
        })}
        ref={canvasPeakRef}
        height={height}
        width={width}
        onMouseLeave={() => {
          movementIndicatorRef.current = false;
          setSliderDraggedStartPosition2(undefined);
        }}
      />
    </>
  );
}
