import Typography from '@material-ui/core/Typography';
import { Li, Ul } from 'components/list';
import { NO_PICTURE } from 'stream-constants';

import contactAngularImage from './contact-angular.png';
import revolverHeadImage from './revolver-head.png';
import shkiv from './shkiv.png';

export function Glossarium() {
  return (
    <div className="layout-min-height">
      <br />
      <Typography variant="h5" color="inherit">
        Глоссарий
      </Typography>
      <Ul>
        <Li>
          <b>Коробка скоростей</b> – это устройство, входящее в шпиндельный привод, задача коробки
          скоростей – менять скорость вращения шпинделя (менять передаточное число между валом
          двигателя и валом шпинделя).
          <a href="http://www.webrarium.ru/mech-kpp.html">
            &nbsp;http://www.webrarium.ru/mech-kpp.html
          </a>
        </Li>
        <Li>
          <b>Коробка подач</b> - это устройство, входящее в привод подачи, задача коробки подачи
          служит для переключения скорости вращения ходового винта и ходового вала, т. е. для выбора
          скорости подачи.
          <a href="http://stanki-katalog.ru/st_73.htm">&nbsp;http://stanki-katalog.ru/st_73.htm</a>
        </Li>

        <Li>
          <b>Револьверная головка</b> – это диск с набором инструментов, который позволяет менять
          инструмент во время обработки детали.
          <a href="https://stankomach.com/o-kompanii/articles/tokarnye-golovki.html">
            &nbsp;https://stankomach.com/o-kompanii/articles/tokarnye-golovki.html
          </a>
          <img
            src={revolverHeadImage}
            alt={NO_PICTURE('Револьверная головка.')}
            height={400}
            style={{ padding: 40 }}
          />
          Рис. Револьверная головка
        </Li>
        <Li>
          <b>Технологическая операция</b> - законченная часть технологического процесса, выполняемая
          на одном рабочем месте.
        </Li>
        <Li>
          <b>Рабочий ход</b> – однократное перемещение режущего инструмента, которым сопровождается
          обработка детали.
        </Li>
        <Li>
          <b>Обратный ход</b> – однократное перемещение режущего инструмента в базовое положение
          после обработки детали.
        </Li>
        <Li>
          <b>Холостой ход</b> – режим работы станка без нагрузки.
        </Li>
        <Li>
          <b>Угол контакта</b> <i>подшипника</i> - это угол между линией, соединяющей точки контакта
          шарика с дорожками качения, по которым комбинированная нагрузка передаётся от одной
          дорожки качения на другую, и линией, перпендикулярной оси подшипника.
          <a href="https://www.skf.com/ru/products/rolling-bearings/ball-bearings/angular-contact-ball-bearings">
            &nbsp;https://www.skf.com/ru/products/rolling-bearings/ball-bearings/angular-contact-ball-bearings
          </a>
          <img
            src={contactAngularImage}
            alt={NO_PICTURE('Угол контакта.')}
            height={400}
            style={{ padding: 40 }}
          />
          Рис. Угол контакта
        </Li>
        <Li>
          <b>Кинематическая цепь</b> – механизм, входящий в состав станка, работающий от одного
          мотора.
        </Li>
        <Li>
          <b>Узел</b> – это кинематическая цепь
          <i>(термин ближе к станкостроению)</i>.
        </Li>
        <Li>
          <b>Мнемосхема станка</b> – интерактивная схема станка с отображением степени дефекта
          каждого элемента (за основу берётся кинематическая схема станка см. определение
          кинематической схемы станка).
        </Li>
        <Li>
          <b>Кинематическая схема станка</b> – схема станка, включающая в себя элементы диагностики,
          использующая условные обозначения для отображения согласно ГОСТ 2.703-2011.
        </Li>
        <Li>
          <b>Элемент</b> – это физический элемент, который будет подвергаться диагностике (например,
          подшипник, зубчатая передача и др.).
        </Li>
        <Li>
          <b>Точка измерения</b> – это точка установки датчика для снятия измерения (при снятии
          вибраций в одной точке, сигнал может диагностировать несколько элементов), включает в себя
          данные по элементам (в том числе данные по фильтрам), которые будут диагностироваться при
          снятии вибраций в данной точки.
        </Li>
        <Li>
          <b>Электронная структура станка</b> – это модель станка, включающая в себя данные по
          элементам станка.
        </Li>
        <Li>
          <b>Маршрут измерений</b> – это индивидуальный для каждого станка набор точек измерений,
          который в себе содержит информацию при каком режиме работы, при каком зацеплении валов
          между собой необходимо произвести измерения в каждой точке измерения и в какой
          последовательности.
        </Li>
        <Li>
          <b>Полосовой фильтр</b> <i>или</i> <b>фильтр</b> — фильтр, который пропускает
          составляющие, находящиеся в некоторой полосе частот.
        </Li>
        <Li>
          <b>Вал</b> – стержень, который вращается, на который могут крепиться шестерни, штифты,
          подшипники и ШВП.
        </Li>
        <Li>
          <b>Шарико-винтовая пара (ШВП)</b> – это линейный механический привод, преобразующий
          вращение в линейное перемещение и наоборот. Конструктивно она представляет собой длинный
          винт, по которому движется шариковая гайка. Внутри гайки между её внутренней резьбой и
          резьбой винта по спиралевидной траектории катятся шарики, затем попадая в возвратные
          каналы – внутренние или внешние.
          <a href="https://lmotion.ru/screwball.shtml#:~:text=Шарико%2Dвинтовые%20пары,по%20которому%20движется%20шариковая%20гайка">
            &nbsp;https://lmotion.ru/screwball.shtml#:~:text=Шарико%2Dвинтовые%20пары,по%20которому%20движется%20шариковая%20гайка
          </a>
        </Li>
        <Li>
          <b>Зубчатое колесо (ЗК)</b> – основная деталь зубчатой передачи в виде диска с зубьями на
          цилиндрической или конической поверхности, входящими в зацепление с зубьями другого
          зубчатого колеса.
        </Li>
        <Li>
          <b>Кинематическая связь узла</b> – при заданных скоростях вращения выходного вала
          двигателя, выбор задействованных валов и выбор зубчатых колёс на валах, которые вступают в
          зацеплении при диагностике.
        </Li>
        <Li>
          <b>Передаточное число</b> - один из параметров пары зацепления из двух зубчатых колёс,
          определяемый как соотношение числа зубьев большего зубчатого колеса к меньшему.
        </Li>
        <Li>
          <b>Шкив</b> <i>sheave</i> —колесо с жёлобом или ободом по окружности, которое передаёт
          движение приводному ремню или канату.
          <img src={shkiv} alt={NO_PICTURE('Шкив.')} height={400} style={{ padding: 40 }} />
          Рис. Ремённая передача
        </Li>
      </Ul>
      <br />

      <Typography variant="h6" color="inherit">
        <b>Аббревиатуры</b>
      </Typography>

      <Ul>
        <Li>
          <b>ЗП</b> – Зубчатая передача
        </Li>
        <Li>
          <b>ШВП</b> – Шарико-винтовая пара
        </Li>
        <Li>
          <b>ЗК</b> – Зубчатое колесо
        </Li>
      </Ul>
      <br />
      <br />
    </div>
  );
}
