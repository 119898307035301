export const findNearestIdInDOM = (event: MouseEvent) => {
  let id;
  let currentElem = event.target as Element;
  while (currentElem) {
    id = currentElem.id;
    if (id) {
      break;
    }
    currentElem = currentElem.parentElement as Element;
  }

  return id;
};
