import { IHttpMethods } from 'interfaces';
import { GET_USER_FULLNAME_URL } from 'services/urls';
import { IS_SERVER_MOCKED } from 'stream-constants';
import { delay } from 'utils/delay';

import { fetchData, probableError } from '../service-utils';

const BASE_TIME = 30;
const MIN_TIME = 20;

export const getUserFullName = async (): Promise<{ fullname: string; redirectUrl?: string }> => {
  const t0 = performance.now();
  let result;

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.00001);
    if (err) {
      result = Promise.reject(new Error(err));
    }

    result = {
      fullname: 'Моков Иван Иванович',
    };
  } else {
    result = fetchData({
      url: GET_USER_FULLNAME_URL,
      method: IHttpMethods.GET,
      name: 'getUserFullName',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getUserFullName took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
