import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ReportIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 19 26'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.85938 12.375C3.85938 11.8831 4.25812 11.4844 4.75 11.4844H14.25C14.7419 11.4844 15.1406 11.8831 15.1406 12.375C15.1406 12.8669 14.7419 13.2656 14.25 13.2656H4.75C4.25812 13.2656 3.85938 12.8669 3.85938 12.375Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.33521 20.0182C4.02283 19.6382 4.0776 19.077 4.45755 18.7646L6.77945 16.8556C7.74417 16.0624 9.1572 16.147 10.0205 17.0495C10.2461 17.2853 10.618 17.3001 10.8616 17.0828L13.2696 14.9353C13.6367 14.6079 14.1996 14.6401 14.527 15.0072C14.8544 15.3743 14.8222 15.9373 14.4551 16.2647L12.0472 18.4122C11.0874 19.2681 9.62219 19.21 8.7333 18.2807C8.51419 18.0517 8.15555 18.0302 7.91069 18.2315L5.58879 20.1405C5.20884 20.4529 4.6476 20.3981 4.33521 20.0182Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.85938 8.8125C3.85938 8.32062 4.25812 7.92188 4.75 7.92188H14.25C14.7419 7.92188 15.1406 8.32062 15.1406 8.8125C15.1406 9.30438 14.7419 9.70312 14.25 9.70312H4.75C4.25812 9.70312 3.85938 9.30438 3.85938 8.8125Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9909 5.25835C11.6688 4.7864 12.1125 4.00142 12.1125 3.11281C12.1125 3.04169 12.1096 2.97123 12.1041 2.90155C14.4118 2.98072 15.8491 3.29909 17.151 4.57936C18.8839 6.28337 18.9174 8.28509 18.9845 12.2885C18.9945 12.8848 19 13.5078 19 14.1564C19 14.8051 18.9945 15.4281 18.9845 16.0244C18.9174 20.0278 18.8839 22.0295 17.151 23.7335C15.4182 25.4376 13.4455 25.4376 9.5 25.4376C5.55452 25.4376 3.58178 25.4376 1.84895 23.7336C0.116133 22.0295 0.0825946 20.0278 0.0155186 16.0244C0.00552854 15.4282 0 14.8051 0 14.1564C0 13.5078 0.00552817 12.8848 0.0155177 12.2885C0.0825922 8.28509 0.116129 6.28337 1.84895 4.57936C3.15086 3.29909 4.58821 2.98072 6.8959 2.90155C6.89033 2.97123 6.88749 3.04169 6.88749 3.11281C6.88749 4.00142 7.33115 4.78641 8.00905 5.25835C5.04325 5.2972 4.30326 5.49679 3.5142 6.27272C2.60922 7.16267 2.4623 8.02377 2.39018 12.3283C2.38041 12.9115 2.375 13.5213 2.375 14.1564C2.375 14.7917 2.38041 15.4014 2.39019 15.9846C2.46231 20.2891 2.60922 21.1502 3.51421 22.0402C4.4215 22.9324 5.26394 23.0626 9.5 23.0626C13.7361 23.0626 14.5785 22.9324 15.4858 22.0402C16.3908 21.1502 16.5377 20.2891 16.6098 15.9846C16.6196 15.4014 16.625 14.7916 16.625 14.1564C16.625 13.5213 16.6196 12.9115 16.6098 12.3283C16.5377 8.02377 16.3908 7.16267 15.4858 6.27272C14.6967 5.49679 13.9567 5.2972 10.9909 5.25835Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3313 3.1125C10.3313 3.57159 9.9591 3.94375 9.50001 3.94375C9.04093 3.94375 8.66876 3.57159 8.66876 3.1125C8.66876 2.65341 9.04093 2.28125 9.50001 2.28125C9.9591 2.28125 10.3313 2.65341 10.3313 3.1125ZM7.99755 5.25H11.0025C11.6739 4.7772 12.1125 3.99608 12.1125 3.1125C12.1125 1.66966 10.9429 0.5 9.50001 0.5C8.05717 0.5 6.88751 1.66966 6.88751 3.1125C6.88751 3.99608 7.32615 4.7772 7.99755 5.25Z"
        fill="black"
      />
    </SvgIcon>
  );
}
