import { IErrorMessagePayload, INotificationMessage } from 'interfaces';

const EMPTY = '---';

export function errorMessageConvert(err: Error, optionalHeader?: string): INotificationMessage {
  const message = err.message;
  try {
    const payload: IErrorMessagePayload = JSON.parse(message);
    const arr = message.split('\n');
    const { url, name, status, method, body, response } = payload;
    console.log('%c { url, name, status, method, body, response} = ', 'color: #bada55', {
      url,
      name,
      status,
      method,
      body,
      response,
    }); //TODO - delete vvtu

    let fullMessage = message;
    let header: string = 'unknown';

    switch (status) {
      case 500:
        header = 'Что-то пошло не так';
        break;
      case 403:
        header = 'У вас нет прав на доступ к приложению';
        break;
      case 401:
        header = 'Вы не авторизованы'; // эта ошибка показываеться не будет, она перехватывается раньше
        break;
      case 404:
        header = 'Запрашиваемые данные не существуют';
        break;
      case 400:
      case 406:
      case 412:
        header = `${response.detail ?? EMPTY}`;
        break;
      case 422:
        if (Array.isArray(response.detail)) {
          header = 'Ошибки валидации:';
          for (const item of response.detail) {
            header += `\n${JSON.stringify(item.loc)}: ${item?.msg},`;
          }
        } else {
          header = `Валидация: ${JSON.stringify(response.detail)}`;
        }

        break;

      default:
        header = arr[0];
    }
    header = `${header} (${status} ${method})`;
    if (optionalHeader) {
      header = `${header} "${optionalHeader}"`;
    }

    return {
      header,
      fullMessage,
      fromServer: status ? true : undefined,
    };
  } catch {
    return {
      header: message,
      fullMessage: '',
    };
  }
}
