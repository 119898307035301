import { FC, useEffect, createRef, useRef, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { FixedSizeGrid } from 'react-window';

import { NewSearchIcon } from 'icons/new-search-icon';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { IMachine, IQueryParamsFilter, ISortParamsOrder } from 'interfaces';
import { Card } from 'pages/machine-pages/components/card';
import {
  MACHINE_COLUMN_COUNT,
  MACHINE_COLUMN_WIDTH,
  MACHINE_ROW_HEIGHT,
  HANDLE_ON_SCROLL_TIMEOUT,
  MACHINES_KEY,
  CREATE_NEW,
} from 'stream-constants';

import { useGetMachines } from './hooks';
import { MachinesFilters } from './machines-filters';
import styles from './styles.module.css';

const PAGE_SIZE = MACHINE_COLUMN_COUNT * 6;
const FAKE_TOTAL_NUMBER = 1000000;

type ICellProps = {
  columnIndex: number;
  rowIndex: number;
  style: any;
};

const offsetCalc = ({
  rowIndex,
  columnIndex,
  columnCount,
}: {
  rowIndex: number;
  columnIndex: number;
  columnCount: number;
}) => {
  const pos = rowIndex * columnCount + columnIndex;
  const offIndex = pos % PAGE_SIZE;
  const offset = pos - offIndex;

  return { offset, offIndex };
};

interface IParams {
  scrollTop?: number;
  searchString?: string;
  filters?: IQueryParamsFilter[];
  order?: string;
}
interface IProps {
  layoutHeight: number;
  layoutWidth: number;
  onCardClick: (machine: IMachine) => void;
  params: IParams;
  setParams: (params: IParams) => void;
  isModal: boolean;
}
export const MachineCards: FC<IProps> = ({
  layoutHeight,
  layoutWidth,
  onCardClick,
  params,
  setParams,
  isModal,
}) => {
  const history = useHistory();
  const [itemsTotal, setItemsTotal] = useState<number>(FAKE_TOTAL_NUMBER);
  const columnCount = Math.max(2, Math.floor((layoutWidth - 30) / MACHINE_COLUMN_WIDTH));

  const gridRef = createRef<FixedSizeGrid<any>>();
  const gridScrollDebouncingRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  useEffect(() => {
    return () => {
      if (gridScrollDebouncingRef.current) {
        clearTimeout(gridScrollDebouncingRef.current);
      }
    };
  }, []);

  const handleSearchStringSubmit = (searchString: string): void => {
    setParams({ searchString, scrollTop: 0 });
    setItemsTotal(FAKE_TOTAL_NUMBER);
    gridRef.current?.scrollTo({ scrollTop: 0, scrollLeft: 0 });
  };
  const handleFiltersChange = (filters: any[]): void => {
    setParams({ filters, scrollTop: 0 });
    setItemsTotal(FAKE_TOTAL_NUMBER);
    gridRef.current?.scrollTo({ scrollTop: 0, scrollLeft: 0 });
  };
  const handleClearFilters = (): void => {
    setParams({ searchString: '', filters: [], scrollTop: 0 });
    setItemsTotal(FAKE_TOTAL_NUMBER);
    gridRef.current?.scrollTo({ scrollTop: 0, scrollLeft: 0 });
  };

  const Cell = ({ columnIndex, rowIndex, style }: ICellProps) => {
    const { offset, offIndex } = offsetCalc({ rowIndex, columnIndex, columnCount });
    const queryCells = useGetMachines({
      offset,
      pageSize: PAGE_SIZE,
      searchString: params.searchString ?? '',
      order:
        params.order === undefined || params.order === ISortParamsOrder.DESC
          ? ISortParamsOrder.DESC
          : ISortParamsOrder.ASC,
      filters: params.filters ?? [],
    });

    const cards = queryCells.data?.data ?? [];
    const total = queryCells.data?.total ?? itemsTotal;

    useEffect(() => {
      setItemsTotal(total);
    }, [total]);

    return (
      <div style={style}>
        <Card
          item={cards[offIndex]}
          onClick={(item: any) => onCardClick(item)}
          id={`id_machine_card_${columnIndex}_${rowIndex}`}
        />
      </div>
    );
  };

  const handleScrollChange = (scl: { scrollTop: any }) => {
    if (gridScrollDebouncingRef.current) {
      clearTimeout(gridScrollDebouncingRef.current);
    }
    gridScrollDebouncingRef.current = setTimeout(() => {
      setParams({ scrollTop: Math.floor(scl.scrollTop) });
      gridScrollDebouncingRef.current = undefined;
    }, HANDLE_ON_SCROLL_TIMEOUT);
  };
  const rowCount = Math.ceil((itemsTotal ?? 0) / columnCount);

  return (
    <div className={styles.center}>
      <div>
        <div className={styles.mainRow}>
          <div className={styles.machineCardsTitle}>Станки</div>
          {isModal === false && (
            <Tooltip title="Добавить станок">
              <span>
                <Button
                  classes={{ label: styles.addButton }}
                  size="small"
                  type="button"
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={() => history.push(`/${MACHINES_KEY}/${CREATE_NEW}`)}
                >
                  Добавить станок
                </Button>
              </span>
            </Tooltip>
          )}
        </div>
        <MachinesFilters
          handleSearchStringSubmit={handleSearchStringSubmit}
          handleFiltersChange={handleFiltersChange}
          setSortOrderAsc={(p) => setParams({ order: p })}
          handleClearFilters={handleClearFilters}
          params={params}
          layoutWidth={layoutWidth}
        />

        <div className={styles.grid}>
          {itemsTotal === 0 ? (
            <div
              style={{
                height: layoutHeight,
                boxSizing: 'border-box',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <div className={styles.searchIconContainer}>
                <div className={styles.searchIcon}>
                  <NewSearchIcon style={{ fontSize: 18 }} />
                </div>
                <div className={styles.searchIconText}>Поиск не дал результатов</div>
              </div>
            </div>
          ) : (
            <FixedSizeGrid
              initialScrollTop={params.scrollTop}
              columnCount={columnCount}
              columnWidth={MACHINE_COLUMN_WIDTH}
              height={layoutHeight}
              rowCount={rowCount}
              rowHeight={MACHINE_ROW_HEIGHT}
              width={MACHINE_COLUMN_WIDTH * columnCount + 20}
              ref={gridRef}
              onScroll={handleScrollChange}
            >
              {Cell}
            </FixedSizeGrid>
          )}
        </div>
      </div>
    </div>
  );
};
