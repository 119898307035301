import { IHttpMethods } from 'interfaces';
import { GET_SIGNAL_TO_CUT_URL, UPDATE_SIGNALS_URL } from 'services/urls';
import { IS_SERVER_MOCKED } from 'stream-constants';
import { delay } from 'utils/delay';

import { fetchData, probableError } from '../service-utils';

import silicingEditorSignalToCut from './mock/silicing-editor-signal-to-cut.json';

const BASE_TIME = 300;
const MIN_TIME = 200;

export const getSignalToCutByCsvId = async (csvId: string): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!csvId) {
    result = Promise.reject(new Error('Не задан csvId'));

    return result;
  }
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(silicingEditorSignalToCut);
    }
  } else {
    result = fetchData({
      url: GET_SIGNAL_TO_CUT_URL(csvId),
      method: IHttpMethods.GET,
      name: 'getSignalToCutByCsvId',
      emptyResponse: false,
      timeoutToAbort: 10 * 60 * 1000,
    });
  }

  const t1 = performance.now();
  console.log(`getSignalToCutByCsvId took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const updateSignalFileByCsvId = async (
  csvId: string,
  cutIntervals: number[][],
): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!csvId) {
    result = Promise.reject(new Error('Не задан csvId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(true);
    }
  } else {
    await fetchData({
      url: UPDATE_SIGNALS_URL(csvId),
      method: IHttpMethods.POST,
      body: JSON.stringify(cutIntervals),
      name: 'updateSignalFileByCsvId',
      emptyResponse: true,
      timeoutToAbort: 10 * 60 * 1000,
    });
    result = Promise.resolve(true);
  }

  const t1 = performance.now();
  console.log(`updateSignalFileByCsvId took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
