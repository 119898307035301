import { UseFormReturn } from 'react-hook-form';

import Grid from '@material-ui/core/Grid';
import FieldInputText from 'components/fields/field-input-text';
import { IDiagnostic } from 'interfaces';

export function DiagnosticBaseParamsForm({
  data,
  form,
}: {
  data: IDiagnostic | undefined;
  form: UseFormReturn<IDiagnostic>;
}) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FieldInputText<IDiagnostic>
            id="id_input_diagnostic_name"
            placeholder="Введите"
            fieldName="description"
            label="Название диагностики"
            form={form}
            defaultValue={data?.description || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInputText<IDiagnostic>
            id="id_input_machine_inventory_number"
            placeholder="Введите"
            fieldName="machineInventoryNumber"
            label="Инвентарный номер"
            form={form}
            defaultValue={data?.machineInventoryNumber || ''}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FieldInputText<IDiagnostic>
            id="id_input_factory_town"
            placeholder="Введите"
            fieldName="factoryTown"
            label="Город"
            form={form}
            defaultValue={data?.factoryTown || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInputText<IDiagnostic>
            id="id_input_factory_name"
            placeholder="Введите"
            fieldName="factoryName"
            label="Предприятие"
            form={form}
            defaultValue={data?.factoryName || ''}
          />
        </Grid>
      </Grid>
    </>
  );
}
