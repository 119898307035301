import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function WormGearIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 32 32">
      <polygon fill="none" points="23.786,3.213 22.617,6.029 23.913,6.029 25.04,3.213 " />
      <polygon fill="none" points="30.402,3.213 26.263,3.213 25.136,6.029 30.402,6.029 " />
      <polygon fill="none" points="25.04,3.213 23.913,6.029 25.136,6.029 26.263,3.213 " />
      <polygon fill="none" points="22.559,3.213 21.389,6.029 22.617,6.029 23.786,3.213 " />
      <path
        d="M16,6.294c-6.38,0-11.55,5.171-11.55,11.55c0,6.38,5.17,11.553,11.55,11.553s11.551-5.173,11.551-11.553
	C27.551,11.465,22.38,6.294,16,6.294z M16.088,19.578c-0.908,0-1.646-0.736-1.646-1.646c0-0.907,0.738-1.645,1.646-1.645
	c0.91,0,1.646,0.738,1.646,1.645C17.734,18.842,16.998,19.578,16.088,19.578z"
      />
      <polygon points="30.664,2.995 26.35,2.995 25.058,6.224 30.664,6.224 " />
      <polygon points="23.876,2.995 22.536,6.224 23.835,6.224 25.128,2.995 " />
      <polygon fill="none" points="25.128,2.995 23.835,6.224 25.058,6.224 26.35,2.995 " />
      <polygon points="1.167,2.995 1.167,6.224 21.403,6.224 22.648,2.995 " />
      <polygon fill="none" points="22.648,2.995 21.307,6.224 22.536,6.224 23.876,2.995 " />
      <polygon points="24.039,2.603 23.786,3.213 25.04,3.213 25.285,2.603 " />
      <polygon
        points="31.131,2.603 26.506,2.603 26.263,3.213 30.402,3.213 30.402,6.029 25.136,6.029 24.895,6.631
	31.131,6.631 "
      />
      <polygon points="22.617,6.029 22.366,6.631 23.671,6.631 23.913,6.029 " />
      <polygon points="23.913,6.029 23.671,6.631 24.895,6.631 25.136,6.029 " />
      <polygon points="26.506,2.603 25.285,2.603 25.04,3.213 26.263,3.213 " />
      <polygon
        points="1.26,6.029 1.26,3.213 22.575,3.213 22.812,2.603 0.643,2.603 0.643,6.631 21.249,6.631
	21.482,6.029 "
      />
      <polygon points="21.389,6.029 21.138,6.631 22.366,6.631 22.617,6.029 " />
      <polygon points="24.039,2.603 22.812,2.603 22.559,3.213 23.786,3.213 " />
    </SvgIcon>
  );
}
