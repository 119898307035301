import { useMemo, useContext } from 'react';

import { ContextSettings } from 'context/context-settings';

import {
  ICoordinate,
  ITimeScaleZoomParams,
  PADDING_LEFT,
  PADDING_RIGHT,
  PADDING_TOP,
  PADDING_BOTTOM,
  SCALE_STANDART_STEPS,
  timeScaleOptimalStepsNumber,
  amplitudeOptimalStepsNumber,
} from './slicing-editor-common';

type IArgs = {
  amplitudes: number[] | null | undefined;
  duration: number | null | undefined;
  canvasSize: {
    height: number;
    width: number;
  };
  timeScaleZoomParams: ITimeScaleZoomParams;
  cutIntervals: number[][];
};

export function usePrepareEditorParams({
  amplitudes,
  duration,
  canvasSize,
  timeScaleZoomParams,
  cutIntervals,
}: IArgs) {
  const { setNotificationMessage } = useContext(ContextSettings);

  const obj1 = useMemo(() => {
    // setNotificationMessage({ fullMessage: '', header: message });

    if (!Array.isArray(amplitudes)) {
      return undefined;
    }

    if (!duration) {
      setNotificationMessage({
        fullMessage: '',
        header: 'Не задана продолжительность по времени (duration) для массива амплитуд',
      });

      return undefined;
    }
    if (amplitudes.length === 0) {
      setNotificationMessage({
        fullMessage: '',
        header: 'Массив амплитуд оказался пустым',
      });

      return undefined;
    }

    // ********* определение максимального модуля амплитуды *******************************************************************
    let amplitudeMax000 = 0;
    for (const amplitude of amplitudes) {
      amplitudeMax000 = Math.max(amplitudeMax000, Math.abs(amplitude));
    }
    if (amplitudeMax000 === 0) {
      amplitudeMax000 = 1;
    }
    const oneTic = duration / amplitudes.length;
    const newAmplitudes000 = amplitudes.map((amplitude, index) => ({
      amplitude: amplitude / amplitudeMax000,
      rawTime: index * oneTic,
    }));
    amplitudeMax000 = 1.05;

    let newDuration = duration;
    let accumulatedShift = 0;

    for (const cutInterval of cutIntervals) {
      const index1 = Math.trunc(
        ((cutInterval[0] - accumulatedShift) / newDuration) * newAmplitudes000.length,
      );
      const index2 = Math.trunc(
        ((cutInterval[1] - accumulatedShift) / newDuration) * newAmplitudes000.length,
      );
      newAmplitudes000.splice(index1, index2 - index1);
      const shift = cutInterval[1] - cutInterval[0];
      accumulatedShift += shift;
      newDuration -= shift;
    }

    return { amplitudeAbsMax: amplitudeMax000, newAmplitudes: newAmplitudes000, newDuration };
  }, [amplitudes, cutIntervals, duration, setNotificationMessage]);

  const obj2 = useMemo(() => {
    if (obj1 === undefined) {
      return undefined;
    }
    const { amplitudeAbsMax, newAmplitudes, newDuration } = obj1;

    const { height, width } = canvasSize;
    if (!height || !width) {
      setNotificationMessage({
        fullMessage: '',
        header: 'Не задан размер canvas для графика амплитуд',
      });

      return undefined;
    }

    // ***  применение параметров зума **********************************************************
    let timeScaleMin = 0;
    let timeScaleMax = newDuration;
    if (timeScaleZoomParams.zoom > 1) {
      const { zoom, weight, center } = timeScaleZoomParams;
      const chartWindow = newDuration / zoom;
      const newTimeScaleMin = center - chartWindow * weight;
      const newTimeScaleMax = newTimeScaleMin + chartWindow;
      timeScaleMin = newTimeScaleMin;
      timeScaleMax = newTimeScaleMax;
    }

    const xScale1 = (width - PADDING_LEFT - PADDING_RIGHT) / (timeScaleMax - timeScaleMin);
    const yScale1 = (((height - PADDING_TOP - PADDING_BOTTOM) / amplitudeAbsMax) * 0.9) / 2;

    const toPixels = ({ x, y }: ICoordinate) => ({
      x: (x - timeScaleMin) * xScale1 + PADDING_LEFT + 6,
      y: height / 2 - y * yScale1 - PADDING_BOTTOM,
    });

    const timeScaleFromXPixel = (x: number) => (x - PADDING_LEFT) / xScale1 + timeScaleMin;

    // ***  amplitudeScaleStep определение шага по амплитуде  **********************

    let minAmplitudeIndex = 0;
    let minAmplitudeValue = Number.POSITIVE_INFINITY;
    SCALE_STANDART_STEPS.forEach((step, index) => {
      const value = Math.abs(Math.floor(amplitudeAbsMax / 2 / step) - amplitudeOptimalStepsNumber);
      if (minAmplitudeValue >= value) {
        minAmplitudeValue = value;
        minAmplitudeIndex = index;
      }
    });
    const amplitudeScaleStep = SCALE_STANDART_STEPS[minAmplitudeIndex];

    // ***  timeScaleSteps *******************************
    let minTimeScaleIndex = 0;
    let minTimeScaleValue = Number.POSITIVE_INFINITY;
    SCALE_STANDART_STEPS.forEach((step, index) => {
      const value = Math.abs(
        Math.floor((timeScaleMax - timeScaleMin) / step) - timeScaleOptimalStepsNumber,
      );
      if (minTimeScaleValue >= value) {
        minTimeScaleValue = value;
        minTimeScaleIndex = index;
      }
    });
    const timeScaleScaleStep = SCALE_STANDART_STEPS[minTimeScaleIndex];

    return {
      toPixels,
      timeScaleFromXPixel,
      timeScaleMin,
      timeScaleMax,
      timeScaleScaleStep,
      amplitudeScaleStep,
      amplitudeAbsMax,
      duration: newDuration,
      newAmplitudes,
    };
  }, [canvasSize, obj1, setNotificationMessage, timeScaleZoomParams]);

  return obj2;
}
