import { useEffect, useRef, useState } from 'react';

import { useIsFetching, useIsMutating } from 'react-query';

import CircularProgress from '@material-ui/core/CircularProgress';
import { SERVER_RESPONSE_SPINNER_TIME_OUT } from 'stream-constants';

import styles from './styles.module.css';

const NOOP = () => undefined;

export function GlobalSpinner() {
  const timeoutId = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [shouldRotating, setShouldRotating] = useState<boolean>(false);
  const isMutating = useIsMutating({ exact: true });
  const isFetching = useIsFetching();

  const isMutatingOrFetching = isMutating + isFetching;

  useEffect(() => {
    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
      timeoutId.current = null;
    }
    if (isMutatingOrFetching > 0) {
      timeoutId.current = setTimeout(() => {
        setShouldRotating(true);
        timeoutId.current = null;
      }, SERVER_RESPONSE_SPINNER_TIME_OUT);
    } else {
      setShouldRotating(false);
    }

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    };
  }, [isMutatingOrFetching, setShouldRotating]);

  if (!shouldRotating) {
    return null;
  }

  return (
    <>
      <div className={styles.layout}>
        <div className={styles.center}>
          <CircularProgress color="primary" size={100} />
        </div>
      </div>
      <div className={styles.fullScreenDiv} onClick={NOOP} />
    </>
  );
}
