import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ManagerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 20 22'}>
      <path
        d="M9.83331 15.6667C9.83331 16.311 10.3556 16.8333 11 16.8333C11.6443 16.8333 12.1666 16.311 12.1666 15.6667L12.1666 9.11089L13.6088 10.8178C14.0247 11.3099 14.7608 11.3718 15.2529 10.956C15.7451 10.5401 15.807 9.80404 15.3911 9.31186L12.8694 6.07006C12.4261 5.50023 11.7441 5.16767 11.0221 5.16938L11 5.16943L10.9778 5.16938C10.2559 5.16767 9.57386 5.50022 9.1306 6.07004L6.60881 9.31186C6.19297 9.80404 6.25484 10.5401 6.74702 10.956C7.23919 11.3718 7.97529 11.3099 8.39114 10.8178L9.83331 9.11089L9.83331 15.6667Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4054 13.4234C21.1783 16.4754 21.0647 18.0014 19.533 19.533C18.0014 21.0647 16.4754 21.1783 13.4234 21.4054C12.6322 21.4643 11.8161 21.5 11 21.5C10.1839 21.5 9.36778 21.4643 8.57663 21.4054C5.52464 21.1783 3.99865 21.0647 2.46696 19.533C0.935278 18.0014 0.821706 16.4754 0.594562 13.4234C0.53568 12.6322 0.499999 11.8161 0.499999 11C0.499999 10.1839 0.53568 9.36778 0.594561 8.57663C0.821705 5.52464 0.935276 3.99865 2.46696 2.46697C3.99865 0.935279 5.52464 0.821708 8.57662 0.594564C9.36778 0.535682 10.1839 0.500001 11 0.500001C11.8161 0.500001 12.6322 0.535682 13.4234 0.594563C16.4754 0.821707 18.0013 0.935278 19.533 2.46696C21.0647 3.99865 21.1783 5.52464 21.4054 8.57663C21.4643 9.36778 21.5 10.1839 21.5 11C21.5 11.8161 21.4643 12.6322 21.4054 13.4234ZM13.2502 19.0785C12.5068 19.1339 11.7493 19.1667 11 19.1667C10.2507 19.1667 9.49315 19.1339 8.74981 19.0785C5.54105 18.8397 4.94078 18.707 4.11688 17.8831C3.29298 17.0592 3.16027 16.4589 2.92146 13.2502C2.86614 12.5069 2.83333 11.7493 2.83333 11C2.83333 10.2508 2.86614 9.49315 2.92146 8.74981C3.16027 5.54106 3.29298 4.94078 4.11688 4.11688C4.94078 3.29298 5.54105 3.16027 8.7498 2.92146C9.49315 2.86614 10.2507 2.83333 11 2.83333C11.7492 2.83333 12.5068 2.86614 13.2502 2.92146C16.4589 3.16027 17.0592 3.29298 17.8831 4.11688C18.707 4.94078 18.8397 5.54105 19.0785 8.74981C19.1339 9.49315 19.1667 10.2507 19.1667 11C19.1667 11.7493 19.1339 12.5068 19.0785 13.2502C18.8397 16.4589 18.707 17.0592 17.8831 17.8831C17.0592 18.707 16.4589 18.8397 13.2502 19.0785Z"
        fill="black"
      />
    </SvgIcon>
  );
}
