import { useEffect } from 'react';

import { IS_MODE_EQ_DEVELOPMENT } from 'stream-constants';

export function InsertMatomoScriptForStatisticInfo() {
  useEffect(() => {
    if (IS_MODE_EQ_DEVELOPMENT) {
      return;
    }
    //@ts-ignore
    var _paq = (window._paq = window._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = 'https://matomo.obs.mts.ru/';
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', process.env.REACT_APP_DIAGNOSTIC ? '719' : '718']);
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = u + 'matomo.js';
      s?.parentNode?.insertBefore(g, s);
    })();
  }, []);

  return <div />;
}
