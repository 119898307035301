import UnitIcon from 'icons/unit-icon';

import styles from '../styles.module.css';

import { NoCommnetsIcon } from './no-commnets-icon';

export function MachineComments({
  comments,
}: {
  comments:
    | {
        id: string;
        name: string;
        comment: string;
      }[]
    | undefined;
}) {
  return (
    <>
      <div>
        {comments?.map((item) => (
          <div key={item.id} className={styles.commentContainer}>
            <div className={styles.commentHeader}>
              <UnitIcon />
              <span>{item.name}</span>
            </div>
            <div className={styles.commentBody}>{item.comment}</div>
          </div>
        ))}
        {(comments?.length ?? 0) === 0 && (
          <div className={styles.commentsEmptyIconContainer}>
            <div className={styles.commentsEmptyIconSubContainer}>
              <div className={styles.commentsEmptyIcon}>
                <NoCommnetsIcon style={{ fontSize: 20 }} />
              </div>
              <div className={styles.commentsEmptyIconMessage}>Комментариев нет</div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
