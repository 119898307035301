export interface ICoordinate {
  x: number;
  y: number;
}

export interface ITimeScaleZoomParams {
  center: number;
  zoom: number;
  weight: number;
}

export const ZOOM_STEP_WHEEL = 1.06;
export const PADDING_LEFT = 4;
export const PADDING_RIGHT = 4;
export const PADDING_TOP = 50;
export const PADDING_BOTTOM = 0;

export const CANVAS_HEIGHT = 400;
export const CANVAS_HEADER_BAR_HEIGHT = 10;

export const PINK_COLOR = 'rgb(250,222,222)'; //'#eee'; //
export const BLUE_COLOR = '#0097FD';
export const GREY_COLOR = '#fff';
export const BLACK_COLOR = '#676';

export const SCALE_STANDART_STEPS = [0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 50, 100, 200, 500];
export const timeScaleOptimalStepsNumber = 20;
export const amplitudeOptimalStepsNumber = 2;
