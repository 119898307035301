import { useContext } from 'react';

import { useQuery, useMutation, useQueryClient } from 'react-query';

import { ContextSettings } from 'context/context-settings';
import { IBearingCalculatorParms } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import {
  IDefect,
  ISummaryElement,
  IDefectDictItem,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import { getDiagnosticsPredict } from 'services/diagnostic-predict';
import {
  getSpectrumAndPeaks,
  getPeaksAndParamsForCalculatorPage,
  getGetNewFreqForCalculatorPage,
  getDefectsDict,
  getAllDefectsByElement,
  createSpectrumDefect,
  deleteSpectrumDefect,
  updateSpectrumDefect,
  getDefectFrequenciesByDefectId,
  getSpectrumElementsList,
  getSpectrumAllSummaryList,
  getSpectrumElementSummary,
  updateSpectrumDefectSummary,
  updateSubelementStatus,
  getFrequencyPortraitsColors,
  getFrequencyPortraits,
  getFrequencyGroupsColorsDict,
} from 'services/diagnostic-spectrum';
import {
  DIAGNOSTICS_SPECTRUM_DEFECTS_DICT,
  DIAGNOSTICS_SPECTRUM_DEFECTS,
  DIAGNOSTICS_SPECTRUM_DEFECT_FREQUENCIES,
  DIAGNOSTICS_SPECTRUM_ELEMENT_LIST,
  DIAGNOSTICS_SPECTRUM_ELEMENT_SPECTRUM,
  DIAGNOSTICS_PEAKS_AND_PARAMS_FOR_CALCULATOR_PAGE,
  DIAGNOSTICS_NEW_FREQ_FOR_CALCULATOR_PAGE,
  DIAGNOSTICS_SPECTRUM_ALL_SUMMARY_LIST,
  DIAGNOSTICS_SPECTRUM_SUMMARY_ITEM,
  DIAGNOSTIC_GET_FREQUENCY_PORTRAITS_COLORS,
  DIAGNOSTIC_GET_FREQUENCY_PORTRAITS,
  DIAGNOSTICS_FREQUENCY_GROUPS_COLORS_DICT,
  DIAGNOSTICS_KEY,
  DIAGNOSTICS_BY_ID_KEY,
  GET_DIAGNOSTICS_PREDICT_KEY,
  DIAGNOSTIC_MEASURE_UPLOAD,
} from 'stream-constants';
import { errorMessageConvert } from 'utils/error-message-convert';
import { midlewareWrapper } from 'utils/midleware-wrapper';

const BIG_STALE_TIME = 1000000000000;

export function useGetSpectrumAndPeaks(diagnosticId: string, elementId: string) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const query = useQuery(
    [DIAGNOSTICS_SPECTRUM_ELEMENT_SPECTRUM, diagnosticId, elementId],
    () => getSpectrumAndPeaks(diagnosticId, elementId),
    {
      staleTime: BIG_STALE_TIME,
      enabled: !notificationMessage,
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}

export function useGetPeaksAndParamsForCalculatorPage(elementId: string) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const query = useQuery(
    [DIAGNOSTICS_PEAKS_AND_PARAMS_FOR_CALCULATOR_PAGE, elementId],
    () => getPeaksAndParamsForCalculatorPage(elementId),
    {
      staleTime: BIG_STALE_TIME,
      enabled: !notificationMessage,
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}
export function useGetDiagnosticsGetNewFreqForCalculatorPage(
  elementId: string,
  params: IBearingCalculatorParms,
) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const {
    speed,
    innerDiameter,
    outerDiameter,
    rollingElementsDiameter,
    rollingElementsNumber,
    contactAngle,
  } = params ?? {};

  const keyArr = [
    DIAGNOSTICS_NEW_FREQ_FOR_CALCULATOR_PAGE,
    elementId,
    speed,
    innerDiameter,
    outerDiameter,
    rollingElementsDiameter,
    rollingElementsNumber,
    contactAngle,
  ];

  const query = useQuery(keyArr, () => getGetNewFreqForCalculatorPage({ elementId, params }), {
    staleTime: BIG_STALE_TIME,
    enabled: !notificationMessage && !!params,
    onError: (error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return query;
}

export function useGetAllDefectsByElement(diagnosticId: string, elementGuideId: string) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);

  const query = useQuery(
    [DIAGNOSTICS_SPECTRUM_DEFECTS, diagnosticId, elementGuideId],
    () => getAllDefectsByElement(diagnosticId, elementGuideId),
    {
      staleTime: BIG_STALE_TIME,
      enabled: !notificationMessage,
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}

export function useGetDefectFrequenciesByDefectId(defectId: string | undefined) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);

  const query = useQuery(
    [DIAGNOSTICS_SPECTRUM_DEFECT_FREQUENCIES, defectId],
    () => getDefectFrequenciesByDefectId(defectId),
    {
      staleTime: BIG_STALE_TIME,
      enabled: !notificationMessage,
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}

export function useMutationCreateSpectrumDefect(handleOnSuccess: (a: any) => void) {
  const queryClient = useQueryClient();
  const { setNotificationMessage } = useContext(ContextSettings);

  const mutationCreate = useMutation(
    (args: {
      diagnosticId: string;
      elementGuideId: string;
      freqElementId: string;
      defect: IDefect;
      envelopDataList: string[];
      spectrumDataList: string[];
    }) => createSpectrumDefect(args),
    {
      onSuccess: async (createdData: any) => {
        await Promise.all([
          queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_DEFECTS]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_DEFECT_FREQUENCIES]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_ELEMENT_LIST]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_ALL_SUMMARY_LIST]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_SUMMARY_ITEM]),
          queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD]),
        ]);

        handleOnSuccess(createdData);
      },
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return mutationCreate;
}
export function useMutationUpdateSpectrumDefect(handleOnSuccess: (a: any) => void) {
  const queryClient = useQueryClient();
  const { setNotificationMessage } = useContext(ContextSettings);
  const mutationUpdate = useMutation(
    (args: {
      diagnosticId: string;
      elementGuideId: string;
      freqElementId: string;
      defect: IDefect;
      envelopDataList: string[];
      spectrumDataList: string[];
    }) => updateSpectrumDefect(args),
    {
      onSuccess: async (createdData: any) => {
        await Promise.all([
          queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_DEFECTS]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_DEFECT_FREQUENCIES]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_ELEMENT_LIST]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_ALL_SUMMARY_LIST]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_SUMMARY_ITEM]),
          queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD]),
        ]);
        handleOnSuccess(createdData);
      },
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return mutationUpdate;
}

export function useMutationDeleteSpectrumDefect(handleOnSuccess: (a: any) => void) {
  const { setNotificationMessage } = useContext(ContextSettings);
  const queryClient = useQueryClient();
  const mutationCreate = useMutation(
    ({ defectId }: { defectId: string }) => deleteSpectrumDefect(defectId),
    {
      onSuccess: async (data: any) => {
        await Promise.all([
          queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_DEFECTS]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_DEFECT_FREQUENCIES]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_ELEMENT_LIST]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_ALL_SUMMARY_LIST]),
          queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_SUMMARY_ITEM]),
          queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD]),
        ]);

        handleOnSuccess(data);
      },
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return mutationCreate;
}

export function useGetElementsList(diagnosticId: string) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);

  const query = useQuery(
    [DIAGNOSTICS_SPECTRUM_ELEMENT_LIST, diagnosticId],
    () => getSpectrumElementsList(diagnosticId),
    {
      staleTime: BIG_STALE_TIME,
      enabled: !notificationMessage,
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}

export function useGetSpectrumAllSummaryList(diagnosticId: string) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);

  const query = useQuery(
    [DIAGNOSTICS_SPECTRUM_ALL_SUMMARY_LIST, diagnosticId],
    () => getSpectrumAllSummaryList(diagnosticId),
    {
      staleTime: BIG_STALE_TIME,
      enabled: !notificationMessage,
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}
export function useGetSpectrumElementSummary(diagnosticId: string, guideParentId: string) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);

  const query = useQuery(
    [DIAGNOSTICS_SPECTRUM_ALL_SUMMARY_LIST, diagnosticId, guideParentId],
    () => getSpectrumElementSummary(diagnosticId, guideParentId),
    {
      enabled: !notificationMessage,
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}
const EMPTY_ARRAY = Object.freeze([]);

function makeDefectsDictMidleware(data: { defects_dict: IDefectDictItem[] } | undefined) {
  const defectsDictArray = data?.defects_dict ?? EMPTY_ARRAY;
  const defectsDict: Record<string, string> = {};
  let isEmpty = true;
  for (const d of defectsDictArray) {
    defectsDict[d.labelId] = d.label;
    isEmpty = false;
  }

  return { defectsDictArray, defectsDict: isEmpty ? undefined : defectsDict };
}

export function useGetDefectsDict() {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);

  const query = useQuery(
    [DIAGNOSTICS_SPECTRUM_DEFECTS_DICT],
    () => midlewareWrapper(getDefectsDict, makeDefectsDictMidleware),
    {
      staleTime: BIG_STALE_TIME,
      enabled: !notificationMessage,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}

export function useGetFrequencyGroupsColorsDict() {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);

  const query = useQuery([DIAGNOSTICS_FREQUENCY_GROUPS_COLORS_DICT], getFrequencyGroupsColorsDict, {
    staleTime: BIG_STALE_TIME,
    enabled: !notificationMessage,
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return query;
}

export function useMutationUpdateSpectrumDefectSummary(
  args: {
    diagnosticId: string;
    element: ISummaryElement | undefined;
    summaryText: string;
  },
  handleOnSuccess: (a: any) => void,
) {
  const { setNotificationMessage } = useContext(ContextSettings);
  const queryClient = useQueryClient();
  const mutationUpdate = useMutation(() => updateSpectrumDefectSummary(args), {
    onSuccess: async (createdData: any) => {
      await Promise.all([
        queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
        queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY]),
        queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_ALL_SUMMARY_LIST, args.diagnosticId]),
        queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_DEFECTS]),
        queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_DEFECT_FREQUENCIES]),
        queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_ELEMENT_LIST]),
        queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD, args.diagnosticId]),
      ]);

      handleOnSuccess(createdData);
    },
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return mutationUpdate;
}

export function useMutationUpdateSubelementStatus({
  diagnosticId,
  subElementId,
  status,
  handleOnSuccess,
}: {
  diagnosticId: string;
  subElementId: string;
  status: boolean;
  handleOnSuccess: () => void;
}) {
  const { setNotificationMessage } = useContext(ContextSettings);
  const queryClient = useQueryClient();
  const mutationUpdate = useMutation(() => updateSubelementStatus({ subElementId, status }), {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
        queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY]),
        queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_DEFECTS, diagnosticId, subElementId]),
        queryClient.invalidateQueries([DIAGNOSTICS_SPECTRUM_ELEMENT_LIST]),
        queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD, diagnosticId]),
      ]);

      handleOnSuccess();
    },
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return mutationUpdate;
}

export function useGetFrequencyPortraitsColors() {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);

  const query = useQuery([DIAGNOSTIC_GET_FREQUENCY_PORTRAITS_COLORS], getFrequencyPortraitsColors, {
    staleTime: BIG_STALE_TIME,
    enabled: !notificationMessage,
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return query;
}

export function useGetFrequencyPortraitsByDiagnosticAndElementId({
  diagnosticId,
  elementGuideId,
  freqElementId,
  handleOnSuccess,
}: {
  diagnosticId: string;
  elementGuideId: string;
  freqElementId: string;
  handleOnSuccess: () => void;
}) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);

  const query = useQuery(
    [DIAGNOSTIC_GET_FREQUENCY_PORTRAITS, diagnosticId, elementGuideId, freqElementId],
    () => getFrequencyPortraits(diagnosticId, elementGuideId, freqElementId),
    {
      staleTime: BIG_STALE_TIME,
      enabled: !notificationMessage,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
      onSuccess: handleOnSuccess,
    },
  );

  return query;
}

export function useGetDiagnosticsPredict({
  elementGuideId,
  freqElementId,
}: {
  elementGuideId: string | undefined;
  freqElementId: string | undefined;
}) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const query = useQuery(
    [GET_DIAGNOSTICS_PREDICT_KEY, elementGuideId, freqElementId ?? elementGuideId],
    () =>
      getDiagnosticsPredict({
        elementGuideId: elementGuideId ?? '',
        freqElementId: freqElementId || elementGuideId || '',
      }),
    {
      staleTime: BIG_STALE_TIME,
      enabled: !notificationMessage,
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}
