import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 8 10'}>
      <path
        d="M4.42816 7.72886C6.47047 6.50348 7.50061 5.89079 7.50061 4.98488C7.50061 4.07898 6.47047 3.46628 4.42816 2.2409L4.09086 2.03852C2.27801 0.950816 1.37159 0.406963 0.68677 0.7947C0.00195312 1.18244 0.00195312 2.2395 0.00195312 4.35362V5.61613C0.00195313 7.73026 0.00195312 8.78732 0.68677 9.17506C1.37159 9.5628 2.27801 9.01894 4.09086 7.93124L4.42816 7.72886Z"
        fill="black"
      />
    </SvgIcon>
  );
}
