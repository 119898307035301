import { FC, useState, useContext } from 'react';

import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import { ContextSettings } from 'context/context-settings';
import { IMachine } from 'interfaces';
import { MachineCards } from 'pages/machine-pages/machines/machine-cards';

type Props = { layoutSize: { height: number; width: number } };

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: '#fff',
      border: '2px solid #fff',
      borderRadius: 4,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      height: (props) => props.layoutSize.height + 60,
      width: (props) => props.layoutSize.width - 100,
    },
  }),
);

export const MachineModal: FC<{
  onMachineSelect: (bearing: any) => void;
  disabled?: boolean;
}> = ({ onMachineSelect, disabled }) => {
  const { layoutSize } = useContext(ContextSettings);
  const classes = useStyles({ layoutSize });
  const [open, setOpen] = useState<boolean>(false);
  const [params, setParams] = useState<any>({});
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {disabled ? (
        <IconButton disabled color="default" aria-label="delete" size="small">
          <FormatAlignJustifyIcon />
        </IconButton>
      ) : (
        <Tooltip title="Выбор станка из базы">
          <span>
            <IconButton
              color="primary"
              aria-label="delete"
              onClick={handleOpen}
              size="small"
              id="id_get_machine_from_machines"
            >
              <FormatAlignJustifyIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        BackdropProps={{ style: { backgroundColor: 'rgba(200, 200, 200, 0.5)' } }}
      >
        <Fade in={open}>
          <div className={classes.paper} id="transition-modal-description">
            <MachineCards
              layoutHeight={layoutSize.height - 200}
              layoutWidth={layoutSize.width - 130}
              onCardClick={(machine: IMachine) => {
                setOpen(false);
                onMachineSelect(machine);
              }}
              params={params}
              setParams={setParams}
              isModal={true}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};
