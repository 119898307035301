import { useMemo } from 'react';

import { IMapValue } from 'interfaces';

export const useVertexesDictionary = (tree: any, childrenName: string) =>
  useMemo(() => {
    const vertexByIdDictionary = new Map<string, IMapValue>();
    const vertexByMnemonicIdDictionary = new Map<string, IMapValue>();

    if (!tree) {
      return { vertexByIdDictionary, vertexByMnemonicIdDictionary }; // empty map
    }

    vertexByIdDictionary.set(tree.id, {
      path: '',
      lastIndex: -1,
      previousVertexesArray: [],
      vertex: tree,
    });

    function treeTraversal(
      subTree: any,
      base: string,
      lastIndex: number,
      previousVertexesArray: string[],
    ): void {
      if (!Array.isArray(subTree.children)) {
        return;
      }
      const newValue = base === '' ? childrenName : `${base}.${lastIndex}.${childrenName}`;

      subTree.children.forEach((vertex: any, index: number) => {
        if (!vertex) {
          throw new Error('Vertex can not be undefined or null.');
        }
        const key = vertex.id;
        if (!key) {
          throw new Error('ID can not be undefined or null.');
        }
        if (vertexByIdDictionary.has(key)) {
          throw new Error(`ID "${key}" is not unic.`);
        }
        vertexByIdDictionary.set(key, {
          path: newValue,
          lastIndex: index,
          vertex,
          previousVertexesArray,
        });

        treeTraversal(vertex, newValue, index, [...previousVertexesArray, vertex.id]);
      });
    }
    treeTraversal(tree, '', -1, [tree.id]);

    vertexByIdDictionary.forEach((vertex) => {
      if (vertex.vertex?.mnemonicSchemeLinkId) {
        vertexByMnemonicIdDictionary.set(vertex.vertex.mnemonicSchemeLinkId, vertex);
      }
    });

    return { vertexByIdDictionary, vertexByMnemonicIdDictionary };
  }, [childrenName, tree]);
