import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { IPreparedPeak } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
type Props = { x: number; y: number };

const WIDTH = 189;
const HEIGHT = 179;

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    root: {
      zIndex: 16,
      position: 'absolute',
      top: (props) => props.y - HEIGHT - 44,
      left: (props) => props.x - WIDTH / 2,
      minWidth: WIDTH,
      minHeight: HEIGHT,
      maxWidth: WIDTH,
      mmaxHeight: HEIGHT,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '8px 8px 8px 8px',
      backgroundColor: '#1D2023',
      borderRadius: '6px',
      color: '#fff',
      lineHeight: '18px',
      fontSize: '12px',
      fontWeight: 300,
    },
    bold: {
      fontWeight: 700,
    },
    subHeader: {
      paddingTop: 4,
    },
    tail: {
      zIndex: 2,
      position: 'absolute',
      top: (props) => props.y - 25,
      left: (props) => props.x - 14,
      width: 30,
      height: 30,
    },
  }),
);

function Raw({
  name,
  value,
  suffix,
  classNameBold,
}: {
  name: string;
  value: number | undefined | null;
  suffix: string;
  classNameBold: string;
}) {
  return (
    <div>
      <span>{`${name}:`}</span>
      &nbsp;
      <span className={classNameBold}>{`${value ? value.toFixed(2) : '-'} ${
        value ? suffix : ''
      }`}</span>
    </div>
  );
}

export function CustomTooltip({
  hoverPeak,
  isEnvelop,
}: {
  hoverPeak: IPreparedPeak;
  isEnvelop: boolean;
}) {
  const { rawPeak } = hoverPeak;
  const xy = isEnvelop ? hoverPeak.inPixels.theory.envelop : hoverPeak.inPixels.theory.direct;
  const realXY = isEnvelop ? hoverPeak.inPixels.real.envelop : hoverPeak.inPixels.real.direct;
  let { x, y } = xy;
  if (realXY) {
    y = Math.min(y, realXY.y);
  }
  const classes = useStyles({ x, y });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.bold}>{rawPeak.frequencyName}</div>
        <div className={classes.subHeader}>{'Выделенный пик'}</div>
        <Raw
          name="X"
          value={isEnvelop ? rawPeak.realEnvelopFreq : rawPeak.realSpectrumFreq}
          suffix="Гц"
          classNameBold={classes.bold}
        />
        <Raw
          name="Y"
          value={isEnvelop ? rawPeak.realEnvelopAmpl : rawPeak.realSpectrumAmpl}
          suffix="дБ"
          classNameBold={classes.bold}
        />
        <div className={classes.subHeader}>{'Расчетная частота'}</div>
        <Raw name="X" value={rawPeak.theoryFreq} suffix="Гц" classNameBold={classes.bold} />
        <Raw
          name="Y"
          value={isEnvelop ? rawPeak.treoryEnvelopAmpl : rawPeak.theorySpectrumAmpl}
          suffix="дБ"
          classNameBold={classes.bold}
        />
        <div className={classes.subHeader}>{'Отклонение от расчетных'}</div>
        <Raw
          name="dx"
          value={isEnvelop ? rawPeak.dxEnvelop : rawPeak.dxSpectrum}
          suffix="%"
          classNameBold={classes.bold}
        />
        <Raw
          name="dy"
          value={isEnvelop ? rawPeak.dyEnvelop : rawPeak.dySpectrum}
          suffix="%"
          classNameBold={classes.bold}
        />
      </div>
      <div className={classes.tail}>
        <ArrowDropDownIcon />
      </div>
    </>
  );
}
