import { useMemo } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { IQueryParams } from 'interfaces';
//@ts-ignore
import * as JSURL from 'jsurl';
import { cleanQueryParams } from 'utils/clean-query-params';

const URL_QUERY_PARAMS = 'params';

export const useQueryParams = () => {
  const history = useHistory();
  const location = useLocation();

  const urlParams = new URLSearchParams(location.search);
  const queryParamsDraft = urlParams.get(URL_QUERY_PARAMS);

  const { queryParams, setQueryParams } = useMemo(() => {
    let queryParamsMemo: IQueryParams;
    try {
      queryParamsMemo = (queryParamsDraft && JSURL.parse(queryParamsDraft)) ?? {};
    } catch {
      console.error(
        'Ошибка JSURL.parse(queryParamsDraft) queryParamsDraft = ',
        JSURL.parse(queryParamsDraft),
      );
      queryParamsMemo = {};
    }
    const setQueryParamsMemo = (qp: IQueryParams, pathname?: string) => {
      let newQueryParams = cleanQueryParams({
        ...queryParamsMemo,
        ...qp,
      });
      if (Object.keys(newQueryParams).length === 0) {
        if (pathname) {
          history.push({
            pathname,
            search: undefined,
          });
        } else {
          history.replace({
            search: undefined,
          });
        }
      } else {
        urlParams.set(URL_QUERY_PARAMS, JSURL.stringify(newQueryParams));
        if (pathname) {
          history.push({
            pathname,
            search: urlParams.toString(),
          });
        } else {
          history.replace({
            search: urlParams.toString(),
          });
        }
      }
    };

    return { queryParams: queryParamsMemo, setQueryParams: setQueryParamsMemo };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, queryParamsDraft]);

  return { queryParams, setQueryParams };
};
