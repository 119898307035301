import { PredictIcon } from 'icons/predict-icon';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { IPeaksColor, MENU_WIDTH } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import { IS_LIKELY_TO_BE_A_DEFECT } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

const useStyles = makeStyles<Theme, IPeaksColor>((theme) =>
  createStyles({
    root: {
      width: MENU_WIDTH - 100,
      backgroundColor: (props) => props.main,
      border: (props) => `0.5px solid ${props.circle}`,
      display: 'flex',
      flexDirection: 'row',
      gap: 8,
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: '32px',
      padding: '0px 13px 0px 12px',
      borderRadius: '18px',
    },
    name: {
      fontWeight: 400,
      fontSize: '14px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
  }),
);

export function RoundedButton({
  color,
  name,
  predictFlag,
}: {
  color: IPeaksColor;
  name: string;
  predictFlag: boolean;
}) {
  const classes = useStyles(color);

  return (
    <div className={classes.root}>
      <div className={classes.name}>{name}</div>

      {predictFlag && (
        <Tooltip title={IS_LIKELY_TO_BE_A_DEFECT} placement={'top'}>
          <span>
            <PredictIcon style={{ fontSize: 16 }} />
          </span>
        </Tooltip>
      )}
    </div>
  );
}
