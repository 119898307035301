import { IElementType, ISortParamsOrder } from 'interfaces';

export interface IBearingCalculatorParms {
  speed: number;
  innerDiameter: number;
  outerDiameter: number;
  rollingElementsDiameter: number;
  rollingElementsNumber: number;
  contactAngle: number;
}

export interface ICanvasSizeProps {
  frequencyMin?: number;
  frequencyMax?: number;
  amplitudeMin?: number;
  amplitudeMax?: number;
  height?: number;
  width?: number;
}
export interface ICoordinate {
  x: number;
  y: number;
}

export enum IHoveredOrigin {
  ENVELOP,
  DIRECT,
  BOTH,
}

export const fixedValue = (x: number) => (Number.isInteger(x) ? x.toFixed(0) : x.toFixed(2));

export const COLOR = {
  line1: '#FB3B3B',
  line2: '#1D56C5',
  base: '#ddd',
  ordinates: '#2C3135',
  ordinatesNet: '#E2E5EB',
  peaks: 'green',
  selectedPeaks: 'black',
  hover: 'lightgrey',
};

export type ISpectrumRawData = [number, number, number, number, number][];

export interface IRawPeak {
  frequencyName: string;
  //
  theoryFreq: number; // частота для палки для обоих графиков
  theorySpectrumAmpl: number; // теоретическая амплитуда на прямом спектре - палка на прямом спектре
  treoryEnvelopAmpl: number; // теоретическая амплитуда на спектре огибающей - палка на огибающем спектре
  //
  realSpectrumFreq: number | null; // частота для точки для прямого спектра
  realSpectrumAmpl: number | null; // амплитуда для точки для прямого спектра
  //
  realEnvelopFreq: number | null; // частота для точки для  спектра огибающей
  realEnvelopAmpl: number | null; // амплитуда для точки для  спектра огибающей
  //
  dxSpectrum: number | null;
  dySpectrum: number | null;
  //
  dxEnvelop: number;
  dyEnvelop: number;
}

export interface IRawGropsAndPeaksData {
  groupId: string;
  groupName: string;
  priority: number;
  data: IRawPeak[];
}

export interface IRawElement {
  elementId: string;
  elementName: string;
  elementRealName: string;
  subelementType: string;
  mnemonicSchemeLinkId: string;
  subelementFrequency: string;
  outputFrequency: string;
  isMain: number;
  data: IRawGropsAndPeaksData[];
  isUncertain: number;
}

export interface IRawData {
  data: {
    elements: IRawElement[];
    spectrums: ISpectrumRawData;
  };
}
export interface IRawDataForCalculatorPage {
  element: IRawElement;
  params: IBearingCalculatorParms;
}

export type IPreparedPeak = {
  inPixels: {
    theory: {
      envelop: ICoordinate;
      direct: ICoordinate;
      envelop00000: ICoordinate;
      direct00000: ICoordinate;
    };
    real: {
      envelop: ICoordinate | null;
      direct: ICoordinate | null;
      envelop00000: ICoordinate | null;
      direct00000: ICoordinate | null;
    };
  };
  unicId: string;
  groupName: string;
  groupColor: string;
  frequencyName: string;
  groupPriority: number;
  rawPeak: IRawPeak;
};

export type IPreparedPeaksMap = Map<string, IPreparedPeak>;
export interface IPreparedPeakData {
  preparedPeaks: IPreparedPeaksMap;
  peakLowAmplitudeInPixels: number;
  lowLineStartInPixels: ICoordinate;
  lowLineEndInPixels: ICoordinate;
}

export interface IFrequencyZoomParams {
  center: number;
  zoom: number;
  weight: number;
}
export interface IPeaksColor {
  circle: string;
  main: string;
  hover: string;
}

const DEFAULT_COLORS: IPeaksColor = {
  circle: '#333',
  main: '#999',
  hover: '#555',
};

export const getGroupsColors = (
  colorId: string,
  frequencyGroupsColorsDict: Record<string, IPeaksColor> | undefined,
) => frequencyGroupsColorsDict?.[colorId] ?? DEFAULT_COLORS;

export interface IDefect {
  id: string;
  defectType: string;
  rank: number | null;
  envelopDataList?: string[];
  spectrumDataList?: string[];
  status: number | null;
}

export interface ISubElement {
  isReady?: boolean;
  elementGuideLinkId: string;
  subElementName: string;
  subElementRealName: string;
  isUncertain: number;
  subElementType: IElementType | undefined;
  mnemonicSchemeLinkId: string;
  subelementFrequency: string;
  defectsList: IDefect[];
}

export interface IGrouppedSubElements {
  nodeName: string;
  nodeSubElements: ISubElement[];
}
export interface ISummaryElement {
  elementGuideParentId: string;
  elementRealName: string;
  elementGuideParentType: IElementType;
  summaryText: string | undefined;
  mnemonicSchemeLinkId: string | undefined;
  isUncertain: number;
  defectsList: IDefect[];
  summaryId: string | undefined | null;
  mnemonicScheme: string | undefined | null;
  nextElement: string | undefined | null;
}

export interface IDefectDictItem {
  labelId: string;
  label: string;
  validFor: string[];
  groupFrequency: string[];
}

export const subElementUnicId = (index: string) =>
  index ? `id_subelement_h5s_${index}` : undefined;

export const MENU_WIDTH = 376;

export const GRAPH_POINT_RADIUS = 4;
export const ENVELOP_AMPLITUDE_INDEX = 0;
export const ENVELOP_MOVING_AVG_INDEX = 1;
export const DIRECT_AMPLITUDE_INDEX = 2;
export const DIRECT_MOVING_AVG_INDEX = 3;
export const FREQUENCY_INDEX = 4;

export const FREQUENCY_INTERVALS_COUNT_FOR_HOVER = 100;

export const ZOOM_STEP_WHEEL = 1.06;

export const PADDING_LEFT = 28;
export const PADDING_RIGHT = 4;
export const PADDING_TOP = 30;
export const PADDING_BOTTOM = 20;

type IFrequencySortOptionsWhat = 'NO_SORT' | 'F' | 'COL1' | 'COL2';
type IFrequencySortOptionOrder = '' | ISortParamsOrder.ASC | ISortParamsOrder.DESC;
export interface IFrequencySortOptions {
  id: string;
  what: IFrequencySortOptionsWhat;
  order: IFrequencySortOptionOrder;
  label: string;
}

export const IS_LIKELY_TO_BE_A_DEFECT = 'Возможен дефект';

export interface IMAttrix {
  frequencyLabel: string;
  frequencyMatrix: number[][];
  yValues: number[];
  xValues: number[];
}
