import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function UnitIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 32 32'}>
      <rect x="21" y="10" width="9" height="13" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 24C13.4183 24 17 20.4183 17 16C17 11.5817 13.4183 8 9 8C4.58172 8 1 11.5817 1 16C1 20.4183 4.58172 24 9 24ZM9 23C12.866 23 16 19.866 16 16C16 12.134 12.866 9 9 9C5.13401 9 2 12.134 2 16C2 19.866 5.13401 23 9 23Z"
      />
      <line x1="16" y1="16" x2="22" y2="16" strokeWidth="2" />
      <path d="M9.15 16.96L10.4 12.1H12.94V19H11.27V13.42L9.83 19H8.19L6.75 13.39V19H5.2V12.1H7.91L9.15 16.96Z" />
    </SvgIcon>
  );
}
