import Typography from '@material-ui/core/Typography';

import { IFieldProps } from '../field-interfaces';
import FieldLabelErrorWrapper from '../field-label-error-wrapper';

import styles from './styles.module.css';

interface IProps<T> extends IFieldProps<T> {
  ModalIconButton: any;
  placeholder?: string;
}
const FieldWithIconForModalWindow = <T,>(props: IProps<T>) => {
  const { value, defaultValue, ModalIconButton, placeholder } = props;
  const text = (value ?? defaultValue) || placeholder;

  return (
    <div className={styles.container}>
      <FieldLabelErrorWrapper {...props} icon={ModalIconButton}>
        <Typography variant={'body1'} color={value ?? defaultValue ? 'secondary' : 'textSecondary'}>
          {text}
        </Typography>
      </FieldLabelErrorWrapper>
    </div>
  );
};

export default FieldWithIconForModalWindow;
FieldWithIconForModalWindow.displayName = 'FieldWithIconForModalWindow';
