import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function TaperRollBearingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 32 32'}>
      <path
        fill="none"
        d="M10.211,11.999H6.86H6.797H3.447c-0.455,0.022-0.831,0.354-0.864,0.787c-0.01,0.121,0.009,0.239,0.05,0.347
	l2.045,5.6c0,0,0.09,0.325,0.241,0.325h3.819c0.15,0,0.241-0.325,0.241-0.325l2.045-5.6c0.042-0.108,0.06-0.226,0.05-0.347
	C11.042,12.354,10.666,12.021,10.211,11.999z"
      />
      <path
        d="M31.339,9.866H19.365h-0.184h-3.776h-1.653h-0.169H0.611H0.36l4.245,11.81h8.901h0.323h0.882h0.735h3.653
	h1.04h7.203l4.245-11.81H31.339z M11.024,13.133l-2.045,5.6c0,0-0.09,0.325-0.241,0.325H4.919c-0.151,0-0.241-0.325-0.241-0.325
	l-2.045-5.6c-0.042-0.108-0.061-0.226-0.05-0.347c0.034-0.432,0.41-0.765,0.864-0.787h3.351H6.86h3.351
	c0.455,0.022,0.831,0.354,0.863,0.787C11.083,12.907,11.065,13.025,11.024,13.133z M20.17,13.133l-2.046,5.6
	c0,0-0.09,0.325-0.24,0.325h-3.819c-0.151,0-0.24-0.325-0.24-0.325l-2.046-5.6c-0.041-0.108-0.06-0.226-0.05-0.347
	c0.034-0.432,0.409-0.765,0.864-0.787h3.35h0.063h3.35c0.455,0.022,0.831,0.354,0.864,0.787
	C20.229,12.907,20.21,13.025,20.17,13.133z M29.245,13.133l-2.046,5.6c0,0-0.088,0.325-0.239,0.325h-3.819
	c-0.15,0-0.24-0.325-0.24-0.325l-2.046-5.6c-0.041-0.108-0.061-0.226-0.051-0.347c0.034-0.432,0.41-0.765,0.865-0.787h3.351h0.062
	h3.353c0.454,0.022,0.83,0.354,0.861,0.787C29.306,12.907,29.286,13.025,29.245,13.133z"
      />
    </SvgIcon>
  );
}
