import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function DownloadButtonIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 19 18'}>
      <path
        d="M10.5 5C10.5 4.44772 10.0523 4 9.50002 4C8.94774 4 8.50002 4.44772 8.50002 5V10.6192L7.26387 9.1562C6.90744 8.73433 6.2765 8.6813 5.85463 9.03773C5.43277 9.39417 5.37973 10.0251 5.73617 10.447L7.89766 13.2257C8.2776 13.7141 8.86222 13.9991 9.48102 13.9977L9.50002 13.9976L9.51899 13.9977C10.1378 13.9991 10.7224 13.7141 11.1023 13.2257L13.2639 10.447C13.6203 10.0251 13.5673 9.39417 13.1454 9.03773C12.7235 8.6813 12.0926 8.73433 11.7362 9.1562L10.5 10.6192V5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.581053 6.92282C0.775748 4.30684 0.873095 2.99884 2.18597 1.68597C3.49884 0.373095 4.80684 0.275748 7.42282 0.0810532C8.10095 0.0305834 8.80048 0 9.5 0C10.1995 0 10.899 0.0305833 11.5772 0.0810531C14.1932 0.275748 15.5012 0.373095 16.814 1.68597C18.1269 2.99884 18.2243 4.30684 18.4189 6.92282C18.4694 7.60095 18.5 8.30048 18.5 9C18.5 9.69952 18.4694 10.399 18.4189 11.0772C18.2243 13.6932 18.1269 15.0012 16.814 16.314C15.5012 17.6269 14.1932 17.7243 11.5772 17.9189C10.899 17.9694 10.1995 18 9.5 18C8.80048 18 8.10095 17.9694 7.42282 17.9189C4.80684 17.7243 3.49884 17.6269 2.18597 16.314C0.873095 15.0012 0.775748 13.6932 0.581053 11.0772C0.530583 10.399 0.5 9.69952 0.5 9C0.5 8.30048 0.530583 7.60095 0.581053 6.92282ZM7.57126 2.07554C8.20842 2.02812 8.85779 2 9.5 2C10.1422 2 10.7916 2.02812 11.4287 2.07554C14.1791 2.28023 14.6936 2.39398 15.3998 3.10018C16.106 3.80638 16.2198 4.3209 16.4245 7.07126C16.4719 7.70842 16.5 8.35779 16.5 9C16.5 9.64221 16.4719 10.2916 16.4245 10.9287C16.2198 13.6791 16.106 14.1936 15.3998 14.8998C14.6936 15.606 14.1791 15.7198 11.4287 15.9245C10.7916 15.9719 10.1422 16 9.5 16C8.85779 16 8.20842 15.9719 7.57126 15.9245C4.8209 15.7198 4.30638 15.606 3.60018 14.8998C2.89398 14.1936 2.78023 13.6791 2.57554 10.9287C2.52812 10.2916 2.5 9.64221 2.5 9C2.5 8.35779 2.52812 7.70842 2.57554 7.07126C2.78023 4.3209 2.89398 3.80638 3.60018 3.10018C4.30638 2.39398 4.8209 2.28023 7.57126 2.07554Z"
        fill="black"
      />
    </SvgIcon>
  );
}
