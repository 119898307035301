import { FC, useRef } from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { IEntity, MODES_DICT_NAMES } from 'interfaces';

interface IProps {
  x: number;
  y: number;
}
interface IProps2 extends IProps {
  width: number;
}

const MAX_WIDTH = 160;

const useStyles = makeStyles<Theme, IProps2>((theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: (props) => props?.y + 16,
      left: (props) => props?.x - props.width / 2,
      maxWidth: MAX_WIDTH,
      padding: 5,
      backgroundColor: 'grey',
      borderRadius: 4,
      boxShadow: '5px 5px 5px #555',
    },

    key: {
      color: 'white',
      fontSize: 12,
      fontWeight: 600,
    },
    value: {
      color: 'white',
      fontSize: 14,
      fontWeight: 400,
      wordBreak: 'break-all',
    },
  }),
);

export const ShowVertex: FC<{
  entity?: IEntity;
  speed?: number;
  position: IProps;
  mouseOverElemId: string;
  xMax: number | undefined;
}> = ({ entity, speed, position, mouseOverElemId, xMax }) => {
  const rootRef = useRef(null);
  const rootDivXY = (rootRef.current &&
    (rootRef.current as HTMLElement).getBoundingClientRect()) as unknown as DOMRect | undefined;

  const newPosition: IProps2 = { ...position, width: rootDivXY?.width ?? 160 };
  newPosition.x = Math.max(newPosition.x, newPosition.width / 2);
  if (xMax !== undefined) {
    newPosition.x = Math.min(newPosition.x, xMax - newPosition.width / 2 - 6);
  }
  const classes = useStyles(newPosition);

  //@ts-ignore
  const { name, vertexType, elementType } = entity ?? {};

  const row = (key: string, value: string) => (
    <div>
      <span className={classes.key} style={{ wordBreak: 'break-all' }}>{`${key}:`}</span>
      &nbsp;<span className={classes.value}>{value}</span>
    </div>
  );

  return (
    <div className={classes.root} ref={rootRef}>
      {row('ид', mouseOverElemId)}
      {name && row('имя', name)}
      {name && row('тип', elementType ? MODES_DICT_NAMES[elementType] : vertexType ?? '')}
      {speed && speed !== -1 && row('Скорость', speed.toString())}
    </div>
  );
};
