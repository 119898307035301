import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ExclamationMarkIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 44 44'}>
      <g clip-path="url(#clip0_1412_2938)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.194233 16.9248C0.670174 10.53 0.908144 7.3325 4.11752 4.12313C7.3269 0.913754 10.5243 0.675785 16.9192 0.199846C18.577 0.0764707 20.287 0.00170898 21.997 0.00170898C23.707 0.00170898 25.417 0.076472 27.0747 0.199848C33.4696 0.675789 36.6671 0.91376 39.8765 4.12314C43.0858 7.33251 43.3238 10.53 43.7997 16.9249C43.9231 18.5826 43.9979 20.2926 43.9979 22.0026C43.9979 23.7126 43.9231 25.4226 43.7997 27.0804C43.3238 33.4753 43.0858 36.6727 39.8765 39.8821C36.6671 43.0915 33.4696 43.3294 27.0747 43.8054C25.417 43.9287 23.707 44.0035 21.997 44.0035C20.287 44.0035 18.577 43.9287 16.9192 43.8054C10.5243 43.3294 7.32689 43.0914 4.11751 39.8821C0.908139 36.6727 0.670169 33.4752 0.194231 27.0803C0.0708554 25.4226 -0.00390625 23.7126 -0.00390625 22.0026C-0.00390625 20.2926 0.0708567 18.5826 0.194233 16.9248Z"
          fill="#FAC031"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0027 24.1317C23.3294 24.1317 24.4049 23.0394 24.4049 21.6921L24.4049 11.9396C24.4049 10.5922 23.3294 9.5 22.0027 9.5C20.676 9.5 19.6005 10.5923 19.6005 11.9396L19.6005 21.6921C19.6005 23.0394 20.676 24.1317 22.0027 24.1317ZM25 31.4533C25 29.7706 23.6569 28.4065 22 28.4065C20.3431 28.4065 19 29.7706 19 31.4533C19 33.1359 20.3431 34.5 22 34.5C23.6569 34.5 25 33.1359 25 31.4533Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1412_2938">
          <rect width="44" height="44" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
