export const MIN_STALE_TIME = 500;
export const MAX_STALE_TIME = 120000;

export const DEFAULT_PAGE_SIZE = 20;

export const HANDLE_ON_SCROLL_TIMEOUT = 30;
export const HANDLE_FILTER_CHANGE_TIMEOUT = 500;

export const DIAGNOSTICS_KEY = 'diagnostics';
export const DIAGNOSTICS_FILTERS_DICTIONARIES = 'diagnostics-filters-dictionaries';
export const DIAGNOSTICS_KEY_ARCHIVE = `${DIAGNOSTICS_KEY}-archive`;
export const DIAGNOSTICS_BY_ID_KEY = 'diagnostics-by-id-key';
export const DIAGNOSTIC_REPORT_PARAMS_BY_ID_KEY = 'diagnostic-report-params-by-id-key';
export const DIAGNOSTICS_SPECTRUM_ELEMENT_SPECTRUM = 'diagnostics-spectrum-element-spectrum';
export const DIAGNOSTICS_PEAKS_AND_PARAMS_FOR_CALCULATOR_PAGE =
  'diagnostics-peaks-and-params-for-calculator-page';
export const DIAGNOSTICS_NEW_FREQ_FOR_CALCULATOR_PAGE = 'diagnostics-new-freq-for-calculator-page';
export const DIAGNOSTICS_SPECTRUM_ELEMENT_LIST = 'diagnostics-spectrum-element-list';
export const DIAGNOSTICS_SPECTRUM_DEFECTS_DICT = 'diagnostics-spectrum-defects-dict';
export const DIAGNOSTICS_FREQUENCY_GROUPS_COLORS_DICT = 'DIAGNOSTICS_SPECTRUM_DEFECTS_DICT';
export const DIAGNOSTICS_SPECTRUM_DEFECTS = 'diagnostics-spectrum-defects';
export const DIAGNOSTICS_SPECTRUM_DEFECT_FREQUENCIES = 'diagnostics-spectrum-defect-frequencies';
export const DIAGNOSTICS_SPECTRUM_ALL_SUMMARY_LIST = 'diagnostics-spectrum-all-summary-list';
export const DIAGNOSTICS_SPECTRUM_SUMMARY_ITEM = 'diagnostics-spectrum-summary-item';

export const DIAGNOSTIC_SPECTRUM = 'diagnostic-spectrum';
export const DIAGNOSTIC_SPECTRUM_BEARING_PARAMS_CALCULATOR =
  'diagnostic-spectrum-bearing-params-calculator';
export const DIAGNOSTIC_SPECTRUM_SUMMARY_LIST = 'diagnostic-spectrum-summary-list';
export const DIAGNOSTIC_SPECTRUM_SUMMARY_ITEM = 'diagnostic-spectrum-summary-item';

export const DIAGNOSTIC_GET_FREQUENCY_PORTRAITS_COLORS =
  'diagnostic-get-frequency-portraits-colors';
export const DIAGNOSTIC_GET_FREQUENCY_PORTRAITS = 'diagnostic-get-frequency-portraits';

export const DIAGNOSTIC_MEASURE_UPLOAD = 'diagnostic-measure-upload';
export const DIAGNOSTIC_DOWNLOAD_REPORT = 'download-report';
export const DIAGNOSTIC_GET_SIGNAL_TO_CUT_BY_CSV_ID = 'diagnostic-get-signal-to-cut-by-csv-id';

export const GET_DIAGNOSTICS_PREDICT_KEY = 'get-diagnostics-predict-key';
export const GET_DIAGNOST_LIST_BY_ID_KEY = 'get-diagnost-list-by-id-key';

export const MACHINES_KEY = 'machines';
export const MACHINE_BY_ID_KEY = 'machine-by-id-key';
export const MACHINE_HEAD_BY_ID_KEY = 'machine-head-by-id-key';
export const MACHINES_FILTERS_DICTIONARIES = 'machines-filters-dictionaries';

export const MACHINE_AND_ALL_MODES_BY_ID_KEY = 'machine-and-all-modes-by-id-key';
export const ALL_MODES_BY_ID_KEY = 'all-modes-by-id-key';

export const MODES_KEY = 'modes-key';
export const MODE_BY_ID_KEY = 'mode-by-id-key';
export const MODE_CREATE_NEW_TABLE = 'mode-create-new-table';

export const MACHINE_MODES_KEY = `${MACHINES_KEY}/modes`;

export const LAST_MODIFIED_MACHINE_ID = 'lastModifiedMachineId';

export const BEARINGS_KEY = 'bearings-key';

export const TAPE_ROLL_BEARINGS_KEY = 'conical-bearings';
export const TAPE_ROLL_BEARING_BY_ID_KEY = 'conical-bearing-by-id-key';
export const COMBINED_BEARINGS_KEY = 'combo-bearings';
export const COMBINED_BEARING_BY_ID_KEY = 'combo-bearing-by-id-key';
export const BALLSCREW_KEY = 'ballscrew';
export const BALLSCREW_BY_ID_KEY = 'ballscrew-by-id-key';

export const USERS_SETTINGS_KEY = 'users';
export const USER_BY_KEY = 'users-by-id';

export const USERS_TN_MACHINES_SETTINGS_KEY = 'tenants-tn-machines';
export const USERS_TN_MACHINES_BY_ID_SETTINGS_KEY = 'tenants-tn-machines-by-id';

export const CREATE_NEW = 'create';
export const CREATE_NEW_AND_COPY_PARAMS_FROM_OLD = 'create-new-and-copy-params-from-old';
export const USER_FULLNAME_KEY = 'user-fullname-key';

export const NOOP = (a?: any) => {};
export const NO_PICTURE = (message?: string) =>
  `Картинка ${message ? `"` + message + '"' : ''} не загрузилась.`;

export const PHONE_MASK = '+7 (999) 999-99-99';

export const MAX_SVG_MNEMOSHEME_FILE_SIZE = 20_000_000;
export const MAX_MACHINE_PHOTO_FILE_SIZE = 5_000_000;
export const MAX_MEASURING_ZIP_FILE_SIZE = 1_300_000_000;
export const MAX_MEASURING_CSV_FILE_SIZE = 184_000_000;

export const ROUTE_REFETCH_TIMEINTERVAL = 2000;
export const REPORT_REFETCH_TIMEINTERVAL = 3500;

export const EMPTY_OBJECT = Object.freeze({});
