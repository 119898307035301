import { useContext, useMemo } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { CustomBreadcrumbs } from 'components/breadcrums';
import { ContextSettings } from 'context/context-settings';
import {
  useGetDefectsDict,
  useGetSpectrumAllSummaryList,
} from 'pages-diagnostic/diagnostic-spectrum/query-hooks/query-hooks';
import { ISummaryElement } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import { useGetDiagnosticById } from 'pages-diagnostic/hooks';
import { DIAGNOSTICS_KEY, DIAGNOSTIC_SPECTRUM_SUMMARY_ITEM, EMPTY_OBJECT } from 'stream-constants';

import { SummaryElementListItem } from './summary-element-list-item';

type IProps = { layoutSize: { width: number; height: number } };

const useStyles = makeStyles<Theme, IProps>((theme) =>
  createStyles({
    layoutMargin: {
      marginTop: 28,
    },
    list: {
      width: (props) => props.layoutSize.width,
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    buttonStyle: {
      width: '240px',
      marginRight: 36,
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#000',
      margin: '14px 0 0 0',
    },
  }),
);

export function DiagnosticSummaryList() {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const { layoutSize } = useContext(ContextSettings);
  const { lastModifiedMachineId, setLastModifiedMachineId } = useContext(ContextSettings);
  console.log('%c lastModifiedMachineId = ', 'color: #bada55', lastModifiedMachineId); //TODO - delete vvtu

  const classes = useStyles({ layoutSize });

  const diagnosticQuery = useGetDiagnosticById(params.id);

  const { description } = diagnosticQuery.data ?? {};

  const elementListQuery = useGetSpectrumAllSummaryList(params?.id);

  const elementList = (elementListQuery?.data?.data ?? []) as ISummaryElement[];

  const defectsDictQuery = useGetDefectsDict();
  const { defectsDict = EMPTY_OBJECT } = defectsDictQuery.data ?? {};

  const handleOnSummaryClick = (item: ISummaryElement) => {
    setLastModifiedMachineId(item?.elementGuideParentId ?? '');
    history.push(`/${DIAGNOSTIC_SPECTRUM_SUMMARY_ITEM}/${params.id}/${item.elementGuideParentId}`);
  };

  const breadcrubms = useMemo(
    () => [
      {
        label: `Диагностика \u00AB${description}\u00BB`,
        handleOnClick: () => history.push(`/${DIAGNOSTICS_KEY}/${params.id}`),
      },
      {
        label: 'Список элементов для выводов',
        handleOnClick: () => {},
      },
    ],
    [description, history, params.id],
  );

  return (
    <div className={classNames('layout-min-height', classes.layoutMargin)}>
      <CustomBreadcrumbs links={breadcrubms} />

      <div className={classes.headerTitle}>{'Список элементов для выводов'}</div>

      <div className={classes.list}>
        <SummaryElementListItem
          isHeader={true}
          itemWidth={layoutSize.width}
          index=""
          item={undefined}
          defectsDict={defectsDict}
          isLastModifiedItem={false}
        />
        {elementList.map((item, index) => (
          <SummaryElementListItem
            key={item.elementGuideParentId}
            itemWidth={layoutSize.width}
            index={`${index + 1}`}
            item={item}
            defectsDict={defectsDict}
            isHeader={false}
            onSummaryClick={handleOnSummaryClick}
            isLastModifiedItem={
              !!(lastModifiedMachineId && lastModifiedMachineId === item.elementGuideParentId)
            }
          />
        ))}
        <br />
      </div>
    </div>
  );
}
