import { IBearingType } from 'interfaces';
import { SERVER_PREFIX, IS_SERVER_MOCKED } from 'stream-constants';

/**
 * URLs for services
 */
export const GET_MACHINES_URL = process.env.REACT_APP_DIAGNOSTIC
  ? `${SERVER_PREFIX}diagnostics/all-machines`
  : `${SERVER_PREFIX}machines/all`;

export const CREATE_MACHINE_URL = `${SERVER_PREFIX}machines/create`;

export const GET_MACHINE_BY_ID_URL = process.env.REACT_APP_DIAGNOSTIC
  ? (id: string) => `${SERVER_PREFIX}diagnostics/get-machine-by-id?machine_id=${id}`
  : (id: string) => `${SERVER_PREFIX}machines/get?machine_id=${id}`;

export const GET_MACHINE_HEAD_BY_ID_URL = process.env.REACT_APP_DIAGNOSTIC
  ? (id: string) => `${SERVER_PREFIX}diagnostics/get-machine-by-id-head?machine_id=${id}`
  : (id: string) => `${SERVER_PREFIX}machines/get-head?machine_id=${id}`;

export const UPDATE_MACHINE_BY_ID_URL = (id: string) => `${SERVER_PREFIX}machines/update?id=${id}`;

export const DUPLICATE_MACHINE_URL = (id: string) =>
  `${SERVER_PREFIX}machines/duplicate-mashine?machine_id=${id}`;

export const UPDATE_UNIT_BY_ID_URL = (id: string) =>
  `${SERVER_PREFIX}machines/units/update?unit_id=${id}`;
export const UPDATE_SHAFT_BY_ID_URL = (id: string) =>
  `${SERVER_PREFIX}machines/units/shafts/update?shaft_id=${id}`;
export const UPDATE_ELEMENT_BY_ID_URL = (id: string, prevElementType: string) =>
  `${SERVER_PREFIX}machines/units/shafts/connections/update?connection_id=${id}&element_type=${prevElementType}`;

export const DELETE_MACHINE_BY_ID_URL = (id: string) => `${SERVER_PREFIX}machines/delete?id=${id}`;
export const DELETE_UNIT_BY_ID_URL = (id: string) =>
  `${SERVER_PREFIX}machines/units/delete?unit_id=${id}`;
export const DELETE_SHAFT_BY_ID_URL = (id: string) =>
  `${SERVER_PREFIX}machines/units/shafts/delete?shaft_id=${id}`;
export const DELETE_ELEMENT_BY_ID_URL = (id: string, elementType: string) =>
  `${SERVER_PREFIX}machines/units/shafts/connections/delete?id=${id}&element_type=${elementType}`;

export const CREATE_MACHINE_BY_PARENT_ID_URL = (id: string) =>
  `${SERVER_PREFIX}machines/create?machine_id=${id}`;
export const CREATE_UNIT_BY_PARENT_ID_URL = (id: string) =>
  `${SERVER_PREFIX}machines/units/create?machine_id=${id}`;
export const CREATE_SHAFT_BY_PARENT_ID_URL = (id: string) =>
  `${SERVER_PREFIX}machines/units/shafts/create?unit_id=${id}`;
export const CREATE_ELEMENT_BY_PARENT_ID_URL = (id: string) =>
  `${SERVER_PREFIX}machines/units/shafts/connections/create?shaft_id=${id}`;

export const ASSIGN_TO_ME_AS_CREATOR_URL = (id: string) =>
  `${SERVER_PREFIX}machines/assign-to-me?id=${id}`;

export const GET_MACHINE_FILTER_DICTIONARIES = process.env.REACT_APP_DIAGNOSTIC
  ? `${SERVER_PREFIX}diagnostics/get-filter-machines-for-diagnostic`
  : `${SERVER_PREFIX}machines/get-filter-machines`;

/**
 * BEARINGS
 */
export const GET_BEARINGS_URL = (bType: IBearingType) => `${SERVER_PREFIX}bearings/all-${bType}`;
export const GET_BEARINGS_BY_ID_URL = (id: string, bType: IBearingType) =>
  `${SERVER_PREFIX}bearings/get-${bType}?id=${id}`;
export const CREATE_BEARINGS_URL = (bType: IBearingType) =>
  `${SERVER_PREFIX}bearings/create-${bType}`;
export const UPDATE_BEARINGS_URL = (id: string, bType: IBearingType) =>
  `${SERVER_PREFIX}bearings/update-${bType}?id=${id}`;
export const DELETE_BEARINGS_URL = (id: string, bType: IBearingType) =>
  `${SERVER_PREFIX}bearings/delete-${bType}?id=${id}`;
export const UPLOAD_RADIAL_BEARINGS_URL = `${SERVER_PREFIX}bearings/upload-radial`;

/**
 * MINIO
 */
export const CREATE_MINIO_URL_WITH_SVG_VALIDATION = `${SERVER_PREFIX}services/create_minio_schema`;
export const FETCH_MINIO_URL = (minioId?: string) =>
  IS_SERVER_MOCKED
    ? minioId ?? ''
    : minioId
    ? `${SERVER_PREFIX}services/fetch_minio_object?id=${minioId}`
    : '';

export const LOG_OUT = `${SERVER_PREFIX}services/logout`;
export const GET_USER_FULLNAME_URL = `${SERVER_PREFIX}services/get_user_fullname`;

/**
 * MODES
 */
export const GET_MODES_BY_UNIT_ID_URL = (unitId: string) =>
  `${SERVER_PREFIX}machines/units/modes/all?unit_id=${unitId}`;
export const ADD_MODE_BY_UNIT_ID_URL = (unitId: string) =>
  `${SERVER_PREFIX}machines/units/modes/create?unit_id=${unitId}`;
export const DELETE_MODE_BY_ID_URL = (id: string) =>
  `${SERVER_PREFIX}machines/units/modes/delete?combination_id=${id}`;
export const GET_MODE_BY_ID_URL = (id: string) =>
  `${SERVER_PREFIX}machines/units/modes/get?combination_id=${id}`;
export const UPDATE_MODE_BY_ID_URL = (id: string) =>
  `${SERVER_PREFIX}machines/units/modes/update?combination_id=${id}`;
export const GET_ALL_MODES_BY_M_ACHINE_ID = process.env.REACT_APP_DIAGNOSTIC
  ? (machineId: string) => `${SERVER_PREFIX}diagnostics/get-modes-data?machine_id=${machineId}`
  : (machineId: string) =>
      `${SERVER_PREFIX}machines/units/modes/get-modes-data?machine_id=${machineId}`;

/**
 * TENANTS
 */

export const GET_ALL_TENANTS_URL = `${SERVER_PREFIX}users/all_tenants`;
export const CREATE_TENANT_URL = `${SERVER_PREFIX}users/create_tenant`;
export const UPDATE_TENANT_URL = (id: string) =>
  `${SERVER_PREFIX}users/update_tenant?tenant_id=${id}`;
export const DELETE_TENANT_URL = (id: string) =>
  `${SERVER_PREFIX}users/delete_tenant?tenant_id=${id}`;

/*
 *
 ******************************************************************/
export const GET_ALL_USERS_URL = `${SERVER_PREFIX}users/all_users`;
export const GET_USER_BY_ID_URL = (id: string) => `${SERVER_PREFIX}users/get_user?user_id=${id}`;
export const CREATE_USER_URL = `${SERVER_PREFIX}users/create_user`;
export const UPDATE_USER_URL = (id: string) => `${SERVER_PREFIX}users/update_user?user_id=${id}`;
export const DELETE_USER_URL = (id: string) => `${SERVER_PREFIX}users/delete_user?user_id=${id}`;
/*
 *
 ******************************************************************/

export const GET_ALL_TENANTS_TN_MACHINES_URL = (id: string) =>
  `${SERVER_PREFIX}users/all_machine_cards?tenant_id=${id}`;
export const GET_TENANT_TN_MACHINE_BY_ID_URL = (id: string) =>
  `${SERVER_PREFIX}users/get_machine_card?machine_card_id=${id}`;
export const CREATE_TENANT_TN_MACHINE_URL = (id: string) =>
  `${SERVER_PREFIX}users/create_machine_card?tenant_id=${id}`;
export const UPDATE_TENANT_TN_MACHINE_URL = (id: string) =>
  `${SERVER_PREFIX}users/update_machine_card?machine_card_id=${id}`;
export const DELETE_TENANT_TN_MACHINE_URL = (id: string) =>
  `${SERVER_PREFIX}users/delete_machine_card?machine_card_id=${id}`;

/**
 * DIAGNOSTICS
 */
export const GET_DIAGNOSTICS_URL = `${SERVER_PREFIX}diagnostics/all`; //diagnostics/all-machines

export const CREATE_DIAGNOSTIC_URL = (machine_id: string) =>
  `${SERVER_PREFIX}diagnostics/create?machine_id=${machine_id}`;
export const GET_DIAGNOSTIC_BY_ID_URL = (diagnostic_id: string) =>
  `${SERVER_PREFIX}diagnostics/get?diagnostic_id=${diagnostic_id}`;
export const GET_DIAGNOSTIC_REPORT_PARAMS_BY_ID_URL = (diagnostic_id: string) =>
  `${SERVER_PREFIX}diagnostics/report?diagnostic_id=${diagnostic_id}`;

export const GET_DIAGNOSTIC_FILTER_DICTIONARIES = `${SERVER_PREFIX}diagnostics/get-filter-dictionaries`;

export const DELETE_DIAGNOSTIC_URL = (diagnostic_id: string) =>
  `${SERVER_PREFIX}diagnostics/delete?diagnostic_id=${diagnostic_id}`;
export const DOWNLOAD_MEASURE_GUIDE_URL = (diagnostic_id: string) =>
  `${SERVER_PREFIX}diagnostics/download-measure-guide?diagnostic_id=${diagnostic_id}`;
export const DOWNLOAD_REPORT_URL = (diagnostic_id: string) =>
  `${SERVER_PREFIX}diagnostics/download-report?diagnostic_id=${diagnostic_id}`;

export const CREATE_REPORT_URL = (diagnostic_id: string) =>
  `${SERVER_PREFIX}diagnostics/create-report-pdf?diagnostic_id=${diagnostic_id}`;

export const VALIDATE_MEASURE_DATA_URL = (diagnostic_id: string, archive_id: string) =>
  `${SERVER_PREFIX}diagnostics/validate-uploading-data?diagnostic_id=${diagnostic_id}&archive_id=${archive_id}`;

export const PROCESSING_MEASURE_DATA_URL = (diagnostic_id: string, archive_id: string) =>
  `${SERVER_PREFIX}diagnostics/upload-measurings?diagnostic_id=${diagnostic_id}&archive_id=${archive_id}`;

export const MOVE_DIAGNOSTIC_TO_ARCHIVE = (diagnostic_id: string) =>
  `${SERVER_PREFIX}diagnostics/to-archive?diagnostic_id=${diagnostic_id}`;

export const EXTRACT_DIAGNOSTIC_FROM_ARCHIVE = (diagnostic_id: string) =>
  `${SERVER_PREFIX}diagnostics/from-archive?diagnostic_id=${diagnostic_id}`;

export const GET_DIAGNOSTIC_BASE_PARAMS_BY_ID_URL = (id: string) =>
  `${SERVER_PREFIX}diagnostics/update?id=${id}`;
export const UPDATE_IS_CHECKED_STATUS_URL = (id: string, status: boolean) =>
  `${SERVER_PREFIX}diagnostics/update-is-checked-status?diagnostic_id=${id}&is_checked=${status}`;

/**
 * DIAGNOSTICS SPECTRUM
 */

export const GET_DIAGNOSTICS_SPECTRUM = (diagnostic_id: string, elementGuideId: string) =>
  `${SERVER_PREFIX}diagnostics/get-spectrum?diagnostic_id=${diagnostic_id}&element_guide_id=${elementGuideId}`;
export const GET_DIAGNOSTICS_PEAKS_AND_PARAMS_FOR_CALCULATOR_PAGE = (elementGuideId: string) =>
  `${SERVER_PREFIX}diagnostics/get-calculate?subelement_id=${elementGuideId}`;
export const GET_DIAGNOSTICS_GET_NEW_FREQ_FOR_CALCULATOR_PAGE = (elementGuideId: string) =>
  `${SERVER_PREFIX}diagnostics/get-new-freq?subelement_id=${elementGuideId}`;

export const GET_ALL_DIAGNOSTICS_SUBELEMENTS = (diagnostic_id: string) =>
  `${SERVER_PREFIX}diagnostics/get-all-subelements?diagnostic_id=${diagnostic_id}`;

export const GET_ALL_DEFECTS_BY_ELEMENT = (diagnostic_id: string, element_guide_id: string) =>
  `${SERVER_PREFIX}defects/all-defects?diagnostic_id=${diagnostic_id}&element_guide_id=${element_guide_id}`;

export const GET_DIAGNOSTICS_SPECTRUM_DEFECT_DICT = `${SERVER_PREFIX}defects/get-defect-dict`;
export const GET_FREQUENCY_GROUPS_COLORS = `${SERVER_PREFIX}defects/get-frequency-groups-colors`;

export const CREATE_SPECTRUM_DEFECT = `${SERVER_PREFIX}defects/create`;

export const UPDATE_SPECTRUM_DEFECT = (defectId: string) =>
  `${SERVER_PREFIX}defects/update?defect_id=${defectId}`;

export const DELETE_SPECTRUM_DEFECT = (defectId: string) =>
  `${SERVER_PREFIX}defects/delete?defect_id=${defectId}`;

export const GET_DEFECT_FREQUENCIES_BY_DEFECT_ID = (defectId: string) =>
  `${SERVER_PREFIX}defects/get_defect_frequencies?defect_id=${defectId}`;

export const GET_ALL_SUMMARY = (diagnostic_id: string) =>
  `${SERVER_PREFIX}defects/get-all-summary?diagnostic_id=${diagnostic_id}`;

export const GET_ELEMENT_SUMMARY = (diagnostic_id: string, element_guide_parent_id: string) =>
  `${SERVER_PREFIX}defects/get-element_summary?diagnostic_id=${diagnostic_id}&element_guide_parent_id=${element_guide_parent_id}`;

export const UPDATE_SPECTRUM_DEFECT_SUMMARY = (summaryId: string) =>
  `${SERVER_PREFIX}defects/update-summary?summary_id=${summaryId}`;

export const DELETE_SPECTRUM_DEFECT_SUMMARY = (summaryId: string) =>
  `${SERVER_PREFIX}defects/delete-summary?summary_id=${summaryId}`;

export const CREATE_SPECTRUM_DEFECT_SUMMARY = `${SERVER_PREFIX}defects/create-summary`;

export const GET_DIAGNOSTIC_FREQUENCY_PORTRAITS_COLORS_URL = `${SERVER_PREFIX}defects/get-frequency-portraits-colors`;

export const GET_DIAGNOSTIC_FREQUENCY_PORTRAITS_URL = (
  diagnostic_id: string,
  element_guide_id: string,
  freq_element_id: string,
) =>
  `${SERVER_PREFIX}diagnostics/get-frequency-portraits?diagnostic_id=${diagnostic_id}&element_guide_id=${element_guide_id}&freq_element_id=${freq_element_id}`;

export const UPDATE_SUBELEMENT_STATUS_URL = (subElementId: string, status: boolean) =>
  `${SERVER_PREFIX}defects/update-subelement-status?subelement_id=${subElementId}&status=${status}`;

/*
 * DIAGNOSTICS SPECTRUM
 * FOLDER LIST
 *********************************************************/

export const GET_FOLDER_LIST_URL = (diagnosticId: string) =>
  `${SERVER_PREFIX}diagnostics/get-folder-list?diagnostic_id=${diagnosticId}`;

export const UPDATE_FOLDER_LIST_URL = (diagnosticId: string) =>
  `${SERVER_PREFIX}diagnostics/update-folder-list?diagnostic_id=${diagnosticId}`;

export const UPLOAD_CSV_URL = (diagnosticId: string) =>
  `${SERVER_PREFIX}diagnostics/upload-csv?diagnostic_id=${diagnosticId}`;

export const DELETE_CSV_FILE_URL = (csvId: string) =>
  `${SERVER_PREFIX}diagnostics/delete-csv-file?csv_id=${csvId}`;

export const FINISH_UPLOAD_URL = (diagnosticId: string) =>
  `${SERVER_PREFIX}diagnostics/finish-upload?diagnostic_id=${diagnosticId}`;

export const ASSIGN_TO_DIAGNOST_URL = (diagnosticId: string, userId: string) =>
  `${SERVER_PREFIX}diagnostics/assign-to-diagnost?id=${diagnosticId}&user_id=${userId}`;
export const GET_DIAGNOST_LIST_URL = (diagnosticId: string) =>
  `${SERVER_PREFIX}diagnostics/diagnost_list?diagnostic_id=${diagnosticId}`;

/**
 * DIAGNOSTICS SIGNAL EDITOR
 */

export const GET_SIGNAL_TO_CUT_URL = (csv_link: string) =>
  `${SERVER_PREFIX}diagnostics/signal-to-cut?csv_link=${csv_link}`;

export const UPDATE_SIGNALS_URL = (csv_link: string) =>
  `${SERVER_PREFIX}diagnostics/cut-signals?csv_link=${csv_link}`;

/**
 * DIAGNOSTICS PREDICT
 */
export const GET_DIAGNOSTICS_PREDICT = (elementGuideId: string, freqElementId: string) =>
  `${SERVER_PREFIX}diagnostics/predict?element_guide_id=${elementGuideId}&freq_element_id=${freqElementId}`;
