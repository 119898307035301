import { useEffect, useRef, useMemo } from 'react';

import { PINK_COLOR, BLUE_COLOR, CANVAS_HEADER_BAR_HEIGHT } from './slicing-editor-common';
import { usePrepareEditorParams } from './use-prepare-editor-params';

const ICON_HALF_SIZE = 5;

export function HeaderBar({
  canvasSize,
  slices,
  setSlices,
  editorParams,
  cutIntervals,
  setCutIntervals,
}: {
  canvasSize: {
    height: number;
    width: number;
  };
  slices: number[];
  setSlices: (slices: number[]) => void;
  editorParams: ReturnType<typeof usePrepareEditorParams>;
  cutIntervals: number[][];
  setCutIntervals: (cutIntervals: number[][]) => void;
}) {
  const canvasHeaderBarRef = useRef<HTMLCanvasElement | null>(null);
  // const hoverIdleTimeIdRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  const canvasWidth = canvasSize.width;
  const canvasHeight = canvasSize.height;
  const { newAmplitudes, toPixels, duration } = editorParams ?? {};

  const iconsArray = useMemo(() => {
    const iconsArray000: {
      iconTimeInPixels: number;
      type: 'division' | 'interval';
      slicesIndex: number;
    }[] = [];

    if (toPixels && newAmplitudes && duration !== undefined && slices.length > 0) {
      let timeInPixelsPreviuos = 0;

      slices.forEach((slice, index) => {
        const start = toPixels({ x: slice, y: 0 });
        iconsArray000.push({
          iconTimeInPixels: (timeInPixelsPreviuos + start.x) / 2,
          type: 'interval',
          slicesIndex: index - 1,
        });
        iconsArray000.push({
          iconTimeInPixels: start.x,
          type: 'division',
          slicesIndex: index,
        });
        timeInPixelsPreviuos = start.x;
      });

      const timeInPixelsLast = toPixels({ x: duration, y: 0 }).x;

      iconsArray000.push({
        iconTimeInPixels: (timeInPixelsLast + timeInPixelsPreviuos) / 2,
        type: 'interval',
        slicesIndex: Number.MAX_SAFE_INTEGER,
      });
    }

    return iconsArray000;
  }, [duration, newAmplitudes, slices, toPixels]);

  useEffect(() => {
    if (!iconsArray || !canvasHeaderBarRef.current) {
      return;
    }
    const canvas = canvasHeaderBarRef.current as HTMLCanvasElement;
    const ctxHeaderBar = canvas.getContext('2d', { alpha: true });
    if (!ctxHeaderBar) {
      return;
    }

    ctxHeaderBar.clearRect(0, 0, canvasWidth, canvasHeight);
    ctxHeaderBar.lineWidth = 2;
    ctxHeaderBar.beginPath();
    for (const iconParam of iconsArray) {
      const iconTimeInPixels = iconParam.iconTimeInPixels;
      switch (iconParam.type) {
        case 'division': {
          ctxHeaderBar.strokeStyle = PINK_COLOR;
          ctxHeaderBar.beginPath();
          ctxHeaderBar.moveTo(iconTimeInPixels - ICON_HALF_SIZE, 0);
          ctxHeaderBar.lineTo(iconTimeInPixels + ICON_HALF_SIZE, ICON_HALF_SIZE * 2);
          ctxHeaderBar.moveTo(iconTimeInPixels - ICON_HALF_SIZE, ICON_HALF_SIZE * 2);
          ctxHeaderBar.lineTo(iconTimeInPixels + ICON_HALF_SIZE, 0);
          ctxHeaderBar.closePath();
          ctxHeaderBar.stroke();
          break;
        }
        case 'interval': {
          ctxHeaderBar.strokeStyle = BLUE_COLOR;
          ctxHeaderBar.beginPath();
          ctxHeaderBar.moveTo(iconTimeInPixels - ICON_HALF_SIZE * 1.41, 1);
          ctxHeaderBar.lineTo(iconTimeInPixels, ICON_HALF_SIZE * 2);
          ctxHeaderBar.lineTo(iconTimeInPixels + ICON_HALF_SIZE * 1.41, 1);
          ctxHeaderBar.lineTo(iconTimeInPixels - ICON_HALF_SIZE * 1.41, 1);
          ctxHeaderBar.closePath();
          ctxHeaderBar.stroke();
          break;
        }
      }
    }
  }, [canvasHeight, canvasWidth, iconsArray]);

  useEffect(() => {
    if (!canvasHeaderBarRef.current || newAmplitudes === undefined) {
      return;
    }
    const canvas = canvasHeaderBarRef.current as HTMLCanvasElement | undefined;
    const ctxHeaderBar = canvas?.getContext('2d', { alpha: true });
    if (!ctxHeaderBar) {
      return;
    }

    const mouseUp = (event: MouseEvent) => {
      const xScreen = event.offsetX;
      if (!xScreen && !duration) {
        return;
      }

      for (const iconParam of iconsArray) {
        if (Math.abs(xScreen - iconParam.iconTimeInPixels) < ICON_HALF_SIZE) {
          switch (iconParam.type) {
            case 'division': {
              const newSlices = [...slices];
              newSlices.splice(iconParam.slicesIndex, 1);
              setSlices(newSlices);
              break;
            }
            case 'interval': {
              let t1 = 0;
              let t2 = 0;
              let shiftIndex = -1;
              const newSlices = [...slices];
              if (iconParam.slicesIndex === Number.MAX_SAFE_INTEGER) {
                newSlices.splice(newSlices.length - 1, 1); // последний интервал
                t1 = slices[slices.length - 1];
                t2 = duration ?? 0;
                shiftIndex = Number.MAX_SAFE_INTEGER;
              } else {
                if (iconParam.slicesIndex === -1) {
                  newSlices.splice(0, 1); // первый интервал
                  t1 = 0;
                  t2 = slices[0];
                  shiftIndex = 0;
                } else {
                  newSlices.splice(iconParam.slicesIndex, 2);
                  shiftIndex = iconParam.slicesIndex;
                  t1 = slices[iconParam.slicesIndex];
                  t2 = slices[iconParam.slicesIndex + 1];
                }
              }
              const newSlices000 = newSlices.map((slice, index) =>
                index >= shiftIndex ? slice + t1 - t2 : slice,
              );
              setSlices(newSlices000);
              if (duration) {
                let index1 = Math.trunc((newAmplitudes.length * t1) / duration);
                let index2 = Math.trunc((newAmplitudes.length * t2) / duration);
                if (index1 >= newAmplitudes.length) {
                  index1 = newAmplitudes.length - 1;
                }
                if (index2 >= newAmplitudes.length) {
                  index2 = newAmplitudes.length - 1;
                }
                const newCutIntervals: number[][] = [
                  ...cutIntervals,
                  [newAmplitudes[index1].rawTime, newAmplitudes[index2].rawTime],
                ];
                newCutIntervals.sort((a, b) => a[0] - b[0]);
                let i = 0;
                while (newCutIntervals[i] !== undefined && newCutIntervals[i + 1] !== undefined) {
                  // очистка включающих друг друга интевалов
                  if (newCutIntervals[i][1] > newCutIntervals[i + 1][1]) {
                    newCutIntervals.splice(i + 1, 1);
                  } else {
                    i += 1;
                  }
                }
                setCutIntervals(newCutIntervals);
              }
              break;
            }
          }
        }
      }
    };
    if (canvas) {
      canvas.addEventListener('mouseup', mouseUp);
      //@ts-ignore
      canvas.addEventListener('touchend', mouseUp);
      //@ts-ignore
      canvas.addEventListener('touchcancel', mouseUp);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('mouseup', mouseUp);
        //@ts-ignore
        canvas.removeEventListener('touchend', mouseUp);
        //@ts-ignore
        canvas.removeEventListener('touchcancel', mouseUp);
      }
    };
  }, [cutIntervals, duration, iconsArray, newAmplitudes, setCutIntervals, setSlices, slices]);

  return <canvas ref={canvasHeaderBarRef} height={CANVAS_HEADER_BAR_HEIGHT} width={canvasWidth} />;
}
