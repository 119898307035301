import { useContext, useState, useEffect, useMemo, useCallback } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import UnitIcon from 'icons/unit-icon';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { CustomBreadcrumbs } from 'components/breadcrums';
import { ContextSettings } from 'context/context-settings';
import { SimpleMnemoSchemeRenderer } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-list/components/simple-mnemo-scheme-renderer';
import {
  useGetDefectsDict,
  useGetElementsList,
} from 'pages-diagnostic/diagnostic-spectrum/query-hooks/query-hooks';
import {
  IGrouppedSubElements,
  subElementUnicId,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import { useGetDiagnosticById } from 'pages-diagnostic/hooks';
import {
  DIAGNOSTIC_SPECTRUM,
  DIAGNOSTIC_SPECTRUM_SUMMARY_LIST,
  DIAGNOSTICS_KEY,
  EMPTY_OBJECT,
} from 'stream-constants';

import { ArrowIcon } from './components/arrow-icon';
import { ElementListItem } from './components/element-list-item';
import { Legenda } from './components/legenda';

const deDivinaProportione = (x: number) => Math.round(x * 0.46);
const GAP_TO_MENMOSCHEME = 24;

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
  },
})(MuiAccordion);
const AccordionSummary = withStyles({
  root: {
    margin: '0',
    backgroundColor: '#ffffff',
    minHeight: 56,
    maxHeight: 56,
    '&$expanded': {
      minHeight: 56,
      maxHeight: 56,
    },
  },
  content: {
    margin: '6px 0 0 0',
    padding: 0,
    '&$expanded': {
      margin: '6px 0 0 0',
      padding: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    backgroundColor: '#ffffff',
  },
}))(MuiAccordionDetails);

type IProps = { layoutSize: { width: number; height: number } };

const useStyles = makeStyles<Theme, IProps>((theme) =>
  createStyles({
    layoutMargin: {
      marginTop: 28,
    },
    groupRow: {
      minHeight: 56,
      maxHeight: 56,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    containerColumns: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      gap: GAP_TO_MENMOSCHEME,
    },
    listSticky: {
      position: 'sticky',
      top: 0,
      width: (props) => deDivinaProportione(props.layoutSize.width),
      height: '100%',
    },
    mnemoschemeContainer: {
      position: 'sticky',
      top: 0,
    },
    mnemoschemeHeader: {
      fontWeight: 400,
      fontSize: '14px',
      color: '#626C77',
      margin: '34px 0 28px 0',
    },
    mnemoscheme: {
      padding: 16,
      border: '1px solid #DADEE5',
      borderRadius: '16px',
    },
    buttonContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    buttonStyle: {
      width: '50%',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#000',
      margin: '20px 0 0 0',
    },

    isEmptyList: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '28px',
      color: '#000',
      margin: '14px 0 0 0',
    },

    headersOfTheTable: {
      margin: '-26px 0 -16px 0',
    },

    nodeNameStyle: {
      fontSize: '17px',
      fontWeight: 500,
      color: '#1D2023',
    },
    unitIconMargin: {
      margin: '4px 6px 0 10px',
    },
    iconRotate2: {
      transform: 'rotate(90deg)',
    },
    iconRotate1: {
      transition: 'all 0.3s',
    },
    headerAndButtonContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerStyle: {
      margin: '28px 0 10px 0',
      fontWeight: 500,
      fontSize: '20px',
    },

    textButtonWidth: {
      width: 200,
    },
    textButtonLabel: {
      paddingLeft: 8,
      width: '100%',
      display: 'flex',
      color: '#007CFF',
    },
    legendaContainer: {
      marginTop: 12,
    },
    legendaDiagColor: {
      backgroundColor: '#000',
    },
    legendaNonDiagColor: {
      backgroundColor: '#990',
    },
    legendaActiveColor: {
      backgroundColor: '#DC032D',
    },
    legendaPreviousColor: {
      backgroundColor: '#0097FD',
    },
  }),
);
const EMPTY_ARRAY = Object.freeze([]);

function scrollTo(theId: string) {
  const theElement = document.getElementById(theId);
  if (theElement) {
    theElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
  }
}

export function DiagnosticSpectrumList() {
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const { layoutSize, lastModifiedSpectrumElement, setLastModifiedSpectrumElement } =
    useContext(ContextSettings);

  const [hoveredElementsMnemonicId, setHoveredElementsMnemonicId] = useState<string | undefined>();
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());

  const classes = useStyles({ layoutSize });

  const diagnosticQuery = useGetDiagnosticById(params.id);

  const { description } = diagnosticQuery.data ?? {};
  const elementListQuery = useGetElementsList(params?.id);
  const elementGrouppedList = (elementListQuery?.data?.data ??
    EMPTY_ARRAY) as IGrouppedSubElements[];

  const scrollIntoTheElement = useCallback(
    function (id: string) {
      for (let group of elementGrouppedList) {
        const theItem = group.nodeSubElements.find((item) => item.elementGuideLinkId === id);
        if (theItem) {
          setExpandedNodes((oldSet) => new Set([...oldSet, group.nodeName]));
          const theId = subElementUnicId(theItem?.elementGuideLinkId ?? '');

          setTimeout(() => {
            theId && scrollTo(theId);
            setTimeout(() => theId && scrollTo(theId), 600);
          }, 50);
          break;
        }
      }
    },
    [elementGrouppedList],
  );

  const { firstElementMnemonicLinkId, lastModifiedSpectrumElementMnemonicLinkId, enabledMemoIds } =
    useMemo(() => {
      let firstElementMnemonicLinkId000: string | undefined = undefined;
      let lastModifiedSpectrumElementMnemonicLinkId000: string | undefined = undefined;
      let enabledMemoIds000 = new Set<string>();

      for (let group of elementGrouppedList) {
        const { nodeSubElements } = group;

        const elem = nodeSubElements.find(
          (item) => hoveredElementsMnemonicId === item.mnemonicSchemeLinkId,
        );
        if (elem) {
          firstElementMnemonicLinkId000 = elem.mnemonicSchemeLinkId;
        }
        const elem2 = nodeSubElements.find(
          (item) => item.elementGuideLinkId === lastModifiedSpectrumElement,
        );
        if (elem2) {
          lastModifiedSpectrumElementMnemonicLinkId000 = elem2.mnemonicSchemeLinkId;
        }

        for (const item of nodeSubElements) {
          enabledMemoIds000.add(item.mnemonicSchemeLinkId);
        }
      }

      return {
        firstElementMnemonicLinkId: firstElementMnemonicLinkId000,
        lastModifiedSpectrumElementMnemonicLinkId: lastModifiedSpectrumElementMnemonicLinkId000,
        enabledMemoIds: enabledMemoIds000,
      };
    }, [elementGrouppedList, hoveredElementsMnemonicId, lastModifiedSpectrumElement]);

  useEffect(() => {
    lastModifiedSpectrumElement && scrollIntoTheElement(lastModifiedSpectrumElement);
  }, [lastModifiedSpectrumElement, scrollIntoTheElement]);

  const mnemonicScheme = elementListQuery?.data?.mnemonicScheme;

  const defectsDictQuery = useGetDefectsDict();
  const { defectsDict = EMPTY_OBJECT } = defectsDictQuery.data ?? {};

  const handleOnClick = (itemId: string) => {
    setLastModifiedSpectrumElement(itemId);
    history.push(`/${DIAGNOSTIC_SPECTRUM}/${params.id}/${itemId}`);
  };

  const breadcrubms = useMemo(
    () => [
      {
        label: `Диагностика \u00AB${description}\u00BB`,
        handleOnClick: () => history.push(`/${DIAGNOSTICS_KEY}/${params.id}`),
      },
      {
        label: 'Список саб-элементов для дефектов',
        handleOnClick: () => {},
      },
    ],
    [description, history, params.id],
  );

  const legendaItems = [
    {
      colorClassName: classes.legendaDiagColor,
      label: 'Диагностируемый',
    },
    {
      colorClassName: classes.legendaNonDiagColor,
      label: 'Недиагностируемый',
    },
    {
      colorClassName: classes.legendaActiveColor,
      label: 'Активный',
    },
    {
      colorClassName: classes.legendaPreviousColor,
      label: 'Предыдущий',
    },
  ];

  function firstSelectedElementIdBymnemonicSchemeLinkId(mnemonicSchemeLinkId: string | undefined) {
    if (mnemonicSchemeLinkId === undefined) {
      return undefined;
    }
    let firstSelectedElementId: string | undefined;
    for (let group of elementGrouppedList) {
      const theItems = group.nodeSubElements.filter(
        (item) => item.mnemonicSchemeLinkId === mnemonicSchemeLinkId,
      );
      for (let theItem of theItems) {
        if (!firstSelectedElementId) {
          firstSelectedElementId = theItem.elementGuideLinkId;
        }
      }
    }

    return firstSelectedElementId;
  }

  return (
    <div className={classNames('layout-min-height-2', classes.layoutMargin)}>
      <CustomBreadcrumbs links={breadcrubms} />

      <div className={classes.headerTitle}>{'Список саб-элементов для дефектов'}</div>

      <div className={classes.containerColumns}>
        <div className={classes.listSticky}>
          {elementGrouppedList.length === 0 && (
            <div className={classes.isEmptyList} key={'empty-list'}>
              --- Список саб-элементов пуст ---
            </div>
          )}
          <div className={classes.headerAndButtonContainer}>
            <div className={classes.headerStyle}>Узлы</div>
            <Button
              className={classes.textButtonWidth}
              variant="text"
              onClick={() => {
                if (expandedNodes.size < elementGrouppedList.length) {
                  const newExpandedNodes = new Set(
                    elementGrouppedList.map((node) => node.nodeName),
                  );
                  setExpandedNodes(newExpandedNodes);
                } else {
                  setExpandedNodes(new Set());
                }
              }}
            >
              <div className={classes.textButtonLabel}>
                {expandedNodes.size < elementGrouppedList.length ? 'Открыть всё' : 'Свернуть всё'}
              </div>
            </Button>
          </div>

          {(elementGrouppedList.length ?? 0) > 0 &&
            elementGrouppedList.map((node) => (
              <div key={node.nodeName}>
                <Accordion
                  expanded={expandedNodes.has(node.nodeName)}
                  onChange={() => {
                    const newExpandedNodes = new Set([...expandedNodes]);
                    if (expandedNodes.has(node.nodeName)) {
                      newExpandedNodes.delete(node.nodeName);
                    } else {
                      newExpandedNodes.add(node.nodeName);
                    }
                    setExpandedNodes(newExpandedNodes);
                  }}
                >
                  <AccordionSummary
                    aria-controls={`panel-${node.nodeName}-content`}
                    id={`panel-${node.nodeName}-content`}
                  >
                    <div className={classes.groupRow}>
                      <div
                        className={classNames(classes.iconRotate1, {
                          [classes.iconRotate2]: expandedNodes.has(node.nodeName),
                        })}
                      >
                        <ArrowIcon style={{ fontSize: 10 }} />
                      </div>
                      <div className={classes.unitIconMargin}>
                        <UnitIcon />
                      </div>

                      <div className={classes.nodeNameStyle}>{node.nodeName}</div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div>
                      <div className={classes.headersOfTheTable}>
                        <ElementListItem
                          isHeader={true}
                          itemWidth={deDivinaProportione(layoutSize.width)}
                          index=""
                          item={undefined}
                          defectsDict={defectsDict}
                          hoveredElementsMnemonicId={undefined}
                          setHoveredElementsMnemonicId={() => {}}
                        />
                      </div>
                      {node?.nodeSubElements?.map((item, index) => (
                        <ElementListItem
                          key={item.elementGuideLinkId}
                          itemWidth={deDivinaProportione(layoutSize.width)}
                          index={`${index + 1}`}
                          item={item}
                          defectsDict={defectsDict}
                          isHeader={false}
                          onGotoTheSpectrumClick={handleOnClick}
                          lastModifiedSpectrumElement={lastModifiedSpectrumElement}
                          hoveredElementsMnemonicId={hoveredElementsMnemonicId}
                          setHoveredElementsMnemonicId={setHoveredElementsMnemonicId}
                        />
                      ))}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
        </div>
        <div>
          <div className={classes.mnemoschemeContainer}>
            <div className={classes.mnemoscheme}>
              <SimpleMnemoSchemeRenderer
                enabledMemoIds={enabledMemoIds}
                mnemonicScheme={mnemonicScheme}
                redMnemoId={firstElementMnemonicLinkId ?? ''}
                greenMnemoId={''}
                blueMnemoId={lastModifiedSpectrumElementMnemonicLinkId ?? ''}
                handleMnemoElementClicked={(id) => {
                  setHoveredElementsMnemonicId(id);
                  const firstSelectedElementId = firstSelectedElementIdBymnemonicSchemeLinkId(id);
                  firstSelectedElementId && scrollIntoTheElement(firstSelectedElementId);
                }}
                layout={{
                  width: `${layoutSize.width - deDivinaProportione(layoutSize.width) - 138}px`,
                  height: `${layoutSize.height - 330}px`,
                }}
              />
              <div className={classes.legendaContainer}>
                <Legenda items={legendaItems} />
              </div>
            </div>
            <br />
            <div className={classes.buttonContainer}>
              <Button
                disabled={!elementGrouppedList.find((item) => item.nodeSubElements?.length > 0)}
                className={classes.buttonStyle}
                variant="contained"
                onClick={() => {
                  history.push(`/${DIAGNOSTIC_SPECTRUM_SUMMARY_LIST}/${params.id}`);
                }}
                color="primary"
              >
                {'К выводам'}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
