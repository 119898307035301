import React, { useMemo, useState } from 'react';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import { CustomStatesCheckBox, ICheckBoxState } from 'components/custom-states-check-box';
import { StyledCheckbox } from 'components/styled-checkbox';
import { ArrowDownIcon } from 'icons/custom-checkbox-icons/arrow-down-icon';
import { ISortParamsOrder } from 'interfaces';
import { useGetFrequencyGroupsColorsDict } from 'pages-diagnostic/diagnostic-spectrum/query-hooks/query-hooks';
import {
  IRawGropsAndPeaksData,
  IPreparedPeaksMap,
  IHoveredOrigin,
  IPeaksColor,
  getGroupsColors,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

import { ISortParamsWhat } from './constants';
import { RoundedButton } from './rounded-button';

const Accordion = withStyles({
  root: {
    border: 'none',
    boxShadow: 'none',
    backgroundColor: '#ffffff',
  },
  expanded: {},
})(MuiAccordion);
const AccordionSummary = withStyles({
  root: {
    paddingLeft: '0 !important',
    margin: '0 !important',
  },
  content: {
    margin: '0px 0 0 0',
    padding: 0,
    '&$expanded': {
      margin: 0,
      padding: 0,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
  },
}))(MuiAccordionDetails);
interface Props {
  height: number;
  width: number;
}

const MENU_HEIGHT_DELTA = 320;

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    menuArea: {
      minHeight: (props) => `${props.height - MENU_HEIGHT_DELTA}px`,
      maxHeight: (props) => `${props.height - MENU_HEIGHT_DELTA}px`,
      overflowY: 'scroll',
      overflowX: 'hidden',
    },
    groupNameRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      alignItems: 'center',
    },
    checkBoxIcon: {
      marginTop: 6,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: 2,
      alignItems: 'center',
    },
    sortIconRow: {
      display: 'flex',
    },
    sortIconRotate: {
      transform: 'rotate(180deg)',
    },
    rowLine: {
      borderBottom: '1px solid rgba(188, 195, 208, 0.5)',
    },

    hoverStyle: {
      backgroundColor: '#eee',
      borderRadius: 8,
    },
    frequencyNameHeaderStyleBold: {
      fontWeight: 700,
      fontSize: '14px',
    },
    frequencyNameStyleFont: {
      fontWeight: 400,
      fontSize: '14px',
    },
    frequencyNameStyle: {
      paddingLeft: 4,
      minWidth: '110px',
      maxWidth: '110px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    frequencyColumnTitle: {
      paddingLeft: '8px',
      minWidth: '118px',
      maxWidth: '118px',
      fontSize: '12px',
      fontWeight: 300,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    delimeter: {
      minWidth: 1,
      height: 32,
      backgroundColor: 'rgba(188, 195, 208, 0.5)',
      margin: '0 -4px 0 2px',
    },
    frequencyParamStyle: {
      minWidth: '40px',
      maxWidth: '40px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    frequencyParamStyle2: {
      minWidth: '50px',
      maxWidth: '50px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
    },
    emptySpaceForCheckbox: {
      paddingLeft: 24,
    },
    cutMargin: {
      marginTop: -12,
    },
  }),
);

const DEFAULT_SORT_PARAMS = {
  what: ISortParamsWhat.F,
  order: ISortParamsOrder.ASC,
};

function getNewSortParams(
  currentSortParams: { what: ISortParamsWhat; order: ISortParamsOrder },
  click: ISortParamsWhat,
) {
  if (currentSortParams.what === click) {
    return {
      what: click,
      order:
        currentSortParams.order === ISortParamsOrder.ASC
          ? ISortParamsOrder.DESC
          : ISortParamsOrder.ASC,
    };
  }

  return { what: click, order: ISortParamsOrder.ASC };
}

export const FrequenciesMenu = React.memo(function ({
  expandedGroups,
  hoverGroupName,
  hoverPeakName,
  modalLayoutSize,
  peaksData,
  preparedPeaks,
  selectedDirectFrequencies,
  selectedEnvelopFrequencies,
  setExpandedGroups,
  setHoverArea,
  setHoverGroupName,
  setHoverPeakName,
  setSelectedDirectFrequencies,
  setSelectedEnvelopFrequencies,
  isLikelyToBeAFreqDefectFlags,
  groupCheckboxValues,
}: {
  expandedGroups: Set<string>;
  hoverGroupName: string;
  hoverPeakName: { name: string; origin: IHoveredOrigin } | undefined;
  modalLayoutSize: { height: number; width: number };
  peaksData: IRawGropsAndPeaksData[] | undefined;
  preparedPeaks: IPreparedPeaksMap | undefined;
  selectedDirectFrequencies: Set<string>;
  selectedEnvelopFrequencies: Set<string>;
  setExpandedGroups: (e: Set<string>) => void;
  setHoverArea: (a: number | undefined) => void;
  setHoverGroupName: (i: string) => void;
  setHoverPeakName: (i: { name: string; origin: IHoveredOrigin } | undefined) => void;
  setSelectedDirectFrequencies: (s: Set<string>) => void;
  setSelectedEnvelopFrequencies: (s: Set<string>) => void;
  isLikelyToBeAFreqDefectFlags?: string[];
  groupCheckboxValues: ICheckBoxState[];
}) {
  const classes = useStyles(modalLayoutSize);

  const [sortParams, setSortParams] = useState<
    Map<String, { what: ISortParamsWhat; order: ISortParamsOrder }>
  >(new Map());

  const frequencyGroupsColorsDictData = useGetFrequencyGroupsColorsDict() as {
    data: Record<string, IPeaksColor> | undefined;
  };

  const isLikelyToBeAFreqDefectFlagsSet = useMemo(
    () => new Set(isLikelyToBeAFreqDefectFlags ?? []),
    [isLikelyToBeAFreqDefectFlags],
  );

  const sortedPeaksData = useMemo(
    () =>
      peaksData?.map((group) => {
        const gropuSortParams = sortParams.get(group.groupName) ?? DEFAULT_SORT_PARAMS;
        const newGroupData = [...(group.data ?? [])];
        newGroupData.sort((a, b) => {
          let result = 0;
          switch (gropuSortParams.what) {
            case ISortParamsWhat.F: {
              result = parseInt(b.frequencyName) - parseInt(a.frequencyName);
              if (isNaN(result) || result === 0) {
                result = b.frequencyName > a.frequencyName ? 1 : -1;
              }
              break;
            }
            case ISortParamsWhat.DY: {
              result = (b.dyEnvelop ?? 0) - (a.dyEnvelop ?? 0);
              if (result === 0) {
                result = parseInt(b.frequencyName) - parseInt(a.frequencyName);
                if (isNaN(result) || result === 0) {
                  result = b.frequencyName > a.frequencyName ? 1 : -1;
                }
              }
              break;
            }
          }
          if (gropuSortParams.order === ISortParamsOrder.ASC) {
            result = -result;
          }

          return result;
        });

        return { ...group, data: newGroupData };
      }) ?? [],
    [peaksData, sortParams],
  );

  return (
    <div className={classes.menuArea}>
      {sortedPeaksData?.map((group, index) => {
        const gropuSortParams = sortParams.get(group.groupName) ?? DEFAULT_SORT_PARAMS;
        const groupColor = getGroupsColors(group.groupId, frequencyGroupsColorsDictData?.data);

        return (
          <div key={group.groupName}>
            <Accordion
              TransitionProps={{ unmountOnExit: true }}
              expanded={expandedGroups.has(group.groupName)}
              onChange={() => {
                const newExpandedGroups = new Set([...expandedGroups]);
                if (expandedGroups.has(group.groupName)) {
                  newExpandedGroups.delete(group.groupName);
                } else {
                  newExpandedGroups.add(group.groupName);
                }
                setExpandedGroups(newExpandedGroups);
              }}
            >
              <AccordionSummary
                aria-controls={`panel-${group.groupName}-content`}
                id={`panel-${group.groupName}-content`}
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: hoverGroupName === group.groupName ? classes.hoverStyle : undefined,
                }}
              >
                <Tooltip title={group.groupName} placement="top">
                  <div
                    className={classes.groupNameRow}
                    onMouseEnter={() => {
                      setHoverArea(undefined);
                      setHoverPeakName(undefined);
                      setHoverGroupName(group.groupName);
                    }}
                    onMouseLeave={() => setHoverGroupName('')}
                  >
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <div className={classes.checkBoxIcon}>
                        <CustomStatesCheckBox
                          state={groupCheckboxValues?.[index] ?? ICheckBoxState.NONE}
                          onClick={(state: ICheckBoxState) => {
                            const newSelectedEnvelopFrequencies = new Set([
                              ...selectedEnvelopFrequencies,
                            ]);
                            const newSelectedDirectFrequencies = new Set([
                              ...selectedDirectFrequencies,
                            ]);
                            switch (state) {
                              case ICheckBoxState.SOME:
                                return;

                              case ICheckBoxState.ALL:
                                group.data?.forEach((frequency) => {
                                  newSelectedEnvelopFrequencies.add(frequency.frequencyName);
                                  newSelectedDirectFrequencies.add(frequency.frequencyName);
                                });
                                break;

                              case ICheckBoxState.ZERO_FREQ:
                                group.data?.forEach((frequency) => {
                                  if ((frequency.dyEnvelop ?? 0) === 0) {
                                    newSelectedEnvelopFrequencies.delete(frequency.frequencyName);
                                  } else {
                                    newSelectedEnvelopFrequencies.add(frequency.frequencyName);
                                  }
                                  if ((frequency.dySpectrum ?? 0) === 0) {
                                    newSelectedDirectFrequencies.delete(frequency.frequencyName);
                                  } else {
                                    newSelectedDirectFrequencies.add(frequency.frequencyName);
                                  }
                                });
                                break;

                              case ICheckBoxState.NONE:
                                group.data?.forEach((frequency) => {
                                  newSelectedEnvelopFrequencies.delete(frequency.frequencyName);
                                  newSelectedDirectFrequencies.delete(frequency.frequencyName);
                                });
                            }
                            setSelectedEnvelopFrequencies(newSelectedEnvelopFrequencies);
                            setSelectedDirectFrequencies(newSelectedDirectFrequencies);
                          }}
                        />
                      </div>
                    </div>
                    <RoundedButton
                      color={groupColor}
                      name={group.groupName}
                      predictFlag={isLikelyToBeAFreqDefectFlagsSet.has(group.groupId)}
                    />
                  </div>
                </Tooltip>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.cutMargin}>
                  <div
                    className={classNames(
                      classes.row,
                      classes.rowLine,
                      classes.frequencyNameHeaderStyleBold,
                    )}
                  >
                    <div className={classes.frequencyNameStyle}></div>
                    <div className={classes.delimeter} />
                    <div className={classes.frequencyColumnTitle}>Спектр огибающей</div>
                    <div className={classes.delimeter} />
                    <div className={classes.frequencyColumnTitle}>
                      <span>Прямой спектр</span>
                      <span>
                        <IconButton
                          size="small"
                          onClick={() => setSelectedDirectFrequencies(new Set())}
                        >
                          <CloseIcon style={{ fontSize: 14 }} color={'primary'} />
                        </IconButton>
                      </span>
                    </div>
                  </div>

                  <div
                    className={classNames(
                      classes.row,
                      classes.rowLine,
                      classes.frequencyNameHeaderStyleBold,
                    )}
                  >
                    <div className={classes.frequencyNameStyle}>
                      <div className={classes.sortIconRow}>
                        {'f'}
                        <div
                          className={classNames({
                            [classes.sortIconRotate]:
                              gropuSortParams.what === ISortParamsWhat.F
                                ? gropuSortParams.order === ISortParamsOrder.ASC
                                : true,
                          })}
                        >
                          <IconButton
                            size="small"
                            onClick={() => {
                              const newSortParams = new Map([...sortParams]);
                              newSortParams.set(
                                group.groupName,
                                getNewSortParams(gropuSortParams, ISortParamsWhat.F),
                              );
                              setSortParams(newSortParams);
                            }}
                          >
                            <ArrowDownIcon
                              style={{ fontSize: 18 }}
                              color={
                                gropuSortParams.what === ISortParamsWhat.F ? 'primary' : 'secondary'
                              }
                            />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                    <div className={classes.delimeter} />
                    <div className={classes.emptySpaceForCheckbox} />
                    <div className={classNames(classes.frequencyParamStyle)}>{'dx,%'}</div>
                    <div className={classNames(classes.frequencyParamStyle2)}>
                      {'dy,%'}
                      <div
                        className={classNames({
                          [classes.sortIconRotate]:
                            gropuSortParams.what === ISortParamsWhat.DY
                              ? gropuSortParams.order === ISortParamsOrder.ASC
                              : true,
                        })}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            const newSortParams = new Map([...sortParams]);
                            newSortParams.set(
                              group.groupName,
                              getNewSortParams(gropuSortParams, ISortParamsWhat.DY),
                            );
                            setSortParams(newSortParams);
                          }}
                        >
                          <ArrowDownIcon
                            style={{ fontSize: 18 }}
                            color={
                              gropuSortParams.what === ISortParamsWhat.DY ? 'primary' : 'secondary'
                            }
                          />
                        </IconButton>
                      </div>
                    </div>

                    <div className={classes.delimeter} />
                    <div className={classes.emptySpaceForCheckbox} />
                    <div className={classNames(classes.frequencyParamStyle)}>{'dx,%'}</div>
                    <div className={classNames(classes.frequencyParamStyle)}>{'dy,%'}</div>
                  </div>
                  {group.data?.map((frequency) => {
                    const preparedPeak = preparedPeaks?.get(frequency.frequencyName);

                    return (
                      <div
                        id={preparedPeak?.unicId}
                        key={frequency.frequencyName}
                        className={classNames(
                          classes.row,
                          classes.rowLine,
                          classes.frequencyNameStyleFont,
                          {
                            [classes.hoverStyle]: hoverPeakName?.name === frequency.frequencyName,
                          },
                        )}
                        onMouseEnter={() => {
                          setHoverArea(undefined);
                          setHoverPeakName({
                            name: frequency.frequencyName,
                            origin: IHoveredOrigin.BOTH,
                          });
                        }}
                        onMouseLeave={() => setHoverPeakName(undefined)}
                      >
                        <Tooltip title={group.groupName} placement="top">
                          <div
                            className={classes.frequencyNameStyle}
                            style={{ color: groupColor.circle }}
                          >
                            {frequency.frequencyName}
                          </div>
                        </Tooltip>
                        <div className={classes.delimeter} />
                        <StyledCheckbox
                          checked={selectedEnvelopFrequencies.has(frequency.frequencyName)}
                          onChange={(_, value) => {
                            const newSelectedEnvelopFrequencies = new Set([
                              ...selectedEnvelopFrequencies,
                            ]);
                            if (value) {
                              newSelectedEnvelopFrequencies.add(frequency.frequencyName);
                            } else {
                              newSelectedEnvelopFrequencies.delete(frequency.frequencyName);
                            }
                            setSelectedEnvelopFrequencies(newSelectedEnvelopFrequencies);
                          }}
                        />

                        <div className={classes.frequencyParamStyle}>
                          {frequency.dxEnvelop?.toFixed(2) ?? '-'}
                        </div>
                        <div className={classes.frequencyParamStyle}>
                          {frequency.dyEnvelop === null || frequency.dyEnvelop === undefined
                            ? '-'
                            : frequency.dyEnvelop.toFixed(0)}
                        </div>

                        <div className={classes.delimeter} />
                        <StyledCheckbox
                          checked={selectedDirectFrequencies.has(frequency.frequencyName)}
                          onChange={(_, value) => {
                            const newSelectedDirectFrequencies = new Set([
                              ...selectedDirectFrequencies,
                            ]);
                            if (value) {
                              newSelectedDirectFrequencies.add(frequency.frequencyName);
                            } else {
                              newSelectedDirectFrequencies.delete(frequency.frequencyName);
                            }
                            setSelectedDirectFrequencies(newSelectedDirectFrequencies);
                          }}
                        />
                        <div className={classes.frequencyParamStyle}>
                          {frequency.dxSpectrum?.toFixed(2) ?? '-'}
                        </div>
                        <div className={classes.frequencyParamStyle}>
                          {frequency.dySpectrum === null || frequency.dySpectrum === undefined
                            ? '-'
                            : (frequency.dySpectrum * 1).toFixed(0)}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </div>
  );
});
