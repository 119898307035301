import { IMode } from 'interfaces';

export const base: { machineId: string; modes: IMode[] }[] = [
  {
    machineId: '1',
    modes: [
      {
        unitId: '1627550330639',
        id: '1111',
        shaftsOrder: ['1627444400205', '1627550400205', '1627550409975', '1627550441500'],
        resultShaftId: '1627550441500',
        order: 50,
        combination: [
          {
            id: '1627550935720',
            outputSpeed: '781 об/мин',

            order: 1,
            isDiagnostic: false,
            shafts: [
              {
                elemOutId: '162220aClutch1',
                elemInId: '',
              },
              {
                elemOutId: '1627550513326',
                elemInId: '162220aClutch2',
              },
              {
                elemInId: '1627550569583',
                elemOutId: '1627550569583',
              },
              {
                elemInId: '1627550632551',
                elemOutId: '',
              },
            ],
          },
          {
            id: '1627551058390',
            isDiagnostic: true,
            outputSpeed: '156 об/мин',

            order: 2,
            shafts: [
              {
                elemOutId: '162220aClutch1',
                elemInId: '',
              },
              {
                elemOutId: '1627550513326',
                elemInId: '162220aClutch2',
              },
              {
                elemInId: '1627550569583',
                elemOutId: '1627550580909',
              },
              {
                elemInId: '1627550643853',
                elemOutId: '',
              },
            ],
          },
          {
            id: '1627551058333390',
            outputSpeed: '94 об/мин',

            order: 3,
            isDiagnostic: true,
            shafts: [
              {
                elemOutId: '162220aClutch1',
                elemInId: '',
              },
              {
                elemOutId: '1627550513326',
                elemInId: '162220aClutch2',
              },
              {
                elemInId: '1627550569583',
                elemOutId: '1627550580909',
              },
              {
                elemInId: '1627550643853',
                elemOutId: '',
              },
            ],
          },
        ],
      },
    ],
  },
];
