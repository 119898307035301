import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function OrderAscIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 13 13'}>
      <path
        d="M2.24803 1.25C2.24803 0.835786 2.58382 0.5 2.99803 0.5H10.998C11.4122 0.5 11.748 0.835786 11.748 1.25C11.748 1.66421 11.4122 2 10.998 2H2.99803C2.58382 2 2.24803 1.66421 2.24803 1.25Z"
        fill="black"
      />
      <path
        d="M6.99933 5.25398C6.99933 4.83977 7.33511 4.50398 7.74933 4.50398H12.2473C12.6615 4.50398 12.9973 4.83977 12.9973 5.25398C12.9973 5.66819 12.6615 6.00398 12.2473 6.00398H7.74933C7.33511 6.00398 6.99933 5.66819 6.99933 5.25398Z"
        fill="black"
      />
      <path
        d="M7.74933 8.50007C7.33511 8.50007 6.99933 8.83586 6.99933 9.25007C6.99933 9.66429 7.33511 10.0001 7.74933 10.0001H12.2473C12.6615 10.0001 12.9973 9.66429 12.9973 9.25007C12.9973 8.83586 12.6615 8.50007 12.2473 8.50007H7.74933Z"
        fill="black"
      />
      <path
        d="M1.37869 9C0.751305 9 0.400153 9.69597 0.784494 10.1918L1.10736 10.6082C1.82832 11.538 2.1888 12.0029 2.64617 12.1242C2.76212 12.155 2.88104 12.1706 3 12.171C3.12242 12.1715 3.24488 12.1559 3.36421 12.1242C3.82158 12.0029 4.18205 11.538 4.90301 10.6082L5.22588 10.1918C5.61022 9.69597 5.25907 9 4.63169 9C4.39176 9 4.1655 9.11136 4.01853 9.301L3.74994 9.6474C3.76597 9.62661 3.77948 9.60879 3.77573 9.61333C3.76737 9.62343 3.75877 9.6333 3.74994 9.64293V4.75C3.74994 4.33579 3.41415 4 2.99994 4C2.58572 4 2.24994 4.33579 2.24994 4.75V9.63125C2.24475 9.62536 2.23966 9.61939 2.23465 9.61333C2.23176 9.60984 2.2391 9.61947 2.24994 9.63357L1.99185 9.301C1.84487 9.11136 1.61861 9 1.37869 9Z"
        fill="black"
      />
    </SvgIcon>
  );
}
