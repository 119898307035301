import { useState } from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { DetailedElemsListModal } from './detailed-elems-list-modal';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    layout: {
      marginTop: -10,
      height: 45,
      borderRadius: 8,
      paddingLeft: 4,
      cursor: 'pointer',
      display: 'flex',
      gap: 10,
      alignItems: 'center',
    },
    checkIcon: {
      paddingTop: '4px',
      transform: 'scale(0.8)',
    },
    roundedButtonTextMessage: {
      maxWidth: 426,
      borderRadius: '100px',
      padding: '12px 16px',
      fontSize: '15px',
      fontWeight: 500,
      color: '#1D2023',
      backgroundColor: '#F2F3F7',
      '&:hover': {
        backgroundColor: '#ddd',
      },
    },
    title: {
      color: '#1D2023',
      fontWeight: 400,
      fontSize: '17px',
    },
    subLabel: {
      fontWeight: 400,
      fontSize: '17px',
      color: '#626C77',
    },
  }),
);

type IProps = {
  machineLinkId: string | undefined;
  disabled?: boolean;
  viewOnly?: boolean;
  defaultSummaryValue?: string;
  defaultUnitsArray?: string[];
  defaultElementsArray?: string[][];
  detailedInformationToView?: string;
};

export const DetailedElemsListModalLinkViewOnly = ({
  machineLinkId,
  defaultSummaryValue,
  defaultUnitsArray,
  defaultElementsArray,
  detailedInformationToView,
}: IProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const summary = defaultSummaryValue;

  return (
    <>
      <div className={classes.layout} onClick={() => setOpen(true)}>
        <div className={classes.title}>{'Детальная настройка станка'}</div>
        {summary ? <div className={classes.roundedButtonTextMessage}>{summary}</div> : <div> </div>}
      </div>
      <DetailedElemsListModal
        open={open}
        setOpen={setOpen}
        defaultUnits={defaultUnitsArray || []}
        defaultElements={defaultElementsArray}
        viewOnly={true}
        machineLinkId={machineLinkId}
        detailedInformationToView={detailedInformationToView}
        onResultSelect={(result: any) => {}}
      />
    </>
  );
};
