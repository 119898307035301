import { IDiagnostic, IDiagnosticStatus, IDiagnosticType } from 'interfaces';
import * as yup from 'yup';

import {
  REQUIRED_ERR_MESSAGE,
  STRING_MUST_BE_LONGER_THAN,
  STRING_MUST_BE_SHORTER_THAN,
} from './validatian-constants';

export const diagnosticgValidationSchema: yup.SchemaOf<IDiagnostic> = yup.object().shape({
  id: yup.string().required(),
  creatorEmail: yup.string().notRequired(),
  creatorFullName: yup.string().notRequired(),
  factoryTown: yup
    .string()
    .required(REQUIRED_ERR_MESSAGE)
    .min(3, STRING_MUST_BE_LONGER_THAN + '2')
    .max(30, STRING_MUST_BE_SHORTER_THAN + '30'),
  factoryName: yup
    .string()
    .notRequired()
    .max(100, STRING_MUST_BE_SHORTER_THAN + '100'),
  description: yup
    .string()
    .required(REQUIRED_ERR_MESSAGE)
    .min(3, STRING_MUST_BE_LONGER_THAN + '2')
    .max(200, STRING_MUST_BE_SHORTER_THAN + '200'),
  machineInventoryNumber: yup
    .string()
    .required(REQUIRED_ERR_MESSAGE)
    .min(3, STRING_MUST_BE_LONGER_THAN + '2')
    .max(100, STRING_MUST_BE_SHORTER_THAN + '100'),

  status: yup.mixed<IDiagnosticStatus>().oneOf(Object.values(IDiagnosticStatus)).notRequired(),

  machineLinkId: yup.string().required(),
  machineLinkName: yup.string().required(REQUIRED_ERR_MESSAGE),
  machineLinkPhoto: yup.string().notRequired(),
  measuringFileMeta: yup.string().notRequired(),
  measuringFileId: yup.string().notRequired(),
  measuringGuidUrl: yup.string().notRequired(),
  measuringGuidPdf: yup.string().notRequired(),
  reportPdfLink: yup.string().notRequired(),
  reportExcelLink: yup.string().notRequired(),
  isChecked: yup.boolean().notRequired(),

  approverEmail: yup.string().notRequired(),
  approverFullName: yup.string().notRequired(),
  creationDate: yup.string().notRequired(),

  isArchive: yup.boolean().notRequired(),
  isTest: yup.boolean().notRequired(),

  params: yup
    .object()
    .shape({
      diagnosticType: yup.string().notRequired(),
      units: yup.array().of(yup.string()).notRequired(),
      comments: yup.array().of(yup.object()).notRequired(),
      measurementsTotalLabel: yup.string().notRequired(),
      measurementsAreUploaded: yup.boolean().notRequired(),
      defectsTotalLabel: yup.string().notRequired(),
      summariesTotalLabel: yup.string().notRequired(),
      reportIsNotPossibleMessage: yup.string().notRequired(),
      elementsSummaryLabel: yup.string().required(REQUIRED_ERR_MESSAGE),
      elements: yup.array().of(yup.array().of(yup.string())).notRequired(),
      detailedInformationToView: yup.string().notRequired(),
      machineData: yup.string().notRequired(),
      manualCombinations: yup.array().of(yup.string()).notRequired(),

      manualCombinationsTextMessage: yup
        .string()
        .when('diagnosticType', (diagnosticType: string | undefined) => {
          if (diagnosticType === IDiagnosticType.MANUAL) {
            return yup.string().required('не выбраны режимы работы');
          } else {
            return yup.string().notRequired();
          }
        }),
    })
    .notRequired(),
});
