import { IQueryParamsFilter } from 'interfaces';

import { AutocompleteForFilters, IFIltersOptionsItem } from './autocomplete-for-filters';

export function StandAllon({
  name,
  label,
  filters,
  queryFiltersDictionaries,
  handleFiltersChange,
}: {
  name: string;
  label: string;
  filters: IQueryParamsFilter[];
  queryFiltersDictionaries: {
    isLoading: boolean;
    data: { [key: string]: IFIltersOptionsItem[] } | undefined;
  };
  handleFiltersChange: (arr: { id: string; value: any }[]) => void;
}) {
  const filterValue = filters.find((item) => item.id === name)?.value as any;

  const filterOptions = queryFiltersDictionaries?.data?.[name] ?? [];

  return (
    <AutocompleteForFilters
      loading={!!queryFiltersDictionaries.isLoading}
      label={label}
      options={filterOptions}
      onOptionClick={(option: any) => {
        const newFilters = [...filters].filter((item) => item.id !== name);
        if (option) {
          newFilters.push({ id: name, value: option });
        }
        handleFiltersChange(newFilters);
      }}
      value={filterValue}
    />
  );
}
