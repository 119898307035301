import StepConnector from '@material-ui/core/StepConnector';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { RED_COLOR } from 'stream-constants';

export const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: RED_COLOR,
    },
  },
  completed: {
    '& $line': {
      borderColor: RED_COLOR,
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: RED_COLOR,
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: RED_COLOR,
    zIndex: 1,
    fontSize: 18,
  },
});

export function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={classNames(classes.root, {
        [classes.active]: active || completed,
      })}
    >
      <div className={classes.circle} />
    </div>
  );
}
