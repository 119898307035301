import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function PredictIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 16 16'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.90497 14.095C0.810913 13.001 0.72979 11.911 0.567544 9.73098C0.525486 9.16587 0.5 8.58294 0.5 8C0.5 7.41706 0.525486 6.83413 0.567544 6.26902C0.72979 4.08903 0.810913 2.99903 1.90497 1.90497C2.99903 0.810912 4.08903 0.729789 6.26902 0.567544C6.83413 0.525485 7.41706 0.5 8 0.5C8.58294 0.5 9.16587 0.525485 9.73098 0.567544C11.911 0.729789 13.001 0.810912 14.095 1.90497C15.1891 2.99904 15.2702 4.08903 15.4325 6.26902C15.4745 6.83413 15.5 7.41706 15.5 8C15.5 8.58294 15.4745 9.16587 15.4325 9.73098C15.2702 11.911 15.1891 13.001 14.095 14.095C13.001 15.1891 11.911 15.2702 9.73098 15.4325C9.16587 15.4745 8.58294 15.5 8 15.5C7.41706 15.5 6.83413 15.4745 6.26902 15.4325C4.08903 15.2702 2.99903 15.1891 1.90497 14.095ZM8 8.83333C8.46024 8.83333 8.83333 8.46023 8.83333 7.99999L8.83332 4.6687C8.83332 4.20846 8.46023 3.83537 7.99999 3.83537C7.53975 3.83537 7.16666 4.20847 7.16666 4.6687L7.16667 8C7.16667 8.46023 7.53977 8.83333 8 8.83333ZM9.03978 11.3343C9.03978 10.7595 8.57383 10.2936 7.99905 10.2936C7.42428 10.2936 6.95833 10.7595 6.95833 11.3343C6.95833 11.909 7.42428 12.375 7.99905 12.375C8.57383 12.375 9.03978 11.909 9.03978 11.3343Z"
        fill="#434A51"
      />
    </SvgIcon>
  );
}
