import React from 'react';

import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Tooltip from '@material-ui/core/Tooltip';
import { IDiagnosticStatus } from 'interfaces';

import styles from '../styles.module.css';

import { QontoConnector, QontoStepIcon } from './qonto-connector';

const STEP_LABELS = ['Создана', 'В работе', 'Готова'];

export const CustomStepper = React.memo(function ({
  status,
}: {
  status: IDiagnosticStatus | undefined;
}) {
  let activeStep = 0;

  switch (status) {
    case IDiagnosticStatus.CREATED:
      activeStep = 0;
      break;
    case IDiagnosticStatus.MEASUREMENTS_UPLOADED:
      activeStep = 1;
      break;
    case IDiagnosticStatus.CHECKED:
      activeStep = 2;
      break;
  }

  return (
    <div className={styles.stepper}>
      <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
        {STEP_LABELS.map((label, index) => (
          <Step key={label}>
            <Tooltip title={label} placement="top">
              <StepLabel StepIconComponent={QontoStepIcon}></StepLabel>
            </Tooltip>
          </Step>
        ))}
      </Stepper>
    </div>
  );
});
