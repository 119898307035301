import { FC, useState, useMemo } from 'react';

import { IScreenSize, INotificationMessage } from 'interfaces';
import { IMainUploadDirectory } from 'pages-diagnostic/upload-measure-files-from-folder/folder-constants';
import { IFolder } from 'pages-diagnostic/upload-measure-files-from-folder/folder-constants';
import { MAX_SCREEN_WIDTH, DEFAULT_SCREEN_HEIGHT } from 'stream-constants';

import { ContextSettings, IFolderListManagerData } from './context-settings';

const ContextProvider: FC = ({ children }) => {
  const [lastModifiedBearingId, setLastModifiedBearingId] = useState<string | undefined>();
  const [lastModifiedMachineId, setLastModifiedMachineId] = useState<string | undefined>();
  const [lastModifiedDiagnosticId, setLastModifiedDiagnosticId] = useState<string | undefined>();
  const [paramsForNewDiagnostic, setParamsForNewDiagnostic] = useState<any>();

  const [layoutSize, setLayoutMinHeightAndWidth] = useState<IScreenSize>({
    height: DEFAULT_SCREEN_HEIGHT,
    width: MAX_SCREEN_WIDTH,
  });
  const [notificationMessage, setNotificationMessage] = useState<
    INotificationMessage | undefined
  >();
  const [allMnemoElementsById, setAllMnemoElementsById] = useState<Record<string, HTMLElement[]>>();

  const [lastModifiedSpectrumElement, setLastModifiedSpectrumElement] = useState<any>(undefined);
  const [folderListManagerData, setFolderListManagerData] =
    useState<IFolderListManagerData>(undefined);

  const [mainUploadDirectoryByDiagnosticId, setMainUploadDirectoryByDiagnosticId] = useState<
    Record<string, IMainUploadDirectory | undefined>
  >({});

  const [globalFileList, setGlobalFileList] = useState<IFolder[] | undefined>(undefined);

  const value = useMemo(
    () => ({
      lastModifiedBearingId,
      setLastModifiedBearingId,
      lastModifiedMachineId,
      setLastModifiedMachineId,
      lastModifiedDiagnosticId,
      setLastModifiedDiagnosticId,
      layoutSize,
      setLayoutMinHeightAndWidth,
      notificationMessage,
      setNotificationMessage,
      paramsForNewDiagnostic,
      setParamsForNewDiagnostic,
      allMnemoElementsById,
      setAllMnemoElementsById,
      lastModifiedSpectrumElement,
      setLastModifiedSpectrumElement,
      folderListManagerData,
      setFolderListManagerData,
      mainUploadDirectoryByDiagnosticId,
      setMainUploadDirectoryByDiagnosticId,
      globalFileList,
      setGlobalFileList,
    }),
    [
      lastModifiedBearingId,
      lastModifiedDiagnosticId,
      lastModifiedMachineId,
      layoutSize,
      notificationMessage,
      paramsForNewDiagnostic,
      allMnemoElementsById,
      lastModifiedSpectrumElement,
      folderListManagerData,
      mainUploadDirectoryByDiagnosticId,
      globalFileList,
    ],
  );

  return <ContextSettings.Provider value={value}>{children}</ContextSettings.Provider>;
};

export default ContextProvider;
ContextProvider.displayName = 'ContextProvider';
