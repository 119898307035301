import { useEffect, useRef } from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import {
  COLOR,
  ISpectrumRawData,
  ENVELOP_AMPLITUDE_INDEX,
  ENVELOP_MOVING_AVG_INDEX,
  DIRECT_AMPLITUDE_INDEX,
  DIRECT_MOVING_AVG_INDEX,
  FREQUENCY_INDEX,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

import { usePrepareGraphParams } from '../hooks/use-prepare-graph-params';

type Props = { height: number; width: number };

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    chart: {
      position: 'absolute',
      top: 0,
      left: 0,
      rigth: 0,
      bottom: 0,
    },
  }),
);

export function SpectrumLayer1({
  canvasSize,
  graphParams,
  isEnvelop,
  spectrumData,
}: {
  canvasSize: { height: number; width: number };
  graphParams: ReturnType<typeof usePrepareGraphParams>;
  isEnvelop: boolean;
  spectrumData: ISpectrumRawData;
}) {
  const classes = useStyles(canvasSize);

  const canvasBaseRef = useRef<HTMLCanvasElement | null>(null);
  const { frequencyMin, frequencyMax, frequencyScaleStep } = graphParams;
  const { amplitudeMin, amplitudeMax, amplitudeScaleStep, toPixels } = isEnvelop
    ? graphParams.envelop ?? {}
    : graphParams.direct ?? {};

  const { height, width } = canvasSize;

  useEffect(() => {
    if (
      !canvasBaseRef.current ||
      spectrumData.length === 0 ||
      frequencyMin === undefined ||
      frequencyMax === undefined ||
      amplitudeMin === undefined ||
      amplitudeMax === undefined ||
      frequencyScaleStep === undefined ||
      amplitudeScaleStep === undefined ||
      toPixels === undefined
    ) {
      return;
    }
    const canvasBase = canvasBaseRef.current as HTMLCanvasElement;
    const ctxBase = canvasBase.getContext('2d', { alpha: false }); // не прозрачный
    if (!ctxBase) {
      return;
    }

    ctxBase.fillStyle = '#ffffff';
    ctxBase.clearRect(0, 0, width, height);
    ctxBase.fillRect(0, 0, width, height);
    ctxBase.fillStyle = COLOR.ordinates;
    ctxBase.font = '8px MTSSans';
    ctxBase.textAlign = 'center';
    // ********* контур *************************************************************************
    ctxBase.strokeStyle = COLOR.ordinates;
    ctxBase.lineWidth = 2;
    ctxBase.beginPath();
    const zero = toPixels({ x: frequencyMin, y: amplitudeMin });
    ctxBase.moveTo(zero.x, zero.y);
    const p2 = toPixels({ x: frequencyMax, y: amplitudeMin });
    ctxBase.lineTo(p2.x, p2.y);
    const p3 = toPixels({ x: frequencyMax, y: amplitudeMax });
    ctxBase.moveTo(p3.x, p3.y);
    const p4 = toPixels({ x: frequencyMin, y: amplitudeMax });
    ctxBase.moveTo(p4.x, p4.y);
    ctxBase.lineTo(zero.x, zero.y);
    ctxBase.closePath();
    ctxBase.stroke();
    // ********* amplitude coordinate lines *************************************************************************
    ctxBase.strokeStyle = COLOR.ordinatesNet;
    ctxBase.lineWidth = 1;
    ctxBase.beginPath();

    const amplitudeMinStart =
      Math.trunc(amplitudeMin / amplitudeScaleStep) * amplitudeScaleStep + amplitudeScaleStep;
    for (let i = amplitudeMinStart; i <= amplitudeMax; i += amplitudeScaleStep) {
      const start = toPixels({ x: frequencyMin, y: i });
      ctxBase.moveTo(start.x, start.y);
      const end = toPixels({ x: frequencyMax, y: i });
      ctxBase.lineTo(end.x, end.y);
    }
    // ********* frequency coordinate lines *************************************************************************
    const frequencyMinStart =
      Math.trunc(frequencyMin / frequencyScaleStep) * frequencyScaleStep + frequencyScaleStep;
    for (let i = frequencyMinStart; i <= frequencyMax; i += frequencyScaleStep) {
      const start = toPixels({ x: i, y: amplitudeMin });
      ctxBase.moveTo(start.x, start.y);
      const end = toPixels({ x: i, y: amplitudeMax });
      ctxBase.lineTo(end.x, end.y);
    }
    ctxBase.closePath();
    ctxBase.stroke();

    // ********* amplitude coordinate marks *************************************************************************
    ctxBase.strokeStyle = COLOR.ordinates;
    ctxBase.lineWidth = 1;
    ctxBase.beginPath();
    for (let i = amplitudeMinStart; i <= amplitudeMax; i += amplitudeScaleStep) {
      const start = toPixels({ x: frequencyMin, y: i });
      ctxBase.moveTo(start.x - 4, start.y);
      ctxBase.lineTo(start.x, start.y);
    }
    // ********* frequency coordinate marks *************************************************************************
    for (let i = frequencyMinStart; i <= frequencyMax; i += frequencyScaleStep) {
      const start = toPixels({ x: i, y: amplitudeMin });
      ctxBase.moveTo(start.x, start.y + 5);
      ctxBase.lineTo(start.x, start.y);
    }
    ctxBase.closePath();
    ctxBase.stroke();

    // ********* amplitude coordinate text and legenda *************************************************************************
    for (let i = amplitudeMinStart; i <= amplitudeMax; i += amplitudeScaleStep) {
      const start = toPixels({ x: frequencyMin, y: i });
      ctxBase.fillText(i.toFixed(0), start.x - 15, start.y + 3);
    }
    {
      ctxBase.save();
      ctxBase.font = '20px MTSSans';
      const start1 = toPixels({ x: frequencyMin, y: amplitudeMax });
      ctxBase.textAlign = 'center';
      ctxBase.fillText('dB', start1.x - 15, start1.y - 8);
      const start2 = toPixels({ x: frequencyMax, y: amplitudeMin });
      ctxBase.fillText('Hz', start2.x - 20, start2.y + 16);
      ctxBase.restore();
    }

    // ********* frequency coordinate text and legenda *************************************************************************
    for (let i = frequencyMinStart; i <= frequencyMax; i += frequencyScaleStep) {
      const start = toPixels({ x: i, y: amplitudeMin });
      ctxBase.fillText(i.toFixed(0), start.x, start.y + 15);
    }

    // ********* график **************************************************************************
    {
      ctxBase.strokeStyle = COLOR.line1;
      ctxBase.lineWidth = 1.5;
      ctxBase.beginPath();
      let index = 0;
      while ((spectrumData?.[index]?.[FREQUENCY_INDEX] ?? Number.MAX_VALUE) <= frequencyMin) {
        index += 1;
      }

      const start = toPixels({
        x: spectrumData?.[index]?.[FREQUENCY_INDEX] ?? 0,
        y: isEnvelop
          ? spectrumData[index][ENVELOP_AMPLITUDE_INDEX]
          : spectrumData[index][DIRECT_AMPLITUDE_INDEX],
      });
      ctxBase.moveTo(start.x, start.y);
      for (; index < spectrumData.length; index += 1) {
        const item = spectrumData[index];
        const freq = item[FREQUENCY_INDEX];
        if (freq > frequencyMax) {
          break;
        }
        const point = toPixels({
          x: freq,
          y: isEnvelop ? item[ENVELOP_AMPLITUDE_INDEX] : item[DIRECT_AMPLITUDE_INDEX],
        });
        ctxBase.lineTo(point.x, point.y);
      }

      ctxBase.moveTo(start.x, start.y);
      ctxBase.closePath();
      ctxBase.stroke();
    }

    // ********* график - средняя линия *********************************************************************
    {
      ctxBase.strokeStyle = COLOR.line2;
      ctxBase.lineWidth = 2;
      ctxBase.beginPath();

      let index = 0;
      while ((spectrumData?.[index]?.[FREQUENCY_INDEX] ?? Number.MAX_VALUE) <= frequencyMin) {
        index += 1;
      }

      const start = toPixels({
        x: spectrumData?.[index]?.[FREQUENCY_INDEX] ?? 0,
        y: isEnvelop
          ? spectrumData[index][ENVELOP_MOVING_AVG_INDEX]
          : spectrumData[index][DIRECT_MOVING_AVG_INDEX],
      });

      ctxBase.moveTo(start.x, start.y);
      for (; index < spectrumData.length; index += 1) {
        const item = spectrumData[index];
        const freq = item[FREQUENCY_INDEX];
        if (freq > frequencyMax) {
          break;
        }
        const point = toPixels({
          x: freq,
          y: isEnvelop ? item[ENVELOP_MOVING_AVG_INDEX] : item[DIRECT_MOVING_AVG_INDEX],
        });
        ctxBase.lineTo(point.x, point.y);
      }
      ctxBase.moveTo(start.x, start.y);
      ctxBase.closePath();
      ctxBase.stroke();
    }
  }, [
    amplitudeMax,
    amplitudeMin,
    amplitudeScaleStep,
    frequencyMax,
    frequencyMin,
    frequencyScaleStep,
    height,
    isEnvelop,
    spectrumData,
    toPixels,
    width,
  ]);

  return <canvas className={classes.chart} ref={canvasBaseRef} height={height} width={width} />;
}
