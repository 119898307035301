import {
  ICoordinate,
  ICanvasSizeProps,
  PADDING_LEFT,
  PADDING_RIGHT,
  PADDING_TOP,
  PADDING_BOTTOM,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

export const prepareGoordinatesToPixelsConvertionFunction = ({
  frequencyMin,
  frequencyMax,
  amplitudeMin,
  amplitudeMax,
  height,
  width,
}: ICanvasSizeProps) => {
  if (
    height === undefined ||
    width === undefined ||
    frequencyMin === undefined ||
    frequencyMax === undefined ||
    amplitudeMin === undefined ||
    amplitudeMax === undefined
  ) {
    throw new Error(
      `Не определены значения:
      height = ${height},
      width = ${width},
      frequencyMin = ${frequencyMin},
      frequencyMax = ${frequencyMax},
      amplitudeMin = ${amplitudeMin},
      amplitudeMax) = ${amplitudeMax}`,
    );
  }

  const xScale1 = (width - PADDING_LEFT - PADDING_RIGHT) / (frequencyMax - frequencyMin);
  const yScale1 = (height - PADDING_TOP - PADDING_BOTTOM) / (amplitudeMax - amplitudeMin);

  const toPixels = ({ x, y }: ICoordinate) => ({
    x: (x - frequencyMin) * xScale1 + PADDING_LEFT,
    y: height - (y - amplitudeMin) * yScale1 - PADDING_BOTTOM,
  });

  const freguencyFromXPixel = (x: number) => (x - PADDING_LEFT) / xScale1 + frequencyMin;

  return { toPixels, freguencyFromXPixel };
};
