import { useContext, useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useForm, useWatch } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { RadioGroupField } from 'components/fields/radio-group-field';
import { ContextSettings } from 'context/context-settings';
import {
  IDiagnosticStatus,
  IDiagnostic,
  DIAGNOSTIC_TYPE_OPTIONS,
  IElement,
  MODES_DICT_NAMES,
} from 'interfaces';
import { CustomPaper } from 'pages-diagnostic/components/custom-paper/custom-paper';
import {
  IRowElement,
  IRowPair,
  IDetailedInformationToView,
} from 'pages-diagnostic/components/detaled-elems-list-modal/common';
import { DetailedElemsListModalLink } from 'pages-diagnostic/components/detaled-elems-list-modal/detailed-elems-list-modal-link';
import { useMemoNodesElementsPairs } from 'pages-diagnostic/components/detaled-elems-list-modal/hook/use-get-machine-and-all-modes-by-machine-id';
import { DiagnosticManualTypeModal } from 'pages-diagnostic/components/diagnostic-manual-type-modal';
import { FieldMachineLink } from 'pages-diagnostic/components/machines-modal/field-machine-link';
import { DiagnosticBaseParamsForm } from 'pages-diagnostic/diagnostic-page/diagnostic-form/base-params-form';
import { useMutationCreateDiagnostic } from 'pages-diagnostic/hooks';
import { CREATE_NEW, DIAGNOSTICS_KEY } from 'stream-constants';
import { diagnosticgValidationSchema } from 'validation-schemas/validation-schema-diagnostic';

import { CardContainer } from './card-container';
import { ParametersIcon } from './icons/parameters-icon';

const useStyles = makeStyles((theme) => ({
  mainRow: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    gap: '32px',
  },

  mainFormLayout: {
    width: 900,
  },
  header: {
    fontWeight: 500,
    fontSize: '32px',
    margin: '32px 0',
    color: '#1D2023',
  },
  subHeaderContainer: {
    margin: '0 0 24px 2px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  subHeader: {
    fontWeight: 500,
    fontSize: '24px',
    color: '#1D2023',
  },
  subSubHeader: {
    fontWeight: 500,
    fontSize: '17px',
    margin: '14px 0 12px 2px',
    color: '#1D2023',
  },
  saveButton: {
    margin: '16px 0 0 0',
  },
  manualCombTextError: {
    fontSize: '11px',
    color: 'var(--main-red-color)',
    position: 'absolute',
    top: 42,
    left: 0,
    width: 260,
  },
  roundedButtonTextMessage: {
    position: 'relative',
    top: '8px',
    maxWidth: 226,
    minWidth: 226,
    background: '#F2F3F7',
    borderRadius: '100px',
  },
}));

const DEFAULT_DIAGNOSTIC = {
  id: CREATE_NEW,
  status: IDiagnosticStatus.CREATED,
  description: '',
  machineLinkId: '',
  machineLinkName: '',
  factoryTown: '',
  machineInventoryNumber: '',
} as unknown as IDiagnostic;

const pathOfUnitArray = 'params.units';
const pathOfElementsArray = 'params.elements';
const pathOfElementsSummaryLabel = 'params.elementsSummaryLabel';
const pathOfDiagnosticType = 'params.diagnosticType';
const pathOfManualCombinations = 'params.manualCombinations';
const pathOfManualCombinationsTextMessage = 'params.manualCombinationsTextMessage';

const makeElemName = (vertex: IElement) =>
  `${(vertex?.elementType && MODES_DICT_NAMES[vertex?.elementType]) ?? ''} ${vertex?.name ?? ''} ${
    vertex?.transmissionK ?? ''
  }`;

export function CreateDiagnostic() {
  const classes = useStyles();
  const history = useHistory();
  const { setNotificationMessage } = useContext(ContextSettings);

  const [diagnosticTypeManual, setDiagnosticTypeManual] = useState<boolean>(false);

  const form = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(diagnosticgValidationSchema),
    defaultValues: DEFAULT_DIAGNOSTIC,
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { errors },
  } = form;

  if (Object.keys(errors).length !== 0) {
    console.log('%c DiagnosticForm errors = ', 'color: red', errors);
  }

  const machineLinkId = useWatch({
    control: form.control,
    name: 'machineLinkId',
  });
  const diagnosticType = useWatch({
    control: form.control,
    name: pathOfDiagnosticType,
  });
  const manualCombinations = useWatch({
    control: form.control,
    name: pathOfManualCombinations,
  });
  const manualCombinationsTextMessage = useWatch({
    control: form.control,
    name: pathOfManualCombinationsTextMessage,
  });

  const {
    machine,
    vertexByIdDictionary,
    nodes,
    nodeElements,
    nodeCogWheelPairs,
    notLnkedElementKeys,
    mnemoschemeFileNotFound,
    combShaftsLengthError,
    vertexByMnemonicIdDictionary,
    allModes,
  } = useMemoNodesElementsPairs(machineLinkId ?? '');

  let comments = machine?.children?.map((node, index) => ({
    id: (index + 1).toString(),
    name: node.name,
    comment: node.comment ?? '',
  }));

  if (comments?.every((comment) => !comment.comment)) {
    comments = undefined;
  }

  useEffect(() => {
    if (vertexByIdDictionary.size > 0) {
      const unboundVIds: string[] = [];
      const unboundPointIds: string[] = [];
      const wrongReBound: string[] = [];
      const notLnkedPairKeys: string[] = [];

      // расчет дефолтных значений для поля - Детальная диагностик станка
      const newSelectedElementsKeys: string[][] = [];
      const newSelectedNodes: Set<string> = new Set();
      const tempMnemoId: Map<string, IElement> = new Map();

      function validateElement(elemVertex: IElement) {
        const elemName = makeElemName(elemVertex);
        if (!elemVertex?.mnemonicSchemeLinkId) {
          unboundVIds.push(elemName);
        }
        if (!elemVertex?.measuringPointLinkId) {
          unboundPointIds.push(elemName);
        }
        if (elemVertex?.mnemonicSchemeLinkId) {
          const oldVertex = tempMnemoId.get(elemVertex?.mnemonicSchemeLinkId);
          if (oldVertex?.id && elemVertex.id && oldVertex.id !== elemVertex.id) {
            const oldVertexName = makeElemName(oldVertex);
            wrongReBound.push(`${oldVertexName} и ${elemName}`);
          } else {
            tempMnemoId.set(elemVertex?.mnemonicSchemeLinkId, elemVertex);
          }
        }
      }

      for (const [nodeId, arr] of nodeElements) {
        newSelectedNodes.add(nodeId);
        arr.forEach((elem) => {
          const vertex = vertexByIdDictionary.get(elem.id)?.vertex as IElement;
          validateElement(vertex);
          newSelectedElementsKeys.push([vertex?.mnemonicSchemeLinkId ?? 'Нет mnemoId', '']);
        });
      }
      for (const [nodeId, pairs] of nodeCogWheelPairs) {
        newSelectedNodes.add(nodeId);
        pairs.forEach((pair) => {
          const vertex1 = vertexByIdDictionary.get(pair[0]?.id)?.vertex as IElement;
          validateElement(vertex1);
          const vertex2 = vertexByIdDictionary.get(pair[1]?.id)?.vertex as IElement;
          validateElement(vertex2);
          newSelectedElementsKeys.push([
            vertex1?.mnemonicSchemeLinkId ?? 'Нет mnemoId',
            vertex2?.mnemonicSchemeLinkId ?? 'Нет mnemoId',
          ]);
        });
      }

      notLnkedElementKeys.forEach((notLinkedId) => {
        const vertex1 = vertexByIdDictionary.get(notLinkedId)?.vertex as IElement;
        const notLinkedElemName = `${
          (vertex1?.elementType && MODES_DICT_NAMES[vertex1?.elementType]) ?? ''
        } ${vertex1?.name ?? ''} ${vertex1?.transmissionK ?? ''}`;
        notLnkedPairKeys.push(notLinkedElemName);
      });

      const errorTotal =
        unboundVIds.length +
        unboundPointIds.length +
        wrongReBound.length +
        notLnkedPairKeys.length +
        (combShaftsLengthError ? 1 : 0);

      const machineValidationErrors: string[] = [];

      mnemoschemeFileNotFound && machineValidationErrors.push('- не найден файл мнемосхемы');
      unboundVIds.length > 0 &&
        machineValidationErrors.push(`- нет привязки к мнемосхеме:\n  ${unboundVIds.join(', ')}`);
      unboundPointIds.length > 0 &&
        machineValidationErrors.push(
          `- нет привязки к точке измерения:\n  ${unboundPointIds.join(', ')}`,
        );
      wrongReBound.length > 0 &&
        machineValidationErrors.push(
          `- привязаны к одному и тому же id на мнемосхеме:\n  ${wrongReBound.join(', ')}`,
        );
      notLnkedPairKeys.length > 0 &&
        machineValidationErrors.push(
          `- не в паре (нет сцепления):\n  ${notLnkedPairKeys.join(', ')}`,
        );

      if (combShaftsLengthError) {
        machineValidationErrors.push(
          '- есть не соответствие числа валов и числа элементов в строке режимов работы станка',
        );
      }

      if (errorTotal > 0) {
        const message = `ВАЛИДАЦИОННОЕ ПРЕДУПРЕЖДЕНИЕ (ОШИБОК: ${errorTotal}) !\n\n${machineValidationErrors.join(
          '\n\n',
        )}`;
        setNotificationMessage({ fullMessage: '', header: message });
      }

      form.setValue(
        pathOfElementsSummaryLabel,
        `выбрано ${newSelectedNodes.size} из ${newSelectedNodes.size} узлов и 100% элементов`,
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
      form.setValue(pathOfUnitArray, [...newSelectedNodes], {
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue(pathOfElementsArray, newSelectedElementsKeys, {
        shouldDirty: true,
        shouldValidate: true,
      });
      form.setValue(pathOfDiagnosticType, DIAGNOSTIC_TYPE_OPTIONS[0].value, {
        shouldDirty: true,
        shouldValidate: true,
      });
    }
  }, [
    combShaftsLengthError,
    form,
    mnemoschemeFileNotFound,
    nodeCogWheelPairs,
    nodeElements,
    notLnkedElementKeys,
    setNotificationMessage,
    vertexByIdDictionary,
  ]);

  const mutationCreate = useMutationCreateDiagnostic(({ id }: { id: string }) => {
    history.push(`/${DIAGNOSTICS_KEY}/${id}`);
  });

  const createSubmit = (diagnostic: IDiagnostic) => {
    const detailedInformationToView: IDetailedInformationToView[] = [];

    const tempUnits = new Set(diagnostic.params?.units);

    const errMessages: string[] = [];
    const disabledManualNodesIds: Set<string> = new Set();

    const newDiagnostic = { ...diagnostic, params: { ...diagnostic.params } };

    if (diagnostic?.params?.diagnosticType === 'manual') {
      // валидация списка детальной настройки станка оносительно режимов работы станка
      if (!allModes) {
        errMessages.push('Нет режимов работы для этого станка');
      } else {
        const shaftsIds: Set<string> = new Set();
        for (let mode of allModes) {
          for (let combination of mode.combination) {
            if (manualCombinations?.find((item) => item === combination.id)) {
              for (let shaftId of mode.shaftsOrder) {
                shaftsIds.add(shaftId);
              }
            }
          }
        }
        // shaftsIds - все валы, которые входят в выбранные комбинации
        for (let node of machine?.children ?? []) {
          if (tempUnits.has(node.id)) {
            let disabled = true;
            for (let shaft of node.children) {
              if (shaftsIds.has(shaft.id)) {
                disabled = false;
              }
            }
            if (disabled) {
              disabledManualNodesIds.add(node.id);
            }
          }
        }
      }
    } else {
      delete newDiagnostic.params.manualCombinations;
      delete newDiagnostic.params.manualCombinationsTextMessage;
    }

    const tempElements = new Set(
      diagnostic.params?.elements
        ?.filter(([_, id2]) => id2 === '')
        ?.map(([id1, _]) => vertexByMnemonicIdDictionary.get(id1)?.vertex.id),
    );
    const tempPairs = new Set(
      diagnostic.params?.elements
        ?.filter(([_, id2]) => id2 !== '')
        ?.map(
          ([id1, id2]) =>
            `${vertexByMnemonicIdDictionary.get(id1)?.vertex.id}"${
              vertexByMnemonicIdDictionary.get(id2)?.vertex.id
            }`,
        ),
    );

    for (const node of nodes) {
      const elements: IRowElement[] | undefined =
        nodeElements
          ?.get(node.id)
          ?.map((elem) => ({ ...elem, selected: tempElements.has(elem.id) })) ?? [];

      const pairs: IRowPair[] | undefined =
        nodeCogWheelPairs
          ?.get(node.id)
          ?.map((pair) => ({ pair, selected: tempPairs.has(`${pair[0]?.id}"${pair[1]?.id}`) })) ??
        [];

      if (disabledManualNodesIds.has(node.id)) {
        errMessages.push(
          `Узел ${node.name} и все его элементы нужно исключить из детальной настройки станка, так как для него нет режимов работы`,
        );
      } else {
        detailedInformationToView.push({
          id: node.id,
          name: node.name,
          elements,
          pairs,
          selected: tempUnits.has(node.id),
        });
      }
    }
    if (errMessages.length > 0) {
      setNotificationMessage({
        header: `Валидация детальной настройки:\n${errMessages.join('\n')}`,
        fullMessage: '',
      });
    } else {
      mutationCreate.mutate({
        ...diagnostic,
        params: {
          ...diagnostic.params,
          detailedInformationToView: JSON.stringify(detailedInformationToView),
        },
      });
    }
  };

  const clearUnitsField = () => {
    form.setValue(pathOfDiagnosticType, DIAGNOSTIC_TYPE_OPTIONS[0].value, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };
  const handleDiagnosticTypeChange = (str: string | undefined) => {
    if (str === DIAGNOSTIC_TYPE_OPTIONS[2].value) {
      setDiagnosticTypeManual(true);
    }
  };
  const manualCombinationsTextMessageError = errors?.params?.manualCombinationsTextMessage?.message;

  return (
    <>
      <div className={classes.header}>Создание диагностики</div>
      <div className={classes.mainRow}>
        <div className={classes.mainFormLayout}>
          <CustomPaper>
            <form id="createNewDiagnostic" onSubmit={handleSubmit(createSubmit)} autoComplete="off">
              <div className={classes.subHeaderContainer}>
                <ParametersIcon style={{ fontSize: 22 }} />
                <div className={classes.subHeader}>Параметры диагностики</div>
              </div>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FieldMachineLink<IDiagnostic>
                    form={form}
                    pathOfName="machineLinkName"
                    pathOfMachineLinkId="machineLinkId"
                    pathOfMachinePhoto="machineLinkPhoto"
                    defaultValue={''}
                    onFieldChange={clearUnitsField}
                    placeholder="Выберите из списка"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <div
                    style={{ display: 'flex', alignItems: 'flex-end', gap: 10, marginBottom: 20 }}
                  >
                    <RadioGroupField
                      disabled={!machine}
                      fieldName={pathOfDiagnosticType}
                      label="Выбор типа диагностики"
                      form={form}
                      defaultValue={DIAGNOSTIC_TYPE_OPTIONS[0].value}
                      options={DIAGNOSTIC_TYPE_OPTIONS}
                      onFieldChange={handleDiagnosticTypeChange}
                    />
                    {diagnosticType === 'manual' && (
                      <div className={classes.roundedButtonTextMessage}>
                        <Button
                          id="id_for_manual_chosen"
                          variant="text"
                          color="secondary"
                          size="small"
                          onClick={() => setDiagnosticTypeManual(true)}
                        >
                          {manualCombinationsTextMessage}
                        </Button>
                        {manualCombinationsTextMessageError && (
                          <div className={classes.manualCombTextError}>
                            {manualCombinationsTextMessageError}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <DetailedElemsListModalLink
                    key={machineLinkId}
                    disabled={!machineLinkId}
                    viewOnly={false}
                    form={form}
                    pathOfElementsSummaryLabel={pathOfElementsSummaryLabel}
                    pathOfUnitArray={pathOfUnitArray}
                    pathOfElementsArray={pathOfElementsArray}
                    machineLinkId={machineLinkId}
                  />
                </Grid>
              </Grid>
              <div className={classes.subSubHeader}>Идентификация</div>
              <DiagnosticBaseParamsForm data={DEFAULT_DIAGNOSTIC} form={form} />
              <div className={classes.saveButton}>
                <Grid container spacing={0}>
                  <Grid item xs={4}>
                    <Button
                      id="id_button_create_diagnostic"
                      disabled={!form.formState.isDirty}
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Создать диагностику
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </form>
          </CustomPaper>
        </div>
        {machine?.id && (
          <CustomPaper>
            <CardContainer machine={machine} height={610} comments={comments} />
          </CustomPaper>
        )}
      </div>
      <DiagnosticManualTypeModal
        onResultSelect={({
          selectedCombinations,
          combinationsTotal,
        }: {
          selectedCombinations: string[] | undefined;
          combinationsTotal: number;
        }) => {
          form.setValue(pathOfManualCombinations, selectedCombinations, {
            shouldDirty: true,
            shouldValidate: true,
          });
          form.setValue(
            pathOfManualCombinationsTextMessage,
            `выбрано режимов ${selectedCombinations?.length ?? 0} / ${combinationsTotal}`,
            {
              shouldDirty: true,
              shouldValidate: true,
            },
          );
        }}
        open={diagnosticTypeManual}
        setOpen={setDiagnosticTypeManual}
        viewOnly={false}
        manualTypeInformation={manualCombinations}
        allModes={allModes}
        vertexByIdDictionary={vertexByIdDictionary}
      />
    </>
  );
}
