import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ParametersIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 20 22'}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 21.5C16.433 21.5 18 19.933 18 18L20.3333 18C20.9777 18 21.5 17.4777 21.5 16.8333C21.5 16.189 20.9777 15.6667 20.3333 15.6667L18 15.6667C18 13.7337 16.433 12.1667 14.5 12.1667H11C9.067 12.1667 7.5 13.7337 7.5 15.6667L1.66667 15.6667C1.02234 15.6667 0.5 16.189 0.500001 16.8333C0.500001 17.4777 1.02234 18 1.66667 18L7.5 18C7.5 19.933 9.06701 21.5 11 21.5H14.5ZM9.83333 18L9.83333 15.6667C9.83333 15.0223 10.3557 14.5 11 14.5H14.5C15.1443 14.5 15.6667 15.0223 15.6667 15.6667L15.6667 18C15.6667 18.6443 15.1443 19.1667 14.5 19.1667H11C10.3557 19.1667 9.83333 18.6443 9.83333 18Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 6.33333L1.66667 6.33333C1.02234 6.33333 0.5 5.811 0.5 5.16667C0.5 4.52234 1.02233 4 1.66667 4L4 4C4 2.067 5.567 0.5 7.5 0.5L11 0.5C12.933 0.5 14.5 2.067 14.5 4H20.3333C20.9777 4 21.5 4.52233 21.5 5.16667C21.5 5.811 20.9777 6.33333 20.3333 6.33333L14.5 6.33333C14.5 8.26633 12.933 9.83333 11 9.83333H7.5C5.567 9.83333 4 8.26633 4 6.33333ZM12.1667 4C12.1667 3.35567 11.6443 2.83333 11 2.83333L7.5 2.83333C6.85567 2.83333 6.33333 3.35567 6.33333 4V6.33333C6.33333 6.97767 6.85567 7.5 7.5 7.5H11C11.6443 7.5 12.1667 6.97766 12.1667 6.33333V4Z"
        fill="black"
      />
    </SvgIcon>
  );
}
