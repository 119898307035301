import { createContext } from 'react';

import { IScreenSize, INotificationMessage } from 'interfaces';
import { IMainUploadDirectory } from 'pages-diagnostic/upload-measure-files-from-folder/folder-constants';
import { IFolder } from 'pages-diagnostic/upload-measure-files-from-folder/folder-constants';
import { MAX_SCREEN_WIDTH, DEFAULT_SCREEN_HEIGHT } from 'stream-constants';

export type IFolderListManagerData =
  | { currentFolderName: string; diagnosticId: string }
  | undefined;

interface ISettingsContext {
  lastModifiedBearingId?: string;
  setLastModifiedBearingId: (id?: string) => void;
  lastModifiedMachineId?: string;
  setLastModifiedMachineId: (id?: string) => void;
  lastModifiedDiagnosticId?: string;
  setLastModifiedDiagnosticId: (id?: string) => void;
  layoutSize: IScreenSize;
  setLayoutMinHeightAndWidth: (size: IScreenSize) => void;
  notificationMessage?: INotificationMessage;
  setNotificationMessage: (m?: INotificationMessage) => void;
  paramsForNewDiagnostic?: any;
  setParamsForNewDiagnostic: (params?: any) => void;
  allMnemoElementsById?: Record<string, HTMLElement[]>;
  setAllMnemoElementsById: (elemsById: Record<string, HTMLElement[]>) => void;
  lastModifiedSpectrumElement?: any;
  setLastModifiedSpectrumElement: (elem: any) => void;
  folderListManagerData?: IFolderListManagerData;
  setFolderListManagerData: (elem: IFolderListManagerData) => void;
  mainUploadDirectoryByDiagnosticId?: Record<string, IMainUploadDirectory | undefined>;
  setMainUploadDirectoryByDiagnosticId: (
    info: Record<string, IMainUploadDirectory | undefined>,
  ) => void;
  globalFileList?: IFolder[];
  setGlobalFileList: (list: IFolder[] | undefined) => void;
}
const emptyFunction = (s?: string) => {};

export const ContextSettings = createContext<ISettingsContext>({
  lastModifiedBearingId: undefined,
  setLastModifiedBearingId: emptyFunction,
  lastModifiedMachineId: undefined,
  setLastModifiedMachineId: emptyFunction,
  lastModifiedDiagnosticId: undefined,
  setLastModifiedDiagnosticId: emptyFunction,
  layoutSize: { height: DEFAULT_SCREEN_HEIGHT, width: MAX_SCREEN_WIDTH },
  setLayoutMinHeightAndWidth: (size: IScreenSize) => {},
  notificationMessage: undefined,
  setNotificationMessage: (m?: INotificationMessage) => {},
  paramsForNewDiagnostic: undefined,
  setParamsForNewDiagnostic: emptyFunction,
  allMnemoElementsById: undefined,
  setAllMnemoElementsById: (elemsById: Record<string, HTMLElement[]>) => {},
  lastModifiedSpectrumElement: undefined,
  setLastModifiedSpectrumElement: (elem: any) => {},
  folderListManagerData: undefined,
  setFolderListManagerData: (elem: IFolderListManagerData) => {},
  mainUploadDirectoryByDiagnosticId: {},
  setMainUploadDirectoryByDiagnosticId: (
    info: Record<string, IMainUploadDirectory | undefined>,
  ) => {},
  globalFileList: undefined,
  setGlobalFileList: (list: IFolder[] | undefined) => {},
});
