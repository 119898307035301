/* eslint-disable no-shadow */

/**
 * Общие
 * ************************************************************
 */
export enum IHttpMethods {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
}

export enum ISortParamsOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type IQueryParamsFilter = {
  id: string;
  value: any;
};

type ISortItem = {
  field: string;
  type: 'asc' | 'desc';
};
export interface IQueryParams {
  sortings?: ISortItem[];
  filters?: IQueryParamsFilter[];
  searchString?: string;
  scrollTop?: number;
  offset?: number;
  pageSize?: number;
  activeTabLabel?: string;
  expandedVertexes?: any;
  selectedVertexes?: any;
  modalIsOpen?: boolean;
  columnsClosed?: string[];
  selectedMnemonicSchemeElemId?: string;
  modesOpenedId?: string;
  copyData?: any;
  tenantId?: string;
  tenantUserId?: string;
  tenantTnMachineId?: string;
  lastFocusedName?: string;
  tenantShowMachines?: boolean;
  order?: string;
}

/**
 * Станки
 * ************************************************************
 */

export enum IVertexType {
  MACHINE = 'Станок',
  NODE = 'Узел',
  SHAFT = 'Вал',
  ELEMENT = 'Элемент',
}

export const NODE_TYPES_ARRAY = Object.values(IVertexType);

export enum IElementType {
  NOTHING = 'NOTHING',
  RADIAL_BEARING = 'RADIAL_BEARING',
  TAPE_ROLL_BEARING = 'TAPE_ROLL_BEARING',
  TAPE_ROLL_BEARING_MAX = 'TAPE_ROLL_BEARING_MAX',
  TAPE_ROLL_BEARING_MIN = 'TAPE_ROLL_BEARING_MIN',
  COMBINED_BEARING = 'COMBINED_BEARING',
  BALLSCREW = 'BALLSCREW',
  COGWHEEL = 'COGWHEEL',
  BELT_TRANSMISSION = 'BELT_TRANSMISSION',
  BELT_TRANSMISSION_NOT_GEAR = 'BELT_TRANSMISSION_NOT_GEAR',
  WORM_GEAR = 'WORM_GEAR',
  CLUTCH = 'CLUTCH',
}

export const ELEMENT_TYPES_ARRAY = [
  { value: IElementType.NOTHING, label: '' },
  { value: IElementType.RADIAL_BEARING, label: 'Радиальный подшипник' },
  { value: IElementType.TAPE_ROLL_BEARING, label: 'Конический подшипник' },
  { value: IElementType.TAPE_ROLL_BEARING_MAX, label: 'Конический подшипник' },
  { value: IElementType.TAPE_ROLL_BEARING_MIN, label: 'Конический подшипник' },
  { value: IElementType.COMBINED_BEARING, label: 'Комбинированный подшипник' },
  { value: IElementType.BALLSCREW, label: 'Шарико-винтовая пара' },
  { value: IElementType.COGWHEEL, label: 'Зубчатое колесо' },
  { value: IElementType.BELT_TRANSMISSION, label: 'Зубчаторемённая передача' },
  { value: IElementType.BELT_TRANSMISSION_NOT_GEAR, label: 'Плоскоремённая передача' },
  { value: IElementType.WORM_GEAR, label: 'Червячная передача' },
  { value: IElementType.CLUTCH, label: 'Муфта' },
];

export const MODES_DICT_NAMES: Record<string, string> = {};
ELEMENT_TYPES_ARRAY.forEach(({ value, label }) => {
  MODES_DICT_NAMES[value] = label;
});

export interface IElement extends IVertexBaseParams {
  elementType: IElementType;
  elementLinkId?: string;
  transmissionK: string;
  beltLength: string;
  isUncertain?: number;
}

export interface IMachineShaft extends IVertexBaseParams {
  children?: IElement[];
}
export interface IOption {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface IOptionWithElementType extends IOption {
  param: string;
}
export interface IMachineNode extends IVertexBaseParams {
  comment?: string;
  children: IMachineShaft[];
}
export interface IVertexBaseParams {
  id: string;
  name: string;
  vertexType: IVertexType;
  description?: string;
  mnemonicSchemeLinkId?: string;
  measuringPointLinkId?: string;
  order?: number;
}

export interface IMachine extends IVertexBaseParams {
  rerenderId?: string;
  machineType: string;
  description: string;
  creatorData?: string;

  manufactured?: string; // завод производитель
  manufacturedYear?: string; //  - год выпуска
  inventoryNumber?: string; //  - инвентарный номер
  machineOwner?: string; // - завод-владелец

  mnemonicScheme?: string;
  mnemonicSchemeMeta?: string;
  isUncertain?: number;
  photo?: string;
  photoMeta?: string;
  dateOfCreation?: string;
  fullness?: boolean;
  validationErrorsCount?: number; // виртуальное поле для валидации станка

  children: IMachineNode[];
}

export type IEntity = IMachine | IMachineNode | IMachineShaft | IElement;
export interface IMapValue {
  vertex: IEntity;
  path: string;
  lastIndex: number;
  previousVertexesArray: string[];
}

/**
 * Подшипники
 * ************************************************************
 */

export enum IBearingType {
  UNKNOWN = 'UNKNOWN',
  RADIAL = 'radial',
  COMBINED = 'combined',
  TAPE_ROLL = 'tape-roll',
  BALLSCREW = 'ballscrew',
}

export interface IBearingRadial {
  id: string;
  name: string;
  articleNumber: string;
  manufactured: string;
  innerDiameter: number;
  outerDiameter: number;
  rollingElementsDiameter: number;
  numberOfRollingElements: number;
  contactAngle: number;
  fullness?: boolean;
  dateOfCreation?: string;
}

export interface IBearingTapeRoll {
  id: string;
  name: string;
  articleNumber: string;
  manufactured: string;
  innerDiameter: number;
  outerDiameter: number;
  rollingElementsDiameterMin: number;
  rollingElementsDiameterMax: number;
  numberOfRollingElements: number;
  contactAngle: number;
  fullness?: boolean;
  dateOfCreation?: string;
}
export interface IBearingBallscrew {
  id: string;
  name: string;
  articleNumber: string;
  manufactured: string;
  averageDiameter: number;
  numberOfRollingElements: number;
  pitchOfThread: number;
  diameterOfRollingElements: number;
  contactAngle: number;
  fullness?: boolean;
  dateOfCreation?: string;
}

export interface IBearingCombinedChild {
  id: string;
  name: string;
  elementType: IElementType;
  elementLinkId: string;
  fullness?: boolean;
}
export interface IBearingCombined {
  id: string;
  name: string;
  articleNumber: string;
  manufactured: string;
  children: IBearingCombinedChild[];
  fullness?: boolean;
  dateOfCreation?: string;
}

/**
 * IScreenSize
 */

export interface IScreenSize {
  height: number;
  width: number;
}

/**
 * Modes
 */

export interface IModeCombination {
  id: string;
  outputSpeed: string;
  isDiagnostic: boolean;
  order: number;
  shafts: {
    elemInId: string;
    elemOutId: string;
  }[];
}

export interface IMode {
  unitId: string;
  id: string;
  shaftsOrder: string[];
  resultShaftId: string;
  combination: IModeCombination[];
  order: number;
}

export type IModes = Omit<IMode, 'combination'>[];

/**
 * Diagnostic
 */

export enum IDiagnosticStatus {
  CREATED = 'CREATED',
  MEASUREMENTS_UPLOADED = 'MEASUREMENTS_UPLOADED',
  CHECKED = 'CHECKED',
}

// export const DIAGNOSTIC_STATUS_LABEL: Record<IDiagnosticStatus, string> = {
//   CREATING: 'Cоздание (редактирование)',
//   ROUTE_DOWNLOADING: 'Получение маршрута',
//   MEASURMENTS_UPLOADING: 'Загрузка измерений',
//   ADD_DEFECTS_AND_SUMMARY: 'Добавить дефекты и выводы',
//   REPORT_PROCESSING: 'Подготовка отчёта',
//   REPORT_IS_READY: 'Отчёт готов',
//   REPORT_DOWNLOADED: 'Отчёт получен',
// };

// export const DIAGNOSTIC_STATUS_ARRAY = Object.values(IDiagnosticStatus);
// export const DIAGNOSTIC_STATUS_INDEX: Record<string, number> = {};
// Object.values(IDiagnosticStatus).forEach((value, index) => {
//   DIAGNOSTIC_STATUS_INDEX[value] = index;
// });

export enum IDiagnosticType {
  EXPRESS = 'express',
  DETAILED = 'detailed',
  MANUAL = 'manual',
}

export const DIAGNOSTIC_TYPE_OPTIONS: IOption[] = [
  { value: IDiagnosticType.EXPRESS, label: 'Экспресс диагностика' },
  { value: IDiagnosticType.DETAILED, label: 'Расширенная диагностика' },
  { value: IDiagnosticType.MANUAL, label: 'Ручная настройка режимов' },
];

export interface IManualSelectedMode {
  id: string;
  name: string;
  modes: {
    motorInputSpeed: number;
    outputSpeed: string;
    selected: boolean;
  }[];
}

export interface IDiagnostic {
  id: string;
  factoryTown: string;
  factoryName?: string;
  approverEmail?: string;
  approverFullName?: string;
  creatorEmail?: string;
  creatorFullName?: string;
  description: string;
  machineLinkId: string;
  machineLinkName?: string;
  machineLinkPhoto?: string;
  machineInventoryNumber: string;
  status?: IDiagnosticStatus;
  isChecked?: boolean;
  measuringGuidUrl?: string; // id маршрута измерений в формате JSON
  measuringGuidPdf?: string; // id маршрута измерений в формате PDF
  measuringFileMeta?: string;
  measuringFileId?: string;
  reportPdfLink?: string;
  reportExcelLink?: string;
  creationDate?: string;
  isArchive?: boolean;
  isTest?: boolean;
  params?: {
    diagnosticType?: string;
    measurementsTotalLabel?: string;
    defectsTotalLabel?: string;
    summariesTotalLabel?: string;
    measurementsAreUploaded?: boolean;
    units?: string[];
    comments?:
      | {
          id: string;
          name: string;
          comment: string;
        }[]
      | undefined;

    elementsSummaryLabel?: string;
    elements?: string[][];
    detailedInformationToView?: string;
    machineData?: string;
    manualCombinations?: string[] | undefined;
    manualCombinationsTextMessage?: string | undefined;
    manualSelectedModes?: IManualSelectedMode[];
  };
}

export interface IErrorMessagePayload {
  url: string;
  name: string;
  status: number;
  method: string;
  body: string;
  response: any;
}

export interface INotificationMessage {
  header: string;
  fullMessage: string;
  fromServer?: boolean;
}

export enum IDownloadReportType {
  PDF = 'PDF',
  Excel = 'Excel',
}

export interface IDiagnostList {
  surname: string;
  name: string;
  patronymic: string | null;
  email: string;
  id: string;
}
