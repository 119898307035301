import { makeStyles } from '@material-ui/core/styles';
import { IDiagnostic, IDiagnostList } from 'interfaces';
import { useAssignDiagnosticToDiagnostById, useGetDiagnostListById } from 'pages-diagnostic/hooks';

import { CustomAutocomplete } from './custom-autocomplete';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 400,
    fontSize: '17px',
    color: '#1D2023',
  },

  row: {
    margin: '0px 0px 13px 3px',
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
}));

function makeUserOption(user: IDiagnostList | undefined) {
  return user && `${user.surname} ${user.name} ${user.email}`;
}

function getEmailFromOption(s: string | undefined) {
  const arr = s?.split(' ');

  return arr?.[2] ?? '';
}

function getUserByEmailFromOptions(email: string | undefined, users: IDiagnostList[] | undefined) {
  if (email === undefined || users === undefined) {
    return undefined;
  }
  const selectedUser = users.find((user) => user.email === email);

  return selectedUser;
}

export function AssignToDiagnost({
  data,
  onSuccess,
}: {
  data: IDiagnostic | undefined;
  onSuccess: () => void;
}) {
  const classes = useStyles();

  const diagnostListQuery = useGetDiagnostListById(data?.id);
  const users = diagnostListQuery.data;

  const dropdownTypeOptions = users?.map((user) => makeUserOption(user) ?? '') ?? [];

  const selectedUser = getUserByEmailFromOptions(data?.approverEmail, users);

  const selectedOption = makeUserOption(selectedUser);

  const mutationAssignDiagnosticToDiagnost = useAssignDiagnosticToDiagnostById(
    data?.id ?? 'undefined',
    () => {},
  );

  return (
    <div className={classes.row}>
      <div className={classes.label}>Диагност</div>

      <CustomAutocomplete
        width={360}
        loading={false}
        options={dropdownTypeOptions}
        onOptionClick={(option: string | undefined) => {
          const email = getEmailFromOption(option);
          const selectedUserId = getUserByEmailFromOptions(email, users)?.id;
          if (selectedUserId) {
            mutationAssignDiagnosticToDiagnost.mutate(selectedUserId);
            onSuccess();
          }
        }}
        value={selectedOption ?? data?.approverEmail ?? ''}
      />
    </div>
  );
}
