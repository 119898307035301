import { useEffect, useRef, useState, useContext } from 'react';

import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { ContextSettings } from 'context/context-settings';
import { IScreenSize } from 'interfaces';
import { ZOOM_STEP_WHEEL, ICoordinate } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import { RED_COLOR } from 'stream-constants';

import { IFolder } from '../folder-constants';
import { MainFolderIcon } from '../icons/main-folder-icon';

import { HeaderBar } from './header-bar';
import { useGetSignalToCutByCsvId, useUpdateSignalFileByCsvId } from './signal-editor-hooks';
import {
  CANVAS_HEIGHT,
  PINK_COLOR,
  BLUE_COLOR,
  GREY_COLOR,
  BLACK_COLOR,
  ITimeScaleZoomParams,
} from './slicing-editor-common';
import { SlicingEditorCustomScrollBar } from './slicing-editor-custom-scroll-bar';
import { usePrepareEditorParams } from './use-prepare-editor-params';

interface IPrors extends IScreenSize {
  readOnly: boolean;
}

const useStyles = makeStyles<Theme, IPrors>((theme) =>
  createStyles({
    root: {
      padding: '20px 15px',
    },
    title: {
      fontSize: '24px',
      fontWeight: 700,
      color: '##000000',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 14,
    },
    rowSmall: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 4,
    },
    headers: {
      marginTop: 20,
      fontWeight: 400,
      fontSize: '14px',
      color: '#626C77',
    },
    bodys: {
      marginTop: 6,
      fontWeight: 400,
      fontSize: '17px',
      color: '#1D2023',
    },
    folderName: {
      width: 460,
    },
    fileName: {
      width: 400,
    },
    speed: {
      width: 150,
    },
    emptySpace: {
      flexGrow: 1,
    },
    button: {
      width: 220,
    },
    chartContainer: {
      height: CANVAS_HEIGHT,
      width: (props) => props.width,
      position: 'relative',
      background: (props) => (props.readOnly ? GREY_COLOR : PINK_COLOR),
      borderRadius: 8,
    },
    chart: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    chartMovement: {
      cursor: 'grab',
    },
    red: {
      color: RED_COLOR,
    },
  }),
);

const DEFAULT_ZOOM_PARAMS = {
  center: 0,
  zoom: 1,
  weight: 0.5,
};

export function SlicingEditor({
  item,
  setClose,
  readOnly,
}: {
  item: IFolder;
  setClose: () => void;
  readOnly: boolean;
}) {
  const { layoutSize, setNotificationMessage } = useContext(ContextSettings);
  const canvasWidth = layoutSize.width - 200;
  const canvasSize = { height: CANVAS_HEIGHT, width: canvasWidth };

  const canvasBaseRef = useRef<HTMLCanvasElement | null>(null);
  const canvasHoverRef = useRef<HTMLCanvasElement | null>(null);
  const movementIndicatorRef = useRef<boolean>(false);
  const hoverIdleTimeIdRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  const [timeScaleZoomParams, setTimeScaleZoomParams] = useState<ITimeScaleZoomParams>({
    ...DEFAULT_ZOOM_PARAMS,
  });
  const [sliderDraggedStartPosition2, setSliderDraggedStartPosition2] = useState<
    number | undefined
  >();

  const [slices, setSlices] = useState<number[]>([]);

  const [cutIntervals, setCutIntervals] = useState<number[][]>([]);

  const signalData000 = useGetSignalToCutByCsvId(item.csvLink ?? '');

  function clearStates() {
    setCutIntervals([]);
    setSlices([]);
    setTimeScaleZoomParams({
      ...DEFAULT_ZOOM_PARAMS,
    });
  }

  const updateSignalFileByCsvId = useUpdateSignalFileByCsvId(item.csvLink ?? '', clearStates);

  const editorParams = usePrepareEditorParams({
    amplitudes: signalData000?.data?.sparse_signal,
    canvasSize,
    duration: signalData000?.data?.max_time ?? 0,
    timeScaleZoomParams: timeScaleZoomParams,
    cutIntervals,
  });

  const editorParamsDurationError =
    editorParams?.duration !== undefined && editorParams?.duration < 10;
  useEffect(() => {
    if (editorParamsDurationError) {
      setNotificationMessage({
        fullMessage: '',
        header:
          'Функционал ограничен! Режим редактированяи недоступен. Длина временного сигнала должна быть более 10 сек',
      });
    }
  }, [editorParamsDurationError, setNotificationMessage]);

  const classes = useStyles({
    ...canvasSize,
    readOnly: readOnly || editorParamsDurationError,
  });

  useEffect(() => {
    const canvas = canvasBaseRef.current as HTMLCanvasElement;
    const ctxMain = canvas.getContext('2d', { alpha: true });
    if (!ctxMain) {
      return;
    }

    ctxMain.clearRect(0, 0, canvasWidth, CANVAS_HEIGHT);

    if (!editorParams || !canvasBaseRef.current) {
      return;
    }

    const {
      toPixels,
      timeScaleMin,
      timeScaleMax,
      timeScaleScaleStep,
      amplitudeScaleStep,
      amplitudeAbsMax,
      duration,
      newAmplitudes,
    } = editorParams;

    ctxMain.clearRect(0, 0, canvasWidth, CANVAS_HEIGHT);

    // ********* amplitude coordinate lines *************************************************************************
    ctxMain.strokeStyle = BLACK_COLOR;
    ctxMain.lineWidth = 1;
    ctxMain.beginPath();

    for (let amplitude = 0; amplitude <= amplitudeAbsMax; amplitude += amplitudeScaleStep) {
      const f = (ampl: number) => {
        const start = toPixels({ x: timeScaleMin, y: ampl });
        ctxMain.moveTo(start.x, start.y);
        const end = toPixels({ x: timeScaleMax, y: ampl });
        ctxMain.lineTo(end.x, end.y);
      };
      f(amplitude);
      if (amplitude !== 0) {
        f(-amplitude);
      }
    }
    // ********* time coordinate lines *************************************************************************
    const timeStart = Math.floor(timeScaleMin / timeScaleScaleStep) * timeScaleScaleStep;
    for (let time = timeStart; time <= timeScaleMax; time += timeScaleScaleStep) {
      const start = toPixels({ x: time, y: -amplitudeAbsMax });
      ctxMain.moveTo(start.x, start.y);
      const end = toPixels({ x: time, y: amplitudeAbsMax });
      ctxMain.lineTo(end.x, end.y);
    }

    ctxMain.closePath();
    ctxMain.stroke();

    // ********* chart  *************************************************************************

    ctxMain.strokeStyle = BLUE_COLOR;
    ctxMain.lineWidth = 1;
    ctxMain.beginPath();

    const startIndex = Math.trunc((timeScaleMin / duration) * newAmplitudes.length);
    const endIndex = Math.trunc((timeScaleMax / duration) * newAmplitudes.length);
    let firstPoint: ICoordinate | undefined = undefined;

    for (let index = startIndex; index < endIndex; index++) {
      const amplitude = newAmplitudes[index].amplitude;
      if (amplitude !== undefined) {
        const time = (index / newAmplitudes.length) * duration;
        const start = toPixels({ x: time, y: amplitude });
        if (index === startIndex) {
          ctxMain.moveTo(start.x, start.y);
          firstPoint = start;
        } else {
          ctxMain.lineTo(start.x, start.y);
        }
      }
    }
    firstPoint && ctxMain.moveTo(firstPoint.x, firstPoint.y);
    ctxMain.closePath();
    ctxMain.stroke();

    // ********* slices lines  *************************************************************************

    const LINE_WIDTH = 4;
    const LINE_WIDTH_2 = 6;
    ctxMain.strokeStyle = '#fff';
    ctxMain.lineWidth = LINE_WIDTH;
    ctxMain.beginPath();

    for (const slice of slices) {
      const start = toPixels({ x: slice, y: 0 });
      ctxMain.fillRect(start.x - LINE_WIDTH / 2, 0, LINE_WIDTH, CANVAS_HEIGHT);

      ctxMain.moveTo(start.x, 0);
      ctxMain.lineTo(start.x, CANVAS_HEIGHT);
      ctxMain.moveTo(start.x - LINE_WIDTH_2, 0);
      ctxMain.lineTo(start.x, LINE_WIDTH_2);
      ctxMain.lineTo(start.x + LINE_WIDTH_2, 0);
      ctxMain.moveTo(start.x - LINE_WIDTH_2, CANVAS_HEIGHT);
      ctxMain.lineTo(start.x, CANVAS_HEIGHT - LINE_WIDTH_2);
      ctxMain.lineTo(start.x + LINE_WIDTH_2, CANVAS_HEIGHT);
    }

    ctxMain.closePath();
    ctxMain.stroke();
    // ********* time  coordinate text *************************************************************************
    ctxMain.font = '16px MTSSans';
    ctxMain.textAlign = 'center';
    ctxMain.fillStyle = BLACK_COLOR;
    const roundFactor = timeScaleScaleStep >= 1 ? 0 : timeScaleScaleStep >= 0.1 ? 1 : 2;

    for (let time = timeStart; time <= timeScaleMax; time += timeScaleScaleStep) {
      const start = toPixels({ x: time, y: -amplitudeAbsMax / 0.93 });
      ctxMain.fillText(time.toFixed(roundFactor), start.x, start.y + 10);
      const start2 = toPixels({ x: time, y: amplitudeAbsMax / 0.93 });
      ctxMain.fillText(time.toFixed(roundFactor), start2.x, start2.y - 10);
    }
  }, [canvasWidth, editorParams, slices]);

  useEffect(() => {
    const canvas: HTMLCanvasElement | null = canvasHoverRef.current;
    if (!canvas) {
      return;
    }
    const ctxHover = canvas.getContext('2d', { alpha: true });
    if (!ctxHover) {
      return;
    }
    ctxHover.clearRect(0, 0, canvasWidth, CANVAS_HEIGHT);

    if (editorParams === undefined || !canvasHoverRef.current) {
      return;
    }

    const { timeScaleFromXPixel, amplitudeAbsMax, toPixels, duration } = editorParams;

    const mouseMove = (event: MouseEvent) => {
      if (hoverIdleTimeIdRef.current) {
        clearTimeout(hoverIdleTimeIdRef.current);
      }
      const xScreen = event.offsetX;

      if (sliderDraggedStartPosition2 !== undefined) {
        // dragging
        movementIndicatorRef.current = true;
        const conversionFactor = timeScaleFromXPixel(1000) / 1000 / timeScaleZoomParams.zoom;
        let newCenter =
          (sliderDraggedStartPosition2 - xScreen) * conversionFactor + timeScaleZoomParams.center;
        const chartWindow = duration / timeScaleZoomParams.zoom;
        const minCenter = 0 + chartWindow * timeScaleZoomParams.weight;
        const maxCenter = duration - chartWindow * (1 - timeScaleZoomParams.weight);

        newCenter = Math.max(newCenter, minCenter);
        newCenter = Math.min(newCenter, maxCenter);

        setTimeScaleZoomParams({
          ...timeScaleZoomParams,
          center: newCenter,
        });
        setSliderDraggedStartPosition2(xScreen);
      } else {
        hoverIdleTimeIdRef.current = setTimeout(() => {
          hoverIdleTimeIdRef.current = undefined;
        }, 20);

        // ********* cursor time line *************************************************************************
        const cursorTimeLinePosition = timeScaleFromXPixel(xScreen);
        ctxHover.clearRect(0, 0, canvasWidth, CANVAS_HEIGHT);

        ctxHover.strokeStyle = '#000';
        ctxHover.lineWidth = 1;
        ctxHover.beginPath();

        const start = toPixels({ x: cursorTimeLinePosition, y: -amplitudeAbsMax * 0.9 });
        ctxHover.moveTo(start.x, start.y);
        const end = toPixels({ x: cursorTimeLinePosition, y: amplitudeAbsMax * 0.9 });
        ctxHover.lineTo(end.x, end.y);

        ctxHover.closePath();
        ctxHover.stroke();
        ctxHover.font = '16px MTSSans';
        ctxHover.textAlign = 'center';
        ctxHover.fillStyle = '#000';
        const text = cursorTimeLinePosition.toFixed(2);
        ctxHover.fillText(text, start.x, start.y + 16);
        ctxHover.fillText(text, end.x, end.y - 10);
      }
    };

    const mouseLeave = () => {
      ctxHover.clearRect(0, 0, canvasWidth, CANVAS_HEIGHT);
    };

    canvas.addEventListener('mousemove', mouseMove);
    canvas.addEventListener('mouseleave', mouseLeave);
    //@ts-ignore
    canvas.addEventListener('touchmove', mouseMove);

    return () => {
      if (hoverIdleTimeIdRef.current) {
        clearTimeout(hoverIdleTimeIdRef.current);
      }
      if (canvas) {
        canvas.removeEventListener('mousemove', mouseMove);
        canvas.removeEventListener('mouseleave', mouseLeave);
        //@ts-ignore
        canvas.removeEventListener('touchmove', mouseMove);
      }
      if (ctxHover) {
        ctxHover.clearRect(0, 0, canvasWidth, CANVAS_HEIGHT);
      }
    };
  }, [canvasWidth, editorParams, sliderDraggedStartPosition2, timeScaleZoomParams]);

  useEffect(() => {
    if (editorParams === undefined || !canvasHoverRef.current) {
      return;
    }
    const { timeScaleFromXPixel, toPixels } = editorParams;

    const mouseDown = (event: MouseEvent) => {
      movementIndicatorRef.current = false;
      setSliderDraggedStartPosition2(event.offsetX);
    };

    const mouseUp = (event: MouseEvent) => {
      const xScreen = event.offsetX;
      if (!(readOnly || editorParamsDurationError) && !movementIndicatorRef.current) {
        //single click - set new slice
        if (!slices.find((slice) => Math.abs(toPixels({ x: slice, y: 0 }).x - xScreen) < 16)) {
          // убрал дребезг клик
          setSlices((stateSlices) =>
            [...stateSlices, timeScaleFromXPixel(xScreen)].sort((a, b) => a - b),
          );
        }
      }
      movementIndicatorRef.current = false;
      setSliderDraggedStartPosition2(undefined);
    };

    const canvas: HTMLCanvasElement | null = canvasHoverRef.current;
    if (canvas) {
      canvas.addEventListener('mousedown', mouseDown);
      canvas.addEventListener('mouseup', mouseUp);
      //@ts-ignore
      canvas.addEventListener('touchstart', mouseDown);
      //@ts-ignore
      canvas.addEventListener('touchend', mouseUp);
      //@ts-ignore
      canvas.addEventListener('touchcancel', mouseUp);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('mousedown', mouseDown);
        canvas.removeEventListener('mouseup', mouseUp);
        //@ts-ignore
        canvas.removeEventListener('touchstart', mouseDown);
        //@ts-ignore
        canvas.removeEventListener('touchend', mouseUp);
        //@ts-ignore
        canvas.removeEventListener('touchcancel', mouseUp);
      }

      if (hoverIdleTimeIdRef.current) {
        clearTimeout(hoverIdleTimeIdRef.current);
      }
    };
  }, [editorParams, editorParamsDurationError, readOnly, slices]);

  useEffect(() => {
    if (!editorParams || !canvasHoverRef.current) {
      return;
    }
    const { timeScaleFromXPixel, timeScaleMin, timeScaleMax, duration } = editorParams;

    const mouseWheel = (event: WheelEvent) => {
      movementIndicatorRef.current = false;
      setSliderDraggedStartPosition2(undefined);

      const xScreen = event.offsetX;
      const deltaY = event.deltaY;
      let newZoom =
        deltaY < 0
          ? timeScaleZoomParams.zoom * ZOOM_STEP_WHEEL
          : timeScaleZoomParams.zoom / ZOOM_STEP_WHEEL;

      if (newZoom < 1) {
        newZoom = 1;
      } else {
        if (newZoom > 20) {
          newZoom = 20;
        }
      }

      let newCenter = timeScaleFromXPixel(xScreen);
      const newWeight = (newCenter - timeScaleMin) / (timeScaleMax - timeScaleMin);

      const chartWindow = duration / newZoom;
      const minCenter = chartWindow * newWeight;
      const maxCenter = duration - chartWindow * (1 - newWeight);

      newCenter = Math.max(newCenter, minCenter);
      newCenter = Math.min(newCenter, maxCenter);

      setTimeScaleZoomParams({
        weight: newWeight,
        center: newCenter,
        zoom: newZoom,
      });
    };

    const canvas: HTMLCanvasElement | null = canvasHoverRef.current;
    if (canvas) {
      canvas.addEventListener('wheel', mouseWheel);
    }

    return () => {
      if (canvas) {
        canvas.removeEventListener('wheel', mouseWheel);
      }
    };
  }, [timeScaleZoomParams.zoom, editorParams, setTimeScaleZoomParams]);

  const fileMeta = item.fileMeta && JSON.parse(item.fileMeta);

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        Редактирование сигнала
        {(readOnly || editorParamsDurationError) && (
          <span className={classes.red}>{' (только просмотр)'}</span>
        )}
      </div>

      <div className={classNames(classes.row, classes.headers)}>
        <div className={classes.folderName}>Маршрутная папка</div>
        <div className={classes.fileName}>Файл csv</div>
        <div className={classes.speed}>Режим работы</div>
        <div className={classes.speed}>Длительность</div>
      </div>
      <div className={classNames(classes.row, classes.bodys)}>
        <div className={classes.folderName}>
          <div className={classes.rowSmall}>
            <MainFolderIcon style={{ fontSize: 22 }} />
            <div>{item.folderName}</div>
          </div>
        </div>
        <div className={classes.fileName}>{fileMeta?.name ?? '---'}</div>
        <div className={classes.speed}>{`${item.outputSpeed?.toFixed(2) ?? '--'} ${
          item.outputSpeedUnits
        }`}</div>
        <div className={classes.speed}>{`${(editorParams?.duration ?? 0).toFixed(1)} сек`}</div>
      </div>
      <HeaderBar
        canvasSize={canvasSize}
        slices={slices}
        setSlices={setSlices}
        editorParams={editorParams}
        cutIntervals={cutIntervals}
        setCutIntervals={setCutIntervals}
      />

      <div className={classes.chartContainer}>
        <canvas
          className={classes.chart}
          ref={canvasBaseRef}
          height={CANVAS_HEIGHT}
          width={canvasWidth}
        />
        <canvas
          className={classNames(classes.chart, {
            [classes.chartMovement]: movementIndicatorRef.current && sliderDraggedStartPosition2,
          })}
          ref={canvasHoverRef}
          height={CANVAS_HEIGHT}
          width={canvasWidth}
        />
      </div>
      <SlicingEditorCustomScrollBar
        editorParams={editorParams}
        timeScaleZoomParams={timeScaleZoomParams}
        setTimeScaleZoomParams={setTimeScaleZoomParams}
      />
      <div className={classNames(classes.row)}>
        <div className={classes.emptySpace} />
        <Button
          className={classes.button}
          disabled={false}
          variant="contained"
          color="default"
          size="small"
          type="button"
          onClick={() => setClose()}
        >
          {'Отмена и выход'}
        </Button>
        <Button
          className={classes.button}
          disabled={cutIntervals.length === 0}
          variant="contained"
          color="secondary"
          size="small"
          type="button"
          onClick={clearStates}
        >
          {'Сбросить изменения'}
        </Button>
        <Button
          className={classes.button}
          disabled={cutIntervals.length === 0 || readOnly || editorParamsDurationError}
          variant="contained"
          color="primary"
          size="small"
          type="button"
          onClick={() => updateSignalFileByCsvId.mutate(cutIntervals)}
        >
          {'Применить изменения'}
        </Button>
      </div>
    </div>
  );
}
