import { useState } from 'react';

import { UseFormReturn, Path, useWatch } from 'react-hook-form';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckIcon from 'components/fields/field-label-error-wrapper/check-icon';
import { IDiagnostic } from 'interfaces';
import { GREY_TEXT_COLOR } from 'stream-constants';
import { getErrorByPath } from 'utils/get-error-by-path';

import { DetailedElemsListModal } from './detailed-elems-list-modal';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    layout: {
      marginTop: -10,
      height: 45,
      borderRadius: 8,
      paddingLeft: 6,
      cursor: 'pointer',
      display: 'flex',
      gap: 10,
      alignItems: 'center',
    },
    checkIcon: {
      paddingTop: '4px',
      transform: 'scale(0.8)',
    },
    roundedButtonTextMessage: {
      maxWidth: 426,
      borderRadius: '100px',
      padding: '12px 16px',
      fontSize: '15px',
      fontWeight: 500,
      color: '#1D2023',
      backgroundColor: '#F2F3F7',
      '&:hover': {
        backgroundColor: '#ddd',
      },
    },
    title: {
      color: GREY_TEXT_COLOR,
      fontWeight: 400,
      fontSize: '14px',
    },
  }),
);

type IProps = {
  form: UseFormReturn<IDiagnostic>;
  pathOfElementsSummaryLabel: Path<IDiagnostic>;
  pathOfUnitArray: Path<IDiagnostic>;
  pathOfElementsArray: Path<IDiagnostic>;
  machineLinkId: string | undefined;
  disabled?: boolean;
  viewOnly?: boolean;
  defaultSummaryValue?: string;
  defaultUnitsArray?: string[];
  defaultElementsArray?: string[][];
  detailedInformationToView?: string;
};

export const DetailedElemsListModalLink = ({
  form,
  pathOfElementsSummaryLabel,
  pathOfUnitArray,
  pathOfElementsArray,
  machineLinkId,
  disabled,
  defaultSummaryValue,
  defaultUnitsArray,
  defaultElementsArray,
  viewOnly,
  detailedInformationToView,
}: IProps) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const errors = form.formState.errors;
  const { isSubmitted } = form.formState;

  const errMessage = isSubmitted
    ? //@ts-ignore
      getErrorByPath(errors, pathOfElementsSummaryLabel)?.message
    : undefined;

  const defaultUnits = useWatch({
    control: form.control,
    name: pathOfUnitArray,
    defaultValue: (defaultUnitsArray ?? []) as any,
  });

  const defaultElements = useWatch({
    control: form.control,
    name: pathOfElementsArray,
    defaultValue: (defaultElementsArray ?? []) as any,
  });

  const summary = useWatch({
    control: form.control,
    name: pathOfElementsSummaryLabel,
    defaultValue: (defaultSummaryValue ?? '') as any,
  });

  return (
    <>
      <div
        className={classes.layout}
        onClick={() => setOpen(true && !!machineLinkId)}
        id="id_detailed_elems_list_modal_link"
      >
        <div className={classes.title}>{'Детальная настройка станка'}</div>
        {summary ? (
          // @ts-ignore
          <div className={classes.roundedButtonTextMessage}>{summary}</div>
        ) : (
          <div> </div>
        )}
        {errMessage && (
          <>
            <Typography variant={'caption'} color="primary">
              {errMessage}
            </Typography>
            <div className={classes.checkIcon}>
              <CheckIcon color="#eb4a13" />
            </div>
          </>
        )}
      </div>
      <DetailedElemsListModal
        open={open}
        setOpen={setOpen}
        defaultUnits={defaultUnits as unknown as string[]}
        defaultElements={defaultElements as unknown as string[][]}
        viewOnly={viewOnly}
        machineLinkId={machineLinkId}
        detailedInformationToView={detailedInformationToView}
        onResultSelect={(result?: { selectedNodes: string[]; summary: string }) => {
          if (result) {
            //@ts-ignore
            form.setValue(pathOfUnitArray, result.selectedNodes, {
              shouldDirty: true,
              shouldValidate: true,
            });

            //@ts-ignore
            form.setValue(pathOfElementsArray, result.selectedElements, {
              shouldDirty: true,
              shouldValidate: true,
            });
            //@ts-ignore
            form.setValue(pathOfElementsSummaryLabel, result.summary, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }
        }}
      />
    </>
  );
};
