import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function BearingIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 32 32'}>
      <circle cx="15.904" cy="5.635" r="2.756" />
      <path
        d="M16.136,1.445C8.015,1.445,1.429,7.961,1.429,16c0,8.038,6.586,14.555,14.707,14.555
	c8.122,0,14.708-6.518,14.708-14.555C30.844,7.961,24.258,1.445,16.136,1.445z M16.075,29.734c-7.597,0-13.758-6.158-13.758-13.757
	c0-7.599,6.161-13.758,13.758-13.758c7.596,0,13.758,6.16,13.758,13.758C29.833,23.576,23.671,29.734,16.075,29.734z"
      />
      <path
        fill="none"
        d="M16.075,2.219c-7.597,0-13.758,6.16-13.758,13.758c0,7.599,6.161,13.757,13.758,13.757
	c7.596,0,13.758-6.158,13.758-13.757C29.833,8.378,23.671,2.219,16.075,2.219z M16.042,21.99c-3.311,0-5.993-2.682-5.993-5.991
	c0-3.31,2.682-5.993,5.993-5.993c3.31,0,5.992,2.683,5.992,5.993C22.033,19.309,19.352,21.99,16.042,21.99z"
      />
      <circle fill="none" cx="16.042" cy="15.999" r="5.992" />
      <path
        d="M16.042,9.126c-3.767,0-6.823,3.054-6.823,6.823c0,3.768,3.056,6.822,6.823,6.822s6.823-3.055,6.823-6.822
	C22.864,12.181,19.809,9.126,16.042,9.126z M16.042,21.99c-3.311,0-5.993-2.682-5.993-5.991c0-3.31,2.682-5.993,5.993-5.993
	c3.31,0,5.992,2.683,5.992,5.993C22.033,19.309,19.352,21.99,16.042,21.99z"
      />
      <circle fill="none" cx="16.042" cy="15.999" r="5.992" />
      <circle cx="16.136" cy="26.285" r="2.756" />
      <circle cx="5.778" cy="15.916" r="2.756" />
      <circle cx="26.362" cy="16" r="2.755" />
      <circle cx="8.521" cy="8.73" r="2.755" />
      <circle cx="23.287" cy="23.167" r="2.755" />
      <circle cx="8.63" cy="23.159" r="2.756" />
      <circle cx="23.243" cy="8.664" r="2.755" />
    </SvgIcon>
  );
}
