import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function BeltDriveIconNotGear(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 25 25">
      <path d="M24.9999 12.999C24.9999 16.3129 22.3134 18.9994 18.9995 18.9994C15.6855 18.9994 12.999 16.3129 12.999 12.999C12.999 9.68503 15.6855 6.99854 18.9995 6.99854C22.3134 6.99854 24.9999 9.68503 24.9999 12.999Z" />
      <path d="M6.99849 12.999C6.99849 14.656 5.65525 15.9992 3.99827 15.9992C2.34129 15.9992 0.998047 14.656 0.998047 12.999C0.998047 11.342 2.34129 9.99876 3.99827 9.99876C5.65525 9.99876 6.99849 11.342 6.99849 12.999Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0976 7.98895L4.09652 10.9892L3.90039 10.0085L18.9015 7.0083L19.0976 7.98895Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.90041 15.9894L18.9015 18.9897L19.0977 18.009L4.09654 15.0088L3.90041 15.9894Z"
      />
    </SvgIcon>
  );
}
