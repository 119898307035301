import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export default function CombinedBearing(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 32 32'}>
      <path
        d="M2.238,8.187v6.548h27.686V8.187H2.238z M5.835,13.957c-1.379,0-2.496-1.117-2.496-2.496
		s1.117-2.496,2.496-2.496c1.378,0,2.495,1.117,2.495,2.496S7.214,13.957,5.835,13.957z M12.486,13.957
		c-1.377,0-2.495-1.117-2.495-2.496s1.118-2.496,2.495-2.496c1.379,0,2.496,1.117,2.496,2.496S13.865,13.957,12.486,13.957z
		 M19.596,13.957c-1.379,0-2.496-1.117-2.496-2.496s1.117-2.496,2.496-2.496c1.378,0,2.495,1.118,2.495,2.496
		S20.974,13.957,19.596,13.957z M26.232,13.957c-1.378,0-2.496-1.117-2.496-2.496s1.118-2.496,2.496-2.496
		c1.379,0,2.496,1.117,2.496,2.496S27.611,13.957,26.232,13.957z"
      />
      <path
        d="M4.879,14.579v8.265h22.606v-8.265H4.879z M9.057,20.979c0,0.58-0.475,1.051-1.06,1.051H7.201
		c-0.585,0-1.061-0.471-1.061-1.051v-4.661c0-0.58,0.475-1.051,1.061-1.051h0.796c0.585,0,1.06,0.471,1.06,1.051V20.979z
		 M13.38,20.979c0,0.58-0.475,1.051-1.061,1.051h-0.795c-0.585,0-1.06-0.471-1.06-1.051v-4.661c0-0.58,0.475-1.051,1.06-1.051h0.795
		c0.586,0,1.061,0.471,1.061,1.051V20.979z M17.764,20.979c0,0.58-0.475,1.051-1.061,1.051h-0.794c-0.586,0-1.061-0.471-1.061-1.051
		v-4.661c0-0.58,0.475-1.051,1.061-1.051h0.794c0.586,0,1.061,0.471,1.061,1.051V20.979z M22.091,21.041
		c0,0.58-0.476,1.051-1.062,1.051h-0.795c-0.586,0-1.061-0.471-1.061-1.051V16.38c0-0.58,0.475-1.051,1.061-1.051h0.795
		c0.586,0,1.062,0.471,1.062,1.051V21.041z M26.36,20.979c0,0.58-0.475,1.051-1.061,1.051h-0.796c-0.586,0-1.061-0.471-1.061-1.051
		v-4.661c0-0.58,0.475-1.051,1.061-1.051H25.3c0.586,0,1.061,0.471,1.061,1.051V20.979z"
      />
    </SvgIcon>
  );
}
