import { useRef } from 'react';

import { OrderAscIcon } from 'icons/order-asc-icon';
import { OrderDescIcon } from 'icons/order-desc-icon';
import { ResetFiltersIcon } from 'icons/reset-filters-icon';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import { IFIltersOptionsItem } from 'components/autocomplete-for-filters/autocomplete-for-filters';
import { StandAllon } from 'components/autocomplete-for-filters/stand-allon';
import SearchInput from 'components/search-input-component';
import { IQueryParamsFilter, ISortParamsOrder } from 'interfaces';

import { useGetMachineFilterDictionaries, IMachineFilterName } from './hooks';

const ALL_TABS_KEY = 'Все';

const useStyles = makeStyles<Theme, { layoutWidth: number }>((theme) =>
  createStyles({
    sortOrder: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      maxWidth: '264px',
      minWidth: '264px',
    },
    sortOrderTitle: {
      color: '#626c77',
    },
    sortOrderSubTitle: {
      color: '#1d2023',
      marginRight: '6px',
    },
    fitersRow: {
      margin: '16px 0px 6px 5px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '20px',
    },
    firstFilterItem: {
      maxWidth: '200px',
      minWidth: '200px',
    },

    resetFilterItem: {
      maxWidth: '42px',
      minWidth: '42px',
      maxHeight: '42px',
      minHeight: '42px',
      backgroundColor: '#f2f3f7',
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#eeeeee',
      },
    },
    grow: {
      flexGrow: 2,
    },
    tab: {
      fontSize: '14px',
      fontWeight: 500,
      fontStretch: '100%',
      letterSpacing: 'normal',
      lineHeight: '24px',
      color: '#626C77',
    },
    tabs: {
      '& button': {
        width: 100,
      },
    },
    badge: {
      marginLeft: 6,
      backgroundColor: '#F2F3F7',
      borderRadius: '100px',
      minWidth: '24px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    tabsMinScreenSize: (props) => ({
      maxWidth: props.layoutWidth - 40,
    }),
  }),
);

interface IParams {
  scrollTop?: number;
  searchString?: string;
  filters?: IQueryParamsFilter[];
  order?: string;
}

export function MachinesFilters({
  handleSearchStringSubmit,
  handleFiltersChange,
  setSortOrderAsc,
  handleClearFilters,
  params,
  layoutWidth,
}: {
  handleSearchStringSubmit: (s: string) => void;
  handleFiltersChange: (arr: { id: string; value: any }[]) => void;
  setSortOrderAsc: (a: string) => void;
  handleClearFilters: () => void;
  params: IParams;
  layoutWidth: number;
}) {
  const classes = useStyles({ layoutWidth });

  const searchInputKeyRef = useRef<number>(1);
  const searchString = params.searchString ?? '';
  const filters = params.filters ?? [];

  const queryFiltersDictionaries = useGetMachineFilterDictionaries(
    {
      searchString,
      filters,
    },
    true,
    () => {},
  ) as unknown as {
    isLoading: boolean;
    data: Record<IMachineFilterName, IFIltersOptionsItem[]> | undefined;
  };

  const sortOrderAsc = params?.order;
  const allTabsAmount =
    queryFiltersDictionaries.data?.[IMachineFilterName.MACHINE_TYPE]?.reduce(
      (akkum, elem) => akkum + elem.amount,
      0,
    ) ?? 0;

  return (
    <>
      <div className={classes.fitersRow}>
        <div className={classes.firstFilterItem}>
          <SearchInput
            key={searchInputKeyRef.current}
            setSearchString={handleSearchStringSubmit}
            searchString={searchString}
          />
        </div>

        <StandAllon
          name={IMachineFilterName.MANUFACTURED_YEAR}
          label="Год выпуска"
          filters={filters}
          queryFiltersDictionaries={queryFiltersDictionaries}
          handleFiltersChange={handleFiltersChange}
        />
        <StandAllon
          name={IMachineFilterName.MANUFACTURED}
          label="Завод производитель"
          filters={filters}
          queryFiltersDictionaries={queryFiltersDictionaries}
          handleFiltersChange={handleFiltersChange}
        />
        <StandAllon
          name={IMachineFilterName.MACHINE_OWNER}
          label="Завод владелец"
          filters={filters}
          queryFiltersDictionaries={queryFiltersDictionaries}
          handleFiltersChange={handleFiltersChange}
        />
        {!process.env.REACT_APP_DIAGNOSTIC && (
          <StandAllon
            name={IMachineFilterName.FULLNESS}
            label="Статус готовности"
            filters={filters}
            queryFiltersDictionaries={queryFiltersDictionaries}
            handleFiltersChange={handleFiltersChange}
          />
        )}
        <Tooltip title={'Сбросить все фильтры'} placement={'top'}>
          <div
            className={classes.resetFilterItem}
            onClick={() => {
              searchInputKeyRef.current += 1;
              handleClearFilters();
            }}
          >
            <ResetFiltersIcon />
          </div>
        </Tooltip>
        <div className={classes.grow} />
        <div className={classes.sortOrder}>
          <div className={classes.sortOrderTitle}>Сортировать:</div>
          <span className={classes.sortOrderSubTitle}>{`По дате создания (сначала ${
            sortOrderAsc === undefined || sortOrderAsc === ISortParamsOrder.DESC
              ? 'новые'
              : 'старые'
          })`}</span>
          <IconButton
            aria-label={'sort-order'}
            onClick={() =>
              setSortOrderAsc(
                sortOrderAsc === undefined || sortOrderAsc === ISortParamsOrder.DESC
                  ? ISortParamsOrder.ASC
                  : ISortParamsOrder.DESC,
              )
            }
            size="small"
          >
            {sortOrderAsc === undefined || sortOrderAsc === ISortParamsOrder.DESC ? (
              <OrderDescIcon style={{ fontSize: 13 }} />
            ) : (
              <OrderAscIcon style={{ fontSize: 13 }} />
            )}
          </IconButton>
        </div>
      </div>
      <div key={222} className={classes.tabsMinScreenSize}>
        <Tabs
          value={
            filters.find((item) => item.id === IMachineFilterName.MACHINE_TYPE)?.value ??
            ALL_TABS_KEY
          }
          onChange={(event: React.BaseSyntheticEvent, newValue: string) => {
            event.preventDefault();
            const newFilters = filters.filter(
              (item) => item.id !== IMachineFilterName.MACHINE_TYPE,
            );
            if (newValue !== ALL_TABS_KEY) {
              newFilters.push({ id: IMachineFilterName.MACHINE_TYPE, value: newValue });
            }
            handleFiltersChange(newFilters);
          }}
          indicatorColor="primary"
          textColor="primary"
          className={classes.tabs}
        >
          <Tab
            label={
              <div>
                <span>{ALL_TABS_KEY}</span>
                <span className={classes.badge}>{allTabsAmount}</span>
              </div>
            }
            key={ALL_TABS_KEY}
            value={ALL_TABS_KEY}
            classes={{ root: classes.tab }}
          />
          {queryFiltersDictionaries.data?.[IMachineFilterName.MACHINE_TYPE]?.map(
            ({ name, amount }) => (
              <Tab
                label={
                  <div>
                    <span>{name}</span>
                    <span className={classes.badge}>{amount}</span>
                  </div>
                }
                key={name}
                value={name}
                classes={{ root: classes.tab }}
              />
            ),
          )}
        </Tabs>
      </div>
    </>
  );
}
