import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { GREEN_COLOR, RED_COLOR } from 'stream-constants';

const useStyles = makeStyles<Theme, { color: string }>((theme) =>
  createStyles({
    inputRankLabel: {
      boxSizing: 'border-box',
      minHeight: '1.5rem',
      padding: '0.2rem',
      fontSize: '14px',
      marginTop: '-24px',
    },

    inputRank: {
      color: (props) => props.color,
      boxSizing: 'border-box',
      minHeight: '44px',
      width: '100%',
      paddingLeft: '0.6rem',
      borderColor: 'lightgray',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '8px',
      fontSize: '17px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'stretch',

      '&:focus': {
        outline: 'none',
        borderColor: 'black',
      },
    },
    calculatedRankStyle: {
      color: GREEN_COLOR,
      minHeight: 16,
      lineHeight: 1,
      padding: '2px 0 0 0.7rem',
      fontSize: '12px',
    },
    errorRankStyle: {
      magingLeft: '30px',
      color: RED_COLOR,
      minHeight: 16,
      lineHeight: 1,
      padding: '2px 0 0 0.7rem',
      fontSize: '12px',
    },
  }),
);

export const InputRank = function ({
  rankColor,
  defectRank,
  setDefectRank,
  disabled,
  calculatedRank,
}: {
  rankColor: string;
  defectRank: { value: string; error: string } | undefined;
  calculatedRank: string | undefined;
  setDefectRank: (s: { value: string; error: string } | undefined) => void;
  disabled?: boolean;
}) {
  const classes = useStyles({ color: rankColor });

  return (
    <>
      <div className={classes.inputRankLabel}>Rank</div>
      <input
        disabled={disabled}
        className={classes.inputRank ?? ''}
        value={defectRank?.value}
        onChange={(e) => {
          const value = e.target.value;
          const fl = parseFloat(value);
          const error = isNaN(fl) || fl < 0 || fl > 100 ? 'ошибка:  Rank = число [0 ... 100]' : '';
          setDefectRank({ value, error });
        }}
      />
      {defectRank?.error ? (
        <div className={classes.errorRankStyle}>
          <span>{defectRank?.error}</span>
        </div>
      ) : (
        <div className={classes.calculatedRankStyle}>
          <span>{defectRank?.value !== calculatedRank ? calculatedRank : ''}</span>
        </div>
      )}
    </>
  );
};
