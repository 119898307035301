import { useEffect } from 'react';

import { useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { UniversalModalDialog } from 'components/universal-modal-dialog';
import { IDiagnostic } from 'interfaces';
import { useMutationUpdateDiagnosticBaseParams } from 'pages-diagnostic/hooks';
import { diagnosticgValidationSchema } from 'validation-schemas/validation-schema-diagnostic';

import { DiagnosticBaseParamsForm } from './base-params-form';

const WIDTH = 700;

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    root: {
      minWidth: WIDTH,
      maxWidth: WIDTH,
    },
    header: {
      fontWeight: 500,
      fontSize: '24px',
      color: '#1D2023',
      lineHeight: '28px',
      margin: '16px 0 16px 0',
    },
  }),
);

export function EditBaseParamsModalDialog({
  data,
  setClose,
}: {
  data: IDiagnostic | undefined;
  setClose: () => void;
}) {
  const classes = useStyles();

  const form = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(diagnosticgValidationSchema),
    defaultValues: data,
    shouldFocusError: true,
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  console.log('%c form errors = ', 'color: red', errors);

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const mutationUpdate = useMutationUpdateDiagnosticBaseParams(data?.id || 'emptyId', () => {
    setClose();
  });

  const onSubmit = (diag: IDiagnostic) => {
    mutationUpdate.mutate(diag);
  };

  return (
    <UniversalModalDialog setClose={setClose}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className={classes.header}>Редактировать идентификацию диагностики</div>
              <DiagnosticBaseParamsForm data={data} form={form} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button variant="contained" color="default" size="small" onClick={setClose}>
                {'Отмена'}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                id={'id_EditBaseParamsModalDialog_save_button_352'}
                disabled={!form.formState.isDirty}
                variant="contained"
                color="primary"
                size="small"
                type="submit"
              >
                {'Сохранить'}
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </UniversalModalDialog>
  );
}
