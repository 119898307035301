import { useState, useContext, useEffect, useCallback, useMemo } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Tooltip from '@material-ui/core/Tooltip';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import { CustomBreadcrumbs } from 'components/breadcrums';
import { ICheckBoxState } from 'components/custom-states-check-box';
import { ContextSettings } from 'context/context-settings';
import { IElementType } from 'interfaces';
import {
  useGetDefectsDict,
  useGetSpectrumAndPeaks,
  useGetAllDefectsByElement,
  useGetDefectFrequenciesByDefectId,
  useGetFrequencyPortraitsByDiagnosticAndElementId,
  useGetDiagnosticsPredict,
} from 'pages-diagnostic/diagnostic-spectrum/query-hooks/query-hooks';
import {
  IDefect,
  IFrequencyZoomParams,
  MENU_WIDTH,
  IHoveredOrigin,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import { useGetDiagnosticById } from 'pages-diagnostic/hooks';
import {
  DIAGNOSTICS_KEY,
  DIAGNOSTIC_SPECTRUM,
  EMPTY_OBJECT,
  DIAGNOSTIC_SPECTRUM_BEARING_PARAMS_CALCULATOR,
} from 'stream-constants';
import { useQueryParams } from 'utils/hooks/use-query-params';

import { Chart } from './chart/chart';
import { DefectCommands } from './defect-commands';
import { FrequenciesMenu } from './frequencies-menu/frequencies-menu';
import { FrequenciesMenuHeader } from './frequencies-menu/frequencies-menu-header';
import { FrequencyPortraits } from './frequency-portraits';
import { usePrepareGraphParams } from './hooks/use-prepare-graph-params';
import { MnemonicSchemeModal } from './modal/mnemonic-scheme-modal';
import { OtherElementName } from './other-element-name';
import { SpectrumLayoutCustomScrollBar } from './spectrum-layout-custom-scroll-bar';
import { SpectrumLayoutHeader } from './spectrum-layout-header';

const FREQUENCY_PORTRAITS_TABS = [
  { label: 'Прямой спектр', value: 'DirectSpectrum' },
  { label: 'Частотный портрет', value: 'FrequencyPortaits' },
];

// const BEARINGS_TYPES = new Set([
//   IElementType.COMBINED_BEARING,
//   IElementType.RADIAL_BEARING,
//   IElementType.TAPE_ROLL_BEARING,
//   'TAPE_ROLL_BEARING_MIN',
//   'TAPE_ROLL_BEARING_MAX',
//   IElementType.BALLSCREW,
// ]);

type Props = { height: number; width: number };

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    headerAndControlsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    headerTitle: {
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#000',
      margin: '16px 0 20px 0',
    },

    charts: {
      position: 'relative',
      height: (props) => props.height,
      width: (props) => props.width,
    },
    layout: {
      marginTop: 28,
      height: (props) => props.height,
      width: (props) => props.width,
    },
    row: {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
    },
    menu: {
      minWidth: MENU_WIDTH,
      maxWidth: MENU_WIDTH,
      overflowX: 'hidden',
      padding: '0 0 0 10px',
    },
    elementBody2: {
      marginLeft: 120,
    },
    chartTitleContainer: {
      padding: '12px 0 0 44px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    chartTitle: {
      fontWeight: 500,
      fontSize: '14px',
      color: '#000',
    },
    skzTextLabel: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#626C77',
    },
    skzTextValue: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      color: '#1D2023',
    },

    zoomLabel: {
      marginLeft: 20,
      fontWeight: 400,
      fontSize: '14px',
    },
    zoomValue: {
      display: 'inline-block',
      width: 42,
      fontWeight: 400,
      fontSize: '17px',
      paddingLeft: 6,
    },
    shiftsButtons: {
      marginLeft: '24px',
    },
    mnemonicSchemeButton: {
      marginRight: 30,
    },
    smallMargin: {
      marginBottom: 3,
    },
    spaceRight: {
      marginRight: 20,
    },
    tab: {
      fontSize: '14px',
      fontWeight: 500,
      fontStretch: '100%',
      letterSpacing: 'normal',
      lineHeight: '24px',
      color: '#000',
    },
    tabs: {
      '& button': {
        width: 190,
      },
      position: 'relative',
      zIndex: 15,
    },
  }),
);
const EMPTY_ARRAY = Object.freeze([]);

export function SpectrumLayout() {
  const history = useHistory();
  const { setQueryParams } = useQueryParams();

  const params = useParams<{ id: string; elementGuideId: string; defectId: string | undefined }>();

  const diagnosticQuery = useGetDiagnosticById(params.id);
  const { description } = diagnosticQuery.data ?? {};

  const { layoutSize } = useContext(ContextSettings);

  const { canvasSize, modalLayoutSize } = useMemo(
    () => ({
      canvasSize: {
        width: layoutSize.width - MENU_WIDTH - 81,
        height: layoutSize.height / 2 - 100,
      },
      modalLayoutSize: {
        height: layoutSize.height,
        width: layoutSize.width - 81,
      },
    }),
    [layoutSize],
  );
  const classes = useStyles(modalLayoutSize);

  const [dropDownOtherFreqencyId, setDropDownOtherFreqencyId] = useState<string | undefined>();
  const [hoverPeakName, setHoverPeakName] = useState<
    { name: string; origin: IHoveredOrigin } | undefined
  >(undefined);
  const [hoverGroupName, setHoverGroupName] = useState<string>('');
  const [withTooltip, setWithTooltip] = useState<boolean>(false);
  const [withMnemonicScheme, setWithMnemonicScheme] = useState<boolean>(false);
  const [hoverArea, setHoverArea] = useState<number | undefined>();

  const [selectedEnvelopFrequencies, setSelectedEnvelopFrequencies] = useState<Set<string>>(
    new Set(),
  );
  const [selectedDirectFrequencies, setSelectedDirectFrequencies] = useState<Set<string>>(
    new Set(),
  );

  const [frequencyZoomParams, setFrequencyZoomParams] = useState<IFrequencyZoomParams>({
    center: 0,
    zoom: 1,
    weight: 0.5,
  });
  const [expandedGroups, setExpandedGroups] = useState<Set<string>>(new Set());

  const [activeFrequencyPortraitsTab, setActiveFrequencyPortraitsTab] = useState<string>(
    FREQUENCY_PORTRAITS_TABS[1].value,
  );

  const clearStates = useCallback(() => {
    setSelectedEnvelopFrequencies(new Set());
    setSelectedDirectFrequencies(new Set());
  }, []);

  const defectsDictQuery = useGetDefectsDict();

  const { defectsDict = EMPTY_OBJECT, defectsDictArray = EMPTY_ARRAY } =
    defectsDictQuery.data ?? {};

  const defectsQuery = useGetAllDefectsByElement(params.id, params.elementGuideId);
  const defects = (defectsQuery.data?.data ?? EMPTY_ARRAY) as IDefect[];

  const isReadyCheckBox = !!defectsQuery?.data?.isReady;

  const currentDefectOrTemplate = defects.find((defect) => defect.id === params.defectId);

  const currentDefect = currentDefectOrTemplate?.status !== 1 ? currentDefectOrTemplate : undefined;

  const currentDefectFrequencies = useGetDefectFrequenciesByDefectId(currentDefectOrTemplate?.id);

  const freqElementId = dropDownOtherFreqencyId ?? currentDefectFrequencies.data?.freqElementId;

  const frequencyPortraitsData = useGetFrequencyPortraitsByDiagnosticAndElementId({
    diagnosticId: params.id,
    elementGuideId: params.elementGuideId || '',
    freqElementId: freqElementId || params.elementGuideId || '',
    handleOnSuccess: () => {},
  });

  const isPortraitAvailable =
    frequencyPortraitsData.isSuccess && frequencyPortraitsData.data?.portraits?.length !== 0;

  const setCurrentDefect = useCallback(
    (defect: IDefect | undefined) => {
      setExpandedGroups(new Set());
      setHoverArea(undefined);
      setHoverGroupName('');
      setHoverPeakName(undefined);
      clearStates();
      setDropDownOtherFreqencyId(undefined);
      if (params.defectId === defect?.id) {
        history.push(`/${DIAGNOSTIC_SPECTRUM}/${params.id}/${params.elementGuideId}`);
      } else {
        history.push(
          `/${DIAGNOSTIC_SPECTRUM}/${params.id}/${params.elementGuideId}${
            defect?.id ? `/${defect.id}` : ''
          }`,
        );
      }
    },
    [clearStates, history, params.defectId, params.elementGuideId, params.id],
  );

  const setOtherFreqElementId = useCallback(
    (otherFreqElementId: string | undefined) => {
      setExpandedGroups(new Set());
      setHoverArea(undefined);
      setHoverGroupName('');
      setHoverPeakName(undefined);

      setDropDownOtherFreqencyId(otherFreqElementId);

      clearStates();
    },
    [clearStates],
  );

  const setSelectedFrequenciesData = currentDefectFrequencies?.data as
    | { envelopDataList: string[] | undefined; spectrumDataList: string[] | undefined }
    | undefined;

  useEffect(() => {
    setSelectedEnvelopFrequencies(
      new Set(setSelectedFrequenciesData?.envelopDataList ?? undefined),
    );
    setSelectedDirectFrequencies(
      new Set(setSelectedFrequenciesData?.spectrumDataList ?? undefined),
    );
  }, [setSelectedFrequenciesData]);

  const query = useGetSpectrumAndPeaks(params?.id ?? '', params?.elementGuideId ?? '');
  const mnemonicSchemeId = query?.data?.mnemonicScheme;

  const predictQuery = useGetDiagnosticsPredict({
    elementGuideId: params?.elementGuideId,
    freqElementId,
  });
  const predictQueryData = predictQuery?.data as
    | {
        namePortrait: string[];
        numberGroupFrequencies: string[];
      }
    | undefined;

  const graphParams = usePrepareGraphParams({
    mainElementId: params.elementGuideId,
    freqElementId,
    data: query.data,
    canvasSize,
    frequencyZoomParams,
  });

  const breadcrubms = useMemo(
    () => [
      {
        label: `Диагностика \u00AB${description}\u00BB`,
        handleOnClick: () => history.push(`/${DIAGNOSTICS_KEY}/${params.id}`),
      },
      {
        label: 'Список саб-элементов для дефектов',
        handleOnClick: () => history.push(`/${DIAGNOSTIC_SPECTRUM}/${params.id}`),
      },
      {
        label: 'Добавление дефекта',
        handleOnClick: () => {},
      },
    ],
    [description, history, params.id],
  );
  let activeFrequencyPortraitsTab000 = activeFrequencyPortraitsTab;

  let activeFrequencyPortraitsTabDiableFlag = false;

  if (graphParams?.element?.elementId === graphParams?.freqElement?.elementId) {
    if (isPortraitAvailable) {
      activeFrequencyPortraitsTabDiableFlag = false;
    } else {
      activeFrequencyPortraitsTab000 = FREQUENCY_PORTRAITS_TABS[0].value;
      activeFrequencyPortraitsTabDiableFlag = true;
    }
  } else {
    if (isPortraitAvailable) {
      activeFrequencyPortraitsTabDiableFlag = false;
    } else {
      activeFrequencyPortraitsTab000 = FREQUENCY_PORTRAITS_TABS[0].value;
      activeFrequencyPortraitsTabDiableFlag = true;
    }
  }

  const { groupCheckboxValues, groupCheckboxValuesByIdSet } = useMemo(() => {
    const groupCheckboxValues000 =
      graphParams.groups?.map((group) => {
        let someEnvelopCheckBoxesCount = 0;
        let someDirectCheckBoxesCount = 0;
        let nonZeroEnvelopFreqCheckBoxesCount = 0;
        let nonZeroDirectFreqCheckBoxesCount = 0;
        let allCheckBoxes = 0;

        for (let freq of group.data) {
          allCheckBoxes++;
          if ((freq.dyEnvelop ?? 0) !== 0) {
            nonZeroEnvelopFreqCheckBoxesCount++;
          }
          if ((freq.dySpectrum ?? 0) !== 0) {
            nonZeroDirectFreqCheckBoxesCount++;
          }
          if (selectedEnvelopFrequencies.has(freq.frequencyName)) {
            someEnvelopCheckBoxesCount++;
          }
          if (selectedDirectFrequencies.has(freq.frequencyName)) {
            someDirectCheckBoxesCount++;
          }
        }

        let result = ICheckBoxState.NONE;
        if (someEnvelopCheckBoxesCount > 0 || someDirectCheckBoxesCount > 0) {
          result = ICheckBoxState.SOME;
        }
        if (
          someEnvelopCheckBoxesCount === allCheckBoxes &&
          someDirectCheckBoxesCount === allCheckBoxes
        ) {
          result = ICheckBoxState.ALL;
        }
        if (
          allCheckBoxes > 0 &&
          nonZeroEnvelopFreqCheckBoxesCount === someEnvelopCheckBoxesCount &&
          nonZeroDirectFreqCheckBoxesCount === someDirectCheckBoxesCount &&
          (nonZeroDirectFreqCheckBoxesCount > 0 || nonZeroDirectFreqCheckBoxesCount > 0)
        ) {
          result = ICheckBoxState.ZERO_FREQ;
        }

        return result;
      }) ?? [];

    const groupCheckboxValuesByIdSet000 = new Set(
      graphParams.groups
        ?.map((group) => group.groupId)
        .filter((id, index) => groupCheckboxValues000[index] !== ICheckBoxState.NONE) ?? [],
    );

    return {
      groupCheckboxValues: groupCheckboxValues000,
      groupCheckboxValuesByIdSet: groupCheckboxValuesByIdSet000,
    };
  }, [graphParams.groups, selectedDirectFrequencies, selectedEnvelopFrequencies]);

  return (
    <div className={classes.layout}>
      <CustomBreadcrumbs links={breadcrubms} />
      <div className={classes.row}>
        <div>
          <div className={classes.headerAndControlsContainer}>
            <div className={classes.headerTitle}>{'Добавление дефекта'}</div>
            <div>
              <span className={classes.mnemonicSchemeButton}>
                Мнемосхема
                <Tooltip
                  title={
                    mnemonicSchemeId
                      ? 'Показать элементы на мнемосхеме'
                      : 'Мнемосхема недоступна (нет айдишника мнемосхемы)'
                  }
                  placement="top"
                >
                  <IconButton
                    color={mnemonicSchemeId ? 'primary' : 'secondary'}
                    onClick={() => setWithMnemonicScheme(true)}
                    size="small"
                    className={classes.smallMargin}
                  >
                    <DeveloperBoardIcon style={{ fontSize: 18 }} />
                  </IconButton>
                </Tooltip>
              </span>
              <span>
                Тултип
                <Switch
                  className={classes.smallMargin}
                  size="small"
                  checked={withTooltip}
                  onChange={() => setWithTooltip(!withTooltip)}
                  color="primary"
                />
              </span>
            </div>
          </div>

          <SpectrumLayoutHeader
            subElement={graphParams?.element}
            defects={defects}
            currentDefect={currentDefect}
            setCurrentDefect={setCurrentDefect}
            defectsDict={defectsDict}
            setSelectedEnvelopFrequencies={setSelectedEnvelopFrequencies}
            setSelectedDirectFrequencies={setSelectedDirectFrequencies}
            isReadyCheckBox={isReadyCheckBox}
            diagnosticId={params.id}
            showCalulatorIcon={true}
            onCalculatorIconClick={() => {
              setQueryParams(
                { selectedVertexes: [...selectedEnvelopFrequencies] },
                `/${DIAGNOSTIC_SPECTRUM_BEARING_PARAMS_CALCULATOR}/${params.id}/${params.elementGuideId}`,
              );
            }}
          />
          <div className={classes.chartTitleContainer}>
            <div className={classes.chartTitle}>{'Спектр огибающей'}</div>
            {query.data?.data?.rootMeanSquare && (
              <div>
                <span className={classes.skzTextLabel}>СКЗ:&nbsp;</span>
                <span className={classes.skzTextValue}>{query.data?.data?.rootMeanSquare}</span>
              </div>
            )}
          </div>
          <Chart
            isEnvelop={true}
            canvasSize={canvasSize}
            graphParams={graphParams}
            hoverPeakName={hoverPeakName}
            setHoverPeakName={setHoverPeakName}
            hoverGroupName={hoverGroupName}
            selectedFrequencies={selectedEnvelopFrequencies}
            setSelectedFrequencies={setSelectedEnvelopFrequencies}
            spectrumData={query.data?.data?.spectrums}
            frequencyZoomParams={frequencyZoomParams}
            setFrequencyZoomParams={setFrequencyZoomParams}
            setExpandedGroups={setExpandedGroups}
            hoverArea={hoverArea}
            setHoverArea={setHoverArea}
            withTooltip={withTooltip}
          />

          <SpectrumLayoutCustomScrollBar
            graphParams={graphParams}
            frequencyZoomParams={frequencyZoomParams}
            setFrequencyZoomParams={setFrequencyZoomParams}
          />

          <div key={222}>
            <Tabs
              disabled={activeFrequencyPortraitsTabDiableFlag}
              value={activeFrequencyPortraitsTab000}
              onChange={(event: React.BaseSyntheticEvent, newValue: string) => {
                event.preventDefault();
                setActiveFrequencyPortraitsTab(newValue);
              }}
              indicatorColor="primary"
              textColor="primary"
              className={classes.tabs}
            >
              {/* {(isPortraitAvailable ? FREQUENCY_PORTRAITS_TABS : [FREQUENCY_PORTRAITS_TABS[0]]).map( */}
              {FREQUENCY_PORTRAITS_TABS.map(({ label, value }) => (
                <Tab
                  label={label}
                  key={label}
                  value={value}
                  classes={{ root: classes.tab }}
                  disabled={activeFrequencyPortraitsTabDiableFlag}
                />
              ))}
            </Tabs>
          </div>

          {activeFrequencyPortraitsTab000 === FREQUENCY_PORTRAITS_TABS[0].value ? (
            <Chart
              isEnvelop={false}
              canvasSize={canvasSize}
              graphParams={graphParams}
              hoverPeakName={hoverPeakName}
              setHoverPeakName={setHoverPeakName}
              hoverGroupName={hoverGroupName}
              selectedFrequencies={selectedDirectFrequencies}
              setSelectedFrequencies={setSelectedDirectFrequencies}
              spectrumData={query.data?.data?.spectrums}
              frequencyZoomParams={frequencyZoomParams}
              setFrequencyZoomParams={setFrequencyZoomParams}
              setExpandedGroups={setExpandedGroups}
              hoverArea={hoverArea}
              setHoverArea={setHoverArea}
              withTooltip={withTooltip}
            />
          ) : (
            <FrequencyPortraits
              key={params.id}
              canvasSize={canvasSize}
              frequencyPortraitsData={frequencyPortraitsData}
              isLikelyToBeAPortraitDefectFlags={predictQueryData?.namePortrait}
            />
          )}
        </div>
        <div className={classes.menu}>
          <OtherElementName
            elementGuideId={params.elementGuideId}
            freqElementId={freqElementId}
            elements={query.data?.data?.elements}
            setOtherFreqElementId={setOtherFreqElementId}
          />
          <FrequenciesMenuHeader />

          <FrequenciesMenu
            modalLayoutSize={modalLayoutSize}
            peaksData={graphParams.groups}
            preparedPeaks={graphParams?.preparedPeaks}
            selectedEnvelopFrequencies={selectedEnvelopFrequencies}
            setSelectedEnvelopFrequencies={setSelectedEnvelopFrequencies}
            selectedDirectFrequencies={selectedDirectFrequencies}
            setSelectedDirectFrequencies={setSelectedDirectFrequencies}
            hoverPeakName={hoverPeakName}
            setHoverPeakName={setHoverPeakName}
            hoverGroupName={hoverGroupName}
            setHoverGroupName={setHoverGroupName}
            expandedGroups={expandedGroups}
            setExpandedGroups={setExpandedGroups}
            setHoverArea={setHoverArea}
            isLikelyToBeAFreqDefectFlags={predictQueryData?.numberGroupFrequencies}
            groupCheckboxValues={groupCheckboxValues}
          />
          <DefectCommands
            key={`a${(defects ?? []).length} ${currentDefectOrTemplate?.id ?? '---'} ${
              freqElementId ?? ' 1 '
            }`}
            diagnosticId={params.id}
            elementGuideId={params.elementGuideId}
            freqElementId={freqElementId}
            fiterTypeForDefectOptions={
              (graphParams?.freqElement?.subelementType ??
                graphParams?.element?.subelementType ??
                '') as IElementType
            }
            defectsDictArray={defectsDictArray}
            currentDefect={currentDefect}
            setCurrentDefect={setCurrentDefect}
            selectedEnvelopFrequencies={selectedEnvelopFrequencies}
            selectedDirectFrequencies={selectedDirectFrequencies}
            preparedPeaks={graphParams?.preparedPeaks}
            clearStates={clearStates}
            nextSubElement={query?.data?.nextSubElement}
            isReadyCheckBox={isReadyCheckBox}
            setFrequencyZoomParams={setFrequencyZoomParams}
            currentDefectOrTemplate={currentDefectOrTemplate}
            setOtherFreqElementId={setOtherFreqElementId}
            groupCheckboxValuesByIdSet={groupCheckboxValuesByIdSet}
          />
        </div>
      </div>
      {withMnemonicScheme && mnemonicSchemeId && (
        <MnemonicSchemeModal
          setClose={() => setWithMnemonicScheme(false)}
          mnemonicSchemeId={mnemonicSchemeId}
          elementGuideId={params.elementGuideId}
          freqElementId={freqElementId}
          elements={query.data?.data?.elements}
        />
      )}
    </div>
  );
}
