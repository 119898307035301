import { Controller } from 'react-hook-form';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { IOption } from 'interfaces';
import { RED_COLOR, DARK_RED_COLOR, GREY_TEXT_COLOR } from 'stream-constants';
import { getErrorByPath } from 'utils/get-error-by-path';

import { IFieldProps } from '../field-interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 8,
      color: GREY_TEXT_COLOR,
      fontWeight: 400,
      fontSize: '14px',
    },
    label: {
      color: GREY_TEXT_COLOR,
    },
    emptyError: {
      opacity: 0,
    },
    error: {
      color: 'var(--main-red-color) !important',
      fontSize: '11px !important',
    },
    root: {
      marginBottom: -2,
      marginLeft: 26,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 12,
      height: 12,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: RED_COLOR,
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        backgroundColor: '#bbb !important',
      },
    },
    checkedIcon: {
      backgroundColor: RED_COLOR,
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 12,
        height: 12,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: `${DARK_RED_COLOR} !important`,
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        backgroundColor: `${DARK_RED_COLOR} !important`,
      },
    },
  }),
);
interface RadioProps2 extends RadioProps {
  forId?: string;
}

export function StyledRadio(props: RadioProps2) {
  const classes = useStyles();
  const { forId, ...otherProps } = props;

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...otherProps}
      id={forId ? `id_radio_for_${forId}` : undefined}
    />
  );
}
interface IProps<T> extends IFieldProps<T> {
  options: IOption[];
}

export function RadioGroupField(props: IProps<any>) {
  const { fieldName, label, form, onFieldChange, disabled, defaultValue } = props;
  const classes = useStyles();

  const errors = form.formState.errors;
  const { isSubmitted } = form.formState;

  const errMessage = isSubmitted
    ? //@ts-ignore
      getErrorByPath(errors, fieldName)?.message
    : undefined;
  const isValid = !errMessage;

  return (
    <>
      <Controller
        name={fieldName}
        control={form.control}
        render={({ field }) => {
          return (
            <FormControl component="fieldset">
              <FormLabel component="legend" color="secondary" className={classes.title}>
                {label}
              </FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender1"
                value={field.value ?? defaultValue}
                onChange={(event: any, optionValue: string) => {
                  onFieldChange && onFieldChange(optionValue);
                  field.onChange(optionValue ?? '');
                }}
              >
                {(props.options ?? []).map((option) => (
                  <FormControlLabel
                    key={option.value}
                    className={classes.label}
                    value={option.value}
                    control={
                      <StyledRadio
                        disabled={disabled}
                        disableRipple={true}
                        forId={`id_for_${option.value}_827`}
                      />
                    }
                    label={option.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          );
        }}
      />
      <div
        className={classNames(classes.label, classes.error, {
          [classes.emptyError]: isValid,
        })}
      >
        {errMessage || '.'}
      </div>
    </>
  );
}
