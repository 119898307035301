import './index.css';
import 'fonts/fonts.css';

import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';

import ReactDOM from 'react-dom';

import { InsertMatomoScriptForStatisticInfo } from 'components/insert-matomo-script-for-statistic-info';
import { LayoutResizeEffect } from 'components/layout-resize-effect';
import ContextProvider from 'context/context-provider';
import { DiagnosticRoutes } from 'pages-diagnostic/diagnostic-routes/diagnostic-routes';
import MyThemeProvider from 'theme';

import ReactQueryWrapper from './react-query-wrapper';
import reportWebVitals from './reportWebVitals';

console.log(`%c Версия фронта : ${process.env.REACT_APP_DEPLOY_TIMESTAMP}`, 'color: orange');

ReactDOM.render(
  <div className="header-frame">
    <div className="width-wrapper">
      <React.StrictMode>
        <ContextProvider>
          <MyThemeProvider>
            <ReactQueryWrapper>
              <InsertMatomoScriptForStatisticInfo />
              <LayoutResizeEffect />
              <Router>
                <DiagnosticRoutes />
              </Router>
            </ReactQueryWrapper>
          </MyThemeProvider>
        </ContextProvider>
      </React.StrictMode>
    </div>
  </div>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
