import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { elementIcon } from 'components/element-icon';
import { StyledCheckbox } from 'components/styled-checkbox';
import { MODES_DICT_NAMES } from 'interfaces';

import { IElem, elementsKey } from './common';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    layout: {
      marginLeft: 50,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: 0,
      alignItems: 'center',
    },
    iconScale: {
      marginTop: 3,
      transform: 'scale(0.6)',
    },
  }),
);
interface IProps {
  nodeId: string;
  nodeElements: Map<string, IElem[]>;
  selectedElements: Set<string>;
  setSelectedElements: (selectedElements: Set<string>) => void;
  setSelectedNodes: (selectedNodes: any) => void;
}

export function BearingsList({
  nodeId,
  nodeElements,
  selectedElements,
  setSelectedElements,
  setSelectedNodes,
}: IProps) {
  const classes = useStyles();

  const list = nodeElements.get(nodeId) ?? [];

  return (
    <div className={classes.layout}>
      {list.map((bearing: IElem) => {
        const key = elementsKey([{ id: bearing.id }, { id: '' }]);
        const IconComponent = elementIcon(bearing.type);

        return (
          <div className={classes.row} key={key}>
            <StyledCheckbox
              checked={selectedElements.has(key)}
              onChange={(_, value) => {
                const newSelectedElements = new Set([...selectedElements]);
                if (value) {
                  newSelectedElements.add(key);
                  setSelectedNodes((oldSet: Set<string>) => {
                    const newSet = new Set<string>([...oldSet]);
                    newSet.add(nodeId);

                    return newSet;
                  });
                } else {
                  newSelectedElements.delete(key);
                }
                setSelectedElements(newSelectedElements);
              }}
            />
            <Tooltip title={MODES_DICT_NAMES[bearing.type]} placement={'top'}>
              <div className={classes.iconScale}>
                <IconComponent />
              </div>
            </Tooltip>

            <Typography variant={'body2'} color="secondary">
              {bearing.title}
            </Typography>
          </div>
        );
      })}
    </div>
  );
}
