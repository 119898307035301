import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function ArrowDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78087 10.0239C8.38054 10.5243 7.61946 10.5243 7.21913 10.0239L5.29976 7.6247C4.77595 6.96993 5.24212 6 6.08062 6L9.91938 6C10.7579 6 11.2241 6.96993 10.7002 7.62469L8.78087 10.0239Z"
      />
    </SvgIcon>
  );
}
