import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classnames from 'classnames';

import SadPicture from './sad-picture.png';

const useStyles = makeStyles((theme) => ({
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  img: {
    width: '200px',
    objectFit: 'cover',
  },
  marginTop: {
    marginTop: theme.spacing(5),
  },

  line: {
    width: '200px',
    border: '10px solid #DCDFE6',
  },
}));

const title = 'Что-то пошло не так! Пожалуйста обратитесь в техподдержку.';

export default function ErrorBoundaryStub({ errorInfo }: { errorInfo: any }) {
  const classes = useStyles();
  const arr = errorInfo?.componentStack?.split('\n').reverse();

  return (
    <>
      <div className={classnames(classes.marginTop, classes.center)}>
        <Typography variant={'h6'} color="error">
          {title}
        </Typography>
      </div>
      <div className={classnames(classes.marginTop, classes.center)}>
        <img src={SadPicture} alt={title} className={classes.img} decoding="async" />
      </div>
      <div className={classnames(classes.marginTop, classes.center)}>
        <div className={classes.line}></div>
      </div>
      <div className={classnames(classes.marginTop, classes.center)}>
        <div>
          {arr.map((textLine: string, index: number) => (
            <Typography variant={'subtitle1'} color="secondary" key={String(index)}>
              {textLine}
            </Typography>
          ))}
        </div>
      </div>
    </>
  );
}
