import { useRef, useMemo } from 'react';

import { OrderAscIcon } from 'icons/order-asc-icon';
import { OrderDescIcon } from 'icons/order-desc-icon';
import { ResetFiltersIcon } from 'icons/reset-filters-icon';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { IFIltersOptionsItem } from 'components/autocomplete-for-filters/autocomplete-for-filters';
import { StandAllon } from 'components/autocomplete-for-filters/stand-allon';
import SearchInput from 'components/search-input-component';
import { ISortParamsOrder } from 'interfaces';
import { useQueryParams } from 'utils/hooks/use-query-params';

import { useGetDiagnosticFilterDictionaries } from '../hooks';

export enum IDiagnosticFilterName {
  CREATOR_FULL_NAME = 'creatorFullName',
  FACTORY_TOWN = 'factoryTown',
  MACHINE_LINK_NAME = 'machineLinkName',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sortOrder: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      maxWidth: '264px',
      minWidth: '264px',
    },
    sortOrderTitle: {
      color: '#626c77',
    },
    sortOrderSubTitle: {
      color: '#1d2023',
      marginRight: '6px',
    },
    fitersRow: {
      margin: '16px 0px 16px 5px',
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      gap: '20px',
    },
    firstFilterItem: {
      maxWidth: '200px',
      minWidth: '200px',
    },

    resetFilterItem: {
      maxWidth: '42px',
      minWidth: '42px',
      maxHeight: '42px',
      minHeight: '42px',
      backgroundColor: '#f2f3f7',
      borderRadius: '6px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#eeeeee',
      },
    },
    grow: {
      flexGrow: 2,
    },
  }),
);

export function DiagnosticsFilters({
  handleSearchStringSubmit,
  handleFiltersChange,
  order,
  setOrder,
  handleClearFilters,
}: {
  handleSearchStringSubmit: (s: string) => void;
  handleFiltersChange: (arr: { id: string; value: any }[]) => void;
  order?: string;
  setOrder: (a: string) => void;
  handleClearFilters: () => void;
}) {
  const classes = useStyles();

  const searchInputKeyRef = useRef<number>(1);
  const { queryParams } = useQueryParams();
  const searchString = queryParams.searchString ?? '';
  const filters = queryParams.filters ?? [];

  const queryFiltersDictionaries000 = useGetDiagnosticFilterDictionaries(
    {
      searchString,
      filters,
    },
    true,
    () => {},
  ) as unknown as {
    isLoading: boolean;
    data: Record<IDiagnosticFilterName, string[] | undefined> | undefined;
  };
  const queryFiltersDictionaries = useMemo(() => {
    const newData = {} as Record<IDiagnosticFilterName, IFIltersOptionsItem[]>;
    Object.entries(queryFiltersDictionaries000?.data ?? {}).forEach(([key, value]) => {
      //@ts-ignore
      newData[key] = value.map((name) => ({ name, amount: 0 }));
    });

    return {
      isLoading: queryFiltersDictionaries000.isLoading,
      data: newData,
    };
  }, [queryFiltersDictionaries000]);

  return (
    <>
      <div className={classes.fitersRow}>
        <div className={classes.firstFilterItem}>
          <SearchInput
            key={searchInputKeyRef.current}
            setSearchString={handleSearchStringSubmit}
            searchString={searchString}
          />
        </div>
        <StandAllon
          name={IDiagnosticFilterName.CREATOR_FULL_NAME}
          label="Диагносты"
          filters={filters}
          queryFiltersDictionaries={queryFiltersDictionaries}
          handleFiltersChange={handleFiltersChange}
        />

        <StandAllon
          name={IDiagnosticFilterName.FACTORY_TOWN}
          label="Города"
          filters={filters}
          queryFiltersDictionaries={queryFiltersDictionaries}
          handleFiltersChange={handleFiltersChange}
        />
        <StandAllon
          name={IDiagnosticFilterName.MACHINE_LINK_NAME}
          label="Станки"
          filters={filters}
          queryFiltersDictionaries={queryFiltersDictionaries}
          handleFiltersChange={handleFiltersChange}
        />
        <Tooltip title={'Сбросить все фильтры'} placement={'top'}>
          <div
            id="id_reset_filters_icon_953"
            className={classes.resetFilterItem}
            onClick={() => {
              searchInputKeyRef.current += 1;
              handleClearFilters();
            }}
          >
            <ResetFiltersIcon />
          </div>
        </Tooltip>

        <div className={classes.grow} />
        <div className={classes.sortOrder}>
          <div className={classes.sortOrderTitle}>Сортировать:</div>
          <span className={classes.sortOrderSubTitle}>{`По дате создания (сначала ${
            order === undefined || order === ISortParamsOrder.DESC ? 'новые' : 'старые'
          })`}</span>
          <IconButton
            id="id_sortby_date_of_creation_953"
            aria-label={'sort-order'}
            onClick={() =>
              setOrder(
                order === undefined || order === ISortParamsOrder.DESC
                  ? ISortParamsOrder.ASC
                  : ISortParamsOrder.DESC,
              )
            }
            size="small"
          >
            {order === undefined || order === ISortParamsOrder.DESC ? (
              <OrderDescIcon style={{ fontSize: 13 }} />
            ) : (
              <OrderAscIcon style={{ fontSize: 13 }} />
            )}
          </IconButton>
        </div>
      </div>
    </>
  );
}
