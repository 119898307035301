import Radio, { RadioProps } from '@material-ui/core/Radio';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { RED_COLOR, DARK_RED_COLOR } from 'stream-constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: -2,
      marginLeft: 0,
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: '50%',
      width: 12,
      height: 12,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: RED_COLOR,
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        backgroundColor: '#bbb !important',
      },
    },
    checkedIcon: {
      backgroundColor: RED_COLOR,
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 12,
        height: 12,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: `${DARK_RED_COLOR} !important`,
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        backgroundColor: `${DARK_RED_COLOR} !important`,
      },
    },
  }),
);

interface RadioProps2 extends RadioProps {
  forId?: string;
}
export function StyledRadio(props: RadioProps2) {
  const classes = useStyles();

  const { forId, ...otherProps } = props;

  return (
    <Radio
      id={forId && 'for_radio_group_id_' + forId}
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...otherProps}
    />
  );
}
