import { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import useAutocomplete from '@material-ui/lab/useAutocomplete';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      display: 'block',
      fontWeight: 400,
      fontSize: '14px',
      color: '#626C77',
    },
    inputContainer: {
      position: 'relative',
    },
    input: {
      width: 200,
      height: '42px',
      backgroundColor: '#F2F3F7',
      border: '1px solid rgba(188, 195, 208, 0.5)',
      borderRadius: '6px',
      padding: '0 56px 0 12px',
    },
    iconsCointainer: {
      position: 'absolute',
      top: 8,
      right: 2,
      display: 'flex',
      alignItems: 'center',
    },
    iconRotate: {
      transform: 'rotate(180deg)',
    },
    listbox: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08)',
      borderRadius: '6px',

      width: 300,
      marginTop: 4,
      padding: 0,
      zIndex: 1,
      position: 'absolute',
      listStyle: 'none',
      overflow: 'auto',
      maxHeight: 300,
      border: '1px solid rgba(0,0,0,.25)',
      '& li[data-focus="true"]': {
        backgroundColor: 'red',
        color: 'white',
        cursor: 'pointer',
        padding: '10px 12px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      '& li:active': {
        backgroundColor: '#F2F3F7',
        color: 'white',
        padding: '10px 12px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      '& li': {
        backgroundColor: '#FFF',
        padding: '10px 12px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
  }),
);

const LOADING_OPTION = ['...загрузка...'];
export interface IFIltersOptionsItem {
  name: string;
  amount: number;
}

export function AutocompleteForFilters({
  label,
  options,
  onOptionClick,
  value,
  loading,
}: {
  label: string;
  options: IFIltersOptionsItem[];
  onOptionClick: (s: string) => void;
  value: string | undefined;
  loading: boolean;
}) {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: `autocomplete_id_${window.btoa(encodeURIComponent(label))}`,
    options: loading ? LOADING_OPTION : options.map((elem) => elem.name),
    getOptionLabel: (option) => option.toString(),
    value: value ?? '',
    open,
    onOpen: () => setOpen(true),
    onClose: () => setOpen(false),
    onChange: (e: any, aaa: any) => {
      if (!loading) {
        onOptionClick(aaa);
        setOpen(false);
      }
    },
  });

  return (
    <div>
      <div {...getRootProps()}>
        <label className={classes.label} {...getInputLabelProps()}>
          {label}
        </label>
        <div className={classes.inputContainer}>
          <input className={classes.input} {...getInputProps()}></input>
          <div className={classes.iconsCointainer}>
            <IconButton size="small">
              <ClearIcon
                style={{ fontSize: 18 }}
                onClick={() => {
                  if (!loading) {
                    onOptionClick('');
                  }
                }}
              />
            </IconButton>
            <IconButton size="small">
              <ArrowDropDownIcon
                className={open ? classes.iconRotate : undefined}
                style={{ fontSize: 26 }}
                onClick={() => setOpen(!open)}
              />
            </IconButton>
          </div>
        </div>
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={classes.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => (
            <li {...getOptionProps({ option, index })}>{option}</li>
          ))}
        </ul>
      ) : null}
    </div>
  );
}
