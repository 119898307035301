import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { IDefect } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    defectIcon: {
      height: 28,
      width: 28,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 14,
      fontWeight: 700,
      color: '#000',
      boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.08), 0px 4px 16px rgba(0, 0, 0, 0.08)',
      borderRadius: '50%',
    },
    defectIconSmallSize: {
      height: 20,
      width: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 12,
      fontWeight: 500,
      color: '#000',
      borderRadius: '50%',
      marginLeft: 4,
    },
    rankGreen: {
      backgroundColor: '#26CD58',
    },
    rankYellow: {
      backgroundColor: '#FAC031',
    },
    rankRed: {
      backgroundColor: '#F95721',
    },
    currentDeffectStyle: {
      boxShadow: '16px 16px 16px rgba(0, 0, 0, 0.20), 0px 4px 16px rgba(0, 0, 0, 0.20)',
    },
    statusOpacity: {
      opacity: 0.35,
    },
  }),
);

export const ShowDefect = React.memo(function ({
  defect,
  defectsDict,
  isSmallSize,
  currentDefect,
  onClick,
  setSelectedEnvelopFrequencies,
  setSelectedDirectFrequencies,
}: {
  defect: IDefect | undefined;
  defectsDict: Record<string, string>;
  isSmallSize: boolean;
  currentDefect?: IDefect;
  onClick?: (d: IDefect | undefined) => void;
  setSelectedEnvelopFrequencies?: (s: Set<string>) => void;
  setSelectedDirectFrequencies?: (s: Set<string>) => void;
}) {
  const classes = useStyles();
  // Цвет Степень дефекта:
  // <5% -слабая степень дефекта - зеленый цвет
  // 5-15% - средняя степень дефекта - желтый цвет
  // 15%< -сильная степень дефекта - красный цвет
  let color: string = classes.rankGreen;
  const rank = defect?.rank;
  if (typeof rank === 'number') {
    if (rank >= 5) {
      color = classes.rankYellow;
    }
    if (rank > 15) {
      color = classes.rankRed;
    }
  }

  return (
    <Tooltip placement="top" title={(defect && defectsDict[defect.defectType]) ?? '---'}>
      <span>
        <IconButton
          className={classNames({ [classes.currentDeffectStyle]: defect === currentDefect })}
          aria-label="defect"
          size={isSmallSize ? 'small' : 'medium'}
          onClick={() => {
            if (onClick) {
              const newDefect = currentDefect === defect ? undefined : defect;
              if (newDefect === undefined) {
                setSelectedEnvelopFrequencies && setSelectedEnvelopFrequencies(new Set());
                setSelectedDirectFrequencies && setSelectedDirectFrequencies(new Set());
              } else {
                setSelectedEnvelopFrequencies && setSelectedEnvelopFrequencies(new Set());
                setSelectedDirectFrequencies && setSelectedDirectFrequencies(new Set());
              }
              onClick(newDefect);
            }
          }}
        >
          <div
            className={classNames(color, {
              [classes.defectIcon]: !isSmallSize,
              [classes.defectIconSmallSize]: isSmallSize,
              [classes.statusOpacity]: defect?.status === 1,
            })}
          >
            {defect?.defectType ?? '?'}
          </div>
        </IconButton>
      </span>
    </Tooltip>
  );
});
