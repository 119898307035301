import { useContext } from 'react';

import { useQuery, useMutation, useQueryClient } from 'react-query';

import { ContextSettings } from 'context/context-settings';
import { IHttpMethods } from 'interfaces';
import {
  getFolderListById,
  updateFolderListById,
  uploadCvs,
  deleteCsvFile,
  finishUpload,
} from 'services/diagnostic-folder-list';
import { fetchData } from 'services/service-utils';
import { CREATE_MINIO_URL_WITH_SVG_VALIDATION } from 'services/urls';
import {
  DIAGNOSTIC_MEASURE_UPLOAD,
  DIAGNOSTICS_KEY,
  DIAGNOSTICS_BY_ID_KEY,
} from 'stream-constants';
import { errorMessageConvert } from 'utils/error-message-convert';

export function useGetFolderListById(id: string) {
  const { setNotificationMessage } = useContext(ContextSettings);
  const query = useQuery([DIAGNOSTIC_MEASURE_UPLOAD, id], () => getFolderListById(id), {
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return query;
}

export function useMutateFolderListById(diagnosticId: string, handleOnSuccess: () => void) {
  const { setNotificationMessage } = useContext(ContextSettings);
  const queryClient = useQueryClient();

  const mutationUpdate = useMutation((data: any) => updateFolderListById(diagnosticId, data), {
    onSuccess: async (result) => {
      handleOnSuccess();
      await Promise.all([
        queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD, diagnosticId]),
        queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
        queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, diagnosticId]),
      ]);
    },
    onError: (error: Error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return mutationUpdate;
}

export async function fetchMeasureUpload(file: File, svgValidation?: boolean) {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('fileType', file.type);
  formData.append('svgValidation', svgValidation ? 'true' : 'false');

  const obj = await fetchData({
    url: CREATE_MINIO_URL_WITH_SVG_VALIDATION,
    method: IHttpMethods.POST,
    name: 'fetchMeasureUpload',
    body: formData,
    emptyResponse: false,
    contentType: 'none',
    timeoutToAbort: 35 * 60 * 1000,
  });
  const result = Promise.resolve(obj);

  return result;
}

export function useMutateUploadCsv(diagnosticId: string, handleOnSuccess: (a: any) => void) {
  const { setNotificationMessage } = useContext(ContextSettings);

  const queryClient = useQueryClient();
  const mutationUpload = useMutation(
    (data: { csvLink: string; folderName: string; fileMeta: string | null }) =>
      uploadCvs(diagnosticId, data),
    {
      onSuccess: async (result) => {
        handleOnSuccess(result);
        await Promise.all([
          queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD, diagnosticId]),
          queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
          queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, diagnosticId]),
        ]);
      },
      onError: (error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return mutationUpload;
}
export function useMutateDeleteCsvFile(diagnosticId: string, handleOnSuccess: (a: any) => void) {
  const { setNotificationMessage } = useContext(ContextSettings);

  const queryClient = useQueryClient();
  const mutationUpload = useMutation((csvId: string | null) => deleteCsvFile(csvId), {
    onSuccess: async (result) => {
      handleOnSuccess(result);
      await Promise.all([
        queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD, diagnosticId]),
        queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
        queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, diagnosticId]),
      ]);
    },
    onError: (error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return mutationUpload;
}
export function useMutateFinishUpload(diagnosticId: string, handleOnSuccess: (a: any) => void) {
  const { setNotificationMessage } = useContext(ContextSettings);

  const queryClient = useQueryClient();
  const mutationUpload = useMutation(() => finishUpload(diagnosticId), {
    onSuccess: async (result) => {
      handleOnSuccess(result);
      await Promise.all([
        queryClient.invalidateQueries([DIAGNOSTIC_MEASURE_UPLOAD, diagnosticId]),
        queryClient.invalidateQueries([DIAGNOSTICS_KEY]),
        queryClient.invalidateQueries([DIAGNOSTICS_BY_ID_KEY, diagnosticId]),
      ]);
    },
    onError: (error) => {
      setNotificationMessage(errorMessageConvert(error as Error));
    },
  });

  return mutationUpload;
}
