import { useEffect, useRef, useMemo, memo } from 'react';

import { UseQueryResult } from 'react-query';

import { PredictIcon } from 'icons/predict-icon';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { useGetFrequencyPortraitsColors } from 'pages-diagnostic/diagnostic-spectrum/query-hooks/query-hooks';
import {
  IS_LIKELY_TO_BE_A_DEFECT,
  IMAttrix,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

export const TITLE_CHART_SHIFT = 80;

const EMPTY_ARR: IMAttrix[] = [];
const MIN_GAP = 24;

interface ICalcWidth {
  calcWidth: number;
}

const useStyles = makeStyles<Theme, ICalcWidth>((theme) =>
  createStyles({
    root: {
      marginTop: '12px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    container: {
      width: (props) => props.calcWidth,
      height: (props) => props.calcWidth,
    },
    row: {
      width: (props) => props.calcWidth,
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      borderTop: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderBottom: '1px solid rgba(188, 195, 208, 0.5)',
      marginBottom: 6,
    },
    label: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: '#1D2023',
    },
  }),
);

export const FrequencyPortraits = memo(function ({
  canvasSize,
  frequencyPortraitsData,
  isLikelyToBeAPortraitDefectFlags,
}: {
  canvasSize: { height: number; width: number };
  frequencyPortraitsData: UseQueryResult<{ portraits: IMAttrix[] }, Error>;
  isLikelyToBeAPortraitDefectFlags: string[] | undefined;
}) {
  let calcWidth = (canvasSize.width - 4 * MIN_GAP) / 5;
  calcWidth = Math.trunc(Math.min(calcWidth, canvasSize.height));

  const classes = useStyles({ calcWidth });

  const canvasHoverRef0 = useRef<HTMLCanvasElement | null>(null);
  const canvasHoverRef1 = useRef<HTMLCanvasElement | null>(null);
  const canvasHoverRef2 = useRef<HTMLCanvasElement | null>(null);
  const canvasHoverRef3 = useRef<HTMLCanvasElement | null>(null);
  const canvasHoverRef4 = useRef<HTMLCanvasElement | null>(null);
  const canvasHoverArr = useMemo(
    () => [canvasHoverRef0, canvasHoverRef1, canvasHoverRef2, canvasHoverRef3, canvasHoverRef4],
    [],
  );

  const colorsData = useGetFrequencyPortraitsColors();
  const colors = (colorsData?.data ?? EMPTY_ARR) as string[];
  const mattrixArray = (frequencyPortraitsData?.data?.portraits ?? EMPTY_ARR) as IMAttrix[];

  useEffect(() => {
    for (let index = 0; index < canvasHoverArr.length; index++) {
      if (!colors) {
        return;
      }
      const canvas = canvasHoverArr[index].current as HTMLCanvasElement;
      const ctx = canvas?.getContext('2d', { alpha: true });
      if (!ctx) {
        return;
      }
      ctx.clearRect(0, 0, calcWidth, calcWidth);
      const marginLeft = 20;
      const marginBottom = 20;

      ctx.font = '8px MTSSans';
      ctx.textAlign = 'center';

      const arr = mattrixArray[index].frequencyMatrix;

      const elemHeight = (calcWidth - marginBottom) / (arr.length || 1);
      for (let j = 0; j < arr.length; j++) {
        const arr2 = arr[j];
        const elemWidth = calcWidth / (arr2.length || 1);

        for (let k = 0; k < arr2.length; k++) {
          const colorIndex = arr2[k];
          const color = colors[colorIndex] ?? 'lightgrey';
          ctx.fillStyle = color;
          ctx.fillRect(marginLeft + elemWidth * k, elemHeight * j, elemWidth + 1, elemHeight + 1);
        }
      }
      // координатные шкалы по осям X и Y
      ctx.fillStyle = '#626C77';
      ctx.strokeStyle = '#626C77';
      ctx.lineWidth = 1;
      const xValues = mattrixArray[index].xValues;

      for (let i = 0; i < xValues.length; i++) {
        const x = (i * calcWidth) / (xValues.length + 0.52) + marginLeft + 2;
        const y = calcWidth - 8;
        ctx.fillText(xValues[i].toString(), x, y);
        ctx.moveTo(x + 1, calcWidth - marginBottom);
        ctx.lineTo(x + 1, calcWidth - marginBottom + 3);
        ctx.stroke();
      }
      const yValues = mattrixArray[index].yValues;
      for (let i = 0; i < yValues.length; i++) {
        const y = calcWidth - elemHeight - yValues[i] * elemHeight;
        ctx.fillText(yValues[i].toString(), marginLeft / 2 - 2, y);
        ctx.moveTo(marginLeft - 3, y - elemHeight / 4);
        ctx.lineTo(marginLeft, y - elemHeight / 4);
        ctx.stroke();
      }
    }
  }, [calcWidth, canvasHoverArr, colors, mattrixArray]);

  return (
    <div className={classes.root}>
      {mattrixArray.map((mattrix, index) => (
        <div key={mattrix.frequencyLabel} className={classes.container}>
          <div className={classes.row}>
            <div className={classes.label}>{mattrix.frequencyLabel}</div>
            {isLikelyToBeAPortraitDefectFlags?.includes(mattrix.frequencyLabel) && (
              <Tooltip title={IS_LIKELY_TO_BE_A_DEFECT} placement={'top'}>
                <span>
                  <PredictIcon style={{ fontSize: 16 }} />
                </span>
              </Tooltip>
            )}
          </div>
          <canvas ref={canvasHoverArr[index]} height={calcWidth} width={calcWidth} />
        </div>
      ))}
    </div>
  );
});
