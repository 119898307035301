import React from 'react';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { elementIcon } from 'components/element-icon';
import { ShowDefect } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-list/components/show-defect';
import { ISummaryElement } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';

const SUMMARY_COLUMN_WIDTH = 0.45;
const DEFECTS_COLUMN_WIDTH = 0.3;

type IProps = { itemWidth: number; isHeader: boolean };

const useStyles = makeStyles<Theme, IProps>((theme) =>
  createStyles({
    itemRow: {
      marginTop: '12px',
      cursor: 'pointer',
      border: (props) => (props.isHeader ? 'none' : '1px solid #DADEE5'),
      borderRadius: '16px',
      maxWidth: (props) => props.itemWidth,
      minWidth: (props) => props.itemWidth,
      minHeight: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '&:hover': {
        border: (props) => (props.isHeader ? 'none' : '1px solid #626C77'),
        backgroundColor: '#EBEEF1 !important',
      },
      color: (props) => (props.isHeader ? '#626C77' : '#000'),
    },

    isLastModified: {
      backgroundColor: '#E1F3FE',
    },

    itemNumber: {
      padding: '0 6px 0 20px',
      maxWidth: 50,
      minWidth: 50,
      fontWeight: (props) => (props.isHeader ? 400 : 700),
      fontSize: '14px',
      cursor: 'pointer',
    },
    itemIcon: {
      padding: '0 2px 0 2px',
      maxWidth: 50,
      minWidth: 50,
    },
    itemText: {
      padding: '0 6px',
      maxWidth: (props) =>
        Math.round(
          props.itemWidth - props.itemWidth * (DEFECTS_COLUMN_WIDTH + SUMMARY_COLUMN_WIDTH) - 100,
        ),
      minWidth: (props) =>
        Math.round(
          props.itemWidth - props.itemWidth * (DEFECTS_COLUMN_WIDTH + SUMMARY_COLUMN_WIDTH) - 100,
        ),
      fontWeight: 500,
      fontSize: '14px',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },

    itemDefects: {
      padding: '0 10px 0 6px',
      maxWidth: (props) => Math.round(props.itemWidth * DEFECTS_COLUMN_WIDTH),
      minWidth: (props) => Math.round(props.itemWidth * DEFECTS_COLUMN_WIDTH),
    },
    itemSummaryStyle: {
      height: '20px',
      padding: '0 6px',
      maxWidth: (props) => Math.round(props.itemWidth * SUMMARY_COLUMN_WIDTH),
      minWidth: (props) => Math.round(props.itemWidth * SUMMARY_COLUMN_WIDTH),
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },

    iconScale: {
      marginTop: 3,
      transform: 'scale(0.9)',
    },
    greyTextColor: {
      opacity: 0.6,
      fontWeight: 400,
    },
    uncertainTextColor: {
      color: '#ff6e3e',
    },
  }),
);

export const SummaryElementListItem = React.memo(function ({
  index,
  item,
  itemWidth,
  defectsDict,
  isHeader,
  isLastModifiedItem,
  onSummaryClick,
}: {
  index: string;
  item: ISummaryElement | undefined;
  itemWidth: number;
  defectsDict: Record<string, string>;
  isHeader: boolean;
  isLastModifiedItem: boolean;
  onSummaryClick?: (item: ISummaryElement) => void;
}) {
  const classes = useStyles({ itemWidth: itemWidth - 81, isHeader });
  if (!isHeader && item === undefined) {
    throw new Error('Не задан элемент списка');
  }
  const IconComponent = elementIcon(item?.elementGuideParentType);

  return (
    <div
      id={item?.elementGuideParentId ?? 'no_id'}
      className={classNames(classes.itemRow, { [classes.isLastModified]: isLastModifiedItem })}
      onClick={() => !isHeader && item && onSummaryClick && onSummaryClick(item)}
    >
      <div className={classes.itemNumber}>{isHeader ? '№' : <div>{index}</div>}</div>
      <div className={classes.itemIcon}>
        {!isHeader && (
          <div className={classes.iconScale}>
            <IconComponent />
          </div>
        )}
      </div>
      {isHeader ? (
        <div className={classes.itemText}>{'Название элемента'}</div>
      ) : (
        <div className={classNames({ [classes.uncertainTextColor]: item?.isUncertain })}>
          <div className={classes.itemText}>{item?.elementRealName}</div>
          <div className={classNames(classes.itemText, classes.greyTextColor)}>
            {item?.mnemonicSchemeLinkId}
          </div>
        </div>
      )}
      <div className={classes.itemDefects}>
        {isHeader
          ? '\u2004\u2004\u2004Дефекты'
          : item?.defectsList?.map((defect) => (
              <ShowDefect
                key={defect.id}
                defect={defect}
                defectsDict={defectsDict}
                isSmallSize={true}
              />
            ))}
      </div>
      <div className={classes.itemSummaryStyle}>
        {isHeader ? 'Выводы' : item?.summaryText || '---'}
      </div>
    </div>
  );
});
