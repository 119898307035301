import Paper from '@material-ui/core/Paper';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles<Theme, { fullHeight: boolean | undefined }>((theme) =>
  createStyles({
    root: {
      border: '2px solid rgba(188, 195, 208, 0.5)',
      borderRadius: '16px',
      padding: '20px',
      minHeight: (props) => (props.fullHeight ? '100%' : undefined),
    },
    disabled: {
      opacity: 0.4,
    },
  }),
);

type IProps = {
  fullHeight?: boolean;
  disabled?: boolean;
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
};

export function CustomPaper({ children, fullHeight, disabled }: IProps) {
  const classes = useStyles({ fullHeight });

  return (
    <Paper
      variant="outlined"
      classes={{ root: classNames(classes.root, { [classes.disabled]: !!disabled }) }}
    >
      {children}
    </Paper>
  );
}
