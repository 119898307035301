import { IMachine, IQueryParams, IHttpMethods, ISortParamsOrder } from 'interfaces';
import {
  GET_MACHINES_URL,
  CREATE_MACHINE_URL,
  GET_MACHINE_BY_ID_URL,
  GET_MACHINE_HEAD_BY_ID_URL,
  DUPLICATE_MACHINE_URL,
  GET_MACHINE_FILTER_DICTIONARIES,
} from 'services/urls';
import { DEFAULT_PAGE_SIZE, IS_SERVER_MOCKED } from 'stream-constants';
import { delay } from 'utils/delay';
import { generateUnicId } from 'utils/generate-unic-id';
import { machineValidationSchema } from 'validation-schemas/validation-schemas-vertext';

import { fetchData, probableError } from '../service-utils';

import filtersDictiontaries from './filters-dictionaries.json';
import { base } from './mock-data';

const BASE_TIME = 30;
const MIN_TIME = 20;

export const getMachines = async (
  params: IQueryParams,
): Promise<{ data: IMachine[]; total: number }> => {
  const t0 = performance.now();
  const { offset = 0, pageSize = DEFAULT_PAGE_SIZE, searchString } = params;
  let result;

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.00001);
    if (err) {
      result = Promise.reject(new Error(err));
    }
    const arr = base.children.filter(
      (item) =>
        !searchString ||
        item.name.includes(searchString) ||
        item?.description?.includes(searchString),
    );
    arr.sort((a, b) => (a.id < b.id ? -1 : 1));
    arr.forEach((item) => {
      machineValidationSchema.validateSync(item);
    });
    result = {
      data: arr.slice(offset, offset + pageSize),
      total: arr.length,
    };
  } else {
    const filters = params?.filters ?? [];
    if (searchString) {
      filters.push({ id: 'searchString', value: searchString });
    }
    result = fetchData({
      url: GET_MACHINES_URL,
      body: JSON.stringify({
        pageSize,
        offset,
        filters,
        order:
          params?.order === undefined || params.order === ISortParamsOrder.DESC
            ? ISortParamsOrder.DESC
            : ISortParamsOrder.ASC,
      }),
      method: IHttpMethods.POST,
      name: 'getMachines',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getMachines took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getMachineById = async (id: string): Promise<IMachine | undefined> => {
  if (id === '') {
    return Promise.resolve(undefined);
  }
  const t0 = performance.now();

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const item = { ...(base.children.find((elem) => elem.id === id) ?? {}) };

    const err = probableError(0.01);
    if (err || item === undefined) {
      result = Promise.reject(new Error(err));
    } else {
      machineValidationSchema.validateSync(item);
      result = item;
    }
  } else {
    result = fetchData({
      url: GET_MACHINE_BY_ID_URL(id),
      method: IHttpMethods.GET,
      name: 'getMachineById',
      emptyResponse: false,
    });
  }
  const t1 = performance.now();
  console.log(`getMachineById took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return Promise.resolve(result);
};

export const addMachine = async (machine: IMachine): Promise<{ id: string }> => {
  const t0 = performance.now();

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      return Promise.reject(new Error(err));
    }
    const newId = generateUnicId();
    machineValidationSchema.validateSync(machine);
    const newMachine = { ...machine, id: newId };
    base.children = [...base.children, newMachine];
    result = Promise.resolve({ id: newId });
  } else {
    result = fetchData({
      url: CREATE_MACHINE_URL,
      method: IHttpMethods.POST,
      name: 'addMachine',
      body: JSON.stringify(machine),
      emptyResponse: false,
    });
  }
  const t1 = performance.now();
  console.log(`addMachine took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getMachineHeadById = async (id?: string): Promise<IMachine | undefined> => {
  const t0 = performance.now();

  if (!id) {
    return Promise.resolve(undefined);
  }
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);

    const item = {
      ...(base.children.find((elem) => elem.id === id) ?? {}),
      children: undefined,
    } as unknown as IMachine;

    const err = probableError(0.01);
    if (err || item === undefined) {
      result = Promise.reject(new Error(err));
    } else {
      machineValidationSchema.validateSync(item);
      result = Promise.resolve(item);
    }
  } else {
    // result = Promise.reject(new Error('getMachineHeadById is not yet implemented'));
    result = fetchData({
      url: GET_MACHINE_HEAD_BY_ID_URL(id),
      method: IHttpMethods.GET,
      name: 'getMachineHeadById',
      emptyResponse: false,
    });
  }
  const t1 = performance.now();
  console.log(`getMachineHeadById took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const duplicateMachineRequest = async (machineId: string): Promise<{ id: string }> => {
  const t0 = performance.now();

  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve({ id: '13' });
    }
  } else {
    result = fetchData({
      url: DUPLICATE_MACHINE_URL(machineId),
      method: IHttpMethods.POST,
      name: 'duplicateMachineRequest',
      emptyResponse: false,
    });
  }
  const t1 = performance.now();
  console.log(`duplicateMachineRequest took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getMachineFilterDictionaries = async (params: IQueryParams): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = filtersDictiontaries;
    }
  } else {
    const { searchString, filters = [] } = params;
    const newFiters = [...filters];
    if (searchString) {
      newFiters.push({ id: 'searchString', value: searchString ?? '' });
    }
    result = fetchData({
      url: GET_MACHINE_FILTER_DICTIONARIES,
      method: IHttpMethods.POST,
      name: 'getMachineFilterDictionaries',
      body: JSON.stringify({ filters: newFiters }),
      emptyResponse: false,
    });
  }
  const t1 = performance.now();
  console.log(`getMachineFilterDictionaries took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
