import { useMemo, useContext } from 'react';

import UnitIcon from 'icons/unit-icon';

import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import classNames from 'classnames';
import { elementIcon } from 'components/element-icon';
import { StyledCheckbox } from 'components/styled-checkbox';
import { ContextSettings } from 'context/context-settings';
import { MODES_DICT_NAMES, IElementType } from 'interfaces';
import { IDetailedInformationToView } from 'pages-diagnostic/components/detaled-elems-list-modal/common';
import { YELLOW_COLOR, NOOP } from 'stream-constants';

type Props = { newLayoutSize: { height: number; width: number } };

const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    layout: {
      padding: '16px 10px 16px 10px',
    },
    nodesTitle: {
      marginLeft: 10,
    },
    nodesSubTitle: {
      marginBottom: 2,
    },
    yellow: {
      color: YELLOW_COLOR,
    },
    nodesStyle: {
      marginTop: 10,
      height: (props) => props.newLayoutSize.height - 160,
      overflowY: 'auto',
      padding: '10px 10px 10px 4px',
    },

    row: {
      display: 'flex',
      flexDirection: 'row',
      gap: 5,
      alignItems: 'center',
    },
    unitIconMargin: {
      marginTop: '6px',
    },
    elementLayout: {
      marginLeft: 50,
    },
    elementRow: {
      display: 'flex',
      flexDirection: 'row',
      gap: 0,
      alignItems: 'center',
    },
    iconScale: {
      marginTop: 3,
      transform: 'scale(0.6)',
    },
    pairslayout: {
      marginLeft: 50,
    },
    pairGap: {
      width: '26px',
    },
  }),
);

interface IProps {
  detailedInformationToView: string | undefined;
  onClose: () => void;
  newLayoutSize: { height: number; width: number };
}

export function DetailedElemsListPageViewOnly({
  detailedInformationToView,
  newLayoutSize,
  onClose,
}: IProps) {
  const { setNotificationMessage } = useContext(ContextSettings);
  const classes = useStyles({ newLayoutSize });
  const nodes = useMemo(() => {
    try {
      if (!detailedInformationToView) {
        throw new Error(
          `поле "detailedInformationToView" не должно быть пустым\nпо факту оно равно "${detailedInformationToView}"`,
        );
      }
      const result = JSON.parse(
        detailedInformationToView as string,
      ) as unknown as IDetailedInformationToView[];

      return result;
    } catch (error) {
      setNotificationMessage({
        fullMessage: '',
        header: 'ОШИБКА парсинга поля detailedInformationToView',
      });
      console.error('ОШИБКА! ', error);
      onClose();

      return [];
    }
  }, [detailedInformationToView, onClose, setNotificationMessage]);

  return (
    <>
      <div>
        <div className={classes.layout}>
          <Typography variant={'h5'} color="secondary" className={classes.nodesTitle}>
            Просмотр узлов и элементов
          </Typography>
          <div className={classes.nodesStyle}>
            <div className={classNames(classes.row, classes.nodesSubTitle)}>
              <StyledCheckbox checked={true} onChange={NOOP} disabled={true} />
              <Tooltip title={'Все узлы'} placement={'top'}>
                <MoreHorizIcon />
              </Tooltip>
              <Typography variant={'body1'} color="secondary">
                {'Все узлы'}
              </Typography>
            </div>
            {nodes.map((node) => (
              <div key={node.id}>
                <div className={classes.row}>
                  <StyledCheckbox checked={node.selected} onChange={NOOP} disabled={true} />
                  <Tooltip title={'Узел'} placement={'top'}>
                    <div className={classes.unitIconMargin}>
                      <UnitIcon />
                    </div>
                  </Tooltip>

                  <Typography variant={'body1'} color="secondary">
                    {node.name}
                  </Typography>
                </div>
                <div className={classes.elementLayout}>
                  {node.elements?.map((element) => {
                    const Component = elementIcon(element.type as IElementType);

                    return (
                      <div className={classes.elementRow} key={element.id}>
                        <StyledCheckbox
                          checked={element.selected}
                          onChange={NOOP}
                          disabled={true}
                        />
                        <Tooltip title={MODES_DICT_NAMES[element.type]} placement={'top'}>
                          <div className={classes.iconScale}>
                            <Component />
                          </div>
                        </Tooltip>

                        <Typography variant={'body2'} color="secondary">
                          {element.title}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
                <div className={classes.pairslayout}>
                  <Typography variant={'body1'} color="secondary" className={classes.message}>
                    {'- зацепления зубчатых передач -'}
                  </Typography>
                  {node.pairs?.map((item) => {
                    const { pair, selected } = item;
                    const key = pair[0].id + '"' + pair[1].id;
                    const Component1 = elementIcon(pair[0].type as IElementType);
                    const Component2 = elementIcon(pair[1].type as IElementType);

                    return (
                      <div key={key} className={classes.row}>
                        <div className={classes.pairGap} />

                        <StyledCheckbox checked={selected} onChange={NOOP} disabled={true} />
                        <Tooltip title={MODES_DICT_NAMES[pair[0].type]} placement={'top'}>
                          <div className={classes.iconScale}>
                            <Component1 />
                          </div>
                        </Tooltip>

                        <Typography variant={'body2'} color="secondary" className={classes.column}>
                          {pair[0].title}
                        </Typography>
                        <Tooltip title={MODES_DICT_NAMES[pair[1].type]} placement={'top'}>
                          <div className={classes.iconScale}>
                            <Component2 />
                          </div>
                        </Tooltip>
                        <Typography variant={'body2'} color="secondary">
                          {pair[1].title}
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Button variant="contained" size="large" color="inherit" onClick={onClose}>
        Закрыть
      </Button>
    </>
  );
}
