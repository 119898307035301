import { FC } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const EXEC_BUTTON_LABEL = 'Выполнить';
const CANCEL_BUTTON_LABEL = 'Отменить';
export interface IConfirmationDialog {
  handleClose?: () => void;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  title?: string;
  content?: string;
}

const ConfirmationDialog: FC<IConfirmationDialog> = ({
  handleClose,
  handleConfirm,
  handleCancel,
  title,
  content,
}) => (
  <Dialog
    open={!!title}
    onClose={handleClose}
    aria-labelledby="confirmation-dialog"
    aria-describedby="confirmation-dialog-description"
  >
    <DialogTitle id="confirmation-dialog">{title}</DialogTitle>
    <DialogContent>
      {content?.split('\n').map((text, index) => (
        <DialogContentText key={index}>{text}</DialogContentText>
      ))}
    </DialogContent>
    <DialogActions>
      <Button
        aria-controls={CANCEL_BUTTON_LABEL}
        onClick={() => {
          handleCancel && handleCancel();
          handleClose && handleClose();
        }}
        color="primary"
      >
        {CANCEL_BUTTON_LABEL}
      </Button>
      <Button
        aria-controls={EXEC_BUTTON_LABEL}
        onClick={() => {
          handleConfirm && handleConfirm();
          handleClose && handleClose();
        }}
        color="primary"
        autoFocus
      >
        {EXEC_BUTTON_LABEL}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmationDialog;
ConfirmationDialog.displayName = 'ConfirmationDialog';
