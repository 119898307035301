import TopBar from 'components/layout/top-bar';
import { DIAGNOSTICS_KEY } from 'stream-constants';

const TABS = [
  { label: 'О проекте', href: '/', forId: 'about' },
  { label: 'Диагностики', href: `/${DIAGNOSTICS_KEY}`, forId: DIAGNOSTICS_KEY },
  { label: 'Глоссарий', href: '/glossarium', forId: 'glossarium' },
];

export function DiagnosticTopBar({ isAuthorized }: { isAuthorized: boolean }) {
  return <TopBar tabsArray={isAuthorized ? TABS : [TABS[0]]} />;
}
