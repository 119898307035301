import { useContext } from 'react';

import { useQuery } from 'react-query';

import { ContextSettings } from 'context/context-settings';
import { IQueryParams } from 'interfaces';
import { getMachines, getMachineFilterDictionaries } from 'services/machines';
import { MACHINES_KEY, MACHINES_FILTERS_DICTIONARIES } from 'stream-constants';
import { cleanQueryParams } from 'utils/clean-query-params';
import { errorMessageConvert } from 'utils/error-message-convert';
import { midlewareWrapper } from 'utils/midleware-wrapper';

export enum IMachineFilterName {
  FULLNESS = 'fullness',
  MANUFACTURED_YEAR = 'manufacturedYear',
  MANUFACTURED = 'manufactured',
  MACHINE_OWNER = 'machineOwner',
  MACHINE_TYPE = 'machineType',
}

export function useGetMachines(params: IQueryParams) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const paramsWithoutScroll = cleanQueryParams({ ...params });
  delete paramsWithoutScroll.scrollTop;

  // paramsWithoutScroll.filters = paramsWithoutScroll.filters?.map((f) =>
  //   f.id === 'fullness' ? { id: f.id, value: f.value === FULLNESS_DICTIONARY[1] } : f,
  // );

  const query = useQuery(
    [MACHINES_KEY, paramsWithoutScroll],
    () => getMachines(paramsWithoutScroll),
    {
      enabled: !notificationMessage,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
    },
  );

  return query;
}

function convertNumbersToStringForUncertainField(
  response: Record<IMachineFilterName, any[]> | undefined,
) {
  if (!response) {
    return response;
  }

  return response;

  // const fullness = response?.fullness?.map((elem) => FULLNESS_DICTIONARY[elem ? 1 : 0]);

  // return { ...response, fullness };
}

export function useGetMachineFilterDictionaries(
  params: IQueryParams,
  enabledFlag: boolean,
  onSuccess: () => void,
) {
  const { setNotificationMessage, notificationMessage } = useContext(ContextSettings);
  const paramsWithoutScroll = cleanQueryParams({ ...params });
  delete paramsWithoutScroll.scrollTop;

  const allFilterMap = new Map((params?.filters ?? []).map((item) => [item.id, item.value]));
  const keyParams = Object.values(IMachineFilterName).map((name) => allFilterMap.get(name) ?? '');

  keyParams.push(params.searchString ?? '');
  keyParams.unshift(MACHINES_FILTERS_DICTIONARIES);

  // paramsWithoutScroll.filters = paramsWithoutScroll.filters?.map((f) =>
  //   f.id === 'fullness' ? { id: f.id, value: f.value === FULLNESS_DICTIONARY[1] } : f,
  // );

  const query = useQuery(
    keyParams,
    () =>
      midlewareWrapper(
        async () => getMachineFilterDictionaries(paramsWithoutScroll),
        convertNumbersToStringForUncertainField,
      ),
    {
      staleTime: 5 * 60 * 1000,
      enabled: enabledFlag && !notificationMessage,
      onError: (error: Error) => {
        setNotificationMessage(errorMessageConvert(error as Error));
      },
      onSuccess: onSuccess,
    },
  );

  return query;
}
