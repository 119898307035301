import { useEffect, createRef, useRef, useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';

import { FixedSizeGrid } from 'react-window';

import { NewSearchIcon } from 'icons/new-search-icon';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { ContextSettings } from 'context/context-settings';
import { IDiagnostic } from 'interfaces';
import { DiagnosticCard } from 'pages-diagnostic/components/diagnostic-card/diagnostic-card';
import {
  DIAGNOSTIC_COLUMN_WIDTH,
  DIAGNOSTIC_ROW_HEIGHT,
  DIAGNOSTICS_KEY,
  HANDLE_ON_SCROLL_TIMEOUT,
  CREATE_NEW,
} from 'stream-constants';
import { useQueryParams } from 'utils/hooks/use-query-params';

import { useGetDiagnostics } from '../hooks';

import { DiagnosticsFilters } from './diagnostics-filters';
import styles from './styles.module.css';

const PAGE_SIZE = 16;
const FAKE_TOTAL_NUMBER = 1000000;

type ICellProps = {
  columnIndex: number;
  rowIndex: number;
  style: any;
};

const offsetCalc = ({
  rowIndex,
  columnIndex,
  diagnosticColumnCount,
}: {
  rowIndex: number;
  columnIndex: number;
  diagnosticColumnCount: number;
}) => {
  const pos = rowIndex * diagnosticColumnCount + columnIndex;
  const offIndex = pos % PAGE_SIZE;
  const offset = pos - offIndex;

  return { offset, offIndex };
};

export function DiagnosticsCards() {
  const { layoutSize } = useContext(ContextSettings);
  const diagnosticColumnCount = Math.max(
    1,
    Math.floor((layoutSize.width - 30) / DIAGNOSTIC_COLUMN_WIDTH),
  );
  const [itemsTotal, setItemsTotal] = useState<number>(FAKE_TOTAL_NUMBER);

  const gridRef = createRef<FixedSizeGrid<any>>();
  const gridScrollDebouncingRef = useRef<ReturnType<typeof setTimeout> | undefined>();
  const history = useHistory();
  const { queryParams, setQueryParams } = useQueryParams();

  useEffect(() => {
    return () => {
      if (gridScrollDebouncingRef.current) {
        clearTimeout(gridScrollDebouncingRef.current);
      }
    };
  }, []);

  const handleSearchStringSubmit = (searchString: string): void => {
    setQueryParams({ searchString, scrollTop: 0 });
    setItemsTotal(FAKE_TOTAL_NUMBER);
    gridRef.current?.scrollTo({ scrollTop: 0, scrollLeft: 0 });
  };
  const handleFiltersChange = (filters: any[]): void => {
    setQueryParams({ filters, scrollTop: 0 });
    setItemsTotal(FAKE_TOTAL_NUMBER);
    gridRef.current?.scrollTo({ scrollTop: 0, scrollLeft: 0 });
  };
  const handleClearFilters = (): void => {
    setQueryParams({ searchString: '', filters: [], scrollTop: 0 });
    setItemsTotal(FAKE_TOTAL_NUMBER);
    gridRef.current?.scrollTo({ scrollTop: 0, scrollLeft: 0 });
  };

  const Cell = ({ columnIndex, rowIndex, style }: ICellProps) => {
    const { offset, offIndex } = offsetCalc({ rowIndex, columnIndex, diagnosticColumnCount });

    const queryCells = useGetDiagnostics({
      offset,
      pageSize: PAGE_SIZE,
      searchString: queryParams.searchString ?? '',
      order: queryParams.order,
      filters: queryParams.filters ?? [],
    });

    const cards = queryCells.data?.data ?? [];
    const total = queryCells.data?.total ?? itemsTotal;

    useEffect(() => {
      setItemsTotal(total);
    }, [total]);

    return (
      <div style={style}>
        <DiagnosticCard
          item={cards[offIndex] as IDiagnostic}
          onClick={(id: string) => history.push(`/${DIAGNOSTICS_KEY}/${id}`)}
        />
      </div>
    );
  };

  const handleScrollChange = (scl: { scrollTop: any }) => {
    if (gridScrollDebouncingRef.current) {
      clearTimeout(gridScrollDebouncingRef.current);
    }
    gridScrollDebouncingRef.current = setTimeout(() => {
      setQueryParams({ scrollTop: scl?.scrollTop ?? 0 });
      gridScrollDebouncingRef.current = undefined;
    }, HANDLE_ON_SCROLL_TIMEOUT);
  };
  const rowCount = Math.ceil((itemsTotal ?? 0) / diagnosticColumnCount);

  const fixedSizeGridWidth = DIAGNOSTIC_COLUMN_WIDTH * diagnosticColumnCount + 20;

  const tableHeight = layoutSize.height - 80;

  return (
    <div className={styles.center}>
      <div className="layout-min-height">
        <div className={styles.mainRow}>
          <div className={styles.headerRow}>
            <div className={styles.header}>Диагностики</div>
            <div className={styles.headerBadge}>
              {itemsTotal === FAKE_TOTAL_NUMBER ? 0 : itemsTotal}
            </div>
          </div>
          <Tooltip title="Добавить новую диагностику">
            <span>
              <Button
                id="id_add_new_diagnostics"
                classes={{ label: styles.addButton }}
                type="button"
                size="small"
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => history.push(`/${DIAGNOSTICS_KEY}/${CREATE_NEW}`)}
              >
                Добавить диагностику
              </Button>
            </span>
          </Tooltip>
        </div>

        <DiagnosticsFilters
          handleSearchStringSubmit={handleSearchStringSubmit}
          handleFiltersChange={handleFiltersChange}
          order={queryParams.order}
          setOrder={(p) => setQueryParams({ order: p })}
          handleClearFilters={handleClearFilters}
        />

        {itemsTotal === 0 ? (
          <div
            style={{
              height: tableHeight,
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: fixedSizeGridWidth,
            }}
          >
            <div className={styles.searchIconContainer}>
              <div className={styles.searchIcon}>
                <NewSearchIcon style={{ fontSize: 18 }} />
              </div>
              <div className={styles.searchIconText}>Поиск не дал результатов</div>
            </div>
          </div>
        ) : (
          <div className={styles.grid}>
            <FixedSizeGrid
              initialScrollTop={queryParams.scrollTop}
              columnCount={diagnosticColumnCount}
              columnWidth={DIAGNOSTIC_COLUMN_WIDTH}
              height={tableHeight}
              rowCount={rowCount}
              rowHeight={DIAGNOSTIC_ROW_HEIGHT}
              width={fixedSizeGridWidth}
              ref={gridRef}
              onScroll={handleScrollChange}
            >
              {Cell}
            </FixedSizeGrid>
          </div>
        )}
      </div>
    </div>
  );
}
