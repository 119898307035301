import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import { IFolder } from '../folder-constants';

import { SlicingEditor } from './slicing-editor';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'relative',
      backgroundColor: '#fff',
      border: '2px solid #fff',
      borderRadius: 16,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },

    closeIconStyle: {
      position: 'absolute',
      right: 8,
      top: 6,
      fontSize: 24,
      color: '#000',
      pointer: 'cursor',
      '&:hover': {
        color: 'red',
      },
    },
  }),
);

export function ModalSlicingEditorWidow({
  item,
  setClose,
  readOnly,
}: {
  item: IFolder;
  setClose: () => void;
  readOnly: boolean;
}) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={true}
      onClose={setClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(200, 200, 200, 0.5)' } }}
    >
      <Fade in={true}>
        <div className={classes.paper} id="transition-modal-description-222">
          <CloseIcon className={classes.closeIconStyle} onClick={setClose} />
          <SlicingEditor item={item} setClose={setClose} readOnly={readOnly} />
        </div>
      </Fade>
    </Modal>
  );
}
