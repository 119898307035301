import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

interface IProps extends SvgIconProps {
  customColor?: string;
}

export function FalseElementIcon(props: IProps) {
  const { customColor, ...other } = props;

  return (
    <SvgIcon {...other} viewBox={'0 0 20 20'}>
      <rect width="20" height="20" rx="10" fill={customColor} />
      <path
        d="M13.8047 7.13807C14.0651 6.87772 14.0651 6.45561 13.8047 6.19526C13.5444 5.93491 13.1223 5.93491 12.8619 6.19526L10 9.05719L7.13807 6.19526C6.87772 5.93491 6.45561 5.93491 6.19526 6.19526C5.93491 6.45561 5.93491 6.87772 6.19526 7.13807L9.05719 10L6.19526 12.8619C5.93491 13.1223 5.93491 13.5444 6.19526 13.8047C6.45561 14.0651 6.87772 14.0651 7.13807 13.8047L10 10.9428L12.8619 13.8047C13.1223 14.0651 13.5444 14.0651 13.8047 13.8047C14.0651 13.5444 14.0651 13.1223 13.8047 12.8619L10.9428 10L13.8047 7.13807Z"
        fill="white"
      />
    </SvgIcon>
  );
}
