import { IElementType } from 'interfaces';
export interface IElem {
  id: string;
  title: string;
  type: IElementType;
}

const keysDevider = '"';

export const elementsKey = (element: [{ id: string }, { id: string }]) =>
  `${element[0]?.id ?? ''}${keysDevider}${element[1]?.id ?? ''}`;

export const getElementIdsFromKey = (key: string) => key.split(keysDevider);

export interface IRowElement {
  id: string;
  title: string;
  type: string;
  selected: boolean;
}
export interface IRowPair {
  pair: {
    id: string;
    title: string;
    type: string;
  }[];
  selected: boolean;
}

export interface IDetailedInformationToView {
  id: string;
  name: string;
  selected: boolean;
  elements: IRowElement[];
  pairs: IRowPair[];
}
