import React from 'react';

import { useHistory } from 'react-router-dom';

import { CalculartorIcon } from 'icons/calculartor-icon';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import { elementIcon } from 'components/element-icon';
import { StyledCheckbox } from 'components/styled-checkbox';
import { MODES_DICT_NAMES } from 'interfaces';
import { IElementType } from 'interfaces';
import { ShowDefect } from 'pages-diagnostic/diagnostic-spectrum/diagnostic-spectrum-list/components/show-defect';
import { IDefect } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import { IRawElement } from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import { RED_COLOR, DIAGNOSTIC_SPECTRUM, NOOP } from 'stream-constants';

import { useMutationUpdateSubelementStatus } from '../query-hooks/query-hooks';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    elementHeader: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '24px',
      color: '#000',
      margin: '14px 0 10px 0',
    },
    row: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    itemIcon: {
      padding: '6px 0 0 5px',
      transform: 'scale(1.5)',
      maxWidth: 46,
      minWidth: 46,
    },
    elementName: {
      minWidth: 500,
      maxWidth: 600,
      fontWeight: 500,
      fontSize: '17px',
      marginBottom: 4,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    elementSpeed: {
      color: '#000',
    },
    defectHeader: {
      fontWeight: 200,
      fontSize: '14px',
      color: '#000',
      margin: '0px 0 12px 24px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      paddingRight: 0,
    },
    defectHeaderRawSpace: {
      flexGrow: 1,
    },

    calculatorIconContainer: {
      marginLeft: 20,
      display: 'flex',
      alignItems: 'center',
      gap: 6,
    },

    calculatorIcon: {
      cursor: 'pointer',
    },
    calculatorIcon2: {
      borderRadius: '6px',
      padding: '5px 5px 0px 5px',
      backgroundColor: '#345',
      cursor: 'pointer',
    },
    calculatorIcon2InverseColor: {
      filter: 'invert(100%)',
    },

    defectBody: {
      margin: '-10px 0 0 10px',
    },
    zIndex: {
      position: 'relative',
      top: -6,
      right: 20,
      zIndex: 10,
      width: '100%',
    },
    checkBoxStyle: {
      marginTop: -3,
    },
    colorRed: {
      color: RED_COLOR,
    },
    greyTextColor: {
      opacity: 0.6,
      fontWeight: 400,
    },
    uncertainTextColor: {
      color: '#ff6e3e',
    },
    bold: {
      fontWeight: 500,
    },
    headerMargins: {
      marginRight: 6,
    },
  }),
);

export const SpectrumLayoutHeader = React.memo(function ({
  subElement,
  defects,
  currentDefect,
  setCurrentDefect,
  defectsDict,
  setSelectedEnvelopFrequencies,
  setSelectedDirectFrequencies,
  isReadyCheckBox,
  diagnosticId,
  showCalulatorIcon,
  onCalculatorIconClick,
}: {
  subElement: IRawElement | undefined;
  defects: IDefect[];
  currentDefect: IDefect | undefined;
  setCurrentDefect: (d: IDefect | undefined) => void;
  defectsDict: Record<string, string>;
  setSelectedEnvelopFrequencies: (s: Set<string>) => void;
  setSelectedDirectFrequencies: (s: Set<string>) => void;
  isReadyCheckBox: boolean;
  diagnosticId: string;
  showCalulatorIcon: boolean;
  onCalculatorIconClick: () => void;
}) {
  const classes = useStyles();
  const history = useHistory();
  const subElementId = subElement?.elementId;

  const IconComponent = elementIcon(subElement?.subelementType as IElementType);

  const mutateIsReadyStatus = useMutationUpdateSubelementStatus({
    diagnosticId,
    subElementId: subElementId ?? '',
    status: !isReadyCheckBox,
    handleOnSuccess: () => {},
  });

  return (
    <>
      <div className={classes.row}>
        <div className={classes.itemIcon}>
          <Tooltip
            title={
              subElement?.subelementType
                ? MODES_DICT_NAMES[subElement?.subelementType]
                : 'Не известный тип элемента'
            }
            placement={'top'}
          >
            <span>
              <IconComponent />
            </span>
          </Tooltip>
        </div>
        <div className={classNames({ [classes.uncertainTextColor]: subElement?.isUncertain })}>
          <div className={classes.elementName}>{subElement?.elementRealName ?? ''}</div>
          <div className={classNames(classes.elementName, classes.greyTextColor)}>
            {subElement?.elementName ?? ''}
          </div>
          <div className={classes.elementSpeed}>
            <span>Режимы работы:&nbsp;</span>
            <span className={classes.bold}>{`${parseFloat(
              String(subElement?.outputFrequency ?? '0'),
            ).toFixed(2)} ${String(subElement?.outputFrequency ?? '0').split(' ')[1] ?? ''}`}</span>
            <span>;&nbsp;Скорость элемента:&nbsp;</span>
            <span className={classes.bold}>{`${subElement?.subelementFrequency ?? ''}`}</span>
          </div>
        </div>

        <div className={classes.zIndex}>
          <div className={classes.defectHeader}>
            <div className={classes.headerMargins}>{'Добавленные дефекты'}</div>
            <div className={classes.defectHeaderRawSpace} />

            <Tooltip
              title={showCalulatorIcon ? '' : 'Недоступно в режиме калькулятора'}
              placement={'top-end'}
            >
              <div>
                <span
                  className={classNames(classes.headerMargins, {
                    [classes.colorRed]: isReadyCheckBox,
                  })}
                >
                  {'Добавление дефектов завершено'}
                </span>
                <StyledCheckbox
                  disabled={!showCalulatorIcon}
                  checked={isReadyCheckBox}
                  onChange={() => mutateIsReadyStatus.mutate()}
                  className={classes.checkBoxStyle}
                />
              </div>
            </Tooltip>
            {showCalulatorIcon && (
              <div className={classes.calculatorIconContainer}>
                <div>{'Калькулятор подшипников'}</div>
                <Tooltip
                  title={
                    subElement?.subelementType === IElementType.RADIAL_BEARING
                      ? 'Переход на страницу калькулятора, где можно варьировать параметры подшипника'
                      : 'Это только для радиальных подшипников. В данном случае эта кнопка не сработает'
                  }
                  placement={'top'}
                >
                  <span
                    className={classes.calculatorIcon}
                    onClick={() => {
                      if (subElement?.subelementType === IElementType.RADIAL_BEARING) {
                        onCalculatorIconClick();
                      }
                    }}
                  >
                    <CalculartorIcon style={{ fontSize: 20 }} />
                  </span>
                </Tooltip>
              </div>
            )}
            {!showCalulatorIcon && (
              <div className={classes.calculatorIconContainer}>
                <div>{'Возврат к дефектам'}</div>
                <Tooltip
                  title={'Возврат на страницу добавления (изменения) дефектов'}
                  placement={'top'}
                >
                  <span
                    className={classes.calculatorIcon2}
                    onClick={() => {
                      history.push(`/${DIAGNOSTIC_SPECTRUM}/${diagnosticId}/${subElementId}`);
                    }}
                  >
                    <div className={classes.calculatorIcon2InverseColor}>
                      <CalculartorIcon style={{ fontSize: 20 }} />
                    </div>
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
          <div className={classes.defectBody}>
            {defects?.map((defect) => (
              <ShowDefect
                key={defect.id}
                defect={defect}
                defectsDict={defectsDict}
                isSmallSize={false}
                currentDefect={currentDefect}
                onClick={showCalulatorIcon ? setCurrentDefect : NOOP}
                setSelectedEnvelopFrequencies={setSelectedEnvelopFrequencies}
                setSelectedDirectFrequencies={setSelectedDirectFrequencies}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
});
