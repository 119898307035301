import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function MenuIconNew(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox={'0 0 32 32'}>
      <rect width="32" height="32" rx="6" fill="#F2F3F7" />
      <g clipPath="url(#clip0_644_363931)">
        <path
          d="M7.01801 15.5384C7.06128 14.9571 7.08291 14.6664 7.37466 14.3747C7.66641 14.0829 7.95707 14.0613 8.53841 14.018C8.6891 14.0068 8.84455 14 9 14C9.15545 14 9.3109 14.0068 9.46159 14.018C10.0429 14.0613 10.3336 14.0829 10.6253 14.3747C10.9171 14.6664 10.9387 14.9571 10.982 15.5384C10.9932 15.6891 11 15.8446 11 16C11 16.1554 10.9932 16.3109 10.982 16.4616C10.9387 17.0429 10.9171 17.3336 10.6253 17.6253C10.3336 17.9171 10.0429 17.9387 9.46159 17.982C9.3109 17.9932 9.15545 18 9 18C8.84455 18 8.6891 17.9932 8.53841 17.982C7.95707 17.9387 7.66641 17.9171 7.37466 17.6253C7.08291 17.3336 7.06128 17.0429 7.01801 16.4616C7.0068 16.3109 7 16.1554 7 16C7 15.8446 7.0068 15.6891 7.01801 15.5384Z"
          fill="black"
        />
        <path
          d="M14.018 15.5384C14.0613 14.9571 14.0829 14.6664 14.3747 14.3747C14.6664 14.0829 14.9571 14.0613 15.5384 14.018C15.6891 14.0068 15.8446 14 16 14C16.1554 14 16.3109 14.0068 16.4616 14.018C17.0429 14.0613 17.3336 14.0829 17.6253 14.3747C17.9171 14.6664 17.9387 14.9571 17.982 15.5384C17.9932 15.6891 18 15.8446 18 16C18 16.1554 17.9932 16.3109 17.982 16.4616C17.9387 17.0429 17.9171 17.3336 17.6253 17.6253C17.3336 17.9171 17.0429 17.9387 16.4616 17.982C16.3109 17.9932 16.1554 18 16 18C15.8446 18 15.6891 17.9932 15.5384 17.982C14.9571 17.9387 14.6664 17.9171 14.3747 17.6253C14.0829 17.3336 14.0613 17.0429 14.018 16.4616C14.0068 16.3109 14 16.1554 14 16C14 15.8446 14.0068 15.6891 14.018 15.5384Z"
          fill="black"
        />
        <path
          d="M21.3747 14.3747C21.0829 14.6664 21.0613 14.9571 21.018 15.5384C21.0068 15.6891 21 15.8446 21 16C21 16.1554 21.0068 16.3109 21.018 16.4616C21.0613 17.0429 21.0829 17.3336 21.3747 17.6253C21.6664 17.9171 21.9571 17.9387 22.5384 17.982C22.6891 17.9932 22.8446 18 23 18C23.1554 18 23.3109 17.9932 23.4616 17.982C24.0429 17.9387 24.3336 17.9171 24.6253 17.6253C24.9171 17.3336 24.9387 17.0429 24.982 16.4616C24.9932 16.3109 25 16.1554 25 16C25 15.8446 24.9932 15.6891 24.982 15.5384C24.9387 14.9571 24.9171 14.6664 24.6253 14.3747C24.3336 14.0829 24.0429 14.0613 23.4616 14.018C23.3109 14.0068 23.1554 14 23 14C22.8446 14 22.6891 14.0068 22.5384 14.018C21.9571 14.0613 21.6664 14.0829 21.3747 14.3747Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_644_363931">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
