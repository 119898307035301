import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { UniversalModalDialog } from 'components/universal-modal-dialog';
import { IScreenSize } from 'interfaces';

const WIDTH = 700;

const useStyles = makeStyles<Theme, IScreenSize>((theme) =>
  createStyles({
    root: {
      minWidth: (props) => WIDTH,
      maxWidth: (props) => props.width - 200,
    },
    header: {
      fontWeight: 500,
      fontSize: '24px',
      color: '#1D2023',
      lineHeight: '28px',
      margin: '16px 0 16px 0',
    },
    message: {
      margin: '20px 0',
      whiteSpace: 'pre-wrap',
      color: '#000',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      maxHeight: (props) => props.height - 200,
      minHeight: (props) => 200,
      overflowY: 'scroll',
    },
    buttonWidth: {
      width: 150,
    },
  }),
);

export function FullMessageModalDialog({
  fullMessage,
  setClose,
  layoutSize,
}: {
  fullMessage: string | undefined;
  setClose: () => void;
  layoutSize: IScreenSize;
}) {
  const classes = useStyles(layoutSize);

  return (
    <UniversalModalDialog setClose={setClose}>
      <div className={classes.root}>
        <div className={classes.header}>Сообщение об ошибке</div>

        <pre className={classes.message}>{fullMessage}</pre>

        <Button
          variant="contained"
          color="primary"
          size="small"
          className={classes.buttonWidth}
          onClick={setClose}
        >
          {'Понятно'}
        </Button>
      </div>
    </UniversalModalDialog>
  );
}
