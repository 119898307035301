import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const Avatar = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 80 80" fill="none" {...props}>
      <path
        d="M0 40C0 17.9086 17.9086 0 40 0C62.0914 0 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40Z"
        fill="#BBC1C7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.77 68.5782C58.1344 74.3345 49.4741 77.8182 40.0001 77.8182C30.526 77.8182 21.8657 74.3345 15.2301 68.5781C17.2894 67.6264 19.462 66.9298 21.5279 66.2674C26.1343 64.7904 30.2105 63.4835 31.3191 59.8969V53.6612C30.017 52.9703 26.2958 48.1913 25.9023 44.4545C25.8625 44.0766 25.5371 43.8285 25.1083 43.5017C24.2935 42.8806 23.1055 41.975 22.7945 39.3531C22.5399 37.2055 23.5527 35.9964 24.1661 35.6163C24.1661 35.6163 22.6383 32.1502 22.6383 28.7128C22.6383 18.487 27.7253 10 40 10C46.6264 10 48.6808 14.6753 48.6808 14.6753C54.6012 14.6753 57.3617 21.1355 57.3617 28.7128C57.3617 32.4899 55.8339 35.6163 55.8339 35.6163C56.4473 35.9964 57.4601 37.2055 57.2054 39.3531C56.7309 43.3548 55.122 44.3682 54.0977 44.4545C53.7042 48.1856 49.983 52.9645 48.6808 53.6612V59.8969C49.7895 63.4835 53.8657 64.7904 58.4721 66.2674C60.538 66.9298 62.7106 67.6264 64.77 68.5782Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Avatar;
