import React from 'react';

import { withRouter } from 'react-router-dom';

import ErrorBoundaryStub from './error-boundary-stub';
interface Props {
  children: any;
  history: any;
}
interface State {
  errorInfo: any;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { errorInfo: undefined };
  }

  componentDidMount() {
    //@ts-ignore
    this.unlisten = this.props.history.listen((location: any, action: any) => {
      this.setState({ errorInfo: undefined });
    });
  }
  componentWillUnmount() {
    //@ts-ignore
    this.unlisten();
  }

  componentDidCatch(error: any, errorInfo: React.ErrorInfo): void {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ errorInfo });
    console.error('ErrorBoundary error: ' + error);
    console.error('ErrorBoundary errorInfo: ' + JSON.stringify(errorInfo, null, 4));
    console.error('ErrorBoundary componentStack: ' + errorInfo.componentStack);
  }

  render(): React.ReactNode {
    if (this.state.errorInfo === undefined) {
      return this.props.children;
    }

    return <ErrorBoundaryStub errorInfo={this.state.errorInfo} />;
  }
}

//@ts-ignore
export default withRouter(ErrorBoundary);
