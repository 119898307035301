import { IHttpMethods } from 'interfaces';
import {
  IRawData,
  IRawDataForCalculatorPage,
  IDefect,
  ISummaryElement,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import {
  IBearingCalculatorParms,
  IMAttrix,
} from 'pages-diagnostic/diagnostic-spectrum/spectrum-common';
import {
  GET_DIAGNOSTICS_SPECTRUM,
  GET_DIAGNOSTICS_PEAKS_AND_PARAMS_FOR_CALCULATOR_PAGE,
  GET_DIAGNOSTICS_GET_NEW_FREQ_FOR_CALCULATOR_PAGE,
  GET_DIAGNOSTICS_SPECTRUM_DEFECT_DICT,
  GET_ALL_DIAGNOSTICS_SUBELEMENTS,
  GET_ALL_DEFECTS_BY_ELEMENT,
  CREATE_SPECTRUM_DEFECT,
  UPDATE_SPECTRUM_DEFECT,
  DELETE_SPECTRUM_DEFECT,
  GET_DEFECT_FREQUENCIES_BY_DEFECT_ID,
  GET_ALL_SUMMARY,
  GET_ELEMENT_SUMMARY,
  UPDATE_SPECTRUM_DEFECT_SUMMARY,
  CREATE_SPECTRUM_DEFECT_SUMMARY,
  DELETE_SPECTRUM_DEFECT_SUMMARY,
  UPDATE_SUBELEMENT_STATUS_URL,
  GET_DIAGNOSTIC_FREQUENCY_PORTRAITS_COLORS_URL,
  GET_DIAGNOSTIC_FREQUENCY_PORTRAITS_URL,
  GET_FREQUENCY_GROUPS_COLORS,
} from 'services/urls';
import { IS_SERVER_MOCKED } from 'stream-constants';
import { delay } from 'utils/delay';

import { fetchData, probableError } from '../service-utils';

import allDefectsForElement from './mock/all-defects-for-element.json';
import defectFrequencies from './mock/defect-frequencies.json';
import defectsDict from './mock/defects-dict.json';
import get_peaks_and_params_for_calculator_page from './mock/freq-for-calculator-page.json';
import getElementSummary from './mock/get-element-summary.json';
import get_spectrum from './mock/get_spectrum.json';
import subElements from './mock/sub-elements.json';
import summaryElements from './mock/summary-elements.json';

const rawData: IRawData = get_spectrum as any;
const rawDataForCalculatorForm: IRawDataForCalculatorPage =
  get_peaks_and_params_for_calculator_page as any;

const BASE_TIME = 300;
const MIN_TIME = 200;

export const getSpectrumAndPeaks = async (
  diagnosticId: string,
  elementGuideId: string,
): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId || !elementGuideId) {
    result = Promise.reject(new Error('Не задан diagnosticId или  elementGuideId'));

    return result;
  }
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(rawData);
    }
  } else {
    result = fetchData({
      url: GET_DIAGNOSTICS_SPECTRUM(diagnosticId, elementGuideId),
      method: IHttpMethods.GET,
      name: 'getSpectrumAndPeaks',
      emptyResponse: false,
      timeoutToAbort: 35 * 60 * 1000,
    });
  }

  const t1 = performance.now();
  console.log(`getSpectrumAndPeaks took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
export const getPeaksAndParamsForCalculatorPage = async (elementId: string): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!elementId) {
    result = Promise.reject(new Error('Не задан  elementId'));

    return result;
  }
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(rawDataForCalculatorForm);
    }
  } else {
    result = fetchData({
      url: GET_DIAGNOSTICS_PEAKS_AND_PARAMS_FOR_CALCULATOR_PAGE(elementId),
      method: IHttpMethods.GET,
      name: 'getPeaksAndParamsForCalculatorPage',
      emptyResponse: false,
      timeoutToAbort: 35 * 60 * 1000,
    });
  }

  const t1 = performance.now();
  console.log(`getPeaksAndParamsForCalculatorPage took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
export const getGetNewFreqForCalculatorPage = async ({
  elementId,
  params,
}: {
  elementId: string;
  params: IBearingCalculatorParms;
}): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!elementId) {
    result = Promise.reject(new Error('Не задан  elementId'));

    return result;
  }
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(rawDataForCalculatorForm);
    }
  } else {
    result = fetchData({
      url: GET_DIAGNOSTICS_GET_NEW_FREQ_FOR_CALCULATOR_PAGE(elementId),
      body: JSON.stringify(params),
      method: IHttpMethods.POST,
      name: 'getGetNewFreqForCalculatorPage',
      emptyResponse: false,
      timeoutToAbort: 35 * 60 * 1000,
    });
  }

  const t1 = performance.now();
  console.log(`getGetNewFreqForCalculatorPage took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getDefectsDict = async (): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(defectsDict);
    }
  } else {
    result = fetchData({
      url: GET_DIAGNOSTICS_SPECTRUM_DEFECT_DICT,
      method: IHttpMethods.GET,
      name: 'getDefectsDict',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getDefectsDict took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getFrequencyGroupsColorsDict = async (): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(defectsDict);
    }
  } else {
    result = fetchData({
      url: GET_FREQUENCY_GROUPS_COLORS,
      method: IHttpMethods.GET,
      name: 'getFrequencyGroupsColorsDict',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getFrequencyGroupsColorsDict took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getSpectrumElementsList = async (diagnosticId: string): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId) {
    result = Promise.reject(new Error('Не задан diagnosticId или  elementGuideId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(subElements);
    }
  } else {
    result = fetchData({
      url: GET_ALL_DIAGNOSTICS_SUBELEMENTS(diagnosticId),
      method: IHttpMethods.GET,
      name: 'getSpectrumElementsList',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getSpectrumElementsList took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getAllDefectsByElement = async (
  diagnosticId: string,
  elementGuideId: string,
): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId || !elementGuideId) {
    console.log(
      '%c getAllDefectsByElement diagnosticId , elementGuideId  = ',
      'color: #bada55',
      diagnosticId,
      elementGuideId,
    ); //TODO - delete vvtu
    result = Promise.reject(new Error('Не задан diagnosticId или elementGuideId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(JSON.parse(JSON.stringify(allDefectsForElement)));
    }
  } else {
    result = fetchData({
      url: GET_ALL_DEFECTS_BY_ELEMENT(diagnosticId, elementGuideId),
      method: IHttpMethods.GET,
      name: 'getAllDefectsByElement',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getAllDefectsByElement took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getDefectFrequenciesByDefectId = async (
  defectId: string | undefined,
): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!defectId) {
    result = Promise.resolve({ data: null });

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(JSON.parse(JSON.stringify(defectFrequencies)));
    }
  } else {
    result = fetchData({
      url: GET_DEFECT_FREQUENCIES_BY_DEFECT_ID(defectId),
      method: IHttpMethods.GET,
      name: 'getDefectFrequenciesByDefectId',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getDefectFrequenciesByDefectId took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const createSpectrumDefect = async ({
  diagnosticId,
  elementGuideId,
  freqElementId,
  defect,
  envelopDataList,
  spectrumDataList,
}: {
  diagnosticId: string;
  elementGuideId: string;
  freqElementId: string;
  defect: IDefect;
  envelopDataList: string[];
  spectrumDataList: string[];
}): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId) {
    result = Promise.reject(new Error('Не задан diagnosticId или  elementGuideId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      allDefectsForElement.data.push({ ...defect, rank: 2 });
      result = Promise.resolve();
    }
  } else {
    result = fetchData({
      url: CREATE_SPECTRUM_DEFECT,
      method: IHttpMethods.POST,
      body: JSON.stringify({
        diagnosticId,
        elementGuideId,
        freqElementId,
        ...defect,
        envelopDataList,
        spectrumDataList,
      }),
      name: 'createSpectrumDefect',
      emptyResponse: true,
    });
  }

  const t1 = performance.now();
  console.log(`createSpectrumDefect took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const updateSpectrumDefect = async ({
  diagnosticId,
  elementGuideId,
  freqElementId,
  defect,
  envelopDataList,
  spectrumDataList,
}: {
  diagnosticId: string;
  elementGuideId: string;
  freqElementId: string;
  defect: IDefect;
  envelopDataList: string[];
  spectrumDataList: string[];
}): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId || !elementGuideId) {
    result = Promise.reject(new Error('Не задан diagnosticId или  elementGuideId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      const index = allDefectsForElement.data.findIndex((item) => item.id === defect.id);
      if (index !== -1) {
        allDefectsForElement.data[index] = { ...defect, rank: 1 };
      } else {
        throw new Error(`updateSpectrumDefect: нет такого дефекта id = ${defect.id}`);
      }
      result = Promise.resolve();
    }
  } else {
    result = fetchData({
      url: UPDATE_SPECTRUM_DEFECT(defect?.id),
      method: IHttpMethods.PUT,
      body: JSON.stringify({
        diagnosticId,
        elementGuideId,
        freqElementId,
        ...defect,
        envelopDataList,
        spectrumDataList,
      }),
      name: 'updateSpectrumDefect',
      emptyResponse: true,
    });
  }

  const t1 = performance.now();
  console.log(`updateSpectrumDefect took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const deleteSpectrumDefect = async (defectId: string): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!defectId) {
    result = Promise.reject(new Error('Не задан diagnosticId или  elementGuideId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      allDefectsForElement.data = allDefectsForElement.data.filter((item) => item.id !== defectId);
      result = Promise.resolve();
    }
  } else {
    result = fetchData({
      url: DELETE_SPECTRUM_DEFECT(defectId),
      method: IHttpMethods.DELETE,
      name: 'deleteSpectrumDefect',
      emptyResponse: true,
    });
  }

  const t1 = performance.now();
  console.log(`deleteSpectrumDefect took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getSpectrumAllSummaryList = async (diagnosticId: string): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId) {
    result = Promise.reject(new Error('Не задан diagnosticId или  elementGuideId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(summaryElements);
    }
  } else {
    result = fetchData({
      url: GET_ALL_SUMMARY(diagnosticId),
      method: IHttpMethods.GET,
      name: 'getSpectrumAllSummaryList',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getSpectrumAllSummaryList took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getSpectrumElementSummary = async (
  diagnosticId: string,
  guideParentId: string,
): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId || !guideParentId) {
    result = Promise.reject(new Error('Не задан diagnosticId или  elementGuideId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      console.log(
        '%c getSpectrumElementSummary summaryElements = ',
        'color: orange',
        summaryElements,
      ); //TODO - delete vvtu
      //@ts-ignore
      //@ts-ignore
      result = Promise.resolve(getElementSummary);
    }
  } else {
    result = fetchData({
      url: GET_ELEMENT_SUMMARY(diagnosticId, guideParentId),
      method: IHttpMethods.GET,
      name: 'getSpectrumElementSummary',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getSpectrumElementSummary took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const updateSpectrumDefectSummary = async ({
  diagnosticId,
  element,
  summaryText,
}: {
  diagnosticId: string;
  element: ISummaryElement | undefined;
  summaryText: string;
}) => {
  const t0 = performance.now();
  let result;
  if (!diagnosticId || !element) {
    result = Promise.reject(new Error('Не задан diagnosticId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve();
    }
  } else {
    if (element?.summaryId) {
      if (summaryText) {
        result = fetchData({
          url: UPDATE_SPECTRUM_DEFECT_SUMMARY(element.summaryId),
          method: IHttpMethods.PUT,
          body: JSON.stringify({
            diagnosticId,
            elementGuideParentId: element.elementGuideParentId,
            summaryText,
          }),
          name: 'updateSpectrumDefectSummary',
          emptyResponse: true,
        });
      } else {
        result = fetchData({
          url: DELETE_SPECTRUM_DEFECT_SUMMARY(element.summaryId),
          method: IHttpMethods.DELETE,
          body: JSON.stringify({
            diagnosticId,
            elementGuideParentId: element.elementGuideParentId,
            summaryText,
          }),
          name: 'updateSpectrumDefectSummary',
          emptyResponse: true,
        });
      }
    } else {
      if (summaryText) {
        result = fetchData({
          url: CREATE_SPECTRUM_DEFECT_SUMMARY,
          method: IHttpMethods.POST,
          body: JSON.stringify({
            diagnosticId,
            elementGuideParentId: element.elementGuideParentId,
            summaryText,
          }),
          name: 'updateSpectrumDefectSummary',
          emptyResponse: true,
        });
      } else {
        result = Promise.reject(new Error('оба параментра   (summaryId, summaryText)  не заданы'));
      }
    }
  }

  const t1 = performance.now();
  console.log(`updateSpectrumDefectSummary took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const updateSubelementStatus = async ({
  subElementId,
  status,
}: {
  subElementId: string;
  status: boolean;
}) => {
  const t0 = performance.now();
  let result;
  if (!subElementId) {
    result = Promise.reject(new Error('updateSubelementStatus: Не задан subElementId'));

    return result;
  }

  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve(true);
    }
  } else {
    result = fetchData({
      url: UPDATE_SUBELEMENT_STATUS_URL(subElementId, status),
      method: IHttpMethods.PUT,
      name: 'updateSubelementStatus',
      emptyResponse: true,
    });
  }

  const t1 = performance.now();
  console.log(`updateSubelementStatus took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getFrequencyPortraitsColors = async (): Promise<any> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve({});
    }
  } else {
    result = fetchData({
      url: GET_DIAGNOSTIC_FREQUENCY_PORTRAITS_COLORS_URL,
      method: IHttpMethods.GET,
      name: 'getFrequencyPortraitsColors',
      emptyResponse: false,
    });
  }

  const t1 = performance.now();
  console.log(`getFrequencyPortraitsColors took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};

export const getFrequencyPortraits = async (
  diagnosticId: string,
  elementGuideId: string,
  freqElementId: string,
): Promise<{ portraits: IMAttrix[] }> => {
  const t0 = performance.now();
  let result;
  if (IS_SERVER_MOCKED) {
    await delay(Math.floor(Math.random() * BASE_TIME) + MIN_TIME);
    const err = probableError(0.01);
    if (err) {
      result = Promise.reject(new Error(err));
    } else {
      result = Promise.resolve({});
    }
  } else {
    result = fetchData({
      url: GET_DIAGNOSTIC_FREQUENCY_PORTRAITS_URL(diagnosticId, elementGuideId, freqElementId),
      method: IHttpMethods.GET,
      name: 'getFrequencyPortraits',
      emptyResponse: false,
      timeoutToAbort: 35 * 60 * 1000,
    });
  }

  const t1 = performance.now();
  console.log(`getFrequencyPortraits took ${(t1 - t0).toFixed(2)} milliseconds.`);

  return result;
};
