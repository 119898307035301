import { useForm } from 'react-hook-form';

import FieldDropdownWithLabelAndValue from 'components/fields/field-dropdown-with-label-and-value';
import { IOption } from 'interfaces';

export function StandAlonDropdown({
  label,
  options,
  name,
  setName,
  placeholder,
  disabled,
  unicFieldName,
}: {
  label: string;
  options: IOption[];
  name: string | undefined;
  setName: (value?: string) => void;
  placeholder?: string;
  disabled?: boolean;
  unicFieldName: string;
}) {
  const form = useForm({
    defaultValues: {
      [unicFieldName]: name,
    },
    reValidateMode: 'onChange',
  });

  return (
    <form autoComplete="off">
      <FieldDropdownWithLabelAndValue<any>
        disabled={disabled}
        placeholder={placeholder}
        fieldName={unicFieldName}
        label={label}
        form={form}
        options={options}
        onFieldChange={(a) => {
          setName(a);
        }}
      />
    </form>
  );
}
